import Logo_w from '../../inc/images/logo_w.png'
import Logo_d from '../../inc/images/logo_wb.png'
import MinLogo from '../../inc/images/flaticon.png'

export const TropicalSunsetTheme = (mode, changeMode) => ({
    palette: {
        logo: mode === 'light' ? Logo_w : Logo_d,
        minLogo: MinLogo,
        mode: mode,
        changeMode: changeMode,
        ...(mode === 'light' ? {
            primary: {
                main: '#ff6347',
                light: '#ff7f66',
                dark: '#cc4f3b',
            },
            secondary: {
                main: '#ff1493',
                light: '#ff33a1',
                dark: '#cc117a',
            },
            tertiary: {
                main: '#ffeb3b',
                light: '#ffef62',
                dark: '#e6d12b',
            },
            background: {
                default: '#fff8e1',
                paper: '#fff3cc',
                inverse: '#141414',
            },
            text: {
                main: '#ff6347',
                primary: '#ff6347',
                secondary: '#4d4d4d',
                disabled: '#c0bcbb',
                inverse: '#ffffff',
                onPrimary: '#000000',
                onDrawer: '#B4B4B4'
            },
        } : {
            primary: {
                main: '#cc4f3b',
                light: '#e65f47',
                dark: '#993c2d',
            },
            secondary: {
                main: '#cc117a',
                light: '#e6338f',
                dark: '#991160',
            },
            tertiary: {
                main: '#e6c200',
                light: '#ffd700',
                dark: '#b39500',
            },
            background: {
                default: '#1b1b1b',
                paper: '#292929',
                inverse: '#f5f5f5',
            },
            text: {
                main: '#ffffff',
                primary: '#ffffff',
                secondary: '#cccccc',
                disabled: '#a6a6a6',
                inverse: '#1b1b1b',
                onPrimary: '#000000',
                onDrawer: '#B4B4B4'
            },
        })
    },
});
