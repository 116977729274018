import { useTheme, Button } from "@mui/material"

export function ButtonTitle({ text, func, funcParameters, background, size }) {
    const theme = useTheme()
    const style = {
        background: background ? background : theme.palette.primary.main,
        fontWeight: 'bold',
        color: theme.palette.text.onPrimary,
        textTransform: 'none',
        '&:hover': {
            background: theme.palette.tertiary.main,
            color: theme.palette.text.onPrimary
        }
    }
    return (
        <Button sx={style} size={size ? size : 'large'} type='submit' onClick={() => func(funcParameters)}>{text}</Button>
    )
}
export function ButtonSubmit({ text, func, funcParameters, background, backgroundHover, color, colorHover, width, fontWeight, padding }) {
    const theme = useTheme()
    const style = {
        background: background ? background : theme.palette.primary.main,
        fontWeight: fontWeight ? 'bold' : 'regular',
        color: color ? color : theme.palette.text.onPrimary,
        textTransform: 'none',
        padding: padding ? padding : 1.5,
        width: width ? width : 90,
        '&:hover': {
            background: backgroundHover ? backgroundHover : theme.palette.tertiary.main,
            color: colorHover ? colorHover : theme.palette.text.onPrimary
        }
    }
    return (
        <Button sx={style} type='submit' onClick={() => func(funcParameters)}>{text}</Button>
    )
}