import { Typography, Box, useTheme, IconButton, Badge, Popover, List, ListItem, ListItemText, Tooltip } from "@mui/material";
import { getIcon } from "../icons";
import { createTheme, ThemeProvider } from "@mui/system";
import { common, grey } from "@mui/material/colors";
import { useState } from "react";

export const ActionButtons = () => {
    const theme = useTheme();
    const [notificationsAnchorEl, setNotificationsAnchorEl] = useState(null);
    const [notifications, setNotifications] = useState([])
    const handleOpenNotifications = (event) => {
        setNotificationsAnchorEl(event.currentTarget);
    };
    const notificationsOpen = Boolean(notificationsAnchorEl);

    const handleCloseNotifications = () => {
        setNotificationsAnchorEl(null);
    };

    const handleToggleFullscreen = () => {
        // Lógica para alternar fullscreen
        if (!document.fullscreenElement) {
            document.documentElement.requestFullscreen();
        } else {
            if (document.exitFullscreen) {
                document.exitFullscreen();
            }
        }
    };
    const customTheme = createTheme({
        palette: {
            primary: { main: theme.palette.primary.main },
            action: {
                active: theme.palette.primary.main,
            },
            grey: grey,
            common: common
        },
    });

    return (
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 3 }}>
            <Tooltip title={'Tela Cheia'} placement='bottom'>
                <IconButton onClick={handleToggleFullscreen} sx={{ background: theme.palette.primary.light, borderRadius: 1 }}>
                    {getIcon(67, theme.palette.primary.main)}
                </IconButton>
            </Tooltip>
            <ThemeProvider theme={customTheme}>
                <Tooltip title={'Notificações'} placement='bottom'>
                    <IconButton onClick={handleOpenNotifications} sx={{ background: theme.palette.tertiary.light, borderRadius: 1 }}>
                        <Badge badgeContent={<Box sx={{ display: 'flex', alignItems: 'center', background: 'red', justifyContent: 'center', borderRadius: 10, padding: 0.2, paddingLeft: 0.8, paddingRight: 0.8 }}>
                            <Typography variant="body2" sx={{ color: 'white', fontSize: 12 }}>{notifications.length}</Typography>
                        </Box>} sx={{ color: theme.palette.primary.main }}>
                            {getIcon(66, theme.palette.tertiary.main)}
                        </Badge>
                    </IconButton>
                </Tooltip>
            </ThemeProvider>
            <Popover
                open={notificationsOpen}
                anchorEl={notificationsAnchorEl}
                onClose={handleCloseNotifications}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <Box sx={{ width: 300 }}>
                    <List>
                        {notifications.length === 0 ?
                            <Box sx={{ padding: 2 }}>
                                <Typography>Não há notificações</Typography>
                            </Box>
                            : notifications.map((e, idx) => (
                                <ListItem key={idx}>
                                    <ListItemText primary={e.nome} />
                                </ListItem>
                            ))}
                    </List>
                </Box>
            </Popover>
        </Box>
    );
};