import React, { useState, useContext, useEffect } from 'react';
import { useTheme, Box, Typography, Divider, Collapse, Input, Switch, createTheme, ThemeProvider } from '@mui/material';
import { ModalInfo } from '../../../../../components/modalInfo';
import { User } from '../../../../../contexts/user';
import MAPI from '../../../../../api/mainApi';
import { UpComp } from '../../../../../components/upComp';
import { getIcon } from '../../../../../components/icons';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import moment from "moment";
import 'moment/locale/pt-br'

export function Configuracoes({ slideAtivo, data, updateData, mensagem }) {
    const theme = useTheme();
    const user = useContext(User);
    const customTheme = createTheme({
        palette: {
            primary: { main: theme.palette.primary.main },
        },
    });
    //estados
    const [MInfo, setMInfo] = useState({
        visible: false,
        title: '',
        subtitle: '',
        canDesactive: true,
        icon: '',
        funcS: () => null
    });

    const [configs, setConfigs] = useState({
        tempo: 1,
        imediato: true,
        date: moment().format('YYYY-MM-DD HH:mm:ss'),
        opt_out: false,
        fluxo: false
    })

    function updateModalInfo(visible, canDesactive, title, subtitle, icon, func) {
        const newMInfo = { ...MInfo };
        newMInfo.visible = visible;
        newMInfo.canDesactive = canDesactive;
        newMInfo.title = title;
        newMInfo.subtitle = subtitle;
        newMInfo.icon = icon;
        newMInfo.funcS = func;
        setMInfo(newMInfo);
    }

    function changeConfig(value, type) {
        if (type === 'tempo' && (parseInt(value) < 1 || !value)) return
        const newConf = { ...configs }
        newConf[type] = value
        setConfigs(newConf)
    }

    function changeData(date) {
        const newConfi = { ...configs }
        newConfi['date'] = moment(date).format('YYYY-MM-DD HH:mm:ss')
        setConfigs(newConfi)
    }

    const textStyle = {
        color: theme.palette.text.primary,
        fontWeight: 'bold'
    }

    useEffect(() => {
        if (slideAtivo === 2) {
            updateData(configs, 'configs')
        }
    }, [configs, slideAtivo])

    return (
        <Box sx={{
            flex: 1,
        }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3, maxHeight: 400, padding: 2, paddingBottom: 0 }}>
                <Box>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Typography sx={textStyle}>Enviar imediatamente?</Typography>
                        <ThemeProvider theme={customTheme}>
                            <Switch checked={configs.imediato} onChange={() => changeConfig(!configs.imediato, 'imediato')} />
                        </ThemeProvider>
                    </Box>
                    <Collapse in={!configs.imediato}>
                        <Box>
                            <ByData date={configs.date} changeData={changeData} />
                        </Box>
                    </Collapse>
                </Box>
                <Divider />
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography sx={textStyle}>Encaminhar para fluxo ao responder?</Typography>
                    <ThemeProvider theme={customTheme}>
                        <Switch disabled />
                    </ThemeProvider>
                </Box>
                <Divider />
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography sx={textStyle}>Ativar opt-out?</Typography>
                    <ThemeProvider theme={customTheme}>
                        <Switch checked={configs.opt_out} onChange={() => changeConfig(!configs.opt_out, 'opt_out')} />
                    </ThemeProvider>
                </Box>
                <Divider />
                <Box>
                    <Typography sx={textStyle}>Tempo entre envios (segundos)</Typography>
                    <Input type='number' value={configs.tempo} onChange={(value) => changeConfig(value.target.value, 'tempo')} />
                </Box>
            </Box>
            <ModalInfo hideModal={() => updateModalInfo(false)} canDesactive={MInfo.canDesactive} visible={MInfo.visible} title={MInfo.title} subtitle={MInfo.subtitle} icon={MInfo.icon} funcS={MInfo.funcS} />
        </Box>
    );
}

function ByData({ date, changeData }) {
    return (
        <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="pt-br" >
            <DemoContainer
                components={['MobileDatePicker']}
            >
                <MobileDateTimePicker disabled={false} label={'Início'} defaultValue={moment()} localeText={{ cancelButtonLabel: 'Cancelar', toolbarTitle: 'Selecionar Data' }}
                    value={moment(date, 'YYYY-MM-DD HH:mm:ss')}
                    onChange={(value) => {
                        changeData(value, 'init')
                    }}
                />
            </DemoContainer>
        </LocalizationProvider>
    )
}