import Logo_w from '../../inc/images/logo_w.png'
import Logo_d from '../../inc/images/logo_wb.png'
import MinLogo from '../../inc/images/flaticon.png'

export const EarthyTerracottaLigthTheme = (mode, changeMode) => ({
    palette: {
        logo: mode === 'light' ? Logo_w : Logo_d,
        minLogo: MinLogo,
        mode: mode,
        changeMode: changeMode,
        ...(mode === 'light' ? {
            primary: {
                main: '#e07b39',
                light: '#e69566',
                dark: '#a25d2d',
            },
            secondary: {
                main: '#d4a373',
                light: '#dfb68f',
                dark: '#9f7355',
            },
            tertiary: {
                main: '#f5deb3',
                light: '#f8e8c0',
                dark: '#c1b08b',
            },
            background: {
                default: '#fffaf0',
                paper: '#fef3e0',
                inverse: '#141414',
            },
            text: {
                main: '#3e2723',
                primary: '#3e2723',
                secondary: '#4d4d4d',
                disabled: '#c0bcbb',
                inverse: '#ffffff',
                onPrimary: '#000000',
                onDrawer: '#B4B4B4'
            },
        } : {
            primary: {
                main: '#8b4513',
                light: '#a0522d',
                dark: '#5a2c10',
            },
            secondary: {
                main: '#6b4423',
                light: '#8b5742',
                dark: '#472f16',
            },
            tertiary: {
                main: '#cc9966',
                light: '#e6b380',
                dark: '#996633',
            },
            background: {
                default: '#2b1b18',
                paper: '#3e2723',
                inverse: '#f5f5f5',
            },
            text: {
                main: '#ffffff',
                primary: '#ffffff',
                secondary: '#cccccc',
                disabled: '#a6a6a6',
                inverse: '#2b1b18',
                onPrimary: '#000000',
                onDrawer: '#B4B4B4'
            },
        })
    },
});
