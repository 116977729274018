import { useState, useContext, useEffect } from 'react';
import { useTheme, Box, Modal, Typography } from '@mui/material';
import { ModalInfo } from '../../../components/modalInfo';
import { User } from '../../../contexts/user';
import { CTextField } from '../../../components/TextField';
import { UserAvatar } from '../../conexoes/components/avatar';
import { ButtonSubmit } from '../../../components/buttons';
import MAPI from '../../../api/mainApi';
import { TestaEMAIL } from '../../../components/validaCampo';
import { FullMenu } from '../../../components/menu';

export function EditOp({ visible, hideModal, updateData, prevData, setores }) {
    const theme = useTheme();
    const user = useContext(User);
    //estados
    const [data, setData] = useState(dataInit)
    const [MInfo, setMInfo] = useState({
        visible: false,
        title: '',
        subtitle: '',
        canDesactive: true,
        icon: '',
        funcS: () => null
    });

    function updateModalInfo(visible, canDesactive, title, subtitle, icon, func) {
        const newMInfo = { ...MInfo };
        newMInfo.visible = visible;
        newMInfo.canDesactive = canDesactive;
        newMInfo.title = title;
        newMInfo.subtitle = subtitle;
        newMInfo.icon = icon;
        newMInfo.funcS = func;
        setMInfo(newMInfo);
    }

    function handleSubmit(type) {
        if (type === 'Sair') handleDismiss()
        if (type === 'Salvar') saveData()
    }
    function handleDismiss() {
        if (hideModal) {
            updateModalInfo(false)
            setData(dataInit)
            hideModal()
        }
    }
    function onChangeText(value, type) {
        const newData = { ...data }
        newData[type] = value
        setData(newData)
    }
    function handleAvatar(base64) {
        const newData = { ...data }
        newData.avatarBase64 = base64
        setData(newData)
    }

    async function saveData() {
        if ([data.nome, data.email, data.senha].includes('')) {
            updateModalInfo(true, true, 'Atenção', 'Todos os campos devem ser preenchidos.', 'exclamation')
            return
        }
        if (!TestaEMAIL(data.email)) {
            updateModalInfo(true, true, 'Atenção', 'E-mail inválido preenchido.', 'exclamation')
            return
        }
        updateModalInfo(true, true, 'Salvando', 'Tentando salvar novo operador.', 'loading')
        const newData = { ...data }
        if (!newData.avatarBase64) delete newData.avatarBase64
        else newData.avatarBase64 = newData.avatarBase64.replace('data:image/png;base64,', '')
        const TRYUpload = await MAPI('operadores/edit', 'PUT', newData, user?.data?.token)
        if (TRYUpload.status !== 200) {
            const message = TRYUpload?.apiReturn?.message ? TRYUpload?.apiReturn?.message : 'Ocorreu um erro ao criar operador.'
            updateModalInfo(true, true, 'Atenção', message, 'exclamation')
            return
        }
        if (TRYUpload?.apiReturn?.apiReturn?.id) {
            updateModalInfo(true, true, 'Atenção', 'Id não recebido. Recarregue a página pra verificar alterações.', 'exclamation')
            return
        }
        newData.avatar = TRYUpload?.apiReturn?.apiReturn?.avatar ? TRYUpload?.apiReturn?.apiReturn?.avatar : ''
        newData.setor = newData?.setor?.id
        updateData(newData)
        handleDismiss()
    }
    function changeSetor(value) {
        const findSetor = setores.filter((e) => e.id === value.props.value)
        if (findSetor.length === 0) return
        const newD = { ...data }
        newD.setor = findSetor[0]
        setData(newD)
    }
    useEffect(() => {
        if (typeof prevData === 'object') {
            const newData = { ...data }
            newData.nome = prevData.nome
            newData.email = prevData.email
            newData.avatar = prevData.avatar
            newData.id = prevData.id
            console.log(prevData)
            if (setores && prevData?.setor) {
                const setor = setores.filter((e) => e.id === prevData?.setor)
                if (setor?.length > 0) {
                    newData.setor = setor[0]
                }
            }
            setData(newData)
        }
    }, [prevData])

    return (
        <Modal open={visible} onClose={handleDismiss} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 2 }} slotProps={{
            backdrop: {
                sx: {
                    backgroundColor: theme.palette.mode === 'dark' ? 'rgba(250,255,255, 0.2)' : 'rgba(0,0,0, 0.4)'
                }
            }
        }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', height: 'auto', width: 600, background: theme.palette.background.default, outline: 'none' }}>
                <Box sx={{ background: theme.palette.background.paper, padding: 5 }}>
                    <Typography sx={{ fontWeight: 'bold', color: theme.palette.text.primary, fontSize: 30 }}>Novo Operador</Typography>
                    <Typography sx={{ color: theme.palette.text.primary, fontSize: 18 }}>Preencha os campos a baixo para criar um novo operador.</Typography>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 5, padding: 3 }}>
                    {Object.keys(data).map((e) => {
                        if (['avatarBase64', 'id', 'setor'].includes(e)) return
                        if (e === 'avatar') {
                            return (
                                <Box key={e} sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <UserAvatar avatar={data.avatar} avatarBase64={data.avatarBase64} editable={true} funcReturn={handleAvatar} />
                                </Box>
                            )
                        }
                        return (
                            <CTextField key={e} prevData={data} type={e} onChangeText={onChangeText} label={converInput[e]} value={data[e]} security={e === 'senha' ? true : false} />
                        )
                    })}
                    {setores && (
                        <Box>
                            <Typography sx={{ color: theme.palette.text.primary }}>Setor</Typography>
                            <FullMenu options={setores} param='nome' title='Setor' isTitle selected={data.setor} handleChange={changeSetor} />
                        </Box>
                    )}
                </Box>
                <Box sx={{ display: 'flex', padding: 3, gap: 2 }}>
                    {['Salvar', 'Sair'].map((e, idx) => {
                        return (
                            <ButtonSubmit key={e} text={e} background={idx === 1 ? theme.palette.background.paper : theme.palette.primary.main} color={idx === 1 ? theme.palette.text.primary : theme.palette.text.onPrimary} func={handleSubmit} funcParameters={e} />
                        )
                    })}
                </Box>
                <ModalInfo hideModal={() => updateModalInfo(false)} canDesactive={MInfo.canDesactive} visible={MInfo.visible} title={MInfo.title} subtitle={MInfo.subtitle} icon={MInfo.icon} funcS={MInfo.funcS} />
            </Box>
        </Modal>
    );
}

const dataInit = {
    id: '',
    avatar: '',
    avatarBase64: '',
    nome: '',
    email: '',
    senha: '',
    setor: null
}

const converInput = {
    nome: 'Nome',
    email: 'E-mail',
    senha: 'Senha'
}