import React, { useState, useContext, useEffect } from 'react';
import { useTheme, Box, Modal, Typography, Stepper, Step, StepLabel } from '@mui/material';
import EmojiPicker from 'emoji-picker-react';
import { ModalInfo } from '../../../../components/modalInfo';
import { User } from '../../../../contexts/user';
import { CMultiTextField, CTextField } from '../../../../components/TextField';
import { ButtonSubmit } from '../../../../components/buttons';
import MAPI from '../../../../api/mainApi';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Autoplay, EffectFade } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import "swiper/css/grid";
import 'swiper/swiper-bundle.css';
import { Tipos } from './novaMensagem/tipos';
import { Mensagem } from './novaMensagem/mensagem';
import { CreateEmail } from './novaMensagem/createEmail';
import { ChoseModel } from './novaMensagem/choseModel';

export function NovaMensagem({ visible, updateSMensagem, pushMessages, mensagem, editMessages }) {
    const theme = useTheme();
    const user = useContext(User);
    //estados
    const [MInfo, setMInfo] = useState({
        visible: false,
        title: '',
        subtitle: '',
        canDesactive: true,
        icon: '',
        funcS: () => null
    });
    const [data, setData] = useState(dataInit)

    const [swiper, setSwiper] = useState(null);
    const params = {
        loop: true,
        modules: [Pagination, Navigation, Autoplay, EffectFade],
        effects: 'slide',
        allowTouchMove: false
    };
    const steps = ['Tipo', 'Mensagem'];
    const [activeSlideIndex, setActiveSlideIndex] = useState(0);
    const [choseEmail, setChoseEmail] = useState(false)
    const [createEmail, setCreateEmail] = useState({
        visible: false,
        model: null
    })
    function updateNewEmail(visible, model) {
        const newE = { ...createEmail }
        newE.visible = visible ? visible : false
        newE.model = model ? model : null
        setCreateEmail(newE)
        if (!visible) {
            hideModal()
        }
    }

    function updateModalInfo(visible, canDesactive, title, subtitle, icon, func) {
        const newMInfo = { ...MInfo };
        newMInfo.visible = visible;
        newMInfo.canDesactive = canDesactive;
        newMInfo.title = title;
        newMInfo.subtitle = subtitle;
        newMInfo.icon = icon;
        newMInfo.funcS = func;
        setMInfo(newMInfo);
    }
    function hideModal() {
        updateSMensagem()
    }
    function handleSubmit(type) {
        if (type === 'Sair') {
            hideModal()
            return
        }
        if (type === 'Próximo') {
            if (!data.tipo?.id) {
                updateModalInfo(true, true, 'Atenção', 'Ao menos um tipo precisa ser selecionado.', 'exclamation')
                return
            }
            if (data.tipo.id === 4) {
                if (!data.nome) {
                    updateModalInfo(true, true, 'Atenção', 'Nome precisa ser preenchido pra continuar.', 'exclamation')
                    return
                }
                if (data.id) {
                    console.log(data)
                    updateNewEmail(true, data)
                    return
                }
                setChoseEmail(true)
                return
            }
            nextSlide()
        }
        if (type === 'Voltar') {
            prevSlide()
        }
        if (type === 'Salvar') {
            save()
        }
    }
    async function save() {
        if (data.nome.length <= 3) {
            updateModalInfo(true, true, 'Atenção', 'Nome precisa ter mais do que 3 caracteres', 'exclamation')
            return
        }
        const newData = { ...data }
        if (newData.tipo?.id === 1) {
            delete newData.arquivos
        }
        updateModalInfo(true, false, 'Atenção', 'Salvando mensagem', 'loading')
        const endpoint = mensagem?.id ? 'editMessage' : 'saveMessage'
        const method = mensagem?.id ? 'PUT' : 'POST'
        const TRYSave = await MAPI(`disparador/${endpoint}`, method, data, user?.data?.token)
        if (TRYSave?.status !== 200) {
            const message = TRYSave?.apiReturn?.message ? TRYSave?.apiReturn?.message : mensagem.id ? 'Ocorreu um erro ao editar a mensagem.' : 'Ocorreu um erro ao salvar a mensagem.'
            updateModalInfo(true, true, 'Atenção', message, 'exclamation')
            return
        }
        const returnData = TRYSave?.apiReturn?.apiReturn
        if (!returnData?.id) {
            updateModalInfo(true, true, 'Atenção', 'Ocorreu um erro ao salvar sua mensagem.', 'exclamation')
            return
        }
        updateModalInfo(false)
        if (mensagem) {
            editMessages(returnData)
            return
        }
        pushMessages(returnData)
    }
    function updateData(value, tipo) {
        const newData = { ...data }
        newData[tipo] = value
        setData(newData)
    }

    function nextSlide() {
        swiper.slideTo(activeSlideIndex + 1)
    }
    function prevSlide() {
        swiper.slideTo(activeSlideIndex - 1)
    }

    useEffect(() => {
        if (mensagem) {
            const newData = { ...data }
            newData.nome = mensagem.nome
            newData.id = mensagem.id
            newData.tipo = {
                id: mensagem.tipo,
                nome: mensagem.tipo_nome,
                descricao: mensagem.tipo_descricao,
                icon: mensagem.tipo_icon,
            }
            if (![1, 4].includes(mensagem.tipo)) {
                newData.arquivos = mensagem.mensagem ? mensagem.mensagem : []
            }
            else {
                newData.mensagem = mensagem.mensagem
            }
            setData(newData)
            return
        }
        setData(dataInit)
    }, [visible, mensagem])


    return (
        <Modal open={visible} onClose={() => hideModal ? hideModal() : null} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} slotProps={{
            backdrop: {
                sx: {
                    backgroundColor: theme.palette.mode === 'dark' ? 'rgba(250,255,255, 0.2)' : 'rgba(0,0,0, 0.4)'
                }
            }
        }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', height: 790, width: '95%', maxWidth: 800, background: theme.palette.background.default, outline: 'none' }}>
                <Box sx={{ background: theme.palette.background.paper, padding: 5 }}>
                    <Typography sx={{ fontWeight: 'bold', color: theme.palette.text.primary, fontSize: 30 }}>{'Modelo de mensagem'}</Typography>
                    <Typography sx={{ color: theme.palette.text.primary, fontSize: 18 }}>Preencha os campos para ajustar seu modelo.</Typography>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3, padding: 5, justifyContent: 'space-between', height: '100%' }}>
                    <Box sx={{ maxHeight: 650 }}>
                        <Stepper activeStep={activeSlideIndex} alternativeLabel>
                            {steps.map((label, index) => (
                                <Step key={label}>
                                    <StepLabel>{label}</StepLabel>
                                </Step>
                            ))}
                        </Stepper>
                        <Box sx={{ padding: 2, display: 'flex', paddingLeft: 3 }}>
                            <CTextField prevData={data} label={'Nome do modelo'} value={data.nome} width={'100%'} onChangeText={updateData} type={'nome'} />
                        </Box>
                        <Box sx={{ display: 'flex', paddingTop: 2 }}>
                            <Swiper {...params} onSlideChange={(swiper) => setActiveSlideIndex(swiper.activeIndex)} onSwiper={setSwiper}>
                                <SwiperSlide><Tipos slideAtivo={activeSlideIndex} prevData={data} updateData={updateData} /></SwiperSlide>
                                <SwiperSlide><Mensagem prevData={data} updateData={updateData} /></SwiperSlide>
                            </Swiper>
                        </Box>
                    </Box>
                    <Box sx={{ display: 'flex', gap: 2 }}>
                        {['Voltar', 'Próximo', 'Salvar', 'Sair',].map((e, idx) => {
                            if (idx === 2 && activeSlideIndex !== 1) return
                            if (idx === 0 && activeSlideIndex === 0) return
                            if (idx === 1 && activeSlideIndex === 1) return
                            const background = [0, 3].includes(idx) ? theme.palette.background.paper : idx === 1 ? theme.palette.primary.main : theme.palette.secondary.main
                            const color = [0, 3].includes(idx) ? theme.palette.text.primary : theme.palette.text.onPrimary
                            return (
                                <ButtonSubmit key={e} text={e} background={background} color={color} func={handleSubmit} funcParameters={e} />
                            )
                        })}
                    </Box>
                </Box>
                <ChoseModel visible={choseEmail} updateNewEmail={updateNewEmail} dismiss={() => setChoseEmail(false)} />
                <CreateEmail visible={createEmail.visible} model={createEmail.model} updateNewEmail={updateNewEmail} editMessages={editMessages} pushMessages={pushMessages} nome={data.nome} />
                <ModalInfo hideModal={() => updateModalInfo(false)} canDesactive={MInfo.canDesactive} visible={MInfo.visible} title={MInfo.title} subtitle={MInfo.subtitle} icon={MInfo.icon} funcS={MInfo.funcS} />
            </Box>
        </Modal>
    );
}

const dataInit = {
    tipo: null,
    nome: '',
    mensagem: '',
    arquivos: []
}