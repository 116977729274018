import { useState, useEffect } from 'react'
import { Box, Typography, useTheme, Button, Checkbox } from '@mui/material'
import Logo from '../inc/images/logo_w.png'
import MAPI from '../api/mainApi'
import { ModalInfo } from '../components/modalInfo'


export function Descadastrar() {
    const theme = useTheme()
    const [isAcept, setIsAcept] = useState(false)
    const [error, setError] = useState(null)
    const [MInfo, setMInfo] = useState({
        visible: false,
        title: '',
        subtitle: '',
        canDesactive: true,
        icon: '',
        funcS: () => null
    });
    async function trySave() {
        const queryParams = new URLSearchParams(window.location.search);
        const contato = queryParams.get('contato');
        const tipo = queryParams.get('tipo');
        const cliente = queryParams.get('cliente');
        if (!contato || !tipo || !cliente) {
            updateModalInfo(true, true, 'Atenção', 'URL inválida.', 'exclamation')
            return
        }
        updateModalInfo(true, false, 'Salvando', 'Salvando suas informações de descredenciamento', 'loading')
        const get = await MAPI(`descadastrar/descadastrarCliente?contato=${contato}&tipo=${tipo}&cliente=${cliente}`, 'GET', null, null)
        if (get?.status !== 200) {
            const message = get?.apiReturn?.message ? get?.apiReturn?.message : 'Ocorreu um erro ao processar a requisição.'
            updateModalInfo(true, true, 'Atenção', message, 'exclamation')
            return
        }
        updateModalInfo(true, true, 'Sucesso', 'Você não receberá mais mensagens nossas. Obrigado pela participação.', 'sucess')
    }
    function updateModalInfo(visible, canDesactive, title, subtitle, icon, func) {
        const newMInfo = { ...MInfo };
        newMInfo.visible = visible;
        newMInfo.canDesactive = canDesactive;
        newMInfo.title = title;
        newMInfo.subtitle = subtitle;
        newMInfo.icon = icon;
        newMInfo.funcS = func;
        setMInfo(newMInfo);
    }
    return (
        <Box sx={{ display: 'flex', padding: 2, flexDirection: 'column', gap: 3, flexGrow: 1, justifyContent: 'flex-start', alignItems: 'center', background: theme.palette.background.paper }}>
            <img src={Logo} style={{ width: 100, height: 'auto' }} />
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, width: '100%', height: 'auto', maxHeight: 600, maxWidth: 500, background: theme.palette.background.default, padding: 4, borderRadius: 3, }}>
                <Box>
                    <Typography sx={{ color: theme.palette.text.primary, fontWeight: 'bold', fontSize: 25 }}>Descadastrar</Typography>
                    <Typography sx={{ color: theme.palette.text.primary }}>Ao clicar no botão abaixo você não receberá mais campanhas de clientes It's Chat. </Typography>
                </Box>
                {error ?
                    <Box sx={{ background: theme.palette.secondary.main, padding: 2 }}>
                        <Typography sx={{ color: theme.palette.text.onPrimary }}>{error}</Typography>
                    </Box>
                    :
                    <>
                        <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                            <Checkbox checked={isAcept} onClick={() => setIsAcept(!isAcept)} />
                            <Typography>Confirmo que não quero mais receber campanhas de clientes It's Chat.</Typography>
                        </Box>
                        <Box sx={{ width: '100%' }}>
                            <Button onClick={trySave} disabled={!isAcept} sx={{ background: !isAcept ? theme.palette.background.paper : theme.palette.primary.main, width: '100%', color: !isAcept ? theme.palette.text.disabled : theme.palette.text.onPrimary }}>Descadastrar</Button>
                        </Box>
                    </>
                }
            </Box>
            <ModalInfo hideModal={() => updateModalInfo(false)} canDesactive={MInfo.canDesactive} visible={MInfo.visible} title={MInfo.title} subtitle={MInfo.subtitle} icon={MInfo.icon} funcS={MInfo.funcS} />
        </Box>
    )
}