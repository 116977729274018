import { useState, useContext, useEffect } from 'react';
import { useTheme, Box, useMediaQuery, Divider } from '@mui/material';
import { ModalInfo } from '../../components/modalInfo';
import { User } from '../../contexts/user';
import { ModuloTitle } from '../../components/moduloTitle';
import { NewSetor } from './components/new';
import MAPI from '../../api/mainApi';
import { ListSetores } from './components/list';

export function Setores({ modulo }) {
    document.title = `Setores - It's Chat`
    const theme = useTheme();
    const user = useContext(User);
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
    const [data, setData] = useState([])
    const [prevSetor, setPrevSetor] = useState(null)
    //estados
    const [MInfo, setMInfo] = useState({
        visible: false,
        title: '',
        subtitle: '',
        canDesactive: true,
        icon: '',
        funcS: () => null
    });

    function pushData(setor) {
        const newD = [...data]
        newD.push(setor)
        setData(newD)
        updateModalInfo(true, true, 'Sucesso', 'Seu setor foi criado.', 'sucess')
    }
    function updateModalInfo(visible, canDesactive, title, subtitle, icon, func) {
        const newMInfo = { ...MInfo };
        newMInfo.visible = visible;
        newMInfo.canDesactive = canDesactive;
        newMInfo.title = title;
        newMInfo.subtitle = subtitle;
        newMInfo.icon = icon;
        newMInfo.funcS = func;
        setMInfo(newMInfo);
    }

    async function getData() {
        updateModalInfo(true, false, 'Carregando', 'Buscando setores', 'loading')
        const get = await MAPI('setores/getSetor', 'GET', null, user?.data?.token)
        if (get?.status !== 200) {
            const message = get?.apiReturn?.message ? get?.apiReturn?.message : 'Ocorreu um erro ao buscar os setores.'
            updateModalInfo(true, true, 'Atenção', message, 'exclamation')
            return
        }
        updateModalInfo(false)
        setData(get?.apiReturn?.apiReturn)
    }

    async function deleteSetor(setor, control) {
        if (!control) {
            updateModalInfo(true, true, 'Atenção', 'Você tem certeza que deseja excluir o setor?', 'question', () => deleteSetor(setor, true))
            return
        }
        updateModalInfo(true, false, 'Excluindo', 'Estamos excluindo seu setor', 'loading')
        const excluir = await MAPI(`setores/deleteSetor/${setor.id}`, 'DELETE', null, user?.data?.token)
        if (excluir.status !== 200) {
            const message = excluir?.apiReturn?.message ? excluir?.apiReturn?.message : 'Ocorreu um erro ao excluir o setor'
            updateModalInfo(true, true, 'Atenção', message, 'exclamation')
            return
        }
        updateModalInfo()
        const newD = data.filter((e) => e.id !== setor.id)
        setData(newD)
    }

    async function updateSetor(setor) {
        setPrevSetor(setor)
    }

    function editSetor(setor) {
        const newD = [...data]
        const findSetor = newD.findIndex((e) => e.id === setor.id)
        if (findSetor === -1) {
            updateModalInfo(true, true, 'Atenção', 'Setor não foi encontrado na lista de setores', 'exclamation')
            return
        }
        newD[findSetor] = setor
        setData(newD)
        updateModalInfo(true, true, 'Sucesso', 'Seu setor foi atualizado.', 'sucess')
        setPrevSetor(null)
    }

    useEffect(() => {
        getData()
    }, [])
    return (
        <Box sx={{
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            height: '100%'
        }}>
            <ModuloTitle icon={modulo.moduloIcon} title={modulo.moduloNome} desc={modulo.moduloDesc} />
            <Box sx={{ display: 'flex', flexDirection: isSmallScreen ? 'column' : 'row', flexGrow: 1, background: theme.palette.background.default, width: '100%', height: '100%' }}>
                <Box sx={{ width: isSmallScreen ? '100%' : '35%' }}>
                    <NewSetor updateModalInfo={updateModalInfo} pushData={pushData} editSetor={editSetor} prevData={prevSetor} />
                </Box>
                <Divider orientation={isSmallScreen ? 'horizontal' : 'vertical'} flexItem />
                <ListSetores setores={data} deleteSetor={deleteSetor} updateSetor={updateSetor} />
            </Box>
            <ModalInfo hideModal={() => updateModalInfo(false)} canDesactive={MInfo.canDesactive} visible={MInfo.visible} title={MInfo.title} subtitle={MInfo.subtitle} icon={MInfo.icon} funcS={MInfo.funcS} />
        </Box>
    );
}