import React, { useState, useContext, useEffect } from 'react';
import { useTheme, Box, createTheme } from '@mui/material';
import { User } from '../../../../../contexts/user';
import { TextSimples } from './mensagem/TextoSimples';
import { ModalInfo } from '../../../../../components/modalInfo';
import { Arquivo } from './mensagem/Arquivos';
import { TextLegenda } from './mensagem/TextoLegenda';

export function Mensagem({ prevData, updateData, slideAtivo }) {
    const theme = useTheme();
    const user = useContext(User);
    const customTheme = createTheme({
        palette: {
            primary: { main: theme.palette.primary.main },
        },
    });
    //estados
    const [MInfo, setMInfo] = useState({
        visible: false,
        title: '',
        subtitle: '',
        canDesactive: true,
        icon: '',
        funcS: () => null
    });

    function updateModalInfo(visible, canDesactive, title, subtitle, icon, func) {
        const newMInfo = { ...MInfo };
        newMInfo.visible = visible;
        newMInfo.canDesactive = canDesactive;
        newMInfo.title = title;
        newMInfo.subtitle = subtitle;
        newMInfo.icon = icon;
        newMInfo.funcS = func;
        setMInfo(newMInfo);
    }

    function saveChanges(value, type) {
        if (type === 'arquivos') {
            if (Array.isArray(value)) {
                updateData(value, type)
                return
            }
            updateData([...prevData.arquivos, value], type)
            return
        }
        updateData(value, type)
    }
    function deleteArquivo(value) {
        const filterArquivos = prevData.arquivos.filter((e) => e.nome !== value.nome)
        updateData(filterArquivos, 'arquivos')
    }
    return (
        <Box sx={{
            flex: 1,
            height: '100%',
            paddingLeft: 2,
            paddingRight: 2,
        }}>
            <Box sx={{ overflowY: 'scroll', height: '100%', padding: 1 }}>
                {prevData?.tipo?.id === 1 ? <TextSimples saveChanges={saveChanges} prevData={prevData} /> : prevData?.tipo?.id === 3 ? <Arquivo deleteArquivo={deleteArquivo} saveChanges={saveChanges} prevData={prevData}  /> : prevData?.tipo?.id === 2 ? <TextLegenda saveChanges={saveChanges} prevData={prevData}  /> : null}
            </Box>
            <ModalInfo hideModal={() => updateModalInfo(false)} canDesactive={MInfo.canDesactive} visible={MInfo.visible} title={MInfo.title} subtitle={MInfo.subtitle} icon={MInfo.icon} funcS={MInfo.funcS} />
        </Box>
    );
}
