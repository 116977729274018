import { useRef } from "react";
import { Box, IconButton, Avatar, useTheme } from "@mui/material";
import { getIcon } from "../../../components/icons";

export function UserAvatar({ avatar, editable, funcReturn, avatarBase64 }) {
    const theme = useTheme();
    const fileInputRef = useRef(null);

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            if (file.type === 'image/png' && file.size <= 10 * 1024 * 1024) {
                const reader = new FileReader();
                reader.onload = () => {
                    const base64String = reader.result;
                    console.log(base64String);
                    funcReturn(base64String);
                };
                reader.readAsDataURL(file);
            } else {
                alert('Por favor, selecione um arquivo PNG dentro do limite de tamanho (10MB)');
            }
        } else {
            alert('Por favor, selecione um arquivo PNG');
        }
    };

    const handleIconClick = () => {
        fileInputRef.current.click();
    };
    return (
        <Box>
            <label htmlFor="upload-image">
                <Box
                    component="div"
                    sx={{
                        position: 'relative',
                        width: 'fit-content',
                        '&:hover .hover-icon': { display: 'block' },
                    }}
                >
                    <Avatar
                        src={avatarBase64 ? avatarBase64 : avatar ? avatar : ''}
                        sx={{
                            width: 100,
                            height: 100,
                            fontSize: '2.5rem',
                            '&:hover ~ .hover-icon': { display: 'block' },
                        }}
                    />
                    {editable && (
                        <>
                            <input
                                type="file"
                                accept="image/png"
                                id="upload-image"
                                ref={fileInputRef}
                                onChange={handleImageChange}
                                style={{ display: 'none' }}
                            />
                            <IconButton
                                className="hover-icon"
                                sx={{
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    transform: 'translate(-50%, -50%)',
                                    backgroundColor: 'rgba(255, 255, 255, 0.8)',
                                    display: 'none',
                                }}
                                onClick={handleIconClick}
                            >
                                {getIcon(34)}
                            </IconButton>
                        </>
                    )}
                </Box>
            </label>
        </Box>
    );
}
