import React, { useState, useContext, useEffect } from 'react';
import { useTheme, Box, Divider, Typography, useMediaQuery } from '@mui/material';
import { ModalInfo } from '../../../components/modalInfo';
import { User } from '../../../contexts/user';
import { ModuloTitle } from '../../../components/moduloTitle';
import MAPI from '../../../api/mainApi';
import { ListTags } from './components/listTags';
import { Newtag } from './components/newTag';
import { EditTag } from './components/editTag';
import { validaEvento } from '../../../Mainfunctions';

export function Tags({ modulo }) {
    document.title = `Con Tags - It's Chat`
    const theme = useTheme();
    const user = useContext(User);
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
    //estados
    const [MInfo, setMInfo] = useState({
        visible: false,
        title: '',
        subtitle: '',
        canDesactive: true,
        icon: '',
        funcS: () => null
    });
    const [tags, setTags] = useState([])
    const [newTagVisible, setNewTagVisible] = useState(false)
    const [editTag, setEditTag] = useState({
        visible: false,
        tag: null
    })

    function updateModalInfo(visible, canDesactive, title, subtitle, icon, func) {
        const newMInfo = { ...MInfo };
        newMInfo.visible = visible;
        newMInfo.canDesactive = canDesactive;
        newMInfo.title = title;
        newMInfo.subtitle = subtitle;
        newMInfo.icon = icon;
        newMInfo.funcS = func;
        setMInfo(newMInfo);
    }
    function pushTag(tag) {
        const newTags = [...tags]
        newTags.push(tag)
        setTags(newTags)
    }
    function changeEditTag(tag) {
        if (!validaEvento(modulo?.permissoes, 'editar')) {
            updateModalInfo(true, true, 'Atenção', 'Você não tem permissão para editar tags', 'exclamation')
            return
        }
        const newEditTag = { ...editTag }
        newEditTag.tag = tag
        setEditTag(newEditTag)
    }
    function updateTag(tag) {
        const newTags = [...tags]
        const findIndex = newTags.findIndex((e) => e.id === tag.id)
        if (findIndex === -1) {
            updateModalInfo(true, true, 'Atenção', 'Algum erro pode ter ocorrido. Recarregue a página pra verificar', 'exclamation')
            return
        }
        newTags[findIndex] = tag
        setTags(newTags)
        changeEditTag(false)
        updateModalInfo(true, true, 'Atenção', 'Tag editada com sucesso.', 'sucess')
    }
    function deleteTag(tag) {
        const newTags = tags.filter((e) => e.id !== tag.id)
        setTags(newTags)
        changeEditTag(false)
        updateModalInfo(true, true, 'Atenção', 'Tag excluida com sucesso.', 'sucess')
    }

    async function getTags() {
        updateModalInfo(true, false, 'Carregando', 'Buscando tags no servidor.', 'loading')
        const get = await MAPI('tags/list', 'GET', null, user?.data?.token)
        if (get?.status !== 200 || !Array.isArray(get?.apiReturn?.apiReturn)) {
            const message = get?.apiReturn?.message ? get?.apiReturn?.message : 'Ocorreu um erro ao buscar suas tags.'
            updateModalInfo(true, true, 'Carregando', message, 'exclamation')
            return
        }
        updateModalInfo(false)
        setTags(get?.apiReturn?.apiReturn)
    }
    function handleNewTagAtive() {
        if (!validaEvento(modulo?.permissoes, 'criar')) {
            updateModalInfo(true, true, 'Atenção', 'Você não tem permissão para criar novas tags.', 'exclamation')
            return
        }
        setNewTagVisible(true)
    }

    useEffect(() => {
        getTags()
    }, [])
    return (
        <Box sx={{
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            height: '100%'
        }}>
            <ModuloTitle icon={modulo.moduloIcon} title={modulo.moduloNome} desc={modulo.moduloDesc} />
            <Box sx={{ display: 'flex', flexDirection: isSmallScreen ? 'column' : 'row', flexGrow: 1, background: theme.palette.background.default, width: '100%', height: '100%' }}>
                <Box sx={{ width: isSmallScreen ? '100%' : '35%' }}>
                    <Newtag canCreate={validaEvento(modulo?.permissoes, 'criar')} visible={newTagVisible} dismiss={() => changeEditTag()} pushTag={pushTag} prevTag={editTag.tag} updateTag={updateTag} deleteTag={deleteTag} permissoes={modulo?.permissoes} />
                </Box>
                <Divider orientation={isSmallScreen ? 'horizontal' : 'vertical'} flexItem />
                <ListTags canExclude={validaEvento(modulo?.permissoes, 'excluir')} tags={tags} changeEditTag={changeEditTag} deleteTag={deleteTag} />
            </Box>
            <ModalInfo hideModal={() => updateModalInfo(false)} canDesactive={MInfo.canDesactive} visible={MInfo.visible} title={MInfo.title} subtitle={MInfo.subtitle} icon={MInfo.icon} funcS={MInfo.funcS} />
        </Box>
    );
}