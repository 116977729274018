import React, { useState, useContext, useEffect } from 'react';
import { useTheme, Box } from '@mui/material';
import { ModalInfo } from '../../../components/modalInfo';
import { User } from '../../../contexts/user';
import { ModuloTitle } from '../../../components/moduloTitle';
import MAPI from '../../../api/mainApi';
import { EscolheCon } from './components/escolherCon';
import { ConFilters } from './components/conFilters';
import { ListContatos } from './components/listContatos';
import { ContTags } from './components/contatoTags';
import { validaEvento } from '../../../Mainfunctions';

export function ConContatos({ modulo }) {
    document.title = `Con Contatos - It's Chat`
    const theme = useTheme();
    const user = useContext(User);
    //estados
    const [filters, setFilter] = useState({
        name: ''
    })
    const [MInfo, setMInfo] = useState({
        visible: false,
        title: '',
        subtitle: '',
        canDesactive: true,
        icon: '',
        funcS: () => null
    });
    const [con, setCon] = useState({})
    const [mEscCon, setMEscCon] = useState({
        visible: true
    })
    const [contatos, setContatos] = useState([])
    const [contTags, setContTags] = useState({
        visible: false,
        contato: null
    })

    function updateModalInfo(visible, canDesactive, title, subtitle, icon, func) {
        const newMInfo = { ...MInfo };
        newMInfo.visible = visible;
        newMInfo.canDesactive = canDesactive;
        newMInfo.title = title;
        newMInfo.subtitle = subtitle;
        newMInfo.icon = icon;
        newMInfo.funcS = func;
        setMInfo(newMInfo);
    }
    function updateModalEscCon(visible) {
        const newModal = { ...mEscCon }
        newModal.visible = visible ? visible : false
        setMEscCon(newModal)
    }
    async function changeCon() {
        setCon({})
        setContatos([])
        updateModalEscCon(true)
    }
    function updateContato(contato) {
        const newContatos = [...contatos]
        const findIndex = newContatos.findIndex((e) => e.id === contato.id)
        if (findIndex !== -1) {
            newContatos[findIndex] = contato
        }
        setContatos(newContatos)
    }
    async function handleEscolheCont(conexao) {
        updateModalEscCon(false)
        setCon(conexao)
    }
    async function sincContatos() {
        if (!validaEvento(modulo?.permissoes, 'editar')) {
            updateModalInfo(true, true, 'Atenção', 'Você não tem permissões para editar contatos.', 'exclamation')
            return
        }
        updateModalInfo(true, false, 'Sincronizando', 'Aguarde enquanto sincronizamos seus contatos.', 'loading')
        const sinc = await MAPI('conexoes/sincContatos/' + con.id, 'GET', null, user?.data?.token)
        if (sinc?.status !== 200) {
            const message = sinc?.apiReturn?.message ? sinc?.apiReturn?.message : 'Ocorreu um erro ao sincronizar os contatos.'
            updateModalInfo(true, true, 'Atenção', message, 'exclamation')
            return
        }
        const contatos = sinc?.apiReturn?.apiReturn
        if (!Array.isArray(contatos) || contatos.length === 0) {
            updateModalInfo(true, true, 'Atenção', 'Não foram encontrados contatos.', 'exclamation')
            return
        }
        setContatos(contatos)
        updateModalInfo(false)
    }
    function updateFilters(value, type) {
        const newFilters = { ...filters }
        newFilters[type] = value
        setFilter(newFilters)
    }
    function updateContTags(visible, contato) {
        const newC = { ...contTags }
        newC.visible = visible ? visible : false
        newC.contato = contato ? contato : null
        setContTags(newC)
    }

    async function getContatos() {
        updateModalInfo(true, false, 'Carregando', 'Aguarde enquanto buscamos seus contatos.', 'loading')
        const sinc = await MAPI('conexoes/listContatos/' + con.id, 'GET', null, user?.data?.token)
        if (sinc?.status !== 200) {
            const message = sinc?.apiReturn?.message ? sinc?.apiReturn?.message : 'Ocorreu um erro ao sincronizar os contatos.'
            updateModalInfo(true, true, 'Atenção', message, 'exclamation')
            return
        }
        const contatos = sinc?.apiReturn?.apiReturn
        if (!Array.isArray(contatos) || contatos.length === 0) {
            updateModalInfo(true, true, 'Atenção', 'Não foram encontrados contatos.', 'exclamation')
            return
        }
        setTimeout(() => {
            setContatos(contatos)
            updateModalInfo(false)
        }, 1000)
    }
    useEffect(() => {
        if (con?.id) {
            getContatos()
        }
    }, [con])
    return (
        <Box sx={{
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            paddingBottom: 5
        }}>
            <ModuloTitle icon={modulo.moduloIcon} title={modulo.moduloNome} desc={modulo.moduloDesc} buttons={[{ text: 'Conexão', func: changeCon }]} />
            <Box sx={{
                display: 'flex', flexDirection: 'column',
                flexGrow: 1,
                height: '100%'
            }}>
                <ConFilters conexao={con} sincContatos={sincContatos} filters={filters} updateFilters={updateFilters} />
                <ListContatos canEdit={validaEvento(modulo?.permissoes, 'editar')} contatos={contatos} filters={filters} updateContato={updateContato} updateContTags={updateContTags} />
            </Box>
            <ContTags canEdit={validaEvento(modulo?.permissoes, 'editar')} visible={contTags.visible} prevContato={contTags.contato} updateContTags={updateContTags} updateContato={updateContato} />
            <EscolheCon visible={mEscCon.visible} dismiss={() => updateModalEscCon(false)} handleSubmit={handleEscolheCont} />
            <ModalInfo hideModal={() => updateModalInfo(false)} canDesactive={MInfo.canDesactive} visible={MInfo.visible} title={MInfo.title} subtitle={MInfo.subtitle} icon={MInfo.icon} funcS={MInfo.funcS} />
        </Box>
    );
}