import { AppBar, Box, useTheme, IconButton, Divider } from "@mui/material";
import { getIcon } from "./icons";
import { useState } from "react";
import { TopBarTField } from "./TextField";
import { UserProfile } from "./topBar/UserProfile";
import { ActionButtons } from "./topBar/ActionsButtons";

export function TopBar({ handleOpen }) {
    const theme = useTheme();
    const [search, setSearch] = useState('');
    function onChangeText(value) {
        setSearch(value);
    }


    return (
        <AppBar position='relative' sx={{
            display: 'flex',
            backgroundColor: theme.palette.background.paper,
            padding: 2,
            justifyContent: 'space-between',
            width: '100%',
            flexDirection: 'row',
            boxShadow: 'none'
        }}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 3 }}>
                <IconButton onClick={() => null}>
                    {getIcon(40)}
                </IconButton>
                <TopBarTField value={search} onChangeText={onChangeText} placeHolder={'Buscar...'} />
            </Box>

            <Box sx={{ display: 'flex', alignItems: 'center', gap: 3 }}>
                <ActionButtons />
                <Divider orientation='vertical' />
                <UserProfile />
            </Box>
        </AppBar >
    );
}
