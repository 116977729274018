import React, { useState, useContext } from 'react';
import { useTheme, Box, Collapse, IconButton, Tooltip, Typography } from '@mui/material';
import { ModalInfo } from '../../../../components/modalInfo';
import { User } from '../../../../contexts/user';
import { getIcon } from '../../../../components/icons';
import { UpComp } from '../../../../components/upComp';
import { CTextField } from '../../../../components/TextField';

export function ConFilters({ conexao, sincContatos, filters, updateFilters }) {
    const theme = useTheme();
    const user = useContext(User);
    //estados
    const [buscarOpen, setBuscarOpen] = useState(false)
    const [buscarText, setBuscarText] = useState('')
    const [MInfo, setMInfo] = useState({
        visible: false,
        title: '',
        subtitle: '',
        canDesactive: true,
        icon: '',
        funcS: () => null
    });

    function updateModalInfo(visible, canDesactive, title, subtitle, icon, func) {
        const newMInfo = { ...MInfo };
        newMInfo.visible = visible;
        newMInfo.canDesactive = canDesactive;
        newMInfo.title = title;
        newMInfo.subtitle = subtitle;
        newMInfo.icon = icon;
        newMInfo.funcS = func;
        setMInfo(newMInfo);
    }
    function handleOnBlurBuscar() {
        updateFilters(buscarText, 'name')
    }

    function onKeyPressBuscar(value, type) {
        if (value.key === 'Enter') {
            updateFilters(buscarText, 'name')
        }
    }
    function changeBuscar(value) {
        setBuscarText(value)
    }
    return (
        <Collapse in={conexao?.id ? true : false}>
            <Box sx={{ display: 'flex', flex: 1, justifyContent: 'space-between', padding: 2, alignItems: 'center', background: theme.palette.background.default, boxShadow: theme.palette.mode === 'dark' ? '0px 4px 7px rgba(0, 0, 0, 0.3)' : '0px 4px 7px rgba(0, 0, 0, 0.1)' }}>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 3 }}>
                    <UpComp>
                        {
                            getIcon(conexao.icon, theme.palette.mode === 'dark' ? theme.palette.text.primary : theme.palette.primary.main, 25)
                        }
                    </UpComp>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                        <Typography sx={{ fontWeight: 'bold', color: theme.palette.text.primary, textAlign: 'left' }}>{conexao?.nome}</Typography>
                        <Typography sx={{ color: theme.palette.text.primary }}>{conexao?.descricao}</Typography>
                    </Box>
                </Box>
                <Box>
                </Box>
                <Box sx={{ display: 'flex', gap: 1 }}>
                    <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                        <Collapse in={buscarOpen} orientation='horizontal'>
                            <CTextField onKeyPress={onKeyPressBuscar} label={'Buscar'} value={buscarText} type={'name'} onChangeText={changeBuscar} onBlur={handleOnBlurBuscar} />
                        </Collapse>
                        <Tooltip title={'Buscar'}>
                            <IconButton onClick={() => setBuscarOpen(!buscarOpen)}>
                                <UpComp tamanho={50}>
                                    {getIcon(54, theme.palette.secondary.main, 25)}
                                </UpComp>
                            </IconButton>
                        </Tooltip>
                    </Box>
                    <Tooltip title={'Sincronizar'}>
                        <IconButton onClick={sincContatos}>
                            <UpComp tamanho={50}>
                                {getIcon(51, theme.palette.secondary.main, 25)}
                            </UpComp>
                        </IconButton>
                    </Tooltip>
                </Box>
            </Box>
            <ModalInfo hideModal={() => updateModalInfo(false)} canDesactive={MInfo.canDesactive} visible={MInfo.visible} title={MInfo.title} subtitle={MInfo.subtitle} icon={MInfo.icon} funcS={MInfo.funcS} />
        </Collapse>
    );
}