import { Box, useTheme } from "@mui/material";

export function BoxHover({ children, width, newTheme }) {
    const baseTheme = useTheme()
    const theme = newTheme ? newTheme : baseTheme
    const boxStyle = {
        width: width ? width : '250px',
        backgroundColor: theme.palette.background.default,
        padding: '20px',
        margin: '15px',
        display: 'flex',
        gap: 1,
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '10px',
        boxShadow: theme.palette.mode === 'dark' ? '0px 4px 10px rgba(0, 0, 0, 0.5)' : '0px 4px 10px rgba(0, 0, 0, 0.1)',
        transition: 'transform 0.2s ease-in-out',
        position: 'relative',
        '&:hover': {
            transform: 'scale(1.05)',
        },
    };
    return (
        <Box sx={boxStyle}>
            {children}
        </Box>
    )
}