
import React, { useState, useContext, useEffect } from 'react';
import { useTheme, Box, Modal, Typography, Button, Badge, createTheme, ThemeProvider, Divider } from '@mui/material';
import { ModalInfo } from '../../../../../../components/modalInfo';
import { User } from '../../../../../../contexts/user';
import { getIcon } from '../../../../../../components/icons';
import MAPI from '../../../../../../api/mainApi';
import { ButtonSubmit } from '../../../../../../components/buttons';

export function Tags({ visible, con, dismiss, tagToListContatos }) {
    const theme = useTheme();
    const user = useContext(User);
    const customTheme = createTheme({
        palette: {
            primary: { main: theme.palette.primary.main },
        },
    });
    //estados
    const [MInfo, setMInfo] = useState({
        visible: false,
        title: '',
        subtitle: '',
        canDesactive: true,
        icon: '',
        funcS: () => null
    });
    const [data, setData] = useState([])
    const [selectTags, setSelectTags] = useState([])

    function updateModalInfo(visible, canDesactive, title, subtitle, icon, func) {
        const newMInfo = { ...MInfo };
        newMInfo.visible = visible;
        newMInfo.canDesactive = canDesactive;
        newMInfo.title = title;
        newMInfo.subtitle = subtitle;
        newMInfo.icon = icon;
        newMInfo.funcS = func;
        setMInfo(newMInfo);
    }
    async function getData() {
        if (data.length > 0) return
        updateModalInfo(true, false, 'Carregando', 'Buscando suas tags', 'loading')
        const get = await MAPI('tags/list', 'GET', null, user?.data?.token)
        if (get?.status !== 200) {
            const message = get?.apiReturn?.message ? get?.apiReturn?.message : 'Ocorreu um erro ao buscar suas tags.'
            updateModalInfo(true, true, 'Atenção', message, 'exclamation')
            return
        }
        updateModalInfo(false)
        setData(get?.apiReturn?.apiReturn)
    }

    function handleClick(value, isSelected) {
        if (isSelected) {
            const newS = selectTags.filter((e) => e !== value.id)
            setSelectTags(newS)
            return
        }
        const newS = [...selectTags]
        newS.push(value.id)
        setSelectTags(newS)
    }
    function handleSubmit(type) {
        if (type === 'Buscar') {
            getContatos()
            return
        }
        hideModal()
    }
    async function getContatos() {
        updateModalInfo(true, false, 'Carregando', 'Buscando contatos', 'loading')
        const body = { tags: selectTags, conexao: con }
        const get = await MAPI('disparador/getContatosByTags', 'POST', body, user?.data?.token)
        if (get?.status !== 200) {
            const message = get?.apiReturn?.message ? get?.apiReturn?.message : 'Ocorreu um erro ao buscar os contatos.'
            updateModalInfo(true, true, 'Atenção', message, 'exclamation')
            return
        }
        const contatos = get?.apiReturn?.apiReturn
        if (contatos.length === 0) {
            updateModalInfo(true, true, 'Atenção', 'Nenhum contato encontrado.', 'exclamation')
            return
        }
        tagToListContatos(contatos)
        updateModalInfo(false)
    }
    function hideModal() {
        dismiss()
        return
    }
    useEffect(() => {
        getData()
    }, [visible])
    return (
        <Modal open={visible} onClose={() => hideModal()} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} slotProps={{
            backdrop: {
                sx: {
                    backgroundColor: theme.palette.mode === 'dark' ? 'rgba(250,255,255, 0.2)' : 'rgba(0,0,0, 0.4)'
                }
            }
        }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, height: 500, width: '95%', maxWidth: 700, background: theme.palette.background.default, outline: 'none' }}>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 3, padding: 5, paddingBottom: 0 }}>
                    {getIcon(53, theme.palette.primary.main, 30)}
                    <Box>
                        <Typography sx={{ color: theme.palette.text.primary, fontWeight: 'bold', fontSize: 22 }}>Selecione as tags para encontrar os contatos.</Typography>
                        <Typography sx={{ color: theme.palette.text.primary }}>Os contatos buscados serão todos que contenham todas as tags selecionadas.</Typography>
                    </Box>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'column', height: '75%' }}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', padding: 5, paddingTop: 2, paddingBottom: 2, overflowY: 'auto' }}>
                        {data.map((e) => {
                            const isSelected = selectTags.findIndex((b) => b === e.id) !== -1
                            return (
                                <Box sx={{ width: '100%' }}>
                                    <Button sx={{ textTransform: 'none', width: '100%' }} onClick={() => handleClick(e, isSelected)}>
                                        <ThemeProvider theme={customTheme}>
                                            <Badge sx={{ width: '100%' }} badgeContent={isSelected ? <Box sx={{ background: theme.palette.primary.main, borderRadius: 5 }}>{getIcon(59, theme.palette.text.onPrimary)}</Box> : null} anchorOrigin={{ vertical: 'top', horizontal: 'left' }}>
                                                <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: 2 }}>
                                                    <Typography sx={{color: theme.palette.text.primary}}>{e?.nome}</Typography>
                                                    {getIcon(53, e.color, 30)}
                                                </Box>
                                            </Badge>
                                        </ThemeProvider>
                                    </Button>
                                    <Divider />
                                </Box>
                            )
                        })}
                    </Box>
                    <Box sx={{ display: 'flex', padding: 5, paddingTop: 0, paddingBottom: 2, gap: 2 }}>
                        {['Buscar', 'Voltar',].map((e, idx) => {
                            return (
                                <ButtonSubmit key={e} text={e} background={idx === 1 ? theme.palette.background.paper : idx === 2 ? theme.palette.secondary.main : theme.palette.primary.main} color={idx === 1 ? theme.palette.text.primary : theme.palette.text.onPrimary} func={handleSubmit} funcParameters={e} />
                            )
                        })}
                    </Box>
                </Box>
                <ModalInfo hideModal={() => updateModalInfo(false)} canDesactive={MInfo.canDesactive} visible={MInfo.visible} title={MInfo.title} subtitle={MInfo.subtitle} icon={MInfo.icon} funcS={MInfo.funcS} />
            </Box>
        </Modal>
    );
}