import React, { useState, useContext, useEffect } from 'react';
import { useTheme, Box, Button, Typography, IconButton, Tooltip, Pagination } from '@mui/material';
import { User } from '../../../../contexts/user';
import { ModalInfo } from '../../../../components/modalInfo';
import { BoxHover } from '../../../../components/boxHover';
import { getIcon } from '../../../../components/icons';
import { formatPhoneNumber, removerCaracteresEspeciais } from '../../../../components/masks';
import { UpComp } from '../../../../components/upComp';
import MAPI from '../../../../api/mainApi';

export function ListContatos({ contatos, filters, updateContato, updateContTags, canEdit }) {
    const theme = useTheme();
    const user = useContext(User);

    // Estados
    const [MInfo, setMInfo] = useState({
        visible: false,
        title: '',
        subtitle: '',
        canDesactive: true,
        icon: '',
        funcS: () => null
    });

    const [page, setPage] = useState(1);
    const itemsPerPage = 10;

    function updateModalInfo(visible, canDesactive, title, subtitle, icon, func) {
        const newMInfo = { ...MInfo };
        newMInfo.visible = visible;
        newMInfo.canDesactive = canDesactive;
        newMInfo.title = title;
        newMInfo.subtitle = subtitle;
        newMInfo.icon = icon;
        newMInfo.funcS = func;
        setMInfo(newMInfo);
    }

    async function changeStatus(contato) {
        if (!canEdit) {
            updateModalInfo(true, true, 'Atenção', 'Você não tem permissão para editar contatos.', 'exclamation')
            return
        }
        updateModalInfo(true, false, 'Atenção', 'Tentando alterar o status do contato', 'loading');
        const status = contato?.status === 1 ? 0 : 1;
        const TRYChange = await MAPI('conexoes/edit_contato_status', 'PUT', { id: contato.id, status: status }, user?.data?.token);
        if (TRYChange?.status !== 200) {
            const message = TRYChange?.apiReturn?.message ? TRYChange?.apiReturn?.message : 'Não foi possível alterar o status.';
            updateModalInfo(true, true, 'Atenção', message, 'exclamation');
            return;
        }
        updateModalInfo(true, true, 'Atenção', 'Status alterado com sucesso.', 'sucess');
        contato.status = status;
        updateContato(contato);
    }

    const handleChangePage = (event, value) => {
        setPage(value);
    };

    const filteredContatos = Array.isArray(contatos) ? contatos.filter(e => handleFilters(filters, e)) : [];
    const paginatedContatos = filteredContatos.slice((page - 1) * itemsPerPage, page * itemsPerPage);

    useEffect(() => {
        setPage(1)
    }, [filters])

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Box sx={{
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: 'center'
            }}>
                {paginatedContatos.map((e, idx) => (
                    <BoxHover key={idx}>
                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-between', height: 200, width: '70%' }}>
                            <Button onClick={() => null} sx={{ textTransform: 'none' }}>
                                <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: 1 }}>
                                    {e?.profilePicture ?
                                        <Box sx={{ borderRadius: 25, boxShadow: theme.palette.mode === 'dark' ? '0px 4px 10px rgba(0, 0, 0, 0.3)' : '0px 4px 10px rgba(0, 0, 0, 0.1)', overflow: 'hidden', height: 70, width: 70 }}>
                                            <img src={e?.profilePicture} style={{ objectFit: 'contain', width: '100%', borderRadius: 90 }} />
                                        </Box>
                                        :
                                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 75, width: 75, borderRadius: 25, boxShadow: theme.palette.mode === 'dark' ? '0px 4px 10px rgba(0, 0, 0, 0.3)' : '0px 4px 10px rgba(0, 0, 0, 0.1)' }}>
                                            {getIcon(33, theme.palette.text.primary, 50)}
                                        </Box>
                                    }
                                    <Box>
                                        <Typography sx={{ color: theme.palette.text.primary, fontWeight: 'bold' }}>{e?.nome}</Typography>
                                        <Typography sx={{ color: theme.palette.text.primary }}>{formatPhoneNumber(e?.numero)}</Typography>
                                    </Box>
                                </Box>
                            </Button>
                            <Box sx={{ display: 'flex', gap: 2 }}>
                                {['Tags', 'Status'].map((el, idx) => {
                                    const color = idx % 2 ? theme.palette.tertiary.main : theme.palette.secondary.main
                                    const icon = idx === 0 ? 53 : e?.status === 1 ? 23 : 24;
                                    const title = idx === 0 ? el : e?.status === 1 ? 'Ativo' : 'Inativo';
                                    return (
                                        <UpComp tamanho={30} key={idx}>
                                            <Tooltip title={title}>
                                                <IconButton onClick={() => {
                                                    if (el === 'Status') {
                                                        changeStatus(e);
                                                    }
                                                    if (el === 'Tags') {
                                                        updateContTags(true, e);
                                                    }
                                                }}>
                                                    {getIcon(icon, color)}
                                                </IconButton>
                                            </Tooltip>
                                        </UpComp>
                                    );
                                })}
                            </Box>
                        </Box>
                    </BoxHover>
                ))}
            </Box>
            <Pagination
                count={Math.ceil(filteredContatos.length / itemsPerPage)}
                page={page}
                onChange={handleChangePage}
                color="primary"
                sx={{ marginTop: 2 }}
            />
            <ModalInfo hideModal={() => updateModalInfo(false)} canDesactive={MInfo.canDesactive} visible={MInfo.visible} title={MInfo.title} subtitle={MInfo.subtitle} icon={MInfo.icon} funcS={MInfo.funcS} />
        </Box>
    );
}

function handleFilters(filters, data) {
    const nome = removerCaracteresEspeciais(data.nome).toLowerCase();
    const filterNome = removerCaracteresEspeciais(filters.name).toLowerCase();
    if (!filters.name) return true;
    if (nome.includes(filterNome)) return true;
    if (data.numero.includes(filterNome)) return true;
    return false;
}
