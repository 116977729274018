
import React, { useState, useContext, useEffect } from 'react';
import { useTheme, Box } from '@mui/material';
import { ModalInfo } from '../../components/modalInfo';
import { User } from '../../contexts/user';
import { ModuloTitle } from '../../components/moduloTitle';
import { New } from './components/new';
import { ReactFlowProvider } from 'reactflow';
import MAPI from '../../api/mainApi';
import { List } from './components/listFluxos';
import { validaEvento } from '../../Mainfunctions';

export function Fluxos({ modulo }) {
    const theme = useTheme();
    const user = useContext(User);
    //estados
    const [MInfo, setMInfo] = useState({
        visible: false,
        title: '',
        subtitle: '',
        canDesactive: true,
        icon: '',
        funcS: () => null
    });
    const [fluxos, setFluxos] = useState([])
    const [modFluxo, setModFluxo] = useState({
        visible: false,
        fluxo: null
    })
    function updateModFluxo(visible, fluxo) {
        if (visible) {
            if (!validaEvento(modulo?.permissoes, 'criar') && !fluxo) {
                updateModalInfo(true, true, 'Atenção', 'Você não tem permissões para criar novos fluxos', 'exclamation')
                return
            }
            if (!validaEvento(modulo?.permissoes, 'editar') && fluxo) {
                updateModalInfo(true, true, 'Atenção', 'Você não tem permissões para editar fluxos.', 'exclamation')
                return
            }
        }
        const newMod = { ...modFluxo }
        newMod.visible = visible ? visible : false
        newMod.fluxo = fluxo ? fluxo : null
        setModFluxo(newMod)
    }

    function updateModalInfo(visible, canDesactive, title, subtitle, icon, func) {
        const newMInfo = { ...MInfo };
        newMInfo.visible = visible;
        newMInfo.canDesactive = canDesactive;
        newMInfo.title = title;
        newMInfo.subtitle = subtitle;
        newMInfo.icon = icon;
        newMInfo.funcS = func;
        setMInfo(newMInfo);
    }

    async function deleteFluxo(fluxo, control) {
        if (!validaEvento(modulo?.permissoes, 'excluir')) {
            updateModalInfo(true, true, 'Atenção', 'Você não tem permissões para excluir um fluxo.', 'exclamation')
            return
        }
        if (!control) {
            updateModalInfo(true, true, 'Atenção', 'Você tem certeza que deseja excluir o fluxo e todos os dados relacionados à ele?', 'question', () => deleteFluxo(fluxo, true))
            return
        }
        updateModalInfo(true, false, 'Excluindo', 'Estamos excluindo seu fluxo', 'loading')
        const exclude = await MAPI(`fluxo/deleteFluxo/${fluxo.id}`, 'DELETE', null, user?.data?.token)
        if (exclude.status !== 200) {
            const message = exclude?.apiReturn?.message ? exclude?.apiReturn?.message : 'Ocorreu um erro ao excluir o fluxo.'
            updateModalInfo(true, true, 'Atenção', message, 'exclamation')
            return
        }
        const newFluxo = fluxos.filter((e) => e.id !== fluxo.id)
        setFluxos(newFluxo)
        updateModalInfo(false)
    }

    async function getFluxos() {
        updateModalInfo(true, false, 'Carregando', 'Buscando seus fluxos', 'loading')
        const get = await MAPI('fluxo/getFluxos', 'GET', null, user?.data?.token)
        if (get?.status !== 200) {
            const message = get?.apiReturn?.message ? get?.apiReturn?.message : 'Ocorreu um erro ao buscar os fluxos.'
            updateModalInfo(true, true, 'Atenção', message, 'exclamation')
            return
        }
        updateModalInfo(false)
        setFluxos(get?.apiReturn?.apiReturn)
    }
    useEffect(() => {
        getFluxos()
    }, [])

    return (
        <ReactFlowProvider>
            <Box sx={{
                flex: 1
            }}>
                <ModuloTitle icon={modulo.moduloIcon} title={modulo.moduloNome} desc={modulo.moduloDesc} />
                <New visible={modFluxo.visible} prevData={modFluxo.fluxo} updateModFluxo={updateModFluxo} getFluxos={getFluxos} />
                <List updateModFluxo={updateModFluxo} data={fluxos} deleteFluxo={deleteFluxo} />
                <ModalInfo hideModal={() => updateModalInfo(false)} canDesactive={MInfo.canDesactive} visible={MInfo.visible} title={MInfo.title} subtitle={MInfo.subtitle} icon={MInfo.icon} funcS={MInfo.funcS} />
            </Box>
        </ReactFlowProvider>
    );
}