import { Box, Typography, useTheme } from '@mui/material'
import { getIcon } from '../../../components/icons'
import { SimpleTable } from '../../../components/tables'


export function ListSetores({ setores, deleteSetor, updateSetor }) {
    const theme = useTheme()

    return (
        <Box sx={{
            flex: 1,
            padding: 2,
            background: theme.palette.background.default,
            maxWidth: '100%',
        }}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 3, justifyContent: 'center' }}>
                {getIcon(30, theme.palette.secondary.main)}
                <Typography sx={{ fontWeight: 'bold', color: theme.palette.text.main, fontSize: 25, textAlign: 'center', color: theme.palette.text.primary }}>Meus setores</Typography>
            </Box>
            <Box sx={{ padding: 2 }}>
                <SimpleTable excluir={(value) => deleteSetor(value)} editar={(value) => updateSetor(value)} headers={['Nome', 'Editar', 'Excluir']} rows={setores} params={['nome']} />
            </Box>
        </Box>
    )
}