import React, { useState, useContext, useEffect } from 'react';
import { useTheme, Box, Modal, Typography, Button } from '@mui/material';
import { ModalInfo } from '../../../../components/modalInfo';
import { User } from '../../../../contexts/user';
import MAPI from '../../../../api/mainApi';
import { getIcon } from '../../../../components/icons';
import { UpComp } from '../../../../components/upComp';

export function EscolheCon({ visible, dismiss, handleSubmit }) {
    const theme = useTheme();
    const user = useContext(User);
    //estados
    const [MInfo, setMInfo] = useState({
        visible: false,
        title: '',
        subtitle: '',
        canDesactive: true,
        icon: '',
        funcS: () => null
    });
    const [cons, setCons] = useState(null)


    function updateModalInfo(visible, canDesactive, title, subtitle, icon, func) {
        const newMInfo = { ...MInfo };
        newMInfo.visible = visible;
        newMInfo.canDesactive = canDesactive;
        newMInfo.title = title;
        newMInfo.subtitle = subtitle;
        newMInfo.icon = icon;
        newMInfo.funcS = func;
        setMInfo(newMInfo);
    }
    async function getCons() {
        updateModalInfo(true, false, 'Carregando', 'Buscando conexões', 'loading')
        const get = await MAPI('conexoes/minhasConexoes', 'GET', null, user?.data?.token)
        if (get?.status !== 200) {
            const message = get?.apiReturn?.message ? get?.apiReturn?.message : 'Não foi possível buscar suas conexões.'
            updateModalInfo(true, true, 'Atenção', message, 'exclamation')
            return
        }
        updateModalInfo(false)
        setCons(get?.apiReturn?.apiReturn)
    }

    function handleDismiss() {
        dismiss()
    }

    useEffect(() => {
        if (!cons && visible) {
            getCons()
        }
    }, [visible])

    return (
        <Modal open={visible} onClose={handleDismiss} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 2 }} slotProps={{
            backdrop: {
                sx: {
                    backgroundColor: theme.palette.mode === 'dark' ? 'rgba(250,255,255, 0.2)' : 'rgba(0,0,0, 0.4)'
                }
            }
        }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', height: 680, width: 650, background: theme.palette.background.default, outline: 'none' }}>
                <Box sx={{ background: theme.palette.background.paper, padding: 5 }}>
                    <Typography sx={{ fontWeight: 'bold', color: theme.palette.text.primary, fontSize: 30 }}>{'Conexões'}</Typography>
                    <Typography sx={{ color: theme.palette.text.primary, fontSize: 18 }}>Escolha uma conexão.</Typography>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column', padding: 5, overflowY: 'scroll' }}>
                    {Array.isArray(cons) ? cons.map((e, index) => {
                        const color = index % 2 ? theme.palette.secondary.main : theme.palette.tertiary.main
                        return (
                            <Button key={e.id} sx={{ width: '100%', textAlign: 'left' }} onClick={() => handleSubmit(e)} >
                                <Box key={index} sx={{ width: '100%', display: 'flex', flexDirection: 'column', gap: 2 }}>
                                    <Box sx={{ display: 'flex', background: theme.palette.background.default, boxShadow: theme.palette.mode === 'dark' ? '0px 4px 10px rgba(155, 155, 155, 0.3)' : '0px 4px 10px rgba(0, 0, 0, 0.1)', padding: 3, alignItems: 'center', gap: 3 }}>
                                        <UpComp>
                                            {
                                                getIcon(e.icon, color, 25)
                                            }
                                        </UpComp>
                                        <Box>
                                            <Typography sx={{ color: theme.palette.text.primary, textTransform: 'none', fontWeight: 'bold', textAlign: 'left' }}>{e.nome}</Typography>
                                            <Typography sx={{ color: theme.palette.text.primary, textTransform: 'none' }}>{e.descricao}</Typography>
                                        </Box>
                                    </Box>
                                </Box>
                            </Button>
                        )
                    }) : null}
                </Box>
                <ModalInfo hideModal={() => updateModalInfo(false)} canDesactive={MInfo.canDesactive} visible={MInfo.visible} title={MInfo.title} subtitle={MInfo.subtitle} icon={MInfo.icon} funcS={MInfo.funcS} />
            </Box>
        </Modal>
    );
}