import { Routes, Route } from 'react-router-dom'
import { Inicio } from "../views/home/home";
import { useTheme, Box, useMediaQuery } from '@mui/material'
import { Conexoes } from '../views/conexoes/conexoes';
import { useContext, useEffect, useState } from 'react';
import { User } from '../contexts/user';
import { Operadores } from '../views/operadores/operadores';
import { ConContatos } from '../views/contatos/con-contatos/conContatos';
import { Tags } from '../views/contatos/tags/Tags';
import { MeusContatos } from '../views/contatos/meus-contatos/meusContatos';
import { Disparador } from '../views/chats/disparador/disparador';
import { Variaveis } from '../views/chats/variaveis/variaveis';
import { Navegacao } from '../components/drawerN';
import { TopBar } from '../components/topBar';
import Cookies from 'universal-cookie';
import { IsSmallScreen } from '../views/isSmallScreen';
import { MeusEmails } from '../views/emails/emails';
import { Fluxos } from '../views/fluxos/fluxos';
import { CamposContato } from '../views/contatos/campos-contato/CamposContato';
import { Conversas } from '../views/chats/conversas/conversas';
import { Setores } from '../views/setores/setores';

export function IsUser() {
    const cookies = new Cookies()
    const theme = useTheme()
    const user = useContext(User)
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('lg'));
    const [drawerOpen, setDrawerOpen] = useState(true)
    function handleOpen() {
        setDrawerOpen(!drawerOpen)
        cookies.set('its_chat_drawer', !drawerOpen, { maxAge: 999999999, path: '/' })
    }
    useEffect(() => {
        const getOpen = cookies.get('its_chat_drawer')
        if (![undefined, null, ''].includes(getOpen)) setDrawerOpen(getOpen)
    }, [])
    if (isSmallScreen) {
        return (<IsSmallScreen />)
    }
    return (
        <Box sx={{
            display: 'flex', height: '100%', flex: 1, overflow: 'scroll', background: theme.palette.background.paper, '&::-webkit-scrollbar': {
                background: theme.palette.background.default,
            }, '&::-webkit-scrollbar-thumb': {
                background: theme.palette.primary.main
            }
        }}>
            <Navegacao drawerOpen={drawerOpen} />
            <Box sx={{ display: 'flex', flexGrow: 1, flexDirection: 'column' }}>
                <TopBar handleOpen={handleOpen} />
                <Box sx={{ display: 'flex', flexGrow: 1, padding: 2 }}>
                    <Routes>
                        <Route path="/" element={<Inicio />} />
                        {user?.data?.permissoes.map((e) => {
                            e.conexoes = user?.data?.conexoes
                            if (e?.inside) {
                                return e.inside.map((el) => {
                                    el.conexoes = user?.data?.conexoes
                                    return (
                                        <Route key={el.moduloID} path={el?.moduloEndp} element={EndPointToUser(el)} />
                                    )
                                })
                            }
                            else {
                                return (
                                    <Route key={e.moduloID} path={e?.moduloEndp} element={EndPointToUser(e)} />
                                )
                            }
                        })}
                    </Routes>
                </Box>
            </Box>
        </Box>
    )
}


function EndPointToUser(modulo) {
    if (modulo?.moduloEndp === '/configuracoes/conexoes') return <Conexoes modulo={modulo} />
    if (modulo?.moduloEndp === '/configuracoes/operadores') return <Operadores modulo={modulo} />
    if (modulo?.moduloEndp === '/configuracoes/setores') return <Setores modulo={modulo} />
    if (modulo?.moduloEndp === '/contatos') return <Operadores modulo={modulo} />
    if (modulo?.moduloEndp === '/contatos/con-contatos') return <ConContatos modulo={modulo} />
    if (modulo?.moduloEndp === '/contatos/meus-contatos') return <MeusContatos modulo={modulo} />
    if (modulo?.moduloEndp === '/contatos/tags') return <Tags modulo={modulo} />
    if (modulo?.moduloEndp === '/chat/disparador') return <Disparador modulo={modulo} />
    if (modulo?.moduloEndp === '/chat/variaveis') return <Variaveis modulo={modulo} />
    if (modulo?.moduloEndp === '/chat/conversas') return <Conversas modulo={modulo} />
    if (modulo?.moduloEndp === '/email/meus-emails') return <MeusEmails modulo={modulo} />
    if (modulo?.moduloEndp === '/fluxos') return <Fluxos modulo={modulo} />
    if (modulo?.moduloEndp === '/contatos/campos-contatos') return <CamposContato modulo={modulo} />
    return <Inicio />
}