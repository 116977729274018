import { Box, Typography, useTheme } from '@mui/material'
import { ButtonTitle } from './buttons'
import { getIcon } from './icons'
import { ConversasTField, CTextField } from './TextField'

export function ModuloTitle({ title, desc, buttons, icon, isSearch, search, chanteSeacth }) {
    const theme = useTheme()
    return (
        <Box sx={{ display: 'flex', flex: 1, justifyContent: 'space-between', padding: 1, paddingLeft: 2, paddingRight: 2, alignItems: 'center', background: theme.palette.background.paper, }}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 3 }}>
                {icon ? getIcon(icon, theme.palette.primary.main, 30) : null}
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Typography sx={{ color: theme.palette.text.primary, fontWeight: 'bold', fontSize: 25 }}>{title}</Typography>
                    <Typography sx={{ color: theme.palette.text.primary, fontSize: 20 }}>{desc}</Typography>
                </Box>
            </Box>
            <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                {isSearch &&
                    <Box>
                        <ConversasTField label={'Buscar'} size={'small'} bgColor={'#fff'} onChangeText={chanteSeacth} value={search} />
                    </Box>
                }
                {buttons &&
                    <Box>
                        {buttons.map((e, idx) => (
                            <ButtonTitle key={idx} text={e?.text} func={e?.func} funcParameters={e?.funcParameters} size={'large'} />
                        ))}
                    </Box>}
            </Box>
        </Box>
    )
}

