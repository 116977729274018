import React, { useState, useContext, useEffect } from 'react';
import { useTheme, Box, Modal, Typography, IconButton, Button, Alert, Select, MenuItem } from '@mui/material';
import { ModalInfo } from '../../../components/modalInfo';
import { User } from '../../../contexts/user';
import { CTextField } from '../../../components/TextField';
import { getIcon } from '../../../components/icons';
import MAPI from '../../../api/mainApi';
import { MD5 } from 'crypto-js';
import moment from 'moment'

export function ModalConfig({ visible, dismiss, conexao }) {
    const theme = useTheme();
    const user = useContext(User);
    //estados
    const [data, setData] = useState(dataInit)
    const [fluxos, setFluxos] = useState([])
    const [conFluxo, setConFluxo] = useState(null)
    const [error, setError] = useState(false)
    const [MInfo, setMInfo] = useState({
        visible: false,
        title: '',
        subtitle: '',
        canDesactive: true,
        icon: '',
        funcS: () => null
    });

    function updateModalInfo(visible, canDesactive, title, subtitle, icon, func) {
        const newMInfo = { ...MInfo };
        newMInfo.visible = visible;
        newMInfo.canDesactive = canDesactive;
        newMInfo.title = title;
        newMInfo.subtitle = subtitle;
        newMInfo.icon = icon;
        newMInfo.funcS = func;
        setMInfo(newMInfo);
    }
    function handleDismiss() {
        dismiss()
    }
    async function getConConfig() {
        updateModalInfo(true, false, 'Carregando', 'Estamos buscando as configurações da conexão.', 'loading')
        const get = await MAPI(`conexoes/getConConfig/${conexao}`, 'GET', null, user?.data?.token)
        if (get?.status !== 200) {
            setError(true)
            const message = get?.apiReturn?.message ? get?.apiReturn?.message : 'Ocorreu um erro ao buscar as informações.'
            updateModalInfo(true, true, 'Atenção', message, 'exclamation')
            return
        }
        const apiData = get?.apiReturn?.apiReturn
        if (!apiData) {
            setError(true)
            updateModalInfo(true, true, 'Atenção', 'Ocorreu um erro ao buscar as informações.', 'exclamation')
            return
        }
        setError(false)
        setFluxos(apiData?.fluxos)
        if (apiData?.conFluxo) {
            apiData.conConfigs.fluxo = apiData?.conFluxo?.fluxo
        }
        setData({ ...data, ...apiData?.conConfigs })
        updateModalInfo(false)
    }

    function generateNewToken() {
        const newToken = MD5(conexao + moment().format('YYYY-MM-DD HH:mm:ss')).toString()
        const newD = { ...data }
        newD['Token Integração'] = newToken
        setData(newD)
    }
    function changeFluxo(value) {
        const newD = { ...data }
        newD.fluxo = value
        setData(newD)
    }
    function handleButton(type) {
        if (type === 'Voltar') {
            handleDismiss()
            return
        }
        saveInfo()
    }
    async function saveInfo() {
        const body = {
            token: data['Token Integração'] && data['Token Integração'],
            fluxo: data.fluxo && data.fluxo !== 987654321 && data.fluxo
        }
        updateModalInfo(true, false, 'Salvando', 'Estamos salvando suas informações.', 'loading')
        const save = await MAPI(`conexoes/saveConConfig/${conexao}`, 'POST', body, user?.data?.token)
        if (save?.status !== 200) {
            updateModalInfo(true, true, 'Atenção', 'Ocorreu um erro ao salvar os seus dados.')
            return
        }
        updateModalInfo(true, true, 'Sucesso', 'Suas informações foram salvas', 'sucess')
    }
    useEffect(() => {
        if (visible) {
            getConConfig()
        } else {
            setData(dataInit)
        }
    }, [visible])

    return (
        <Modal open={visible} onClose={handleDismiss} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} slotProps={{
            backdrop: {
                sx: {
                    backgroundColor: theme.palette.mode === 'dark' ? 'rgba(250,255,255, 0.2)' : 'rgba(0,0,0, 0.4)'
                }
            }
        }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', height: 'auto', width: 600, background: theme.palette.background.default, outline: 'none' }}>
                <Box sx={{ background: theme.palette.background.default, padding: 5, paddingLeft: 3 }}>
                    <Typography sx={{ fontWeight: 'bold', color: theme.palette.text.primary, fontSize: 30 }}>Configurações</Typography>
                    <Typography sx={{ color: theme.palette.text.primary, fontSize: 18 }}>Configurações adicionais para a conexão.</Typography>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 5, padding: 3 }}>
                    {error === true ? (
                        <Alert severity='error' icon={getIcon(25)}>Ocorreu um erro ao buscar as informações.</Alert>
                    ) : (
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
                            <Box>
                                <Typography sx={{ color: theme.palette.text.primary }}>Token de integração</Typography>
                                <Box sx={{ display: 'flex' }}>
                                    <CTextField disabled={true} value={data['Token Integração']} />
                                    <IconButton sx={{ background: theme.palette.secondary.main, borderRadius: 0 }} onClick={generateNewToken}>
                                        {getIcon(81, theme.palette.text.onPrimary)}
                                    </IconButton>
                                </Box>
                            </Box>
                            <Box>
                                <Typography sx={{ color: theme.palette.text.primary }}>Fluxo da conexão</Typography>
                                <Select value={data.fluxo} fullWidth onChange={(value) => changeFluxo(value.target.value)}>
                                    <MenuItem value={987654321}>Selecionar</MenuItem>
                                    {fluxos.map((e) => (
                                        <MenuItem value={e.id}>{e.nome}</MenuItem>
                                    ))}
                                </Select>
                            </Box>
                        </Box>
                    )}
                    <Box sx={{ display: 'flex', gap: 2 }}>
                        {['Salvar', 'Voltar'].map((e, idx) => {
                            const bg = idx === 0 ? theme.palette.primary.main : theme.palette.background.paper
                            const color = idx === 0 ? theme.palette.text.onPrimary : theme.palette.text.primary
                            return (
                                <Button size='large' sx={{ background: bg, textTransform: 'none', color: color }} onClick={() => handleButton(e)}>{e}</Button>
                            )
                        })}
                    </Box>
                    <ModalInfo hideModal={() => updateModalInfo(false)} canDesactive={MInfo.canDesactive} visible={MInfo.visible} title={MInfo.title} subtitle={MInfo.subtitle} icon={MInfo.icon} funcS={MInfo.funcS} />
                </Box>
            </Box>
        </Modal>
    );
}

const dataInit = {
    'Token Integração': '',
    'fluxo': '987654321'
}