import React, { useState, useContext, useEffect } from 'react';
import { useTheme, Box, Typography, Divider, useMediaQuery } from '@mui/material';
import { ModalInfo } from '../../../components/modalInfo';
import { User } from '../../../contexts/user';
import { ModuloTitle } from '../../../components/moduloTitle';
import MAPI from '../../../api/mainApi';
import { NovaViariavel } from './components/new';
import { ListVariavel } from './components/list';
import { validaEvento } from '../../../Mainfunctions';

export function Variaveis({ modulo }) {
    const theme = useTheme();
    const user = useContext(User);
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
    //estados
    const [MInfo, setMInfo] = useState({
        visible: false,
        title: '',
        subtitle: '',
        canDesactive: true,
        icon: '',
        funcS: () => null
    });
    const [tipos, setTipos] = useState([])
    const [variaveis, setVariaveis] = useState([])
    const [prevNew, setPrevNew] = useState(null)
    function pushVariaveis(variavel) {
        const newC = [...variaveis]
        newC.push(variavel)
        setVariaveis(newC)
    }
    function editVariavel(variavel) {
        const newC = [...variaveis]
        const findIndex = newC.findIndex((e) => e.id === variavel.id)
        if (findIndex !== -1) {
            newC[findIndex] = variavel
            setVariaveis(newC)
        }
    }
    function deleteVariavel(variavel) {
        const newV = variaveis.filter((e) => e.id !== variavel.id)
        setVariaveis(newV)
        setPrevNew(null)
    }
    function updatePrevNew(prev) {
        if (!validaEvento(modulo?.permissoes, 'editar')) {
            updateModalInfo(true, true, 'Atenção', 'Você não tem permissões para editar variáveis', 'exclamation')
            return
        }
        setPrevNew(prev ? prev : null)
    }
    function updateModalInfo(visible, canDesactive, title, subtitle, icon, func) {
        const newMInfo = { ...MInfo };
        newMInfo.visible = visible;
        newMInfo.canDesactive = canDesactive;
        newMInfo.title = title;
        newMInfo.subtitle = subtitle;
        newMInfo.icon = icon;
        newMInfo.funcS = func;
        setMInfo(newMInfo);
    }
    async function getTipos() {
        updateModalInfo(true, false, 'Carregando', 'Buscando tipos.', 'loading')
        const get = await MAPI('variaveis/getVariaveis', 'GET', null, user?.data?.token)
        if (get?.status !== 200) {
            const message = get?.apiReturn?.message ? get?.apiReturn?.message : 'Ocorreu um erro ao buscar as variaveis'
            updateModalInfo(true, true, 'Atenção', message, 'exclamation')
            return
        }
        updateModalInfo(false)
        const apiReturn = get?.apiReturn?.apiReturn
        setTipos(apiReturn.tipos)
        setVariaveis(apiReturn.variaveis)

    }
    useEffect(() => {
        getTipos()
    }, [])

    return (
        <Box sx={{
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            height: '100%'
        }}>
            <ModuloTitle icon={modulo.moduloIcon} title={modulo.moduloNome} desc={modulo.moduloDesc} />
            <Box sx={{ display: 'flex', flexDirection: isSmallScreen ? 'column' : 'row', flexGrow: 1, background: theme.palette.background.default, width: '100%', height: '100%' }}>
                <Box sx={{ width: isSmallScreen ? '100%' : '35%' }}>
                    <NovaViariavel canCreate={validaEvento(modulo?.permissoes, 'criar')} tipos={tipos} pushVariaveis={pushVariaveis} prevNew={prevNew} updatePrevNew={updatePrevNew} editVariavel={editVariavel} />
                </Box>
                <Divider orientation={isSmallScreen ? 'horizontal' : 'vertical'} flexItem />
                <ListVariavel canExclude={validaEvento(modulo?.permissoes, 'excluir')} variaveis={variaveis} updatePrevNew={updatePrevNew} deleteVariavel={deleteVariavel} />
            </Box>
            <ModalInfo hideModal={() => updateModalInfo(false)} canDesactive={MInfo.canDesactive} visible={MInfo.visible} title={MInfo.title} subtitle={MInfo.subtitle} icon={MInfo.icon} funcS={MInfo.funcS} />
        </Box>
    );
}