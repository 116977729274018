import { Box, useTheme } from '@mui/material'
import { UnitConex } from './unitConex'

export function ListConexoes({ data, permissoes, deleteData, updateEditCon, verifyStatus, conexoes }) {
    const theme = useTheme()
    const newCon = typeof conexoes === 'string' ? conexoes.split(',') : []
    return (
        <Box sx={{
            display: 'flex',
            flexWrap: 'wrap'
        }}>
            {Array.isArray(data) ? data.map((e) => {
                if (conexoes !== 'all' && !newCon.includes(e.id.toString())) return
                return (
                    <UnitConex verifyStatus={verifyStatus} key={e.id} conexao={e} permissoes={permissoes} deleteData={deleteData} updateEditCon={updateEditCon} />
                )
            })
                : null
            }
        </Box>
    )
}