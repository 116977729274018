import React, { useState, useContext, useEffect } from 'react';
import { useTheme, Box, Modal, Typography, Pagination, Badge, Button, createTheme, ThemeProvider, Checkbox } from '@mui/material';
import { ModalInfo } from '../../../../../../components/modalInfo';
import { User } from '../../../../../../contexts/user';
import { getIcon } from '../../../../../../components/icons';
import { formatPhoneNumber } from '../../../../../../components/masks';
import { ButtonSubmit } from '../../../../../../components/buttons';
import { CTextField } from '../../../../../../components/TextField';

export function ListContatos({ visible, contatos, updateContatos, saveContatos }) {
    const theme = useTheme();
    const user = useContext(User);
    const customTheme = createTheme({
        palette: {
            primary: { main: theme.palette.primary.main },
        },
    });
    const [page, setPage] = useState(1);
    const itemsPerPage = 10;
    const [MInfo, setMInfo] = useState({
        visible: false,
        title: '',
        subtitle: '',
        canDesactive: true,
        icon: '',
        funcS: () => null
    });
    const [selectContatos, setSelectContatos] = useState([]);
    const [filter, setFilter] = useState('');
    const [allSelected, setAllSelected] = useState(true);
    function updateModalInfo(visible, canDesactive, title, subtitle, icon, func) {
        const newMInfo = { ...MInfo };
        newMInfo.visible = visible;
        newMInfo.canDesactive = canDesactive;
        newMInfo.title = title;
        newMInfo.subtitle = subtitle;
        newMInfo.icon = icon;
        newMInfo.funcS = func;
        setMInfo(newMInfo);
    }
    function hideModal() {
        updateContatos();
        return;
    }
    const handleChangePage = (event, value) => {
        setPage(value);
    };
    function handleCheck(contato, isCheck) {
        if (isCheck) {
            const newD = selectContatos.filter((e) => e.id !== contato.id);
            setSelectContatos(newD);
        } else {
            const newD = [...selectContatos, contato];
            setSelectContatos(newD);
        }
    }
    function handleSelectAll(event) {
        if (event.target.checked) {
            setSelectContatos(filteredContatos);
        } else {
            setSelectContatos([]);
        }
        setAllSelected(event.target.checked);
    }
    function handleSubmit(type) {
        if (type === 'Voltar') {
            hideModal();
            return;
        }
        saveContatos(selectContatos);
    }
    const filteredContatos = contatos.filter(contato => {
        const filterLower = filter.toLowerCase();
        return Object.keys(contato).some(key => {
            const value = contato[key];
            if (typeof value === 'string') {
                return value.toLowerCase().includes(filterLower);
            }
            return false;
        });
    });

    const paginatedContatos = filteredContatos.slice((page - 1) * itemsPerPage, page * itemsPerPage);

    useEffect(() => {
        setSelectContatos(contatos);
        setFilter('')
    }, [visible]);

    useEffect(() => {
        setAllSelected(selectContatos.length === filteredContatos.length && filteredContatos.length > 0);
    }, [selectContatos, filteredContatos]);

    return (
        <Modal open={visible} onClose={() => hideModal()} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} slotProps={{
            backdrop: {
                sx: {
                    backgroundColor: theme.palette.mode === 'dark' ? 'rgba(250,255,255, 0.2)' : 'rgba(0,0,0, 0.4)'
                }
            }
        }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, height: 820, width: '95%', maxWidth: 700, background: theme.palette.background.default, outline: 'none', }}>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 3, padding: 5, paddingBottom: 0 }}>
                    {getIcon(39, theme.palette.primary.main, 30)}
                    <Typography sx={{ color: theme.palette.text.primary, fontWeight: 'bold', fontSize: 22 }}>Selecione os contatos para o envio.</Typography>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column', height: '80%', padding: 5, paddingTop: 2, gap: 2, paddingBottom: 2 }}>
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 2 }}>
                        <CTextField label={'Buscar'} value={filter} onChangeText={(value) => setFilter(value)} width={'50%'} />
                        <Checkbox
                            checked={allSelected}
                            onChange={handleSelectAll}
                            inputProps={{ 'aria-label': 'select all contacts' }}
                        />
                        <Typography>Selecionar Todos</Typography>
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, overflowY: 'scroll', padding: 2 }}>
                        {paginatedContatos.map((contato, index) => {
                            const isCheck = selectContatos.findIndex((el) => el.id === contato.id) !== -1;
                            return (
                                <Button onClick={() => handleCheck(contato, isCheck)} sx={{ width: '100%', textAlign: 'left', textTransform: 'none' }} key={contato.id}>
                                    <ThemeProvider theme={customTheme} >
                                        <Badge sx={{ width: '100%' }} badgeContent={isCheck ? <Box sx={{ background: theme.palette.primary.main, borderRadius: 5 }}>{getIcon(59, theme.palette.text.onPrimary)}</Box> : null} anchorOrigin={{ vertical: 'top', horizontal: 'left' }}>
                                            <Box sx={{ display: 'flex', background: theme.palette.background.default, boxShadow: theme.palette.mode === 'dark' ? '0px 4px 10px rgba(155, 155, 155, 0.3)' : '0px 4px 10px rgba(0, 0, 0, 0.1)', padding: 3, alignItems: 'center', gap: 3, width: '100%' }}>
                                                {contato?.profilePicture ?
                                                    <Box sx={{ borderRadius: 25, boxShadow: theme.palette.mode === 'dark' ? '0px 4px 10px rgba(0, 0, 0, 0.3)' : '0px 4px 10px rgba(0, 0, 0, 0.1)', overflow: 'hidden', height: 40, width: 40 }}>
                                                        <img src={contato?.profilePicture} style={{ objectFit: 'contain', width: '100%', borderRadius: 90 }} />
                                                    </Box>
                                                    :
                                                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 40, width: 40, borderRadius: 25, boxShadow: theme.palette.mode === 'dark' ? '0px 4px 10px rgba(0, 0, 0, 0.3)' : '0px 4px 10px rgba(0, 0, 0, 0.1)' }}>
                                                        {getIcon(33, theme.palette.primary.main, 30)}
                                                    </Box>
                                                }
                                                <Box>
                                                    <Typography sx={{ color: theme.palette.text.primary, fontWeight: 'bold' }}>{contato.nome}</Typography>
                                                    {contato?.numero &&
                                                        <Typography sx={{ color: theme.palette.text.primary }}>{formatPhoneNumber(contato.numero)}</Typography>
                                                    }
                                                    {contato?.email &&
                                                        <Typography sx={{ color: theme.palette.text.primary }}>{contato.email}</Typography>
                                                    }
                                                </Box>
                                            </Box>
                                        </Badge>
                                    </ThemeProvider>
                                </Button>
                            );
                        })}
                    </Box>
                    <Pagination
                        count={Math.ceil(filteredContatos.length / itemsPerPage)}
                        page={page}
                        onChange={handleChangePage}
                        color="primary"
                        sx={{ marginTop: 2, alignSelf: 'center' }}
                    />
                </Box>
                <Box sx={{ display: 'flex', padding: 5, paddingTop: 0, paddingBottom: 2, gap: 2 }}>
                    {['Salvar', 'Voltar',].map((e, idx) => {
                        return (
                            <ButtonSubmit key={e} text={e} background={idx === 1 ? theme.palette.background.paper : idx === 2 ? theme.palette.secondary.main : theme.palette.primary.main} color={idx === 1 ? theme.palette.text.primary : theme.palette.text.onPrimary} func={handleSubmit} funcParameters={e} />
                        );
                    })}
                </Box>
                <ModalInfo hideModal={() => updateModalInfo(false)} canDesactive={MInfo.canDesactive} visible={MInfo.visible} title={MInfo.title} subtitle={MInfo.subtitle} icon={MInfo.icon} funcS={MInfo.funcS} />
            </Box>
        </Modal>
    );
}
