import { createContext, useState, useEffect } from 'react'
import Cookies from 'universal-cookie';
import MAPI from '../api/mainApi';
import { decrypt, encrypt, } from '../components/encDec';

export const User = createContext()

export default props => {
    const cookies = new Cookies()
    const [data, setData] = useState({
        token: null
    })
    const [moduloAtivo, setModuloAtivo] = useState(null)
    const [themeMode, setThemeMode] = useState('defaultTheme')
    async function getUser() {
        let user = sessionStorage.getItem('its_chat_ck_user')
        if (user) {
            user = decrypt(user)
            const get = await getPermissoes(user.token)
            if (get !== false) {
                user.permissoes = Array.isArray(get?.modulos) ? get.modulos : []
                user.conexoes = get?.conexoes ? get.conexoes : false
                setData(user)
                sessionStorage.setItem('its_chat_ck_user', encrypt(user))
                return
            }
            logOff()
        } else {
            user = cookies.get('its_chat_ck_user')
            if (user) {
                user = decrypt(user)
                const get = await getPermissoes(user.token)
                if (get === false) {
                    logOff()
                    return
                }
                user.permissoes = Array.isArray(get?.modulos) ? get.modulos : []
                user.conexoes = get?.conexoes ? get.conexoes : false
                setData(user)
                cookies.update('its_chat_ck_user', encrypt(user), {
                    //secure: true,
                    sameSite: 'strict',
                    maxAge: 999999999,
                    path: '/'
                })
            }
            else {
                logOff()
            }
        }
    }
    function updateUser(newUser, keepConnect) {
        if (keepConnect) {
            cookies.set('its_chat_ck_user', encrypt(newUser), {
                //secure: true,
                sameSite: 'strict',
                maxAge: 999999999,
                path: '/'
            })
        } else {
            sessionStorage.setItem('its_chat_ck_user', encrypt(newUser))
        }
        setData(newUser)
    }

    async function getPermissoes(token) {
        const getPermissoes = await MAPI('usuarios/permissoes', 'GET', null, token)
        if (getPermissoes?.status === 200) {
            return getPermissoes?.apiReturn?.apiReturn
        } else {
            return false
        }
    }

    function changeModuloAtivo(modulo) {
        setModuloAtivo(modulo)
    }
    function logOff() {
        setData({
            token: false
        })
        sessionStorage.removeItem('its_chat_ck_user')
        cookies.remove('its_chat_ck_user', { path: '/' })
    }
    useEffect(() => {
        setTimeout(() => {
            getUser()
        }, 1000)
    }, [])
    return (
        <User.Provider value={{ data, updateUser, moduloAtivo, changeModuloAtivo, themeMode, logOff }}>
            {props.children}
        </User.Provider>
    )
}

