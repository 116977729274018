import React, { useState, useContext, useEffect } from 'react';
import { useTheme, Box, Typography, Button, Badge, createTheme, ThemeProvider } from '@mui/material';
import { ModalInfo } from '../../../../../components/modalInfo';
import { User } from '../../../../../contexts/user';
import MAPI from '../../../../../api/mainApi';
import { UpComp } from '../../../../../components/upComp';
import { getIcon } from '../../../../../components/icons';

export function Conexao({ slideAtivo, data, updateData, mensagem }) {
    const theme = useTheme();
    const user = useContext(User);
    const customTheme = createTheme({
        palette: {
            primary: { main: theme.palette.primary.main },
        },
    });
    //estados
    const [MInfo, setMInfo] = useState({
        visible: false,
        title: '',
        subtitle: '',
        canDesactive: true,
        icon: '',
        funcS: () => null
    });
    const [cons, setCons] = useState([])

    function updateModalInfo(visible, canDesactive, title, subtitle, icon, func) {
        const newMInfo = { ...MInfo };
        newMInfo.visible = visible;
        newMInfo.canDesactive = canDesactive;
        newMInfo.title = title;
        newMInfo.subtitle = subtitle;
        newMInfo.icon = icon;
        newMInfo.funcS = func;
        setMInfo(newMInfo);
    }

    async function getCon() {
        updateModalInfo(true, false, 'Carregando', 'Buscando suas conexões', 'loading')
        const get = await MAPI('conexoes/minhasConexoes', 'GET', null, user?.data?.token)
        if (get?.status !== 200) {
            const message = get?.apiReturn?.message ? get?.apiReturn?.message : 'Ocorreu um erro ao buscar as conexões.'
            updateModalInfo(true, true, 'Atenção', message, 'exclamation')
            return
        }
        setCons(Array.isArray(get?.apiReturn?.apiReturn) ? get?.apiReturn?.apiReturn : [])
        updateModalInfo(false)
    }

    async function handleSubmit(con) {
        if (data.con === con) {
            updateData(null, 'con')
            return

        }
        updateModalInfo(true, false, 'Carregando', 'Validando conexão.', 'loading')
        const verifyStatus = await MAPI(`conexoes/status/${con.id}?noqrcode`, 'GET', null, user?.data?.token)
        if (verifyStatus?.status !== 200) {
            const message = verifyStatus?.apiReturn?.message ? verifyStatus?.apiReturn?.message : 'Ocorreu um erro ao buscar o status da conexão.'
            updateModalInfo(true, true, 'Atenção', message, 'exclamation')
            return
        }
        if (verifyStatus?.apiReturn?.apiReturn[0] !== 'ready') {
            updateModalInfo(true, true, 'Atenção', 'Conexão inativa ou com problemas.', 'exclamation')
            return
        }
        updateModalInfo(false)
        updateData(con, 'con')
        return
    }

    useEffect(() => {
        if (slideAtivo === 0 && cons.length === 0) {
            getCon()
        }
    }, [slideAtivo])

    return (
        <Box sx={{
            flex: 1,
        }}>
            <Box sx={{ overflowY: 'scroll', maxHeight: 400, padding: 2, paddingBottom: 0 }}>
                {[cons.map((e, index) => {
                    if (mensagem.tipo === 4 && e.tipo_id !== 2) return
                    if (mensagem.tipo !== 4 && e.tipo_id === 2) return
                    return (
                        <Button sx={{ width: '100%', textAlign: 'left' }} onClick={() => handleSubmit(e)} >
                            <ThemeProvider theme={customTheme}>
                                <Badge sx={{ width: '100%' }} badgeContent={data?.con?.id === e.id ? <Box sx={{ background: theme.palette.secondary.main, borderRadius: 5 }}>{getIcon(59, theme.palette.text.onPrimary)}</Box> : null} anchorOrigin={{ vertical: 'top', horizontal: 'left' }}>
                                    <Box key={index} sx={{ width: '100%', display: 'flex', flexDirection: 'column', gap: 2 }}>
                                        <Box sx={{ display: 'flex', background: theme.palette.background.default, boxShadow: theme.palette.mode === 'dark' ? '0px 4px 10px rgba(155, 155, 155, 0.3)' : '0px 4px 10px rgba(0, 0, 0, 0.1)', padding: 3, alignItems: 'center', gap: 3 }}>
                                            <UpComp>
                                                {
                                                    getIcon(e.icon, theme.palette.primary.main, 25)
                                                }
                                            </UpComp>
                                            <Box>
                                                <Typography sx={{ color: theme.palette.text.primary, textTransform: 'none', fontWeight: 'bold', textAlign: 'left' }}>{e.nome}</Typography>
                                                <Typography sx={{ color: theme.palette.text.primary, textTransform: 'none' }}>{e.descricao}</Typography>
                                                <Typography sx={{ color: theme.palette.text.primary, textTransform: 'none' }}>{'Tipo: ' + e.tipo_nome}</Typography>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Badge>
                            </ThemeProvider>
                        </Button>
                    )
                })]}
            </Box>
            <ModalInfo hideModal={() => updateModalInfo(false)} canDesactive={MInfo.canDesactive} visible={MInfo.visible} title={MInfo.title} subtitle={MInfo.subtitle} icon={MInfo.icon} funcS={MInfo.funcS} />
        </Box>
    );
}