export const cpfMask = value => {
    return value
        .replace(/\D/g, '') // substitui qualquer caracter que nao seja numero por nada
        .replace(/(\d{3})(\d)/, '$1.$2') // captura 2 grupos de numero o primeiro de 3 e o segundo de 1, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de numero
        .replace(/(\d{3})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d{1,2})/, '$1-$2')
        .replace(/(-\d{2})\d+?$/, '$1') // captura 2 numeros seguidos de um traço e não deixa ser digitado mais nada
}

export const celularMask = value => {
    return value.replace(/\D/g, '')
        .replace(/(\d{2})(\d)/, '($1) $2')
        .replace(/(\d{5})(\d)/, '$1-$2')
        .replace(/(-\d{4})\d+?$/, '$1')
}

export const cepMask = value => {
    return value.replace(/\D/g, '')
        .replace(/(\d{5})(\d)/, '$1-$2')
        .replace(/(-\d{3})\d+?$/, '$1')
}

export const onlyString = value => {
    return value.replace(/[^a-zA-Z]+/g, '')
}
export const onlyInteger = value => {
    return value.replace(/\D/g, "")
}
export const realMask = value => {
    if (!value) {
        return '00,00';
    }
    let newText = value.replace(/(\D)/g, '').replace(/^0+/, '');

    if (newText.length < 4) {
        for (let i = newText.length; i < 4; i++) {
            newText = '0' + newText;
        }
    }
    newText = newText
        .replace(/(\d{2}$)/g, ',$&')
        .replace(/(\d{1})(\d{3})([,])/, '$1$2$3')
        .replace(/(\d{1})(\d{3})([.])/, '$1$2$3')
        .replace(/(\d{1})(\d{3})([.])/, '$1$2$3');
    return newText;
}

export const formatPhoneNumber = (phoneNumber) => {
    const cleaned = phoneNumber.replace(/\D/g, '');
    const countryCode = '55';
    let numberWithoutCountryCode = cleaned;
    if (cleaned.startsWith(countryCode)) {
        numberWithoutCountryCode = cleaned.slice(countryCode.length);
    }
    const regex = /^(\d{2})(9?\d{4})(\d{4})$/;
    const match = numberWithoutCountryCode.match(regex);
    if (match) {
        const formattedNumber = `(${match[1]}) ${match[2]}-${match[3]}`;
        return formattedNumber;
    } else {
        return '';
    }
}

export function removerCaracteresEspeciais(str) {
    const mapaAcentos = {
        'Á': 'A', 'á': 'a',
        'É': 'E', 'é': 'e',
        'Í': 'I', 'í': 'i',
        'Ó': 'O', 'ó': 'o',
        'Ú': 'U', 'ú': 'u',
        'Â': 'A', 'â': 'a',
        'Ê': 'E', 'ê': 'e',
        'Î': 'I', 'î': 'i',
        'Ô': 'O', 'ô': 'o',
        'Û': 'U', 'û': 'u',
        'Ã': 'A', 'ã': 'a',
        'Õ': 'O', 'õ': 'o',
        'À': 'A', 'à': 'a',
        'È': 'E', 'è': 'e',
        'Ì': 'I', 'ì': 'i',
        'Ò': 'O', 'ò': 'o',
        'Ù': 'U', 'ù': 'u',
        'Ä': 'A', 'ä': 'a',
        'Ë': 'E', 'ë': 'e',
        'Ï': 'I', 'ï': 'i',
        'Ö': 'O', 'ö': 'o',
        'Ü': 'U', 'ü': 'u',
        'Ç': 'C', 'ç': 'c',
        'Ñ': 'N', 'ñ': 'n',
        'Ý': 'Y', 'ý': 'y',
        '[': ' ', ']': ' ',
        '{': ' ', '}': ' ',
        '`': ' ', '´': ' ',
        "'": ' ', '"': ' ',
        '~': ' ', '^': ' ',
        ';': ' ', '/': ' ',
        '\\': ' ', '|': ' ',
        '&': ' ', '%': ' ',
        '$': ' ', '#': ' ',
        '@': ' ', '!': ' ',
        '?': ' ', '(': ' ',
        ')': ' ', '=': ' ',
        '+': ' ', '*': ' ',
        ',': ' ', '.': ' ',
        '<': ' ', '>': ' ',
        ':': ' ', '-': ' ',
        '_': ' ', '§': ' ',
        '¬': ' ', '¨': ' ',
        ' ': ' ', '\n': ' ',
        '\r': ' ', '\t': ' ',
        '\v': ' ', '\f': ' '
    };

    return str.replace(/[^a-zA-Z0-9\s]/g, function (match) {
        return mapaAcentos[match] || ' ';
    }).replace(/\s+/g, ' ').trim();
}
