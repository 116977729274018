import { createContext, useContext, useEffect, useState } from "react";
import MAPI from "../api/mainApi";
import { User } from "./user";


export const Variaveis = createContext([])

export function VariaveisC({ children }) {
    const [data, setData] = useState([])
    const user = useContext(User)

    async function getData() {
        if (data.length > 0 || !user?.data?.token) return
        const get = await MAPI('variaveis/getVariaveisInput', 'GET', null, user?.data?.token)
        if (get?.status === 200) {
            setData(get?.apiReturn?.apiReturn)
        }
    }

    useEffect(() => {
        getData()
    }, [user?.data])
    return (
        <Variaveis.Provider value={{ data }}>
            {children}
        </Variaveis.Provider>
    )
}