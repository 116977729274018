import Logo_w from '../../inc/images/logo_w.png'
import Logo_d from '../../inc/images/logo_wb.png'
import MinLogo from '../../inc/images/flaticon.png'

export const DesertDuskTheme = (mode, changeMode) => {
    return {
        palette: {
            logo: mode === 'light' ? Logo_w : Logo_d,
            minLogo: MinLogo,
            mode: mode,
            changeMode: changeMode,
            ...(mode === 'light'
                ? {
                    primary: {
                        main: '#FF8F00',
                        light: '#FFE082',
                        dark: '#FF6F00'
                    },
                    secondary: {
                        main: '#D84315',
                        light: '#FF7043',
                        dark: '#BF360C'
                    },
                    tertiary: {
                        main: '#FFB300',
                        light: '#FFEB3B',
                        dark: '#FFA000'
                    },
                    background: {
                        default: '#FFF8E1',
                        paper: '#FFECB3',
                        inverse: '#3E2723'
                    },
                    inverseBackground: {
                        default: '#5D4037',
                        paper: '#4E342E'
                    },
                    warning: { main: '#E64A19' },
                    success: { main: '#81C784' },
                    error: { main: '#D32F2F' },
                    text: {
                        main: '#6D4C41',
                        primary: '#6D4C41',
                        disabled: '#BDBDBD',
                        inverse: '#FFF8E1',
                        onPrimary: '#000000',
                        dark: '#4E342E',
                        light: '#FAFAFA',
                        onDrawer: '#757575'
                    }
                } : {
                    primary: {
                        main: '#FFA726',
                        light: '#FFCC80',
                        dark: '#FB8C00'
                    },
                    secondary: {
                        main: '#E65100',
                        light: '#FF8A65',
                        dark: '#BF360C'
                    },
                    tertiary: {
                        main: '#FFC107',
                        light: '#FFD54F',
                        dark: '#FFA000'
                    },
                    background: {
                        default: '#3E2723',
                        paper: '#4E342E',
                        inverse: '#FFF8E1'
                    },
                    inverseBackground: {
                        default: '#FFECB3',
                        paper: '#FFE082'
                    },
                    warning: { main: '#FF5722' },
                    success: { main: '#8BC34A' },
                    error: { main: '#FF5252' },
                    text: {
                        main: '#EFEBE9',
                        primary: '#EFEBE9',
                        disabled: '#757575',
                        inverse: '#3E2723',
                        onPrimary: '#000000',
                        dark: '#FFCCBC',
                        onDrawer: '#9E9E9E',
                        light: '#FAFAFA'
                    }
                }
            )
        }
    }
}
