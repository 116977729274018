import React, { useState, useContext, useRef, useCallback, useMemo, useEffect } from 'react';
import { useTheme, Box, Modal, Button, Typography, Divider } from '@mui/material';
import { ModalInfo } from '../../../components/modalInfo';
import { User } from '../../../contexts/user';
import ReactFlow, { MiniMap, Controls, Background, useNodesState, useEdgesState, addEdge, useReactFlow } from 'reactflow';
import 'reactflow/dist/style.css';
import { SideBar } from './new/sideBar';
import { EnvEmail, EnvMessage, Init, No, Yes, Options, Condicao, IfElse, NewContato, NewLead, Relogio, InitChamado } from './new/blocks';
import CustomEdge from './new/customEdge';
import MAPI from '../../../api/mainApi';
import { CTextField } from '../../../components/TextField';
import { ButtonSubmit } from '../../../components/buttons';
const initialNodes = [
    { id: '1', type: 'init', position: { x: 50, y: 300 } },
];
const initialEdges = [];
const initReferencias = [{ id: 800, nome: `[[nome]]`, option: 1 }, { id: 801, nome: `[[nome_completo]]`, option: 2 }, { id: 802, nome: `[[cpf]]`, option: 3 }, { id: 803, nome: `[[cnpj]]`, option: 4 }, { id: 804, nome: `[[email]]`, option: 5 }]
export function New({ visible, prevData, updateModFluxo, getFluxos }) {
    const theme = useTheme();
    const user = useContext(User);
    const reactFlowWrapper = useRef(null);
    const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
    const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);
    const [responsaveis, setResponsaveis] = useState(null)
    const { project, setViewport } = useReactFlow();
    const [selectedNodeId, setSelectedNodeId] = useState(null);
    const [rfInstance, setRfInstance] = useState(null);
    const [tags, setTags] = useState(null)
    const [nome, setNome] = useState('')
    const [id, setId] = useState(null)
    const [referencias, setReferecias] = useState(initReferencias)
    const [emailModelos, setEmailModelos] = useState(null)
    const nodeTypes = useMemo(() => ({ init: Init, envEmail: (props) => <EnvEmail {...props} GetEmailModelos={GetEmailModelos} emailModelos={emailModelos} createYesNoNodes={createYesNoNodes} excludeTargetNodes={excludeTargetNodes} />, envMensagem: (props) => <EnvMessage {...props} updateModalInfo={updateModalInfo} userToken={user?.data?.token} referencias={referencias} updateReferencias={updateReferencias} createOptions={createOptions} createYesNoNodes={createYesNoNodes} excludeTargetNodes={excludeTargetNodes} />, yes: (props) => <Yes {...props} />, no: (props) => <No {...props} />, IfElse: (props) => <IfElse {...props} />, option: (props) => <Options {...props} referencias={referencias} />, condicao: (props) => <Condicao {...props} tags={tags} getTags={getTags} createIfElse={createIfElse} excludeTargetNodes={excludeTargetNodes} />, newContato: (props) => <NewContato {...props} />, newLead: (props) => <NewLead {...props} />, relogio: (props) => <Relogio {...props} />, initChamado: (props) => <InitChamado {...props} getResponsaveis={getResponsaveis} responsaveis={responsaveis} /> }), [emailModelos, referencias, tags, responsaveis])
    const edgeTypes = useMemo(() => ({ customEdge: (props) => <CustomEdge {...props} setEdges={setEdges} /> }), [])
    const onConnect = useCallback((params) => setEdges((eds) => addEdge(params, eds)), [setEdges]);
    const [MInfo, setMInfo] = useState({
        visible: false,
        title: '',
        subtitle: '',
        canDesactive: true,
        icon: '',
        funcS: () => null
    });
    async function getResponsaveis() {
        const get = await MAPI('responsaveis/getResponsaveisFluxo', 'GET', null, user?.data?.token)
        console.log(get)
        if (get?.status !== 200) {
            setResponsaveis(false)
            return
        }
        setResponsaveis(get?.apiReturn?.apiReturn)
    }
    async function getTags() {
        const get = await MAPI('tags/list', 'GET', null, user?.data?.token)
        if (get?.status !== 200) {
            setTags(false)
            return
        }
        setTags(get?.apiReturn?.apiReturn)
    }
    function updateReferencias(referencia, id, option) {
        const newReferencia = [...referencias]
        const findIndex = newReferencia.findIndex((e) => e.id === id)
        if (findIndex === -1) {
            newReferencia.push({ id: id, nome: `[[${referencia}]]`, option: option })
        }
        else {
            newReferencia[findIndex].nome = `[[${referencia}]]`
        }
        setReferecias(newReferencia)
    }
    const removeReferencia = useCallback((id) => {
        setReferecias((prev) => {
            if (Array.isArray(id)) {
                console.log(referencias)
                const newRef = prev.filter((e) => !id.includes(e.id))
                return newRef
            }
            const newReferencia = prev.filter((e) => e.id !== id)
            return newReferencia
        })
    }, [updateReferencias, referencias, setReferecias])

    const onDragOver = useCallback((event) => {
        event.preventDefault();
        event.dataTransfer.dropEffect = 'move';
    }, []);
    const onDrop = useCallback(
        (event) => {
            event.preventDefault();
            const type = event.dataTransfer.getData('application/reactflow');
            if (!type) return;

            const position = project({
                x: event.clientX - reactFlowWrapper.current.getBoundingClientRect().left,
                y: event.clientY - reactFlowWrapper.current.getBoundingClientRect().top,
            });

            setNodes((nds) => {
                let maxNodeId = '1'
                nds.map((e) => {
                    maxNodeId = parseInt(e.id) > parseInt(maxNodeId) ? String(e.id) : maxNodeId
                })
                maxNodeId = parseInt(maxNodeId)
                console.log(maxNodeId)
                const nNode = newN(String(maxNodeId + 1), type, position, excludeNode, setNodes, false)
                return nds.concat(nNode)
            });
        },
        [project, nodes, setNodes]
    );
    const excludeNode = useCallback(async (id) => {
        removeReferencia(id)
        let removedNodes = await getConnectedNodeIds(id);
        setNodes((nodes) => {
            const currentNode = nodes.find(node => node.id === id);
            let newNodes = [...nodes]
            if (!currentNode?.id) return nodes;
            if (['envMensagem', 'condicao'].includes(currentNode.type)) {
                newNodes = newNodes.filter((e) => !removedNodes.includes(e.id))
            }
            newNodes = newNodes.filter((e) => e.id !== id)
            return newNodes
        });
        setEdges((prevEdges) => {
            const filter = prevEdges.filter((e) => ![e.target, e.source].includes(id))
            return filter
        })
    }, [setNodes, onDrop, nodes, edges]);
    const onNodeClick = useCallback((event, node) => {
        setSelectedNodeId(node.id);
    }, []);
    async function GetEmailModelos() {
        const get = await MAPI('email/getEmailModelos', 'GET', null, user?.data?.token)
        if (get?.status !== 200) {
            setEmailModelos(false)
            return
        }
        setTimeout(() => {
            setEmailModelos(get?.apiReturn?.apiReturn)
        }, 3000)
    }
    function updateModalInfo(visible, canDesactive, title, subtitle, icon, func) {
        setMInfo({ visible, canDesactive, title, subtitle, icon, funcS: func });
    }
    const createOptions = useCallback(async (id, quantidade) => {
        let removedNodes = await getConnectedNodeIds(id)
        removeReferencia(removedNodes)
        setNodes((currentNodes) => {
            const currentNode = currentNodes.find(node => node.id === id);
            if (!currentNode?.id) return currentNodes;
            let maxNodeId = '0';
            currentNodes.forEach((node) => {
                maxNodeId = parseInt(node.id) > parseInt(maxNodeId) ? String(node.id) : maxNodeId;
            });
            maxNodeId = parseInt(maxNodeId);
            let basePosition = -100;
            const newNodes = [];
            for (let i = 0; i < quantidade; i++) {
                const position = { x: currentNode.position.x + 500, y: currentNode.position.y + basePosition };
                basePosition += 150;
                const newId = `${maxNodeId + 1 + i}`;
                const nNode = newN(newId, 'option', position, excludeNode, setNodes, true, (i + 1), id);
                newNodes.push(nNode);
            }
            setEdges((edges) => {
                const newEdges = newNodes.map((nNode) => ({
                    id: `e${currentNode.id}-${nNode.id}`,
                    type: 'customEdge',
                    source: currentNode.id,
                    target: nNode.id,
                    data: { permanent: true }
                }));
                const updatedEdges = edges.concat(newEdges);
                return updatedEdges;
            });
            const updatedNodes = currentNodes.filter((node) => !removedNodes.includes(node.id));
            return [...updatedNodes, ...newNodes];
        });
    }, [excludeNode, setEdges, setNodes, removeNodes, newN]);

    const getConnectedNodeIds = useCallback(async (nodeId) => {
        return new Promise((resolve) => {
            let connectedNodeIds = [];
            setEdges((edges) => {
                edges.forEach(edge => {
                    if (edge.source === nodeId) {
                        connectedNodeIds.push(edge.target);
                    }
                });
                resolve(connectedNodeIds);
                return edges
            });
        });
    }, [excludeNode, setEdges, setNodes, removeNodes, newN]);

    const createYesNoNodes = useCallback(async (id) => {
        let removedNodes = await getConnectedNodeIds(id)
        removeReferencia(removedNodes)
        setNodes((currentNodes) => {
            const currentNode = currentNodes.find(node => node.id === id);
            if (!currentNode?.id) return currentNodes;

            let maxNodeId = '0';
            currentNodes.forEach((node) => {
                maxNodeId = parseInt(node.id) > parseInt(maxNodeId) ? String(node.id) : maxNodeId;
            });
            maxNodeId = parseInt(maxNodeId);
            const positionY = { x: currentNode.position.x + 500, y: currentNode.position.y };
            const positionN = { x: currentNode.position.x + 500, y: currentNode.position.y + 70 };
            const yesNode = newN(`${maxNodeId + 1}`, 'yes', positionY, excludeNode, setNodes, true, null, id);
            const noNode = newN(`${maxNodeId + 2}`, 'no', positionN, excludeNode, setNodes, true, null, id);
            setEdges((edges) => {
                const edge1 = {
                    id: `e${currentNode.id}-${yesNode.id}`,
                    type: 'customEdge',
                    source: currentNode.id,
                    target: yesNode.id,
                    data: { permanent: true }
                };
                const edge2 = {
                    id: `e${currentNode.id}-${noNode.id}`,
                    type: 'customEdge',
                    source: currentNode.id,
                    target: noNode.id,
                    data: { permanent: true }
                };
                return edges.concat(edge1, edge2);
            });
            const updatedNodes = currentNodes.filter((node) => !removedNodes.includes(node.id));
            return [...updatedNodes, yesNode, noNode];
        });
    }, [excludeNode, setEdges, setNodes, removeNodes, newN]);
    const createIfElse = useCallback(async (id) => {
        let removedNodes = await getConnectedNodeIds(id)
        removeReferencia(removedNodes)
        setNodes((currentNodes) => {
            const currentNode = currentNodes.find(node => node.id === id);
            if (!currentNode?.id) return currentNodes;

            let maxNodeId = '0';
            currentNodes.forEach((node) => {
                maxNodeId = parseInt(node.id) > parseInt(maxNodeId) ? String(node.id) : maxNodeId;
            });
            maxNodeId = parseInt(maxNodeId);
            const positionY = { x: currentNode.position.x + 500, y: currentNode.position.y };
            const positionN = { x: currentNode.position.x + 500, y: currentNode.position.y + 70 };
            const yesNode = newN(`${maxNodeId + 1}`, 'IfElse', positionY, excludeNode, setNodes, true, 'Se Sim', id);
            const noNode = newN(`${maxNodeId + 2}`, 'IfElse', positionN, excludeNode, setNodes, true, 'Se Não', id);
            setEdges((edges) => {
                const edge1 = {
                    id: `e${currentNode.id}-${yesNode.id}`,
                    type: 'customEdge',
                    source: currentNode.id,
                    target: yesNode.id,
                    data: { permanent: true }
                };
                const edge2 = {
                    id: `e${currentNode.id}-${noNode.id}`,
                    type: 'customEdge',
                    source: currentNode.id,
                    target: noNode.id,
                    data: { permanent: true }
                };
                return edges.concat(edge1, edge2);
            });
            const updatedNodes = currentNodes.filter((node) => !removedNodes.includes(node.id));
            return [...updatedNodes, yesNode, noNode];
        });
    }, [excludeNode, setEdges, setNodes, removeNodes, newN]);
    const excludeTargetNodes = useCallback(async (nodeId) => {
        setEdges((currentEdges) => {
            const [newEdges, newNodes] = removeNodes(currentEdges, nodeId);
            removeReferencia(newNodes)
            if (newNodes.length === 0) return currentEdges
            setNodes((currentNodes) => {
                return currentNodes.filter((node) => !newNodes.includes(node.id));
            });
            return currentEdges.filter((edge) => !newEdges.includes(edge.id))
        })
    }, [setNodes, setEdges]);
    function handleDismiss() {
        setEdges(initialEdges)
        setNodes(initialNodes)
        setNome('')
        updateModFluxo()
        setId(null)
    }
    const formatNode = (node) => {
        const type = node?.type
        if (type === 'init') {
            return {
                id: node?.id,
                type: 'init'
            }
        }
        if (type === 'envEmail') {
            return {
                id: node.id,
                type: 'envEmail',
                email: node?.data?.enviarEmail?.selected
            }
        }
        if (type === 'envMensagem') {
            if (node?.data?.enviarMensagem?.selected?.options) {
                delete node?.data?.enviarMensagem?.selected?.options
            }
            return {
                id: node.id,
                type: 'envMensagem',
                mensagem: node?.data?.enviarMensagem?.mensagem,
                selected: node?.data?.enviarMensagem?.selected,
            }
        }
        if (type === 'condicao') {
            return {
                id: node?.id,
                type: 'condicao',
                selected: node?.data?.condicao?.selected,
                tag: node?.data?.condicao?.tag
            }
        }
        if (type === 'option') {
            return {
                id: node?.id,
                type: 'option',
                numero: node?.data?.option,
                mensagem: node?.data?.envOptionMsn?.mensagem,
            }
        }
        if (type === 'relogio') {
            return {
                id: node?.id,
                type: 'relogio',
                tempo: node?.data?.relogio?.tempo,
                selected: node?.data?.relogio?.selected
            }
        }
        if (type === 'initChamado') {
            return {
                id: node?.id,
                type: 'initChamado',
                tipos: node?.data?.initChamado?.tipos,
                selected: node?.data?.initChamado?.selected
            }
        }
        if (['yes', 'no', 'IfElse', 'newContato', 'newLead'].includes(type)) {
            return {
                id: node.id,
                type: type,
            }
        }
        return node;
    }
    const buildFlowHierarchy = (nodeId, edgesMap, nodesMap) => {
        const node = nodesMap[nodeId];
        const children = edgesMap[nodeId] || [];
        return {
            ...formatNode(node),
            children: children.map(childId => buildFlowHierarchy(childId, edgesMap, nodesMap))
        };
    };
    const removeEmptyChildren = (node) => {
        if (node.children) {
            node.children = node.children.map(removeEmptyChildren).filter(child => Object.keys(child).length > 0);
            if (node.children.length === 0) {
                delete node.children;
            }
        }
        return node;
    };
    const removeEmptyAndDuplicateChildren = (node, visitedIds = new Set()) => {
        if (visitedIds.has(node.id)) {
            return null;
        }
        visitedIds.add(node.id);

        if (node.children) {
            node.children = node.children.map(child => removeEmptyAndDuplicateChildren(child, visitedIds))
                .filter(child => child && Object.keys(child).length > 0);
            if (node.children.length === 0) {
                delete node.children;
            }
        }
        return node;
    };
    const isConnectedToRoot = (nodeId, edgesMap, targetId, visitedIds = new Set()) => {
        if (nodeId === targetId) return true;
        if (visitedIds.has(nodeId)) return false;
        visitedIds.add(nodeId);

        const children = edgesMap[nodeId] || [];
        for (let childId of children) {
            if (isConnectedToRoot(childId, edgesMap, targetId, visitedIds)) {
                return true;
            }
        }
        return false;
    };
    const exportFlow = async (nodes, edges, nome, control, confirm, referencias) => {
        if (id && !control) {
            updateModalInfo(true, true, 'Atenção', 'Ao editar um fluxo, as informações registradas para o fluxo serão perdidas. Deseja continuar?', 'question', () => exportFlow(nodes, edges, nome, true, false, referencias));
            return;
        }
        if (nome.length < 3) {
            updateModalInfo(true, true, 'Atenção', 'Nome precisa ter no mínimo 3 caracteres', 'exclamation');
            return;
        }
        const newNodes = [...nodes];
        const newEdges = [...edges];
        const nodesMap = newNodes.reduce((acc, node) => {
            const findReferencia = referencias.filter((e) => e.id === node?.id)
            acc[node?.id] = node;
            if (findReferencia.length > 0) {
                acc[node?.id].referencia = findReferencia[0];
            }
            return acc;
        }, {});
        const edgesMap = newEdges.reduce((acc, edge) => {
            if (!acc[edge.source]) {
                acc[edge.source] = [];
            }
            acc[edge.source].push(edge.target);
            return acc;
        }, {});
        const rootNodes = newNodes.filter(node => !newEdges.some(edge => edge.target === node?.id));
        const flowHierarchy = rootNodes.map(rootNode => buildFlowHierarchy(rootNode.id, edgesMap, nodesMap));
        const cleanedHierarchy = flowHierarchy.map(rootNode => removeEmptyChildren(rootNode))
            .filter(rootNode => rootNode && Object.keys(rootNode).length > 0);
        if (cleanedHierarchy.length > 1 && !confirm) {
            updateModalInfo(true, true, 'Atenção', 'Existem componentes não conectados ao fluxo principal e não funcionarão. Deseja continuar?', 'question', () => exportFlow(nodes, edges, nome, control, true, referencias));
            return
        }

        updateModalInfo(true, false, 'Salvando', 'Estamos salvando seu fluxo', 'loading');
        console.log(cleanedHierarchy)
        const body = {
            nome: nome,
            fluxo: [cleanedHierarchy[0]],
            nodes: newNodes,
            edges: newEdges,
            referencias: referencias
        };
        if (id) {
            body.id = id;
        }

        const save = await MAPI('fluxo/saveFluxo', 'POST', body, user?.data?.token);
        if (save?.status !== 200) {
            const message = save?.apiReturn?.message ? save?.apiReturn?.message : 'Ocorreu um erro ao salvar o fluxo.';
            updateModalInfo(true, true, 'Atenção', message, 'exclamation');
            return;
        }

        setId(save?.apiReturn?.apiReturn);
        getFluxos();
        updateModalInfo(true, true, 'Sucesso', 'Seu fluxo foi salvo.', 'sucess');
    };
    const onSave = useCallback(() => {
        setNodes((prevNodes) => {
            exportFlow(prevNodes, edges, nome, false, false, referencias);
            return prevNodes;
        });
    }, [nome, id, excludeNode, setEdges, setNodes, removeNodes, createYesNoNodes, createOptions, setReferecias]);
    function handleButton(type) {
        if (type === 'Salvar') {
            onSave()
        }
        else {
            handleDismiss()
        }
    }
    const restoreFlow = useCallback((prevData) => {
        setNodes(prevData?.nodes ? getPrevNodes(prevData.nodes, excludeNode, setNodes) : initialNodes)
        setEdges(prevData?.edges ? prevData.edges : initialEdges)
        setNome(prevData?.nome ? prevData.nome : '')
        console.log(prevData.referencias)
        setReferecias(prevData.referencias ? prevData.referencias : initReferencias)
        setId(prevData?.id)
    }, [setNodes, setViewport])
    useEffect(() => {
        if (visible && prevData) {
            restoreFlow(prevData)
        }
    }, [prevData])
    return (
        <Modal
            open={visible}
            onClose={() => null}
            sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 2 }}
            slotProps={{
                backdrop: {
                    sx: {
                        backgroundColor: theme.palette.mode === 'dark' ? 'rgba(250,255,255, 0.2)' : 'rgba(0,0,0, 0.4)'
                    }
                }
            }}
        >
            <Box sx={{ display: 'flex', flexDirection: 'row', width: '98%', height: '98%', background: theme.palette.background.default, outline: 'none' }}>
                <div className="reactflow-wrapper" ref={reactFlowWrapper} style={{ height: '100%', width: '80%' }}>
                    <ReactFlow
                        nodes={nodes}
                        edges={edges}
                        onNodesChange={onNodesChange}
                        onEdgesChange={onEdgesChange}
                        onConnect={onConnect}
                        onDrop={onDrop}
                        onInit={setRfInstance}
                        onDragOver={onDragOver}
                        nodeTypes={nodeTypes}
                        edgeTypes={edgeTypes}
                        onNodeClick={onNodeClick}
                        connectionLineStyle={{
                            stroke: theme.palette.tertiary.main,
                            strokeWidth: 4,
                        }}
                        defaultEdgeOptions={{
                            type: 'customEdge',
                            style: { stroke: theme.palette.secondary.main, strokeWidth: 5 },
                        }}
                    >
                        <Controls />
                        <Background />
                    </ReactFlow>
                </div>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3, width: '20%', borderLeft: 1, borderColor: theme.palette.divider, padding: 2 }}>
                    <Typography sx={{ color: theme.palette.primary.main, fontWeight: 'bold', fontSize: 25, textAlign: 'center' }}>Criador de fluxo</Typography>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                        <Box sx={{ width: '70%' }}>
                            <CTextField label={'Nome do Fluxo'} value={nome} onChangeText={(value) => setNome(value)} />
                        </Box>
                        <Box sx={{ display: 'flex', gap: 3 }}>
                            {['Voltar', 'Salvar'].map((e, idx) => {
                                const background = idx === 0 ? theme.palette.background.paper : theme.palette.tertiary.main
                                const color = idx === 0 ? theme.palette.text.primary : theme.palette.text.onPrimary
                                return (
                                    <ButtonSubmit key={idx} func={handleButton} funcParameters={e} text={e} padding={1} width={'100%'} background={background} color={color} />
                                )
                            })}
                        </Box>
                    </Box>
                    <Divider />
                    <SideBar />
                </Box>
                <ModalInfo
                    hideModal={() => updateModalInfo(false)}
                    canDesactive={MInfo.canDesactive}
                    visible={MInfo.visible}
                    title={MInfo.title}
                    subtitle={MInfo.subtitle}
                    icon={MInfo.icon}
                    funcS={MInfo.funcS}
                />
            </Box>
        </Modal>
    );
}
function getPrevNodes(nodes, excludeNode, setNodes) {
    const newNodes = []
    nodes.map((e) => {
        const nameToNome = {
            envEmail: 'enviarEmail',
            option: 'envOptionMsn',
            envMensagem: 'enviarMensagem',
            condicao: 'condicao',
            relogio: 'relogio',
            initChamado: 'initChamado'
        }
        const { type } = e;
        if (nameToNome.hasOwnProperty(type)) {
            const nome = nameToNome[type]
            e.data[nome].function = (value, type) => {
                setNodes((nds) => nds.map((node) => node?.id === e.id ? {
                    ...node, data: { ...node.data, [nome]: { ...node.data[nome], [type]: value } }
                } : node))
            }
        }
        e.data = { ...e.data, ...{ excludeNode: excludeNode } }
        newNodes.push(e)
    })
    return newNodes
}
const newN = (id, type, position, excludeNode, setNodes, permanent, option, parent) => {
    const newNode = {
        id: id,
        type,
        position,
        data: {
            option: option,
            permanent: permanent,
            excludeNode: (id) => excludeNode(id),
            label: `${type} node`,
            enviarEmail: {
                selected: null,
                function: (value, type) => {
                    console.log(value, type)
                    setNodes((nds) => nds.map((node) => node?.id === newNode.id ? { ...node, data: { ...node.data, enviarEmail: { ...node.data.enviarEmail, [type]: value } } } : node))
                }
            },
            enviarMensagem: {
                selected: null,
                mensagem: '',
                function: (value, type) => {
                    setNodes((nds) => nds.map((node) => node?.id === newNode.id ? { ...node, data: { ...node.data, enviarMensagem: { ...node.data.enviarMensagem, [type]: value } } } : node))
                }
            },
            envOptionMsn: {
                mensagem: '',
                function: (value, type) => {
                    setNodes((nds) => nds.map((node) => node?.id === newNode.id ? { ...node, data: { ...node.data, envOptionMsn: { ...node.data.envOptionMsn, [type]: value } } } : node))
                }
            },
            condicao: {
                selected: null,
                IfElse: false,
                tag: null,
                function: (value, type) => {
                    setNodes((nds) => nds.map((node) => node?.id === newNode.id ? { ...node, data: { ...node.data, condicao: { ...node.data.condicao, [type]: value } } } : node))
                }
            },
            relogio: {
                selected: null,
                tempo: 1,
                function: (value, type) => {
                    setNodes((nds) => nds.map((node) => node?.id === newNode.id ? { ...node, data: { ...node.data, relogio: { ...node.data.relogio, [type]: value } } } : node))
                }
            },
            initChamado: {
                selected: null,
                tipos: null,
                function: (value, type) => {
                    setNodes((nds) => nds.map((node) => node?.id === newNode.id ? { ...node, data: { ...node.data, initChamado: { ...node.data.initChamado, [type]: value } } } : node))
                }
            }
        },
    }
    return newNode
}
function removeNodes(currentEdges, nodeId) {
    const newEdges = []
    const newNodes = []
    currentEdges.map((e) => {
        if (e.source == nodeId) {
            newEdges.push(e.id)
            newNodes.push(e.target)
        }
    })
    currentEdges.map((e) => {
        if (newNodes.includes(e.source)) {
            newEdges.push(e.id)
        }
    })
    return [newEdges, newNodes]
}