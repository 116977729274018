import { Typography, Box, Button, useTheme, Divider } from '@mui/material'
import { Form } from './blocks';
import { getIcon } from '../../../../components/icons';


export function SideBar() {
    const theme = useTheme()
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3, width: '100%', height: '100%' }}>
            <Box sx={{ width: '100%' }}>
                <Typography sx={{ fontWeight: 'bold', color: theme.palette.text.primary, fontSize: 22 }}>Ações</Typography>
                <Typography sx={{ color: theme.palette.text.primary }}>Arraste os botões para adicionar funções ao fluxo.</Typography>
            </Box>
            <Divider />
            <Box sx={{
                display: 'flex', flexDirection: 'column', width: '100%', gap: 3, height: '65%', overflowY: 'scroll', '&::-webkit-scrollbar': {
                    background: theme.palette.background.default,
                }, '&::-webkit-scrollbar-thumb': {
                    background: theme.palette.primary.main
                }
            }}>
                {ButtonsD.map((e, index) => {
                    return (
                        <Box key={index} sx={{ width: '100%' }}>
                            <Typography sx={{ fontWeight: 'bold', color: theme.palette.text.primary }}>{e.type}</Typography>
                            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                                {
                                    e.data.map((b, idx) => {
                                        return (
                                            <Button
                                                key={idx}
                                                draggable
                                                onDragStart={(event) => {
                                                    event.dataTransfer.setData('application/reactflow', b.class);
                                                    event.dataTransfer.effectAllowed = 'move';
                                                }}
                                                fullWidth
                                                sx={{ border: 1, borderColor: theme.palette.divider, textTransform: 'none', textAlign: 'left', padding: 1, }}
                                            >
                                                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}
                                                >
                                                    {getIcon(b.icon)}
                                                    <Typography sx={{ color: theme.palette.text.primary }}>{b.nome}</Typography>
                                                </Box>
                                            </Button>
                                        )
                                    })
                                }
                            </Box>
                        </Box>
                    )
                })}
            </Box>
        </Box>
    )
}

const ButtonsD = [
    {
        type: 'Comunicação',
        data: [
            {
                nome: 'Enviar email',
                icon: 71,
                class: 'envEmail'
            },
            {
                nome: 'Enviar mensagem',
                icon: 57,
                class: 'envMensagem'
            },
        ]
    },
    {
        type: 'Ações',
        data: [
            {
                nome: 'Tornar Contato',
                icon: 55,
                class: 'newContato'
            },
            {
                nome: 'Tornar Lead',
                icon: 76,
                class: 'newLead'
            },
            {
                nome: 'Iniciar chamado',
                icon: 77,
                class: 'initChamado'
            },
        ]
    },
    {
        type: 'Outros',
        data: [
            {
                nome: 'Condição',
                icon: 75,
                class: 'condicao'
            },
            {
                nome: 'Relógio',
                icon: 78,
                class: 'relogio'
            },
        ]
    }
]