import React, { useState } from 'react';
import { Button, TextField, Typography, Container, Grid, Card, CardContent, Avatar, useTheme, Box } from '@mui/material';
import { Main } from '../../components/View_Main';
import { LoginForm } from './components/loginForm';


export function Login() {
  const theme = useTheme()
  document.title = `Entrar - It's Chat`
  return (
    <Box sx={{ display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center', background: theme.palette.background.paper }}>
      <Box sx={{
        background: theme.palette.background.default, display: 'flex', width: '100%', height: '100%', borderRadius: 5
      }}>
        <Box sx={{
          width: '70%', background: theme.palette.background.paper, [theme.breakpoints.down('md')]: {
            display: 'none'
          },
        }}>

        </Box>
        <Box sx={{
          width: '30%', [theme.breakpoints.down('md')]: {
            width: '100%'
          },
        }}>
          <LoginForm />
        </Box>
      </Box>
    </Box>
  )
}
