import React, { useState, useContext, useEffect } from 'react';
import { useTheme, Box } from '@mui/material';
import { ModalInfo } from '../../components/modalInfo';
import { User } from '../../contexts/user';
import { ModuloTitle } from '../../components/moduloTitle';
import MAPI from '../../api/mainApi';
import { OpList } from './components/opList';
import { NovoOp } from './components/newOperador';
import { EditOp } from './components/editOperador';
import { Modulos } from './components/modulos';
import { Conexoes } from './components/conexoes';
import { validaEvento } from '../../Mainfunctions';

export function Operadores({ modulo }) {
    document.title = `Operadores - It's Chat`
    const theme = useTheme();
    const user = useContext(User);
    //estados
    const [data, setData] = useState([])
    const [setores, setSetores] = useState(null)
    const [MInfo, setMInfo] = useState({
        visible: false,
        title: '',
        subtitle: '',
        canDesactive: true,
        icon: '',
        funcS: () => null
    });
    const [newOp, setNewOp] = useState(false)
    const [editOp, setEditOp] = useState({
        data: {},
        visible: false
    })
    const [modulos, setModulos] = useState({
        visible: false,
        operador: null,
        data: null
    })
    const [conexoes, setConexoes] = useState({
        visible: false,
        operador: null,
        data: null
    })
    //funções
    function updateModalInfo(visible, canDesactive, title, subtitle, icon, func) {
        const newMInfo = { ...MInfo };
        newMInfo.visible = visible;
        newMInfo.canDesactive = canDesactive;
        newMInfo.title = title;
        newMInfo.subtitle = subtitle;
        newMInfo.icon = icon;
        newMInfo.funcS = func;
        setMInfo(newMInfo);
    }
    function pushData(operador) {
        const newData = [...data]
        newData.push(operador)
        setData(newData)
    }
    function openEdit(visible, operador) {
        if (validaEvento(modulo?.permissoes, 'editar') === false) {
            updateModalInfo(true, true, 'Atenção', 'Você não tem permissão para editar um operador.', 'exclamation')
            return
        }
        const newOp = { ...editOp }
        newOp.visible = visible ? visible : false
        newOp.data = operador ? operador : {}
        setEditOp(newOp)
    }
    function updateData(operador) {
        const newData = [...data]
        const findIndex = newData.findIndex((e) => e.id === operador.id)
        if (findIndex !== -1) {
            newData[findIndex] = operador
            setData(newData)
        }
    }
    function deleteOperador(operador) {
        const newData = data.filter((e) => e.id !== operador)
        setData(newData)
    }

    async function getSetores() {
        const get = await MAPI('setores/getSetor', 'GET', null, user?.data?.token)
        const apiData = get?.apiReturn?.apiReturn
        if (apiData?.length > 0) {
            setSetores(apiData)
        }
    }
    async function getData() {
        updateModalInfo(true, false, 'Carregando', 'Estamos buscando os operadores', 'loading')
        const get = await MAPI('operadores/list', 'GET', null, user?.data?.token)
        if (get?.status !== 200) {
            const message = get?.apiReturn?.message ? get?.apiReturn?.message : 'Ocorreu um erro ao buscar os dados.'
            updateModalInfo(true, true, 'Atenção', message, 'exclamation')
            return
        }
        updateModalInfo(false)
        setData(get?.apiReturn?.apiReturn)
    }
    function openAjustes(visible, operador, type) {
        const ajuste = type ? conexoes : modulos
        const newModulos = { ...ajuste }
        newModulos.visible = visible ? visible : false
        newModulos.operador = operador ? operador : null
        const func = type ? setConexoes : setModulos
        func(newModulos)
    }
    function updateAjusteData(data, type) {
        const ajuste = type ? conexoes : modulos
        const newModulos = { ...ajuste }
        newModulos.data = data
        const func = type ? setConexoes : setModulos
        func(newModulos)
    }

    function handleNewOP() {
        if (validaEvento(modulo?.permissoes, 'criar') === false) {
            updateModalInfo(true, true, 'Atenção', 'Você não tem permissão para criar um operador.', 'exclamation')
            return
        }
        setNewOp(true)
    }

    useEffect(() => {
        getSetores()
        getData()
    }, [])

    return (
        <Box sx={{
            flex: 1,
        }}>
            <ModuloTitle icon={modulo.moduloIcon} title={modulo.moduloNome} desc={modulo.moduloDesc} buttons={[{ text: 'Adicionar', func: handleNewOP }]} />
            <OpList permissoes={modulo?.permissoes} data={data} openEdit={openEdit} deleteOperador={deleteOperador} openModulos={openAjustes} />
            <NovoOp visible={newOp} hideModal={() => setNewOp(false)} pushData={pushData} setores={setores} />
            <EditOp visible={editOp.visible} hideModal={() => openEdit(false)} prevData={editOp.data} updateData={updateData} setores={setores} />
            <Modulos visible={modulos.visible} data={modulos.data} operador={modulos.operador} updateOperador={updateData} dismiss={openAjustes} updateModulosData={updateAjusteData} />
            <Conexoes visible={conexoes.visible} data={conexoes.data} operador={conexoes.operador} updateOperador={updateData} dismiss={openAjustes} updateConexoesData={updateAjusteData} />
            <ModalInfo hideModal={() => updateModalInfo(false)} canDesactive={MInfo.canDesactive} visible={MInfo.visible} title={MInfo.title} subtitle={MInfo.subtitle} icon={MInfo.icon} funcS={MInfo.funcS} />
        </Box>
    );
}