
import React, { useState, useContext, useEffect } from 'react';
import { useTheme, Box, Modal, Typography, Checkbox } from '@mui/material';
import { ModalInfo } from '../../../../components/modalInfo';
import { User } from '../../../../contexts/user';
import MAPI from '../../../../api/mainApi';
import { getIcon } from '../../../../components/icons';
import { formatPhoneNumber } from '../../../../components/masks';
import { ButtonSubmit } from '../../../../components/buttons';

export function ContTags({ visible, prevContato, updateContTags, updateContato, canEdit }) {
    const theme = useTheme();
    const user = useContext(User);
    //estados
    const [MInfo, setMInfo] = useState({
        visible: false,
        title: '',
        subtitle: '',
        canDesactive: true,
        icon: '',
        funcS: () => null
    });
    const [tags, setTags] = useState(null)
    const contatoInit = {
        id: null,
        nome: '',
        numero: '',
        profilePicture: '',
        tags: '',
    }
    const [contato, setContato] = useState(contatoInit)

    function updateModalInfo(visible, canDesactive, title, subtitle, icon, func) {
        const newMInfo = { ...MInfo };
        newMInfo.visible = visible;
        newMInfo.canDesactive = canDesactive;
        newMInfo.title = title;
        newMInfo.subtitle = subtitle;
        newMInfo.icon = icon;
        newMInfo.funcS = func;
        setMInfo(newMInfo);
    }
    function handleDismiss() {
        setContato(contatoInit)
        updateContTags(false)
    }
    async function getTags() {
        if (tags !== null) return
        updateModalInfo(true, false, 'Carregando', 'Buscando tags no servidor.', 'loading')
        const get = await MAPI('tags/list', 'GET', null, user?.data?.token)
        if (get?.status !== 200 || !Array.isArray(get?.apiReturn?.apiReturn)) {
            const message = get?.apiReturn?.message ? get?.apiReturn?.message : 'Ocorreu um erro ao buscar suas tags.'
            updateModalInfo(true, true, 'Carregando', message, 'exclamation')
            setTags(false)
            return
        }
        updateModalInfo(false)
        setTags(get?.apiReturn?.apiReturn)
    }

    function handleCheck(idTag) {
        idTag = idTag.toString()
        const newC = { ...contato }
        let tags = newC.tags ? newC.tags.split(',') : []
        if (tags.includes(idTag)) {
            tags = tags.filter((e) => e !== idTag)
        }
        else {
            tags.push(idTag)
        }
        newC.tags = tags.toString()
        setContato(newC)
    }
    function handleButton(type) {
        if (type === 'Sair') {
            handleDismiss()
            return
        }
        save()
    }

    async function save() {
        if (!canEdit) {
            updateModalInfo(true, true, 'Atenção', 'Você não tem permissão para editar contatos.', 'exclamation')
            return
        }
        updateModalInfo(true, false, 'Salvando', 'Tentando salvar suas tags.', 'loading')
        const TRYSave = await MAPI('conexoes/edit_contato_tags', 'PUT', { contato: contato.id, tags: contato.tags }, user?.data?.token)
        if (TRYSave?.status !== 200) {
            const message = TRYSave?.apiReturn?.message ? TRYSave?.apiReturn?.message : 'Ocorreu um erro ao salvar as tags'
            updateModalInfo(true, true, 'Atenção', message, 'exclamation')
            return
        }
        updateModalInfo(true, true, 'Atenção', 'Tags atualizadas com sucesso.', 'sucess')
        const newC = { ...prevContato }
        newC.tags = contato.tags
        updateContato(newC)
    }

    useEffect(() => {
        if (visible === true) {
            if (prevContato.id) {
                const newC = { ...contato }
                newC.id = prevContato.id
                newC.nome = prevContato.nome
                newC.numero = prevContato.numero
                newC.tags = prevContato.tags
                newC.profilePicture = prevContato.profilePicture
                setContato(newC)
            }
            getTags()
        }
    }, [visible])

    return (
        <Modal open={visible} onClose={handleDismiss} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 2 }} slotProps={{
            backdrop: {
                sx: {
                    backgroundColor: theme.palette.mode === 'dark' ? 'rgba(250,255,255, 0.2)' : 'rgba(0,0,0, 0.4)'
                }
            }
        }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', height: 700, width: 650, background: theme.palette.background.default, outline: 'none' }}>
                <Box sx={{ background: theme.palette.background.paper, padding: 5 }}>
                    <Typography sx={{ fontWeight: 'bold', color: theme.palette.text.primary, fontSize: 30 }}>{'Tags de Contato'}</Typography>
                    <Typography sx={{ color: theme.palette.text.primary, fontSize: 18 }}>Ajuste as tags para esse contato.</Typography>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', padding: 5, height: '100%', gap: 2 }}>
                    {tags === null ? null : tags === false ?
                        <Box sx={{ padding: 3, background: theme.palette.tertiary.main }}>
                            <Typography sx={{ color: theme.palette.text.onPrimary, textAlign: 'center' }}>Não foram encontradas Tags</Typography>
                        </Box>
                        :
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: 1 }}>
                                {contato?.profilePicture ?
                                    <Box sx={{ borderRadius: 25, boxShadow: theme.palette.mode === 'dark' ? '0px 4px 10px rgba(0, 0, 0, 0.3)' : '0px 4px 10px rgba(0, 0, 0, 0.1)', overflow: 'hidden', height: 70, width: 70 }}>
                                        <img src={contato?.profilePicture} style={{ objectFit: 'contain', width: '100%', borderRadius: 90 }} />
                                    </Box>
                                    :
                                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 75, width: 75, borderRadius: 25, boxShadow: theme.palette.mode === 'dark' ? '0px 4px 10px rgba(0, 0, 0, 0.3)' : '0px 4px 10px rgba(0, 0, 0, 0.1)' }}>
                                        {getIcon(33, theme.palette.mode === 'dark' ? theme.palette.text.primary : theme.palette.primary.main, 50)}
                                    </Box>
                                }
                                <Box>
                                    <Typography sx={{ color: theme.palette.text.primary, fontWeight: 'bold', textAlign: 'center' }}>{contato?.nome}</Typography>
                                    <Typography sx={{ color: theme.palette.text.primary, textAlign: 'center' }}>{formatPhoneNumber(contato?.numero ? contato?.numero : '')}</Typography>
                                </Box>
                            </Box>
                            <Box sx={{
                                display: 'flex', flexWrap: 'wrap', gap: 2, justifyContent: 'center', overflowY: 'scroll', maxHeight: 250, '&::-webkit-scrollbar': {
                                    background: theme.palette.background.default,
                                },
                            }}>
                                {tags.map((e) => {
                                    const contatoTag = contato?.tags ? contato?.tags.split(',') : []
                                    const isCheck = contatoTag.includes(e.id.toString()) ? true : false
                                    return (
                                        <Box key={e} sx={{ display: 'flex', alignItems: 'center', background: theme.palette.background.paper, borderRadius: 3, padding: 1, gap: 1 }}>
                                            <Checkbox checked={isCheck} onClick={() => handleCheck(e.id)} />
                                            <Box sx={{ height: 10, width: 10, borderRadius: 5, background: e?.color }} />
                                            <Typography sx={{ color: theme.palette.text.primary }}>{e?.nome}</Typography>
                                        </Box>
                                    )
                                })}
                            </Box>
                        </Box>
                    }
                    <Box sx={{ display: 'flex', gap: 2 }}>
                        {['Salvar', 'Sair'].map((e) => {
                            const backgroundColor = e === 'Salvar' ? theme.palette.primary.main : theme.palette.background.paper
                            const color = e === 'Salvar' ? theme.palette.text.onPrimary : theme.palette.text.primary
                            return (
                                <ButtonSubmit key={e} func={handleButton} funcParameters={e} text={e} background={backgroundColor} color={color} />
                            )
                        })}
                    </Box>
                </Box>
                <ModalInfo hideModal={() => updateModalInfo(false)} canDesactive={MInfo.canDesactive} visible={MInfo.visible} title={MInfo.title} subtitle={MInfo.subtitle} icon={MInfo.icon} funcS={MInfo.funcS} />
            </Box>
        </Modal>
    );
}