export function TestaCPF(strCPF) {
    var Soma;
    var Resto;
    Soma = 0;
    strCPF = strCPF.replace('.', '').replace('.', '')
    strCPF = strCPF.replace('-', '')
    if (strCPF == "00000000000") return false;
    let i = 1
    for (i = 1; i <= 9; i++) Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i);
    Resto = (Soma * 10) % 11;

    if ((Resto == 10) || (Resto == 11)) Resto = 0;
    if (Resto != parseInt(strCPF.substring(9, 10))) return false;

    Soma = 0;
    for (i = 1; i <= 10; i++) Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (12 - i);
    Resto = (Soma * 10) % 11;

    if ((Resto == 10) || (Resto == 11)) Resto = 0;
    if (Resto != parseInt(strCPF.substring(10, 11))) return false;
    return true;
}

export const TestaEMAIL = (email) => {
    const valida = (/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email))
    return valida
}

export const TestaCELULAR = (celular) => {
    var expressao = /\(\d{2,}\) \d{4,}\-\d{4}/g;
    var regex = new RegExp(expressao);
    const newCelular = celular.replace(' ', '')
    return regex.test(celular);
}