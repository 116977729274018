import Logo_w from '../../inc/images/logo_w.png'
import Logo_d from '../../inc/images/logo_wb.png'
import MinLogo from '../../inc/images/flaticon.png'

export const EarthyTerracottaTheme = (mode, changeMode) => {
    return {
        palette: {
            logo: mode === 'light' ? Logo_w : Logo_d,
            minLogo: MinLogo,
            mode: mode,
            changeMode: changeMode,
            ...(mode === 'light'
                ? {
                    primary: {
                        main: '#795548',
                        light: '#D7CCC8',
                        dark: '#4E342E'
                    },
                    secondary: {
                        main: '#A1887F',
                        light: '#D7CCC8',
                        dark: '#5D4037'
                    },
                    tertiary: {
                        main: '#FF5722',
                        light: '#FF8A65',
                        dark: '#D84315'
                    },
                    background: {
                        default: '#EFEBE9',
                        paper: '#D7CCC8',
                        inverse: '#3E2723'
                    },
                    inverseBackground: {
                        default: '#5D4037',
                        paper: '#4E342E'
                    },
                    warning: { main: '#FF9800' },
                    success: { main: '#8BC34A' },
                    error: { main: '#F44336' },
                    text: {
                        main: '#4E342E',
                        primary: '#4E342E',
                        disabled: '#BDBDBD',
                        inverse: '#EFEBE9',
                        onPrimary: '#FFFFFF',
                        dark: '#3E2723',
                        light: '#FAFAFA',
                        onDrawer: '#757575'
                    }
                } : {
                    primary: {
                        main: '#A1887F',
                        light: '#D7CCC8',
                        dark: '#5D4037'
                    },
                    secondary: {
                        main: '#8D6E63',
                        light: '#BCAAA4',
                        dark: '#3E2723'
                    },
                    tertiary: {
                        main: '#FF7043',
                        light: '#FF8A65',
                        dark: '#BF360C'
                    },
                    background: {
                        default: '#3E2723',
                        paper: '#5D4037',
                        inverse: '#EFEBE9'
                    },
                    inverseBackground: {
                        default: '#D7CCC8',
                        paper: '#BCAAA4'
                    },
                    warning: { main: '#FF5722' },
                    success: { main: '#8BC34A' },
                    error: { main: '#E91E63' },
                    text: {
                        main: '#EFEBE9',
                        primary: '#EFEBE9',
                        disabled: '#757575',
                        inverse: '#3E2723',
                        onPrimary: '#FFFFFF',
                        dark: '#D7CCC8',
                        onDrawer: '#9E9E9E',
                        light: '#FAFAFA'
                    }
                }
            )
        }
    }
}
