import { Box, Button, Checkbox, Collapse, createTheme, Switch, ThemeProvider, Typography, useTheme } from "@mui/material";
import { eventosTransform } from "../../../../Mainfunctions";
import { getIcon } from "../../../../components/icons";



export function RenderModulos({ data, op, level, handleSwitch, handleCollapse, colAtive, colAtiveSub, handleCheck }) {
    const theme = useTheme()
    const customTheme = createTheme({
        palette: {
            primary: { main: theme.palette.primary.main },
        },
    });

    return data.map((modulo, index) => {
        const filter = Array.isArray(op?.modulos) ? op.modulos.filter((el) => el.modulo === modulo.id) : [];
        const mainModulo = modulo.tipo !== 0 ? data.filter((el) => el.id === modulo.tipo) : false;
        const nome = modulo.nome;
        const action = modulo?.inside ? 'Módulos' : 'Eventos';
        const actionBg = modulo?.inside ? theme.palette.primary.main : theme.palette.secondary.main;
        const whatCol = level === 1 ? colAtiveSub : colAtive
        return (
            <Box key={modulo.id} sx={{ display: 'flex', flexDirection: 'column', gap: 2, ml: level * 2 }}>
                <Box sx={{ display: 'flex', background: theme.palette.background.default, boxShadow: theme.palette.mode === 'dark' ? '0px 4px 10px rgba(0, 0, 0, 0.5)' : '0px 4px 10px rgba(0, 0, 0, 0.1)', padding: 3, alignItems: 'center', justifyContent: 'space-between' }}>
                    <Box sx={{display: 'flex', gap: 2, alignItems: 'center'}}>
                        {getIcon(modulo.icon)}
                        <Typography sx={{ color: theme.palette.text.primary, fontWeight: 'bold' }}>{nome}</Typography>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                        <ThemeProvider theme={customTheme}>
                            <Switch onChange={() => handleSwitch(modulo, level && true)} checked={filter.length > 0} />
                        </ThemeProvider>
                        <Button type='submit' sx={{ borderRadius: 1, background: actionBg, textTransform: 'none', color: theme.palette.text.onPrimary, fontWeight: 'bold' }} onClick={() => handleCollapse(modulo.id, filter, level && true)}>
                            {action}
                        </Button>
                    </Box>
                </Box>
                {modulo.inside && (
                    <Collapse in={colAtive === modulo.id}>
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, ml: 2 }}>
                            <RenderModulos data={modulo.inside} op={op} level={1} handleSwitch={handleSwitch} handleCollapse={handleCollapse} colAtive={colAtive} colAtiveSub={colAtiveSub} handleCheck={handleCheck} />
                        </Box>
                    </Collapse>
                )}
                {!modulo.inside && (
                    <Collapse in={whatCol === modulo.id}>
                        <Box sx={{ display: 'flex', background: theme.palette.background.default, boxShadow: theme.palette.mode === 'dark' ? '0px 4px 10px rgba(0, 0, 0, 0.5)' : '0px 4px 10px rgba(0, 0, 0, 0.1)', padding: 3, alignItems: 'center', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                            {eventosTransform(modulo.permissoes, filter[0]?.permissoes).map((el, idx) => (
                                <Box key={idx} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <Typography sx={{ color: theme.palette.text.primary }}>{el.text}</Typography>
                                    <Checkbox title={el.text} checked={el.status} onChange={() => handleCheck(idx, modulo)} />
                                </Box>
                            ))}
                        </Box>
                    </Collapse>
                )}
            </Box>
        )
    })

}