import React, { useState, useContext, useEffect } from 'react';
import { useTheme, Box, Typography, Button, Collapse } from '@mui/material';
import { ModalInfo } from '../../../../components/modalInfo';
import { User } from '../../../../contexts/user';
import { BTextField } from '../../../../components/TextField';
import { FullMenu, MenuList } from '../../../../components/menu';
import { getIcon } from '../../../../components/icons';
import MAPI from '../../../../api/mainApi';

export function NovaViariavel({ tipos, pushVariaveis, editVariavel, prevNew, canCreate }) {
    const theme = useTheme();
    const user = useContext(User);
    //estados
    const [MInfo, setMInfo] = useState({
        visible: false,
        title: '',
        subtitle: '',
        canDesactive: true,
        icon: '',
        funcS: () => null
    });
    const [tipoSelected, setTipoSelected] = useState(null)
    const dataInit = {
        nome: '',
        descricao: '',
        texto: ''
    }
    const [data, setData] = useState(dataInit)

    function updateModalInfo(visible, canDesactive, title, subtitle, icon, func) {
        const newMInfo = { ...MInfo };
        newMInfo.visible = visible;
        newMInfo.canDesactive = canDesactive;
        newMInfo.title = title;
        newMInfo.subtitle = subtitle;
        newMInfo.icon = icon;
        newMInfo.funcS = func;
        setMInfo(newMInfo);
    }

    function handleChangeData(value, type) {
        const newData = { ...data }
        newData[type] = type === 'nome' ? value.replace(' ', '') : value
        setData(newData)
    }

    function handleSelect(value) {
        const filter = tipos.filter((e) => e.id == value.props.value)
        if (filter.length > 0) {
            setTipoSelected(filter[0])
        }
    }
    function limpar() {
        setTipoSelected(null)
        setData(dataInit)
    }

    async function saveVariavel() {
        if (!canCreate) {
            updateModalInfo(true, true, 'Atenção', 'Você não tem permissões para criar variáveis', 'exclamation')
            return
        }
        if (!tipoSelected?.id) {
            updateModalInfo(true, true, 'Atenção', 'Ao menos um tipo deve ser selecionado.', 'exclamation')
            return
        }
        if (!data.nome) {
            updateModalInfo(true, true, 'Atenção', 'Nome precisa ser preenchido', 'exclamation')
            return
        }
        if (tipoSelected?.id === 4 && !data.texto) {
            updateModalInfo(true, true, 'Atenção', 'Texto precisa ser preenchido', 'exclamation')
            return
        }
        updateModalInfo(true, false, 'Salvando', 'Estamos salvando sua variável', 'loading')
        const body = {
            id: data?.id,
            tipo: tipoSelected.id,
            nome: data.nome,
            descricao: data.descricao,
            texto: data.texto
        }
        const apiParams = {
            endpoint: data?.id ? 'editVariavel' : 'saveVariavel',
            method: data?.id ? 'PUT' : 'POST'
        }
        const save = await MAPI(`variaveis/${apiParams.endpoint}`, apiParams.method, body, user?.data?.token)
        if (save?.status !== 200) {
            const message = save?.apiReturn?.message ? save?.apiReturn?.message : 'Ocorreu um erro ao processar a requisição.'
            updateModalInfo(true, true, 'Atenção', message, 'exclamation')
            return
        }
        limpar()
        updateModalInfo(false)
        const newData = {
            id: data.id ? data.id : save?.apiReturn?.apiReturn,
            nome: `{{${data.nome}}}`,
            descricao: data.descricao,
            tipo: tipoSelected.id,
            tipo_nome: tipoSelected.nome,
            texto: data.texto
        }
        const func = data.id ? editVariavel : pushVariaveis
        func(newData)
    }

    function handleClick(type) {
        if (type === 'Limpar') {
            limpar()
            return
        }
        saveVariavel()
    }

    useEffect(() => {
        if (prevNew) {
            const regex = /\{\{(\w+)\}\}/g;
            setData({
                id: prevNew.id,
                nome: prevNew.nome.replace(regex, (match, p1) => p1),
                descricao: prevNew.descricao,
                texto: prevNew.texto
            })
            setTipoSelected({
                id: prevNew.tipo,
                nome: prevNew.tipo_nome
            })
        }
        else {
            setTipoSelected(null)
            setData(dataInit)
        }
    }, [prevNew])

    return (
        <Box sx={{
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            height: '100%',
            width: '100%',
            background: theme.palette.background.default,
            padding: 2,
            gap: 3
        }}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 3 }}>
                {getIcon(65, theme.palette.secondary.main)}
                <Typography sx={{ fontWeight: 'bold', color: theme.palette.text.main, fontSize: 25, textAlign: 'center', color: theme.palette.text.primary }}>Variável</Typography>
            </Box>
            <Box sx={{ width: '70%' }}>
                <Typography sx={{ color: theme.palette.text.primary }}>Variável nome *</Typography>
                <BTextField value={data.nome} onChangeText={handleChangeData} type={'nome'} prevData={data} />
            </Box>
            <Box sx={{ width: '70%' }}>
                <Typography sx={{ color: theme.palette.text.primary }}>Descrição</Typography>
                <BTextField multiline minRows={3} maxRows={3} value={data.descricao} onChangeText={handleChangeData} type={'descricao'} prevData={data} />
            </Box>
            <Box sx={{ width: '70%' }}>
                <Typography sx={{ color: theme.palette.text.primary }}>Tipo *</Typography>
                <FullMenu options={tipos} param={'nome'} title={'Selecionar...'} width={'100%'} handleChange={handleSelect} selected={tipoSelected} />
            </Box>
            <Collapse in={tipoSelected?.id === 4} sx={{ width: '70%' }}>
                <Box sx={{ width: '100%' }}>
                    <Typography sx={{ color: theme.palette.text.primary }}>Texto</Typography>
                    <BTextField value={data.texto} onChangeText={handleChangeData} type={'texto'} prevData={data} />
                </Box>
            </Collapse>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, width: '70%' }}>
                {['Salvar', 'Limpar'].map((e, idx) => {
                    const background = idx === 0 ? theme.palette.primary.main : theme.palette.background.paper
                    const color = idx === 0 ? theme.palette.text.onPrimary : theme.palette.text.primary
                    const hoverBackground = idx === 0 ? theme.palette.secondary.light : 'transparent'
                    return (
                        <Button onClick={() => handleClick(e)} key={e} sx={{
                            background: background, width: '100%', color: color, padding: 1.5, textTransform: 'none', '&:hover': {
                                background: hoverBackground
                            }
                        }}>{e}</Button>
                    )
                })}
            </Box>
            <ModalInfo hideModal={() => updateModalInfo(false)} canDesactive={MInfo.canDesactive} visible={MInfo.visible} title={MInfo.title} subtitle={MInfo.subtitle} icon={MInfo.icon} funcS={MInfo.funcS} />
        </Box>
    );
}

const options = [
    {
        nome: 'teste'
    },
    {
        nome: 'teste2'
    },
    {
        nome: 'teste2'
    },
    {
        nome: 'teste2'
    },
    {
        nome: 'teste2'
    },
]