import { useContext, useEffect, useState } from 'react'
import { Box, Typography, useTheme } from '@mui/material'
import MAPI from '../../api/mainApi'
import { User } from '../../contexts/user'
import { ModuloTitle } from '../../components/moduloTitle'
import { NovaConexao } from './components/novaConexao'
import { ListConexoes } from './components/listConexoes'
import { validaEvento } from '../../Mainfunctions'
import { ModalInfo } from '../../components/modalInfo'
import { EditarConexao } from './components/editarConexao'
import { ModalQRCode } from './components/modalQrCode'
import { ConfigEmail } from './components/configEmail'

export function Conexoes({ modulo }) {
    document.title = `Conexões - It's Chat`
    const theme = useTheme()
    const user = useContext(User)

    // states
    const [data, setData] = useState([])
    const [conTipos, setContTipos] = useState([])
    const [conexaoVisible, setConexaoVisible] = useState(false)
    const [configEmail, setConfigEmail] = useState({
        visible: false,
        con: null
    })
    const [editCon, setEditCon] = useState({
        visible: false,
        data: {
            icone: '',
            nome: '',
            descricao: ''
        }
    })
    const qrInit = {
        visible: false,
        qr: ''
    }
    const [qrInfo, setQrInfo] = useState(qrInit)
    // functions
    async function getData() {
        updateModalInfo(true, false, 'Carregando', 'Estamos buscando sua conexões.', 'loading')
        const get = await MAPI('conexoes/list', 'GET', null, user?.data?.token)
        if (get?.status !== 200) {
            const message = get?.apiReturn?.message ? get?.apiReturn?.message : 'Ocorreu um erro ao buscar as conexões.'
            updateModalInfo(true, true, 'Atenção', message, 'exclamation')
            return
        }
        updateModalInfo(false)
        setData(get?.apiReturn?.apiReturn)
        setContTipos(get?.apiReturn?.tipos ? get?.apiReturn?.tipos : [])
    }
    function pushData(conexao) {
        const newData = [...data]
        newData.push(conexao)
        setData(newData)
    }

    async function verifyStatus(conexao, retest) {
        if (!retest) {
            updateModalInfo(true, false, 'Carregando', 'Verificando o status da conexão.', 'loading')
        }
        const TRYVerify = await MAPI('conexoes/status/' + conexao.id, 'GET', null, user?.data?.token)
        const message = TRYVerify?.apiReturn?.message ? TRYVerify?.apiReturn?.message : 'Ocorreu um erro ao verificar o status da conexão.'
        if (TRYVerify.status === 404) {
            updateModalInfo(false)
            setConfigEmail({
                visible: true,
                con: conexao.id
            })
            return
        }
        if (TRYVerify?.status !== 200) {
            updateModalInfo(true, true, 'Atenção', message, 'loading')
            return
        }
        const apiReturn = TRYVerify?.apiReturn?.apiReturn
        if (apiReturn[0] === 'ready') {
            updateModalInfo(true, true, 'Atenção', 'Conexão está estável.', 'sucess')
            return
        }
        if (apiReturn[0] === 'timeout') {
            verifyStatus(conexao, true)
            return
        }
        updateModalInfo(false)
        if (apiReturn[0] === 'qr') {
            const newQrInfo = { ...qrInfo }
            newQrInfo.visible = true
            newQrInfo.qr = apiReturn[1].slice(0, -2)
            setQrInfo(newQrInfo)
            if (qrInfo.visible) {
                setTimeout(() => {
                    verifyStatus(conexao, true)
                }, 5000)
            }
        }
    }

    function deleteData(conexao) {
        const newData = data.filter((e) => e.id !== conexao.id)
        setData(newData)
    }
    async function novaConexao() {
        if (validaEvento(modulo.permissoes, 'criar') === false) {
            updateModalInfo(true, true, 'Atenção', 'Você não tem permissão para criar conexões.', 'exclamation')
            return
        }
        setConexaoVisible(!conexaoVisible)
    }
    const [MInfo, setMInfo] = useState({
        visible: false,
        title: '',
        subtitle: '',
        canDesactive: true,
        icon: '',
        funcS: () => null
    })

    function updateEditCon(visible, data) {
        const newCon = { ...editCon }
        newCon.visible = visible ? visible : false
        newCon.data = data ? data : newCon.data
        setEditCon(newCon)
    }
    function updateCon(con) {
        const newData = [...data]
        const findIndex = newData.findIndex((e) => e.id === con.id)
        if (findIndex !== -1) {
            newData[findIndex] = con
        }
        setData(newData)
    }
    function updateModalInfo(visible, canDesactive, title, subtitle, icon, func) {
        const newMInfo = { ...MInfo }
        newMInfo.visible = visible
        newMInfo.canDesactive = canDesactive
        newMInfo.title = title
        newMInfo.subtitle = subtitle
        newMInfo.icon = icon
        newMInfo.funcS = func
        setMInfo(newMInfo)
    }
    useEffect(() => {
        getData()
    }, [])
    return (
        <Box sx={{
            flex: 1
        }}>
            <ModuloTitle icon={modulo.moduloIcon} title={modulo.moduloNome} desc={modulo.moduloDesc} buttons={[{ text: 'Adicionar', func: novaConexao }]} />
            <ListConexoes verifyStatus={verifyStatus} data={data} permissoes={modulo?.permissoes} conexoes={modulo?.conexoes} deleteData={deleteData} updateEditCon={updateEditCon} />
            <NovaConexao visible={conexaoVisible} hideModal={novaConexao} data={data} pushData={pushData} conTipos={conTipos} updatePrevModalInfo={updateModalInfo} />
            <EditarConexao visible={editCon.visible} prevData={editCon.data} hideModal={() => updateEditCon(false)} updateCon={updateCon} conTipos={conTipos} updatePrevModalInfo={updateModalInfo} />
            <ModalQRCode visible={qrInfo.visible} qrcode={qrInfo.qr} hideModal={() => setQrInfo(qrInit)} />
            <ConfigEmail visible={configEmail.visible} con={configEmail.con} dismiss={() => setConfigEmail({ visible: false, con: null })} />
            <ModalInfo hideModal={() => updateModalInfo(false)} canDesactive={MInfo.canDesactive} visible={MInfo.visible} title={MInfo.title} subtitle={MInfo.subtitle} icon={MInfo.icon} funcS={MInfo.funcS} />
        </Box>
    )
}