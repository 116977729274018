import React, { useState, useContext, useEffect } from 'react';
import { useTheme, Box, Modal, Typography, Stepper, Step, StepLabel } from '@mui/material';
import { ModalInfo } from '../../../../components/modalInfo';
import { User } from '../../../../contexts/user';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Autoplay, EffectFade } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import "swiper/css/grid";
import 'swiper/swiper-bundle.css';
import { Conexao } from './enviarMensagem/conexao';
import { ButtonSubmit } from '../../../../components/buttons';
import { Contatos } from './enviarMensagem/contatos';
import { Configuracoes } from './enviarMensagem/configuracoes';
import MAPI from '../../../../api/mainApi';

export function EnviarMensagem({ visible, mensagem, updateEnvMensagem, mensagemEnviada }) {
    const theme = useTheme();
    const user = useContext(User);
    const [swiper, setSwiper] = useState(null);
    const params = {
        loop: true,
        modules: [Pagination, Navigation, Autoplay, EffectFade],
        effects: 'slide',
        allowTouchMove: false
    };
    const steps = ['Conexão', 'Contatos', 'Configurações'];
    const [activeSlideIndex, setActiveSlideIndex] = useState(0);
    const [MInfo, setMInfo] = useState({
        visible: false,
        title: '',
        subtitle: '',
        canDesactive: true,
        icon: '',
        funcS: () => null
    });
    const dataInit = {
        con: null,
        contatos: {
            type: null,
            data: []
        },
        configs: null
    }
    const [data, setData] = useState(dataInit)


    function updateModalInfo(visible, canDesactive, title, subtitle, icon, func) {
        const newMInfo = { ...MInfo };
        newMInfo.visible = visible;
        newMInfo.canDesactive = canDesactive;
        newMInfo.title = title;
        newMInfo.subtitle = subtitle;
        newMInfo.icon = icon;
        newMInfo.funcS = func;
        setMInfo(newMInfo);
    }
    function handleSubmit(type) {
        if (type === 'Próximo') {
            if (!data.con && activeSlideIndex === 0) {
                updateModalInfo(true, true, 'Atenção', 'Ao menos uma conexão deve ser selecionada.', 'exclamation')
                return
            }
            if (data.contatos.data.length === 0 && activeSlideIndex === 1) {
                updateModalInfo(true, true, 'Atenção', 'Ao menos um contato deve ser selecionado.', 'exclamation')
                return
            }
            nextSlide()
        }
        if (type === 'Voltar') {
            prevSlide()
        }
        if (type === 'Enviar') {
            disparar()
        }
    }
    async function disparar() {
        const body = {
            con: data?.con?.id,
            mensagem: mensagem?.id,
            contatos: data?.contatos?.data,
            configs: data?.configs
        }
        if (data?.contatos?.variaveis) {
            body.variaveis = data.contatos.variaveis
        }
        updateModalInfo(true, false, 'Enviando', 'Estamos disparando sua mensagem.', 'loading')
        const save = await MAPI('disparador/disparar', 'POST', body, user?.data?.token)
        if (save?.status !== 200) {
            const message = save?.apiReturn?.message ? save?.apiReturn?.message : 'Ocorreu um erro ao disparar a mensagem.'
            updateModalInfo(true, true, 'Atenção', message, 'exclamation')
            return
        }
        updateModalInfo(false)
        mensagemEnviada()
    }

    function hideModal() {
        updateEnvMensagem(false);
    }
    function updateData(value, type) {
        const newData = { ...data }
        newData[type] = value
        setData(newData)
    }

    function nextSlide() {
        swiper.slideTo(activeSlideIndex + 1)
    }
    function prevSlide() {
        swiper.slideTo(activeSlideIndex - 1)
    }

    useEffect(() => {
        setData(dataInit)
    }, [visible])


    return (
        <Modal open={visible} onClose={() => hideModal()} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} slotProps={{
            backdrop: {
                sx: {
                    backgroundColor: theme.palette.mode === 'dark' ? 'rgba(250,255,255, 0.2)' : 'rgba(0,0,0, 0.4)'
                }
            }
        }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3, height: 820, width: '95%', maxWidth: 700, background: theme.palette.background.default, outline: 'none', }}>
                <Box sx={{ background: theme.palette.background.paper, padding: 5 }}>
                    <Typography sx={{ fontWeight: 'bold', color: theme.palette.text.primary, fontSize: 30 }}>{'Disparar mensagem'}</Typography>
                    <Typography sx={{ color: theme.palette.text.primary, fontSize: 18 }}>Percorra os passos para fazer o envio.</Typography>
                </Box>
                <Stepper activeStep={activeSlideIndex} alternativeLabel>
                    {steps.map((label, index) => (
                        <Step key={label}>
                            <StepLabel>{label}</StepLabel>
                        </Step>
                    ))}
                </Stepper>
                <Box sx={{ display: 'flex', flexGrow: 1, padding: 5, paddingTop: 0 }}>
                    <Swiper {...params} onSlideChange={(swiper) => setActiveSlideIndex(swiper.activeIndex)} onSwiper={setSwiper}>
                        <SwiperSlide><Conexao slideAtivo={activeSlideIndex} mensagem={mensagem} data={data} updateData={updateData} /></SwiperSlide>
                        <SwiperSlide><Contatos mensagem={mensagem} slideAtivo={activeSlideIndex} data={data} updateData={updateData} /></SwiperSlide>
                        <SwiperSlide><Configuracoes slideAtivo={activeSlideIndex} data={data} updateData={updateData} /></SwiperSlide>
                    </Swiper>
                </Box>
                <Box sx={{ display: 'flex', padding: 5, paddingTop: 0, gap: 2 }}>
                    {['Voltar', 'Próximo', 'Enviar'].map((e, idx) => {
                        if (e === 'Voltar' && activeSlideIndex === 0) return
                        if (e === 'Enviar' && activeSlideIndex !== 2) return
                        if (e === 'Próximo' && activeSlideIndex === 2) return
                        return (
                            <ButtonSubmit key={e} text={e} background={idx === 0 ? theme.palette.background.paper : idx === 2 ? theme.palette.secondary.main : theme.palette.primary.main} color={idx === 0 ? theme.palette.text.primary : theme.palette.text.onPrimary} func={handleSubmit} funcParameters={e} />
                        )
                    })}
                </Box>
                <ModalInfo hideModal={() => updateModalInfo(false)} canDesactive={MInfo.canDesactive} visible={MInfo.visible} title={MInfo.title} subtitle={MInfo.subtitle} icon={MInfo.icon} funcS={MInfo.funcS} />
            </Box>
        </Modal>
    );
}
