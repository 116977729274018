import React, { useState, useContext, useEffect } from 'react';
import { useTheme, Box, Modal, Typography, Switch, Button, Zoom, Collapse, createTheme, ThemeProvider } from '@mui/material';
import { ModalInfo } from '../../../components/modalInfo';
import { User } from '../../../contexts/user';
import MAPI from '../../../api/mainApi';
import { getIcon } from '../../../components/icons'
import { eventosTransform } from '../../../Mainfunctions';
import { ButtonSubmit } from '../../../components/buttons';


export function Conexoes({ visible, data, operador, dismiss, updateConexoesData, updateOperador }) {
    const theme = useTheme();
    const customTheme = createTheme({
        palette: {
            primary: { main: theme.palette.primary.main },
        },
    });
    const user = useContext(User);
    //estados
    const [MInfo, setMInfo] = useState({
        visible: false,
        title: '',
        subtitle: '',
        canDesactive: true,
        icon: '',
        funcS: () => null
    });
    const [colAtive, setColAtive] = useState(null)
    const [op, setOp] = useState({})

    //funções
    async function getData() {
        const newOperador = { ...operador }
        if (!Array.isArray(data) || data.length === 0) {
            updateModalInfo(true, true, 'Carregando', 'Buscando módulos no servidor.', 'loading')
            const getConexoes = await MAPI('conexoes/list', 'GET', null, user?.data?.token)
            if (getConexoes?.status !== 200) {
                const message = getConexoes?.apiReturn?.message ? getConexoes?.apiReturn?.message : 'Ocorreu um erro ao buscar os módulos.'
                updateModalInfo(true, true, 'Atenção', message, 'exclamation')
                updateConexoesData(false, true)
                return
            }
            updateConexoesData(getConexoes?.apiReturn?.apiReturn, true)
        }
        if (!operador?.conexoes) {
            updateModalInfo(true, true, 'Carregando', 'Buscando conexoes do operador.', 'loading')
            const getOpModulos = await MAPI('conexoes/op_conexao/' + operador.id, 'GET', null, user?.data?.token)
            if (getOpModulos?.status !== 200) {
                const message = getOpModulos?.apiReturn?.message ? getOpModulos?.apiReturn?.message : 'Ocorreu um erro ao buscar os módulos.'
                updateModalInfo(true, true, 'Atenção', message, 'exclamation')
                return
            }
            newOperador.conexoes = getOpModulos?.apiReturn?.apiReturn
            updateOperador(newOperador, true)
        }
        setOp(newOperador)
        updateModalInfo(false)
    }
    function updateModalInfo(visible, canDesactive, title, subtitle, icon, func) {
        const newMInfo = { ...MInfo };
        newMInfo.visible = visible;
        newMInfo.canDesactive = canDesactive;
        newMInfo.title = title;
        newMInfo.subtitle = subtitle;
        newMInfo.icon = icon;
        newMInfo.funcS = func;
        setMInfo(newMInfo);
    }
    function handleDismiss() {
        setOp({})
        dismiss(false, {}, true)
    }
    function handleSwith(conexao) {
        const newOp = { ...op }
        const newCon = { ...conexao }
        newOp.conexoes = Array.isArray(newOp.conexoes) ? newOp.conexoes : []
        const findIndex = Array.isArray(newOp?.conexoes) ? newOp.conexoes.findIndex((e) => e == newCon?.id) : -1
        if (findIndex === -1) {
            newOp.conexoes.push(conexao.id)
        }
        else {
            newOp.conexoes = newOp.conexoes.filter((e, index) => index !== findIndex)
            setColAtive(null)
        }
        setOp(newOp)

    }
    function handleButton(type) {
        if (type === 'Sair') handleDismiss()
        if (type === 'Salvar') Save()
    }

    async function Save() {
        updateModalInfo(true, false, 'Salvando', 'Tentando salvar informações no servidor.', 'loading')
        const body = {
            operador: op.id,
            conexoes: op.conexoes
        }
        const TRYSave = await MAPI('conexoes/op_insert', 'POST', body, user?.data?.token)
        if (TRYSave.status !== 200) {
            const message = TRYSave?.apiReturn?.message ? TRYSave?.apiReturn.message : 'Ocorreu um erro ao salvar as permissões.'
            updateModalInfo(true, true, 'Atenção', message, 'exclamation')
            return
        }
        updateModalInfo(true, true, 'Sucesso', 'Permissões salvas com sucesso.', 'sucess')
    }

    useEffect(() => {
        if (visible === true) getData()
    }, [visible])

    return (
        <Modal open={visible} onClose={handleDismiss} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 2 }} slotProps={{
            backdrop: {
                sx: {
                    backgroundColor: theme.palette.mode === 'dark' ? 'rgba(250,255,255, 0.2)' : 'rgba(0,0,0, 0.4)'
                }
            }
        }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', height: 680, width: 550, background: theme.palette.background.default, outline: 'none' }}>
                <Box sx={{ background: theme.palette.background.paper, padding: 5 }}>
                    <Typography sx={{ fontWeight: 'bold', color: theme.palette.text.primary, fontSize: 30 }}>{'Conexões #' + operador?.nome}</Typography>
                    <Typography sx={{ color: theme.palette.text.primary, fontSize: 18 }}>Ative as conexões para esse usuário.</Typography>
                </Box>
                <Box sx={{
                    display: 'flex', flexDirection: 'column', flex: 1,
                    padding: 5, overflowY: 'scroll', '&::-webkit-scrollbar': {
                        background: theme.palette.background.paper,
                        width: 20
                    },
                    '&::-webkit-scrollbar-thumb': {
                        background: theme.palette.primary.main
                    }
                }}>
                    {data === null ?
                        <Box sx={{ display: 'flex', justifyContent: 'center', padding: 5 }}>
                            {
                                getIcon(25, theme.palette.secondary.main)
                            }
                        </Box>
                        :
                        data.length === 0 || data === false ?
                            <Box sx={{ padding: 2, background: theme.palette.tertiary.main }}>
                                <Typography sx={{ color: theme.palette.text.onPrimary, fontWeight: 'bold', textAlign: 'center' }}>Não foi encontrado conexões.</Typography>
                            </Box>
                            :
                            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
                                {
                                    data.map((e, index) => {
                                        let filter = Array.isArray(op?.conexoes) ? op.conexoes.filter((el) => el == e.id) : []
                                        return (
                                            <Box key={index} sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                                                <Box sx={{ display: 'flex', background: theme.palette.background.default, boxShadow: theme.palette.mode === 'dark' ? '0px 4px 10px rgba(0, 0, 0, 0.5)' : '0px 4px 10px rgba(0, 0, 0, 0.1)', padding: 3, alignItems: 'center', justifyContent: 'space-between' }}>
                                                    <Typography sx={{ color: theme.palette.text.primary, fontWeight: 'bold' }}>{e.nome}</Typography>
                                                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                                                        <ThemeProvider theme={customTheme}>
                                                            <Switch color='primary' onChange={() => handleSwith(e)} checked={filter.length > 0 ? true : false} />
                                                        </ThemeProvider>
                                                    </Box>
                                                </Box>
                                            </Box>
                                        )
                                    })
                                }
                            </Box>
                    }
                </Box>
                <Box sx={{ display: 'flex', gap: 2, padding: 5 }}>
                    {['Salvar', 'Sair'].map((e, idx) => {
                        return (
                            <ButtonSubmit key={e} text={e} background={idx === 1 ? theme.palette.background.paper : theme.palette.primary.main} color={idx === 1 ? theme.palette.text.primary : theme.palette.text.onPrimary} func={handleButton} funcParameters={e} />
                        )
                    })}
                </Box>
                <ModalInfo hideModal={() => updateModalInfo(false)} canDesactive={MInfo.canDesactive} visible={MInfo.visible} title={MInfo.title} subtitle={MInfo.subtitle} icon={MInfo.icon} funcS={MInfo.funcS} />
            </Box >
        </Modal >
    );
}