import { useTheme } from "@mui/material";
import { Box, Button, TextField, Typography, Checkbox } from "@mui/material";
import { useContext, useRef, useState } from "react";
import { CTextField } from "../../../components/TextField";
import MAPI from "../../../api/mainApi";
import { ModalInfo } from "../../../components/modalInfo";
import { TestaEMAIL } from "../../../components/validaCampo";
import { User } from "../../../contexts/user";



export function LoginForm({ }) {
    const theme = useTheme()
    const user = useContext(User)
    const [email, setEmail] = useState('')
    const [senha, setSenha] = useState('')
    const [keepConnect, setKeepConnect] = useState(false)
    const [MInfo, setMInfo] = useState({
        visible: false,
        title: '',
        subtitle: '',
        canDesactive: true,
        icon: ''
    })
    const emailRef = useRef(null)
    const senhaRef = useRef(null)
    const cbRef = useRef(null)
    const buttonRef = useRef(null)

    function handleChangeInput(value, type) {
        if (type === 'email') {
            setEmail(value)
        }
        if (type === 'senha') {
            setSenha(value)
        }
    }
    function onKeyPress(value, type) {
        if (value.key === 'Enter' && type === 'email') {
            senhaRef.current.focus()
        }
        if (value.key === 'Enter' && type === 'senha') {
            cbRef.current.focus()
        }
        if (value.key === 'Enter' && type === 'check') {
            buttonRef.current.focus()
        }
        if (value.key === 'Enter' && type === 'button') {
        }
    }

    function updateModalInfo(visible, canDesactive, title, subtitle, icon) {
        const newMInfo = { ...MInfo }
        newMInfo.visible = visible
        newMInfo.canDesactive = canDesactive
        newMInfo.title = title
        newMInfo.subtitle = subtitle
        newMInfo.icon = icon
        setMInfo(newMInfo)
    }

    async function TryLogin() {
        if ([email, senha].includes('')) {
            updateModalInfo(true, true, 'Atenção', 'Todos os campos devem ser preenchidos', 'exclamation')
            return
        }
        if (!TestaEMAIL(email)) {
            updateModalInfo(true, true, 'Atenção', 'E-mail preenchido é inválido', 'exclamation')
            return
        }

        const body = {
            email: email,
            senha: senha
        }
        updateModalInfo(true, false, 'Carregando', 'Validando dados', 'loading')
        const getUser = await MAPI('usuarios/login', 'POST', body, null)
        if (getUser?.status !== 200) {
            const message = getUser?.apiReturn?.message ? getUser?.apiReturn?.message : 'Ocorreu um erro ao fazer o login.'
            updateModalInfo(true, true, 'Atenção', message, 'exclamation')
            return
        }
        user?.updateUser(getUser?.apiReturn?.apiReturn, keepConnect)
    }

    return (
        <Box sx={{ display: 'flex', flex: 1, padding: 7, flexDirection: 'column', gap: 5, justifyContent: 'center', height: '100%' }}>
            <Box sx={{ display: 'flex', justifyContent: 'center', height: 180 }}>
                <img src={theme.palette.logo} alt="Logo" style={{ objectFit: 'contain', maxWidth: '100%' }} />
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                <CTextField ref={emailRef} onKeyPress={onKeyPress} autoFocus={true} value={email} type={'email'} onChangeText={handleChangeInput} label={'E-mail'} />
                <CTextField ref={senhaRef} onKeyPress={onKeyPress} value={senha} type={'senha'} security={true} onChangeText={handleChangeInput} label={'Senha'} />
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                    <Typography style={{ color: theme.palette.text.primary }}>Manter conectado</Typography>
                    <Checkbox value={keepConnect} onChange={() => setKeepConnect(!keepConnect)} onKeyDown={(value) => onKeyPress(value, 'check')} inputRef={cbRef} />
                </Box>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button onKeyDown={(value) => onKeyPress(value, 'button')} ref={buttonRef} variant='contained' sx={{ width: '100%', color: '#fff', borderRadius: 1, fontWeight: 'bold' }} onClick={TryLogin}>Entrar</Button>
            </Box>
            <Box sx={{ justifyContent: 'flex-end' }}>
                <Typography sx={{ textAlign: 'end', color: theme.palette.text.primary }}>Esqueceu a senha?</Typography>
            </Box>
            <ModalInfo hideModal={() => updateModalInfo(false)} canDesactive={MInfo.canDesactive} visible={MInfo.visible} title={MInfo.title} subtitle={MInfo.subtitle} icon={MInfo.icon} />
        </Box>
    )
}