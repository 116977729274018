import { useContext, useState } from 'react'
import { Box, Button, IconButton, Tooltip, Typography, useTheme } from '@mui/material'
import { getIcon } from '../../../components/icons'
import { BoxHover } from '../../../components/boxHover'
import { ModalInfo } from '../../../components/modalInfo'
import { validaEvento } from '../../../Mainfunctions'
import MAPI from '../../../api/mainApi'
import { User } from '../../../contexts/user'
import { UpComp } from '../../../components/upComp'


export function OpUnit({ operador, permissoes, deleteOperador, openModulos, openEdit }) {
    const theme = useTheme()
    const user = useContext(User)
    const textStyle = {
        color: theme.palette.text.primary,
        textAlign: 'center',
        textTransform: 'none'
    }
    const [MInfo, setMInfo] = useState({
        visible: false,
        title: '',
        subtitle: '',
        canDesactive: true,
        icon: '',
        funcS: () => null
    })

    function updateModalInfo(visible, canDesactive, title, subtitle, icon, func) {
        const newMInfo = { ...MInfo }
        newMInfo.visible = visible
        newMInfo.canDesactive = canDesactive
        newMInfo.title = title
        newMInfo.subtitle = subtitle
        newMInfo.icon = icon
        newMInfo.funcS = func
        setMInfo(newMInfo)
    }
    async function handleExcluir() {
        updateModalInfo(true, false, 'Excluindo', 'Tentando excluir o operador', 'loading')
        const TRYDelete = await MAPI('operadores/excluir/' + operador.id, 'DELETE', null, user?.data?.token)
        if (TRYDelete?.status !== 200) {
            const message = TRYDelete?.apiReturn?.message ? TRYDelete?.apiReturn?.message : 'Ocorreu um erro ao excluir a conexão.'
            updateModalInfo(true, true, 'Atenção', message, 'exclamation')
        }
        deleteOperador(operador.id)
        updateModalInfo(false, true, 'Atenção', 'Conexão excluída com sucesso.', 'sucess')
    }
    function handleButton(e) {
        if (e === 'Excluir') {
            if (validaEvento(permissoes, 'excluir') === false) {
                updateModalInfo(true, true, 'Atenção', 'Você não tem permissão para excluir um operador.', 'exclamation')
                return
            }
            updateModalInfo(true, true, 'Atenção', 'Você tem certeza que deseja excluir esse operador?', 'question', handleExcluir)
            return
        }
        if (e === 'Permissões') {
            if (validaEvento(permissoes, 'editar') === false) {
                updateModalInfo(true, true, 'Atenção', 'Você não tem permissão para editar um operador.', 'exclamation')
                return
            }
            openModulos(true, operador)
        }
        if (e === 'Conexões') {
            if (validaEvento(permissoes, 'editar') === false) {
                updateModalInfo(true, true, 'Atenção', 'Você não tem permissão para editar um operador.', 'exclamation')
                return
            }
            openModulos(true, operador, true)
        }
    }
    return (
        <BoxHover>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-between', height: 200, width: '70%' }}>
                <Button onClick={() => openEdit(true, operador)}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: 1 }}>
                        <Box>
                            {operador?.avatar ?
                                <Box sx={{ borderRadius: 25, boxShadow: theme.palette.mode === 'dark' ? '0px 4px 10px rgba(0, 0, 0, 0.3)' : '0px 4px 10px rgba(0, 0, 0, 0.1)', overflow: 'hidden', height: 70, width: 70 }}>
                                    <img src={operador.avatar} style={{ objectFit: 'contain', width: '100%', borderRadius: 90 }} />
                                </Box>
                                :
                                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 75, width: 75, borderRadius: 25, boxShadow: theme.palette.mode === 'dark' ? '0px 4px 10px rgba(0, 0, 0, 0.3)' : '0px 4px 10px rgba(0, 0, 0, 0.1)' }}>
                                    {
                                        getIcon(33, theme.palette.mode === 'dark' ? theme.palette.text.primary : theme.palette.primary.main, 50)
                                    }
                                </Box>
                            }
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                            <Typography sx={[textStyle, { fontWeight: 'bold', fontSize: 22 }]}>{operador?.nome}</Typography>
                            <Typography sx={textStyle}>{operador?.email}</Typography>
                        </Box>
                    </Box>
                </Button>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                    {['Permissões', 'Conexões', 'Excluir'].map((e, idx) => {
                        const icon = {
                            Permissões: 31,
                            Conexões: 1,
                            Excluir: 28
                        }
                        const color = idx % 2 ? theme.palette.tertiary.main : theme.palette.secondary.main
                        return (
                            <UpComp key={e} tamanho={30}>
                                <Tooltip key={e} title={e}>
                                    <IconButton
                                        onClick={() => handleButton(e)}
                                    >
                                        {getIcon(icon[e], color)}
                                    </IconButton>
                                </Tooltip>
                            </UpComp>
                        )
                    })}
                </Box>
            </Box>
            <ModalInfo hideModal={() => updateModalInfo(false)} canDesactive={MInfo.canDesactive} visible={MInfo.visible} title={MInfo.title} subtitle={MInfo.subtitle} icon={MInfo.icon} funcS={MInfo.funcS} />
        </BoxHover >
    )
}