import { useState, useContext, useEffect } from 'react'
import { Box, Typography, Button, useTheme } from '@mui/material'
import { getIcon } from '../../../components/icons'
import { BTextField } from '../../../components/TextField'
import MAPI from '../../../api/mainApi'
import { User } from '../../../contexts/user'

const setorInit = {
    text: ''
}
export function NewSetor({ updateModalInfo, pushData, prevData, editSetor }) {
    const theme = useTheme()
    const user = useContext(User)
    const [setor, setSetor] = useState(setorInit)

    function handleChangeText(value) {
        const newS = { ...setor }
        newS.text = value
        setSetor(newS)
    }

    function handleClick(type) {
        if (type === 'Limpar') {
            setSetor(setorInit)
            return
        }
        save()
    }

    async function save() {
        updateModalInfo(true, false, 'Salvando', 'Estamos salvando seu setor.', 'loading')
        const endpoint = setor?.id ? 'editSetor' : 'saveSetor'
        const method = setor?.id ? 'PUT' : 'POST'
        const apiSave = await MAPI(`setores/${endpoint}`, method, { nome: setor.text, id: setor?.id }, user?.data?.token)
        if (apiSave?.status !== 200) {
            const message = apiSave?.apiReturn?.message ? apiSave?.apiReturn?.message : 'Ocorreu um erro ao salvar o setor.'
            updateModalInfo(true, true, 'Atenção', message, 'exclamation')
            return
        }
        setSetor(setorInit)
        const whatFunc = setor?.id ? editSetor : pushData
        const id = setor?.id ? setor?.id : apiSave?.apiReturn?.apiReturn
        whatFunc({ id: id, nome: setor.text })
    }

    useEffect(() => {
        if (prevData) {
            const newS = {
                id: prevData.id,
                text: prevData.nome
            }
            setSetor(newS)
        }
        else {
            setSetor(setorInit)
        }
    }, [prevData])
    return (
        <Box sx={{
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            height: '100%',
            width: '100%',
            background: theme.palette.background.default,
            padding: 2,
            gap: 3
        }}>
            <Box sx={{ width: '70%' }}>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 3 }}>
                    {getIcon(65, theme.palette.secondary.main)}
                    <Typography sx={{ fontWeight: 'bold', color: theme.palette.text.main, fontSize: 25, textAlign: 'center', color: theme.palette.text.primary }}>Novo Setor</Typography>
                </Box>
                <Typography sx={{ color: theme.palette.text.primary }}>Adicione setores que poderão ser vinculados aos seu operadores e utilizados para organização de conversas.</Typography>
            </Box>
            <Box sx={{ width: '70%' }}>
                <Typography sx={{ color: theme.palette.text.primary }}>Setor nome *</Typography>
                <BTextField value={setor.text} onChangeText={handleChangeText} prevData={setor} />
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, width: '70%' }}>
                {['Salvar', 'Limpar'].map((e, idx) => {
                    const background = idx === 0 ? theme.palette.primary.main : theme.palette.background.paper
                    const color = idx === 0 ? theme.palette.text.onPrimary : theme.palette.text.primary
                    const hoverBackground = idx === 0 ? theme.palette.secondary.light : 'transparent'
                    return (
                        <Button onClick={() => handleClick(e)} key={e} sx={{
                            background: background, width: '100%', color: color, padding: 1.5, textTransform: 'none', '&:hover': {
                                background: hoverBackground
                            }
                        }}>{e}</Button>
                    )
                })}
            </Box>
        </Box>
    )
}