import { useState, useEffect, useContext } from 'react'
import { Box, Typography, useTheme, Button } from '@mui/material'
import { getIcon } from '../../../../components/icons'
import { BTextField } from '../../../../components/TextField'
import MAPI from '../../../../api/mainApi'
import { User } from '../../../../contexts/user'



export function NewCampo({ prevData, canCreate, updateModalInfo, pushCampo, editCampo, canEdit }) {
    const theme = useTheme()
    const [campo, setCampo] = useState({
        campo: ''
    })
    const user = useContext(User)

    function handleChangeCampo(value) {
        if (value.length > 50) return
        const newC = { ...campo }
        newC.campo = value
        setCampo(newC)
    }
    async function saveCampo() {
        if (!canCreate && !campo?.id) {
            updateModalInfo(true, true, 'Atenção', 'Você não tem permissões para criar campos.', 'exclamation')
            return
        }
        if (!canEdit && campo?.id) {
            updateModalInfo(true, true, 'Atenção', 'Você não tem permissões para editar campos.', 'exclamation')
            return
        }
        if (!campo.campo) {
            updateModalInfo(true, true, 'Atenção', 'Campo não pode ser vazio', 'exclamation')
            return
        }
        const endpoint = campo?.id ? 'editCampo' : 'saveCampo'
        const method = campo?.id ? 'PUT' : 'POST'
        const save = await MAPI(`camposContato/${endpoint}`, method, campo, user?.data?.token)
        if (save?.status !== 200) {
            const message = save?.apiReturn?.message ? save?.apiReturn?.message : 'Ocorreu um erro ao salvar o campo.'
            updateModalInfo(true, true, 'Atenção', message, 'exclamation')
            return
        }
        handleClick('Limpar')
        const whatFund = campo?.id ? editCampo : pushCampo
        whatFund({ campo: campo.campo, id: save?.apiReturn?.apiReturn })
    }

    function handleClick(type) {
        if (type === 'Salvar') {
            saveCampo()
        }
        else {
            setCampo({
                campo: ''
            })
        }
    }
    useEffect(() => {
        if (prevData) {
            setCampo(prevData)
        } else {
            setCampo({
                campo: ''
            })
        }
    }, [prevData])

    return (
        <Box sx={{
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            height: '100%',
            width: '100%',
            background: theme.palette.background.default,
            padding: 2,
            gap: 3
        }}>
            <Box sx={{ width: '70%' }}>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 3 }}>
                    {getIcon(65, theme.palette.secondary.main)}
                    <Typography sx={{ fontWeight: 'bold', color: theme.palette.text.main, fontSize: 25, textAlign: 'center', color: theme.palette.text.primary }}>Novo Campo</Typography>
                </Box>
                <Typography sx={{ color: theme.palette.text.primary }}>Adicione campos personalizados à ficha dos contatos. Eles podem ser usados na construção dos fluxos, em suas variáveis de chats e em outras funcionalidades It's Chat.</Typography>
            </Box>
            <Box sx={{ width: '70%' }}>
                <Typography sx={{ color: theme.palette.text.primary }}>Campo nome *</Typography>
                <BTextField value={campo.campo} onChangeText={handleChangeCampo} prevData={campo} />
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, width: '70%' }}>
                {['Salvar', 'Limpar'].map((e, idx) => {
                    const background = idx === 0 ? theme.palette.primary.main : theme.palette.background.paper
                    const color = idx === 0 ? theme.palette.text.onPrimary : theme.palette.text.primary
                    const hoverBackground = idx === 0 ? theme.palette.secondary.light : 'transparent'
                    return (
                        <Button onClick={() => handleClick(e)} key={e} sx={{
                            background: background, width: '100%', color: color, padding: 1.5, textTransform: 'none', '&:hover': {
                                background: hoverBackground
                            }
                        }}>{e}</Button>
                    )
                })}
            </Box>
        </Box>
    )
}