import { Drawer, useTheme, Box, Typography, ListItemButton, Collapse, Tooltip, Divider } from "@mui/material";
import { useState, useContext } from "react";
import Logo from '../../inc/images/main_logo.png';
import { User } from "../../contexts/user";
import { getIcon } from "../icons";
import { Link, useLocation } from "react-router-dom";
import { removerCaracteresEspeciais } from "../masks";
import { alpha } from '@mui/material/styles';

const drawerWidth = 290;

export function DrawerFull({ open }) {
    const theme = useTheme();
    const user = useContext(User);
    const [colAtive, setColAtive] = useState(null);
    const location = useLocation();

    function handleCollAtive(col) {
        setColAtive(col === colAtive ? null : col);
    }

    function handleClick(modulo, idx) {
        if (modulo?.inside) {
            handleCollAtive(idx);
        }
    }
    const activeColor = alpha(theme.palette.text.primary, 1);
    const isHome = location.pathname === '/'
    return (
        <Drawer
            sx={{
                width: drawerWidth,
                flexShrink: 0,
                '& .MuiDrawer-paper': {
                    width: drawerWidth,
                    boxSizing: 'border-box',
                    backgroundColor: theme.palette.background.default,
                },
            }}
            variant='permanent'
            anchor="left"
        >
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 3 }}>
                <img src={Logo} style={{ objectFit: 'contain', maxWidth: 100 }} alt="Logo" />
            </Box>
            <Box sx={{ padding: 2 }}>
                <Divider />
            </Box>
            <Box>
                <Tooltip title={'Início'} placement='right-end'>
                    <Box sx={{ display: 'flex', gap: 3, alignItems: 'center', paddingRight: 4 }}>
                        <Box sx={{ flex: 1, flexGrow: 1, height: 40, background: isHome ? theme.palette.primary.main : theme.palette.background.default, width: 5, padding: 0.3, borderRadius: 1 }} />
                        <ListItemButton component={Link} to={'/'} sx={{
                            width: '100%',
                            color: isHome ? theme.palette.primary.main : activeColor, '&:hover': {
                                color: '#fff'
                            },
                            padding: 1
                        }}>
                            <Box sx={{ display: 'flex', flex: 1, alignItems: 'center', padding: 1.3, justifyContent: 'space-between', borderRadius: 2 }}>
                                <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                                    {getIcon(46, isHome ? theme.palette.primary.main : activeColor, 20)}
                                    <Typography sx={{ color: isHome ? theme.palette.primary.main : activeColor, fontWeight: isHome ? 'bold' : 'regular' }}>{'Início'}</Typography>
                                </Box>
                                {getIcon(2, theme.palette.primary.main)}
                            </Box>
                        </ListItemButton>
                    </Box>
                </Tooltip>
                {
                    user?.data?.permissoes.map((e, idx) => {
                        const isActive = location.pathname.includes(removerCaracteresEspeciais(e.moduloEndp).toLowerCase());
                        const iconColor = !isActive ? activeColor : theme.palette.primary.main;
                        const color = !isActive ? activeColor : theme.palette.primary.main;
                        const isInside = e?.inside ? true : false;
                        const arrow = !isInside ? 2 : colAtive === idx ? 37 : 36;
                        return (
                            <Box key={idx}>
                                <Tooltip title={e.moduloDesc} placement='right-end'>
                                    <Box sx={{ display: 'flex', gap: 3, alignItems: 'center', paddingRight: 4 }}>
                                        <Box sx={{ flex: 1, flexGrow: 1, height: 40, background: isActive || colAtive === idx ? theme.palette.primary.main : theme.palette.background.default, width: 5, padding: 0.3, borderRadius: 1 }} />
                                        <ListItemButton component={isInside ? null : Link} to={e?.moduloEndp} onClick={() => handleClick(e, idx)} sx={{
                                            width: '100%',
                                            color: color, '&:hover': {
                                                color: '#fff'
                                            },
                                            padding: 1
                                        }}>
                                            <Box sx={{ display: 'flex', flex: 1, alignItems: 'center', padding: 1.3, justifyContent: 'space-between', borderRadius: 2 }}>
                                                <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                                                    {getIcon(e.moduloIcon, iconColor, 20)}
                                                    <Typography sx={{ color: color, fontWeight: isActive ? 'bold' : 'regular' }}>{e.moduloNome}</Typography>
                                                </Box>
                                                {getIcon(arrow, color)}
                                            </Box>
                                        </ListItemButton>
                                    </Box>
                                </Tooltip>
                                {isInside ?
                                    <Collapse in={colAtive === idx}>
                                        {e?.inside.map((i, subIdx) => {
                                            const isSubActive = location.pathname.includes(i.moduloEndp);
                                            const subColor = !isSubActive ? activeColor : theme.palette.primary.main;
                                            const subIconColor = !isSubActive ? activeColor : theme.palette.primary.main;
                                            return (
                                                <Tooltip key={subIdx} title={i.moduloDesc} placement='right-end'>
                                                    <ListItemButton component={Link} to={i?.moduloEndp} sx={{
                                                        color: subColor,
                                                        marginLeft: 3
                                                    }}>
                                                        <Box sx={{ display: 'flex', flex: 1, alignItems: 'center', padding: 1, justifyContent: 'space-between' }}>
                                                            <Box sx={{ display: 'flex', gap: 2, alignItems: 'center', justifyContent: 'center', paddingLeft: 2 }}>
                                                                {getIcon(i.moduloIcon, subIconColor, 20)}
                                                                <Typography sx={{ color: theme.palette.onDrawer, fontSize: 15 }}>{i.moduloNome}</Typography>
                                                            </Box>
                                                        </Box>
                                                    </ListItemButton>
                                                </Tooltip>
                                            )
                                        })}
                                    </Collapse>
                                    : null}
                            </Box>
                        )
                    })
                }
            </Box >
        </Drawer >
    );
}
