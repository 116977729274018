import Logo_w from '../../inc/images/logo_w.png'
import Logo_d from '../../inc/images/logo_wb.png'
import MinLogo from '../../inc/images/flaticon.png'

export const NorthernLightsTheme = (mode, changeMode) => {
    return {
        palette: {
            logo: mode === 'light' ? Logo_w : Logo_d,
            minLogo: MinLogo,
            mode: mode,
            changeMode: changeMode,
            ...(mode === 'light'
                ? {
                    primary: {
                        main: '#8E24AA',
                        light: '#E1BEE7',
                        dark: '#6A1B9A'
                    },
                    secondary: {
                        main: '#7B1FA2',
                        light: '#CE93D8',
                        dark: '#4A0072'
                    },
                    tertiary: {
                        main: '#7E57C2',
                        light: '#B39DDB',
                        dark: '#4E2A84'
                    },
                    background: {
                        default: '#F3E5F5',
                        paper: '#E1BEE7',
                        inverse: '#311B92'
                    },
                    inverseBackground: {
                        default: '#4527A0',
                        paper: '#311B92'
                    },
                    warning: { main: '#FF7043' },
                    success: { main: '#66BB6A' },
                    error: { main: '#E53935' },
                    text: {
                        main: '#4A148C',
                        primary: '#4A148C',
                        disabled: '#BDBDBD',
                        inverse: '#F3E5F5',
                        onPrimary: '#FFFFFF',
                        dark: '#311B92',
                        light: '#F5F5F5',
                        onDrawer: '#757575'
                    }
                } : {
                    primary: {
                        main: '#BA68C8',
                        light: '#E1BEE7',
                        dark: '#7B1FA2'
                    },
                    secondary: {
                        main: '#9C27B0',
                        light: '#CE93D8',
                        dark: '#6A1B9A'
                    },
                    tertiary: {
                        main: '#AB47BC',
                        light: '#D1C4E9',
                        dark: '#6A1B9A'
                    },
                    background: {
                        default: '#311B92',
                        paper: '#4527A0',
                        inverse: '#F3E5F5'
                    },
                    inverseBackground: {
                        default: '#CE93D8',
                        paper: '#B39DDB'
                    },
                    warning: { main: '#FF5722' },
                    success: { main: '#8BC34A' },
                    error: { main: '#D32F2F' },
                    text: {
                        main: '#EDE7F6',
                        primary: '#EDE7F6',
                        disabled: '#757575',
                        inverse: '#311B92',
                        onPrimary: '#FFFFFF',
                        dark: '#CE93D8',
                        onDrawer: '#B0BEC5',
                        light: '#FAFAFA'
                    }
                }
            )
        }
    }
}
