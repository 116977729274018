import React, { useState, useContext, useEffect } from 'react';
import { useTheme, Box, Button, Typography, IconButton, Dialog, DialogTitle, DialogContent, Divider } from '@mui/material';
import { User } from '../../../../../../contexts/user';
import { ModalInfo } from '../../../../../../components/modalInfo';
import PreviewIcon from '@mui/icons-material/Preview';
import DeleteIcon from '@mui/icons-material/Delete';
import { CMultiTextField, CTextField } from '../../../../../../components/TextField';

export function TextLegenda({ saveChanges, prevData }) {
    const theme = useTheme();
    const user = useContext(User);

    // Estados
    const [files, setFiles] = useState([]);
    const [legenda, setLegenda] = useState('');
    const [preview, setPreview] = useState({ open: false, file: null });
    const [MInfo, setMInfo] = useState({
        visible: false,
        title: '',
        subtitle: '',
        canDesactive: true,
        icon: '',
        funcS: () => null
    });

    function updateModalInfo(visible, canDesactive, title, subtitle, icon, func) {
        const newMInfo = { ...MInfo };
        newMInfo.visible = visible;
        newMInfo.canDesactive = canDesactive;
        newMInfo.title = title;
        newMInfo.subtitle = subtitle;
        newMInfo.icon = icon;
        newMInfo.funcS = func;
        setMInfo(newMInfo);
    }

    function handleFileChange(event) {
        const newFiles = [...files];
        const file = event.target.files[0];
        if (file) {
            const fileReader = new FileReader();
            fileReader.onload = (e) => {
                newFiles.push({
                    tipo: file.type,
                    nome: file.name,
                    arquivo: e.target.result,
                    legenda: legenda
                });
                setFiles(newFiles);
                saveChanges(newFiles, 'arquivos');
                setLegenda('');
            };
            fileReader.readAsDataURL(file);
        }
    }

    function handlePreview(file) {
        setPreview({ open: true, file });
    }

    function handleClosePreview() {
        setPreview({ open: false, file: null });
    }

    function handleDelete(index) {
        const newFiles = [...files];
        newFiles.splice(index, 1);
        setFiles(newFiles);
    }

    function renderPreviewContent(file) {
        const src = file.arquivo || file.link
        if (file.tipo.startsWith('image/')) {
            return <img src={src} alt="preview" style={{ width: '100%' }} />;
        } else if (file.tipo.startsWith('video/')) {
            return <video controls style={{ width: '100%' }} src={src}></video>;
        } else if (file.tipo === 'application/pdf') {
            return <embed src={src} type="application/pdf" width="100%" height="600px" />;
        } else if (file.tipo.startsWith('audio/')) {
            return <audio controls style={{ width: '100%' }} src={src}></audio>;
        } else if (file.tipo.startsWith('text/')) {
            return <pre>{src}</pre>;
        } else {
            return <Typography variant="body1">Pré-visualização não disponível para este tipo de arquivo.</Typography>;
        }
    }

    useEffect(() => {
        if (prevData?.arquivos && Array.isArray(prevData?.arquivos)) {
            setFiles(prevData?.arquivos)
        }
        else {
            setFiles([])
        }
    }, [prevData])

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            height: '100%'
        }}>
            <input
                type="file"
                accept="image/*, video/*, audio/*, application/pdf, text/*"
                style={{ display: 'none' }}
                id="file-input"
                onChange={handleFileChange}
            />
            <label htmlFor="file-input">
                <Button variant="contained" component="span">
                    Selecionar Arquivo
                </Button>
            </label>

            {files.length > 0 && (
                <Box sx={{ mt: 1, maxHeight: 250, overflowY: 'scroll' }}>
                    {files.map((file, index) => {
                        return (
                            <Box key={index} sx={{ mt: 1 }}>
                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                    <Typography variant="body1">{file.nome}</Typography>
                                    <IconButton onClick={() => handlePreview(file)}>
                                        <PreviewIcon />
                                    </IconButton>
                                    <IconButton onClick={() => handleDelete(index)}>
                                        <DeleteIcon />
                                    </IconButton>
                                </Box>
                                <CMultiTextField
                                    label="Legenda"
                                    isVariable
                                    value={file.legenda}
                                    onChangeText={(e) => {
                                        const newFiles = [...files];
                                        newFiles[index].legenda = e;
                                        setFiles(newFiles);
                                    }}
                                    onBlur={() => {
                                        const newFiles = [...files];
                                        newFiles[index].legenda = file.legenda;
                                        saveChanges(newFiles, 'legenda')
                                    }
                                    }
                                    minRows={5}
                                    maxRows={5}
                                />
                                <Divider />
                            </Box>
                        )
                    })}
                </Box>
            )}

            <Dialog open={preview.open} onClose={handleClosePreview} maxWidth="md" fullWidth>
                <DialogTitle>Pré-visualização do Arquivo</DialogTitle>
                <DialogContent>
                    {preview.file && renderPreviewContent(preview.file)}
                </DialogContent>
            </Dialog>

            <ModalInfo
                hideModal={() => updateModalInfo(false)}
                canDesactive={MInfo.canDesactive}
                visible={MInfo.visible}
                title={MInfo.title}
                subtitle={MInfo.subtitle}
                icon={MInfo.icon}
                funcS={MInfo.funcS}
            />
        </Box>
    );
}
