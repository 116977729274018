import React, { useState, useContext } from 'react';
import { useTheme, Box, Modal, Typography } from '@mui/material';
import { ModalInfo } from '../../../components/modalInfo';
import { User } from '../../../contexts/user';
import QRCode from 'qrcode.react';

export function ModalQRCode({ visible, qrcode, hideModal }) {
    const theme = useTheme();
    const user = useContext(User);
    //estados
    const [MInfo, setMInfo] = useState({
        visible: false,
        title: '',
        subtitle: '',
        canDesactive: true,
        icon: '',
        funcS: () => null
    });

    function updateModalInfo(visible, canDesactive, title, subtitle, icon, func) {
        const newMInfo = { ...MInfo };
        newMInfo.visible = visible;
        newMInfo.canDesactive = canDesactive;
        newMInfo.title = title;
        newMInfo.subtitle = subtitle;
        newMInfo.icon = icon;
        newMInfo.funcS = func;
        setMInfo(newMInfo);
    }

    return (
        <Modal open={visible} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 5 }} onClose={hideModal} slotProps={{
            backdrop: {
                sx: {
                    backgroundColor: theme.palette.mode === 'dark' ? 'rgba(250,255,255, 0.2)' : 'rgba(0,0,0, 0.4)'
                }
            }
        }}>
            <Box sx={{
                background: '#fff',
                height: 600, width: 800,
                outline: 'none',
                display: 'flex',
                flexDirection: 'column'
            }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', background: theme.palette.primary.main, height: '30%', padding: 3 }}>
                    <Typography sx={{ color: theme.palette.text.onPrimary, fontWeight: 'bold', fontSize: 50 }}>QRCode</Typography>
                    <Typography sx={{ color: theme.palette.text.onPrimary, fontSize: 20 }}>Leia o qrcode a baixo com seu whatsaap para carregar uma nova conexão.</Typography>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                    <QRCode value={qrcode} size={250} />
                </Box>
                <ModalInfo hideModal={() => updateModalInfo(false)} canDesactive={MInfo.canDesactive} visible={MInfo.visible} title={MInfo.title} subtitle={MInfo.subtitle} icon={MInfo.icon} funcS={MInfo.funcS} />
            </Box>
        </Modal>
    );
}