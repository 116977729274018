var CryptoJS = require("crypto-js");

export const encrypt = (value) => {
    var ciphertext = CryptoJS.AES.encrypt(JSON.stringify(value), 'smashpointdarwin*').toString();
    return (ciphertext)
}

export const decrypt = (value) => {
    var bytes = CryptoJS.AES.decrypt(value, 'smashpointdarwin*');
    var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    return (decryptedData)
}