import React, { useState, useContext } from 'react';
import { useTheme, Box, Typography, Divider, Button } from '@mui/material';
import { ModalInfo } from '../../../components/modalInfo';
import { User } from '../../../contexts/user';
import { getIcon } from '../../../components/icons';
import { SimpleTable } from '../../../components/tables';

export function List({ data, updateModFluxo, deleteFluxo }) {
    const theme = useTheme();
    const user = useContext(User);
    //estados
    const [MInfo, setMInfo] = useState({
        visible: false,
        title: '',
        subtitle: '',
        canDesactive: true,
        icon: '',
        funcS: () => null
    });

    function updateModalInfo(visible, canDesactive, title, subtitle, icon, func) {
        const newMInfo = { ...MInfo };
        newMInfo.visible = visible;
        newMInfo.canDesactive = canDesactive;
        newMInfo.title = title;
        newMInfo.subtitle = subtitle;
        newMInfo.icon = icon;
        newMInfo.funcS = func;
        setMInfo(newMInfo);
    }

    return (
        <Box sx={{
            flex: 1,
            display: 'flex',
            gap: 2,
            height: '100%',
        }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', background: theme.palette.background.default, height: '100%', width: '30%', padding: 5, gap: 2 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 3, justifyContent: 'center' }}>
                    {getIcon(73, theme.palette.secondary.main)}
                    <Typography sx={{ color: theme.palette.text.primary, fontSize: 22, fontWeight: 'bold' }}>Criação de fluxo</Typography>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                    <Typography sx={{ color: theme.palette.text.primary, textAlign: 'center' }}>O Módulo de Fluxo permite criar e gerenciar sequências de atendimento ao cliente, com passos configuráveis e mensagens automáticas, otimizando o atendimento.</Typography>
                </Box>
                <Box sx={{ height: '1px', width: '100%', background: theme.palette.divider }} />
                <Button fullWidth sx={{ textTransform: 'none', border: 1, borderColor: theme.palette.divider }} onClick={() => updateModFluxo(true)}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                        {getIcon(74, theme.palette.primary.main, 50)}
                        <Typography sx={{ color: theme.palette.text.primary, fontWeight: 'bold' }}>Novo Fluxo</Typography>
                    </Box>
                </Button>
            </Box>
            <Box sx={{ width: '65%', background: theme.palette.background.default, padding: 2 }}>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 3, justifyContent: 'center' }}>
                    {getIcon(30, theme.palette.secondary.main)}
                    <Typography sx={{ fontWeight: 'bold', color: theme.palette.text.main, fontSize: 25, textAlign: 'center', color: theme.palette.text.primary }}>Meus fluxos</Typography>
                </Box>
                <Box>
                    <SimpleTable headers={['ID', 'Nome', 'Entradas', 'Editar', 'Excluir']} rows={data} params={['id', 'nome', 'entries']} editar={(value) => updateModFluxo(true, value)} excluir={(value) => deleteFluxo(value)} />
                </Box>
            </Box>
            <ModalInfo hideModal={() => updateModalInfo(false)} canDesactive={MInfo.canDesactive} visible={MInfo.visible} title={MInfo.title} subtitle={MInfo.subtitle} icon={MInfo.icon} funcS={MInfo.funcS} />
        </Box>
    );
}