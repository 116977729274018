import { useState, useMemo, useEffect, createContext } from "react";
import { createTheme } from "@mui/material/styles";
import Cookies from 'universal-cookie';
import { DefaultTheme } from "./layouts/defaultTheme";
import { AuroraTheme } from "./layouts/auroraBorealTheme";
import { DesertDuskTheme } from "./layouts/desertDuskTheme";
import { EarthyTerracottaLigthTheme } from "./layouts/terracotaTheme";
import { EarthyTerracottaTheme } from "./layouts/earthyTerracottaTheme";
import { NorthernLightsTheme } from "./layouts/northernLigthsTheme";
import { OceanSerenityTheme } from "./layouts/serenidadeTheme";
import { TropicalVibrantTheme } from "./layouts/tropicalVibrant";
import { TropicalSunsetTheme } from "./layouts/tropicalSunset";
import { NewTheme } from "./layouts/newTheme";

//color design tokens

// mui theme settings

export const themeSettings = (mode, themeMode) => {
    const getThemeFunc = getTheme(themeMode)
    return getThemeFunc(mode)
}

export const ColorModeContext = createContext({
    toggleColorMode: () => { }
})

export const useMode = (themeLayout) => {
    const [mode, setMode] = useState("light")
    const [themeMode, setThemeMode] = useState('NewTheme')
    const cookies = new Cookies()

    const colorMode = useMemo(
        () => ({
            toggleColorMode: () => {
                cookies.set('its_chat_mode', mode === 'dark' ? 'light' : 'dark', { path: '/', maxAge: 999999999999999 });
                setMode((prev) => (prev === 'dark' ? 'light' : 'dark'))
            }
        })
    )
    function changeTheme(value) {
        cookies.set('its_chat_theme', value, { path: '/', maxAge: 999999999999999 })
        setThemeMode(value)
    }
    useEffect(() => {
        const getMode = cookies.get('its_chat_mode')
        const getTheme = cookies.get('its_chat_theme')
        if (getMode) {
            setMode(getMode)
        }
        const whatTheme = themeLayout ? themeLayout : getTheme ? getTheme : 'newTheme'
        setThemeMode(whatTheme)
    }, [themeLayout])

    const theme = useMemo(() => createTheme(themeSettings(mode, themeMode)), [mode, themeMode])
    theme.changeTheme = changeTheme
    theme.allThemes = getTheme(null, true)
    theme.activeTheme = themeMode
    return [theme, colorMode, mode, changeTheme]
}

function getTheme(themeMode, all) {
    const themes = {
        defaultTheme: DefaultTheme,
        AuroraTheme: AuroraTheme,
        DesertDuskTheme: DesertDuskTheme,
        EarthyTerracottaLigthTheme: EarthyTerracottaLigthTheme,
        EarthyTerracottaTheme: EarthyTerracottaTheme,
        NorthernLightsTheme: NorthernLightsTheme,
        OceanSerenityTheme: OceanSerenityTheme,
        TropicalVibrantTheme: TropicalVibrantTheme,
        TropicalSunsetTheme: TropicalSunsetTheme,
        NewTheme: NewTheme
    }
    if (all) {
        return themes
    }
    if (themes.hasOwnProperty(themeMode)) {
        return themes[themeMode]
    }
    else return NewTheme
}