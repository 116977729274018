import React, { useState, useContext, useEffect } from 'react';
import { useTheme, Box, Button, ThemeProvider, Badge, createTheme, Typography } from '@mui/material';
import { ModalInfo } from '../../../../../components/modalInfo';
import { User } from '../../../../../contexts/user';
import MAPI from '../../../../../api/mainApi';
import { UpComp } from '../../../../../components/upComp';
import { getIcon } from '../../../../../components/icons';

export function Tipos({ prevData, updateData, slideAtivo }) {
    const theme = useTheme();
    const user = useContext(User);
    const customTheme = createTheme({
        palette: {
            primary: { main: theme.palette.primary.main },
        },
    });
    //estados
    const [data, setData] = useState([])
    const [MInfo, setMInfo] = useState({
        visible: false,
        title: '',
        subtitle: '',
        canDesactive: true,
        icon: '',
        funcS: () => null
    });

    function updateModalInfo(visible, canDesactive, title, subtitle, icon, func) {
        const newMInfo = { ...MInfo };
        newMInfo.visible = visible;
        newMInfo.canDesactive = canDesactive;
        newMInfo.title = title;
        newMInfo.subtitle = subtitle;
        newMInfo.icon = icon;
        newMInfo.funcS = func;
        setMInfo(newMInfo);
    }

    async function getData() {
        updateModalInfo(true, false, 'Carregando', 'Buscando tipos de mensagens', 'loading')
        const get = await MAPI('disparador/getTipos', 'GET', null, user?.data?.token)
        if (get?.status !== 200) {
            const message = get?.apiReturn?.message ? get?.apiReturn?.message : 'Ocorreu um erro ao buscar os tipos'
            updateModalInfo(true, true, 'Atenção', message, 'exclamation')
            return
        }
        updateModalInfo(false)
        setData(get?.apiReturn?.apiReturn)

    }

    function handleSubmit(tipo) {
        const value = prevData?.tipo?.id === tipo?.id ? null : tipo
        updateData(value, 'tipo')
        return
    }
    useEffect(() => {
        if (data.length === 0) {
            getData()
        }
    }, [slideAtivo])

    return (
        <Box sx={{
            flex: 1,
            maxHeight: 320,
            height: '100%'
        }}>
            <Box sx={{ overflowY: 'scroll', height: '100%', padding: 2 }}>
                {[data.map((e, index) => {
                    return (
                        <Button sx={{ width: '100%', textAlign: 'left' }} onClick={() => handleSubmit(e)} >
                            <ThemeProvider theme={customTheme}>
                                <Badge sx={{ width: '100%' }} badgeContent={prevData?.tipo?.id === e.id ? <Box sx={{ background: theme.palette.secondary.main, borderRadius: 5 }}>{getIcon(59, theme.palette.text.onPrimary)}</Box> : null} anchorOrigin={{ vertical: 'top', horizontal: 'left' }}>
                                    <Box key={index} sx={{ width: '100%', display: 'flex', flexDirection: 'column', gap: 2 }}>
                                        <Box sx={{ display: 'flex', background: theme.palette.background.default, boxShadow: theme.palette.mode === 'dark' ? '0px 4px 10px rgba(155, 155, 155, 0.3)' : '0px 4px 10px rgba(0, 0, 0, 0.1)', padding: 2, alignItems: 'center', gap: 3 }}>
                                            <UpComp tamanho={30}>
                                                {
                                                    getIcon(e.icon, theme.palette.mode === 'dark' ? theme.palette.text.primary : theme.palette.primary.main, 20)
                                                }
                                            </UpComp>
                                            <Box>
                                                <Typography sx={{ color: theme.palette.text.primary, textTransform: 'none', fontWeight: 'bold', textAlign: 'left' }}>{e.nome}</Typography>
                                                <Typography sx={{ color: theme.palette.text.primary, textTransform: 'none' }}>{e.descricao}</Typography>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Badge>
                            </ThemeProvider>
                        </Button>
                    )
                })]}
            </Box>
            <ModalInfo hideModal={() => updateModalInfo(false)} canDesactive={MInfo.canDesactive} visible={MInfo.visible} title={MInfo.title} subtitle={MInfo.subtitle} icon={MInfo.icon} funcS={MInfo.funcS} />
        </Box>
    );
}