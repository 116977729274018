import React from 'react';
import { Box, Button, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import DescriptionIcon from '@mui/icons-material/Description';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import InsertChartIcon from '@mui/icons-material/InsertChart';
import { replaceMensagemVariaveis } from './replaceMensagemVariaveis';

export const WhatsAppMessage = ({ tipo, nome, mensagem, link, legenda, variaveis, data, width }) => {
    const theme = useTheme();

    const renderIcon = (fileType) => {
        switch (fileType) {
            case 'application/pdf':
                return <PictureAsPdfIcon sx={{ marginRight: 1, color: theme.palette.text.onPrimary }} />;
            case 'application/msword':
            case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
                return <DescriptionIcon sx={{ marginRight: 1, color: theme.palette.text.onPrimary }} />;
            case 'application/vnd.ms-excel':
            case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
                return <InsertChartIcon sx={{ marginRight: 1, color: theme.palette.text.onPrimary }} />;
            default:
                return <InsertDriveFileIcon sx={{ marginRight: 1, color: theme.palette.text.onPrimary }} />;
        }
    };
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                padding: 2,
                borderRadius: 2,
                backgroundColor: theme.palette.primary.main,
                marginBottom: 2,
                maxWidth: width ? width : '50%',
                alignSelf: 'flex-end',
                boxShadow: 1,
                borderRadius: '20px',
                padding: '10px',
                wordBreak: 'break-word'
            }}
        >
            <Box sx={{ background: tipo !== 1 && tipo.startsWith('image/') ? theme.palette.background.default : 'transparent', padding: 2 }}>
                {tipo === 1 ? (
                    <Box sx={{ marginTop: 1 }}>
                        <Typography sx={{ whiteSpace: 'pre-line', color: theme.palette.text.onPrimary }}>{replaceMensagemVariaveis(mensagem, variaveis, data)}</Typography>
                    </Box>
                ) :
                    <>
                        {
                            tipo.startsWith('image/') && (
                                <Box sx={{ maxWidth: '100%', maxHeight: 250 }}>
                                    <img src={link} alt={nome} style={{ maxWidth: '100%', maxHeight: 250, borderRadius: '10px' }} />
                                </Box>
                            )
                        }
                        {tipo.startsWith('video/') && (
                            <Box sx={{ maxWidth: '100%', maxHeight: 250 }}>
                                <video controls style={{ maxWidth: '100%', borderRadius: '10px' }}>
                                    <source src={link} type={tipo} />
                                    Seu navegador não suporta o elemento de vídeo.
                                </video>
                            </Box>
                        )}
                        {tipo.startsWith('audio/') && (
                            <Box sx={{ maxWidth: '100%', maxHeight: 250 }}>
                                <audio controls style={{ width: '100%' }}>
                                    <source src={link} type={tipo} />
                                    Seu navegador não suporta o elemento de áudio.
                                </audio>
                            </Box>
                        )}
                        {!tipo.startsWith('image/') && !tipo.startsWith('video/') && !tipo.startsWith('audio/') && link && (
                            <Box sx={{ maxWidth: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2 }}>
                                <Box sx={{ display: 'flex' }}>
                                    {renderIcon(tipo)}
                                    <Typography variant="body1" sx={{ color: theme.palette.text.onPrimary }}>{nome}</Typography>
                                </Box>
                                <Box sx={{ display: 'flex' }}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() => window.open(link, '_blank')}
                                        sx={{ marginLeft: 1 }}
                                    >
                                        Abrir
                                    </Button>
                                </Box>
                            </Box>
                        )}
                        {!tipo.startsWith('image/') && !tipo.startsWith('video/') && !tipo.startsWith('audio/') && mensagem && (
                            <Box sx={{ maxWidth: '100%', maxHeight: 250 }}>
                                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>{nome}</Typography>
                                <Typography variant="body2">{replaceMensagemVariaveis(mensagem, variaveis, data)}</Typography>
                            </Box>
                        )}
                    </>
                }
            </Box>
            {
                legenda && (
                    <Box sx={{ marginTop: 1 }}>
                        <Typography sx={{ whiteSpace: 'pre-line', color: theme.palette.text.onPrimary }}>{replaceMensagemVariaveis(legenda, variaveis, data)}</Typography>
                    </Box>
                )
            }
        </Box >
    );
};
