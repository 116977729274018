import React, { useState, useContext } from 'react';
import { useTheme, Box, Typography, Button } from '@mui/material';
import { ModalInfo } from '../../../../components/modalInfo';
import { User } from '../../../../contexts/user';
import { UpComp } from '../../../../components/upComp';
import { getIcon } from '../../../../components/icons';
import { SimpleTable } from '../../../../components/tables';
import MAPI from '../../../../api/mainApi';

export function ListTags({ tags, changeEditTag, deleteTag, canExclude }) {
    const theme = useTheme();
    const user = useContext(User);
    //estados
    const [MInfo, setMInfo] = useState({
        visible: false,
        title: '',
        subtitle: '',
        canDesactive: true,
        icon: '',
        funcS: () => null
    });

    function updateModalInfo(visible, canDesactive, title, subtitle, icon, func) {
        const newMInfo = { ...MInfo };
        newMInfo.visible = visible;
        newMInfo.canDesactive = canDesactive;
        newMInfo.title = title;
        newMInfo.subtitle = subtitle;
        newMInfo.icon = icon;
        newMInfo.funcS = func;
        setMInfo(newMInfo);
    }
    async function Delete(tag, control) {
        if (!canExclude) {
            updateModalInfo(true, true, 'Atenção', 'Você não tem permissão para excluir tags', 'exclamation')
            return
        }
        if (!control) {
            updateModalInfo(true, true, 'Atenção', 'Você tem certeza que deseja excluir essa tag?', 'question', () => Delete(tag, true))
            return
        }
        updateModalInfo(true, true, 'Excluindo', 'Tentando excluir sua tag.', 'loading')
        const save = await MAPI(`tags/delete/${tag.id}`, 'DELETE', null, user?.data?.token)
        if (save?.status !== 200) {
            const message = save?.apiReturn?.message ? save?.apiReturn?.message : 'Ocorreu um erro ao excluir a tag.'
            updateModalInfo(true, true, 'Atenção', message, 'exclamation')
            return
        }
        updateModalInfo(false)
        deleteTag(tag)
    }

    return (
        <Box sx={{
            flex: 1,
            padding: 2,
            background: theme.palette.background.default
        }}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 3, justifyContent: 'center' }}>
                {getIcon(30, theme.palette.secondary.main)}
                <Typography sx={{ fontWeight: 'bold', color: theme.palette.text.main, fontSize: 25, textAlign: 'center', color: theme.palette.text.primary }}>Minhas tags</Typography>
            </Box>
            <Box sx={{ padding: 2 }}>
                <SimpleTable excluir={(value) => Delete(value)} editar={(value) => changeEditTag(value)} headers={['Nome', 'TAG', 'Editar', 'Excluir']} rows={tags} params={['nome', 'color']} />
            </Box>
            <ModalInfo hideModal={() => updateModalInfo(false)} canDesactive={MInfo.canDesactive} visible={MInfo.visible} title={MInfo.title} subtitle={MInfo.subtitle} icon={MInfo.icon} funcS={MInfo.funcS} />
        </Box>
    );
}

/* {Array.isArray(tags) ? tags.map((e) => {
                return (
                    <Button sx={{ textTransform: 'none' }} onClick={() => changeEditTag(true, e)}>
                        <Box sx={{ position: 'relative', display: 'inline-block', width: 'fit-content' }}>
                            <Box sx={{ position: 'relative' }}>
                                {getIcon(53, theme.palette.background.default, 150)}
                                <Box sx={{
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    transform: 'translate(-50%, -50%)',
                                    padding: 2
                                }}>

                                    <Typography
                                        sx={{
                                            fontWeight: 'bold',
                                            pointerEvents: 'none',
                                            color: theme.palette.text.primary,
                                            display: '-webkit-box',
                                            WebkitBoxOrient: 'vertical',
                                            WebkitLineClamp: 1, // Número de linhas
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                        }}

                                    >
                                        {e.nome}
                                    </Typography>
                                    <Box sx={{ width: 10, height: 10, borderRadius: 5, background: e?.color }}></Box>
                                </Box>
                            </Box>
                        </Box>
                    </Button>
                )
            })
                : null} */