import * as React from 'react';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import { Box, Button, FormControl, IconButton, OutlinedInput, Select, Typography, useTheme } from '@mui/material';
import { CTextField } from './TextField';

export function MenuList({ options, param, selected, title, icon, width, handleChange, disabled }) {
    const theme = useTheme()
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [selectedIndex, setSelectedIndex] = React.useState(1);
    const open = Boolean(anchorEl);
    const handleClickListItem = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuItemClick = (event, index) => {
        setSelectedIndex(index);
        setAnchorEl(null);
        if (handleChange) {
            handleChange(index, title)
        }
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    return (
        <div>
            <Box>
                <Button
                    disabled={disabled ? disabled : false}
                    onClick={handleClickListItem}
                    sx={{
                        borderWidth: 0,
                        borderBottom: `0.1px solid ${theme.palette.inverseBackground.default}`,
                        borderRadius: 1,
                        color: theme.palette.text.primary,
                        width: width ? width : '100%',
                        minHeight: 0, minWidth: 0, padding: 1, paddingLeft: 0.4,
                        '&:hover': {
                            backgroundColor: theme.palette.action.hover
                        },
                        textTransform: 'none'
                    }}>
                    <Box sx={{ display: 'flex', gap: 1, flexDirection: 'column', width: width ? width : '100%', justifyContent: 'flex-start' }}>
                        <Typography sx={{ fontSize: 15, textAlign: 'left' }}>{title}</Typography>
                        <Box sx={{ display: 'flex', gap: 1 }}>
                            {icon ? icon : null}
                            <Typography sx={{ color: theme.palette.text.primary }}>{selected && param ? selected[param] : ''}</Typography>
                        </Box>
                    </Box>
                </Button>
            </Box>
            <Menu
                id="lock-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'lock-button',
                    role: 'listbox',
                }}
            >
                {Array.isArray(options) ? options.map((option, index) => (
                    <MenuItem
                        key={option[param]}
                        selected={index === selectedIndex}
                        onClick={(event) => handleMenuItemClick(event, index)}
                    >
                        {param && option ? option[param] : ''}
                    </MenuItem>
                )) : null}
            </Menu>
        </div >
    );
}



export function FullMenu({ options = [], param = '', title = '', disabled = false, width = '100%', icon = null, selected = null, handleChange, isTitle }) {
    const theme = useTheme();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [selectedIndex, setSelectedIndex] = React.useState(0);
    const open = Boolean(anchorEl);

    const handleClickListItem = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuItemClick = (event, index) => {
        setSelectedIndex(index);
        setAnchorEl(null);
        if (handleChange) {
            handleChange(index, title);
        }
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
                padding: 2,
            },
        },
    };

    return (
        <Box sx={{ width: '100%' }}>
            <FormControl sx={{ width: '100%' }}>
                <Select
                    displayEmpty
                    disabled={disabled ? disabled : false}
                    value={selected && param ? selected[param] : ''}
                    onChange={handleMenuItemClick}
                    input={<OutlinedInput label={param} />}
                    sx={{ width: '100%' }}
                    MenuProps={MenuProps}
                    renderValue={(value) => {
                        if (!selected) {
                            return <Typography sx={{ color: theme.palette.text.primary }}>{isTitle ? title : 'Selecionar'}</Typography>
                        }
                        return selected[param]
                    }}
                    inputProps={{ 'aria-label': 'Without label' }}
                >
                    <MenuItem disabled value="">
                        <em>Selecionar</em>
                    </MenuItem>
                    {Array.isArray(options) ? options.map((e, idx) => {
                        return (
                            <MenuItem
                                key={idx}
                                value={e.id}
                                sx={{ padding: 2 }}
                            >{e[param]}</MenuItem>
                        )
                    }) : null}
                </Select>
            </FormControl>
        </Box>

    );
}

export function MenuFluxo({ options = [], param = '', title = '', disabled = false, width = '100%', icon = null, selected = null, handleChange, isTitle, newTheme }) {
    const baseTheme = useTheme()
    const theme = newTheme ? newTheme : baseTheme;
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [selectedIndex, setSelectedIndex] = React.useState(0);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuItemClick = (event, index) => {
        setSelectedIndex(index);
        setAnchorEl(null);
        if (handleChange) {
            handleChange(index, title);
        }
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
                padding: 2,
            },
        },
    };

    return (
        <Box sx={{ width }}>
            <FormControl sx={{ width: '100%' }}>
                <IconButton
                    onClick={handleClick}
                    disabled={disabled}
                    aria-label={title || 'Open menu'}
                    sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderRadius: 2, border: 1, borderColor: theme.palette.divider }}
                >
                    <Typography sx={{ color: theme.palette.text.primary }}>
                        {selected ? selected[param] : isTitle ? title : 'Selecionar'}
                    </Typography>
                    {icon}
                </IconButton>
                <Menu
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                >
                    <MenuItem disabled>
                        <Typography style={{ color: theme.palette.text.primary }}>Selecionar</Typography>
                    </MenuItem>
                    {Array.isArray(options) ? options.map((e, idx) => (
                        <MenuItem
                            key={idx}
                            onClick={(event) => handleMenuItemClick(event, idx)}
                        >
                            {e[param]}
                        </MenuItem>
                    )) : null}
                </Menu>
            </FormControl>
        </Box>
    );
}