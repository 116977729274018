import { useContext } from "react";
import { User } from "../contexts/user";
import { NotUser } from "./notUser";
import { IsUser } from "./isUser";
import { useMode } from "../theme/theme";
import { ColorModeContext } from "../theme/theme";
import { ThemeProvider, Typography } from "@mui/material";
import { SplashScreen } from "../views/splashScreen";
import { useLocation } from "react-router-dom";
import { Descadastrar } from "../views/descadastrar";

export function Router() {
    const user = useContext(User)
    const location = useLocation()
    const [theme, colorMode] = useMode('newTheme')
    if (location.pathname.includes('/descadastrar')) return (
        <ThemeProvider theme={theme}>
            <ColorModeContext.Provider value={colorMode}><Descadastrar />
            </ColorModeContext.Provider>
        </ThemeProvider>

    )
    if (user?.data?.token === null) {
        return (
            <ThemeProvider theme={theme}>
                <ColorModeContext.Provider value={colorMode}>
                    <SplashScreen />
                </ColorModeContext.Provider>
            </ThemeProvider>
        )
    }
    if (user.data?.token === false) {
        return (
            <ThemeProvider theme={theme}>
                <ColorModeContext.Provider value={colorMode}>
                    <NotUser />
                </ColorModeContext.Provider>
            </ThemeProvider>
        )
    }
    return (
        <ThemeProvider theme={theme}>
            <ColorModeContext.Provider value={colorMode}>
                <IsUser />
            </ColorModeContext.Provider>
        </ThemeProvider>
    )
}