import React, { useState, useContext, useEffect } from 'react';
import { createTheme, Badge, useTheme, Box, Button, Typography, List, ListItem, ListItemText, ListItemAvatar, Avatar, IconButton, Dialog, DialogTitle, DialogContent, ThemeProvider } from '@mui/material';
import { User } from '../../../../../../contexts/user';
import { ModalInfo } from '../../../../../../components/modalInfo';
import PreviewIcon from '@mui/icons-material/Preview';
import DeleteIcon from '@mui/icons-material/Delete';

export function Arquivo({ saveChanges, deleteArquivo, prevData }) {
    const theme = useTheme();
    const user = useContext(User);
    const customTheme = createTheme({
        palette: {
            primary: { main: theme.palette.primary.main },
        },
    });

    // estados
    const [data, setData] = useState([]);
    const [MInfo, setMInfo] = useState({
        visible: false,
        title: '',
        subtitle: '',
        canDesactive: true,
        icon: '',
        funcS: () => null
    });
    const [preview, setPreview] = useState({ open: false, file: null });
    const [alertMessage, setAlertMessage] = useState(true)
    function updateModalInfo(visible, canDesactive, title, subtitle, icon, func) {
        const newMInfo = { ...MInfo };
        newMInfo.visible = visible;
        newMInfo.canDesactive = canDesactive;
        newMInfo.title = title;
        newMInfo.subtitle = subtitle;
        newMInfo.icon = icon;
        newMInfo.funcS = func;
        setMInfo(newMInfo);
    }

    function handleFileChange(event) {
        const file = event.target.files[0];
        if (file) {
            const fileReader = new FileReader();
            fileReader.onload = (e) => {
                const newFile = {
                    tipo: file.type,
                    nome: file.name,
                    arquivo: e.target.result,
                };
                setData((prevData) => [...prevData, newFile]);
                saveChanges(newFile, 'arquivos');
            };
            fileReader.readAsDataURL(file);
        }
    }

    function handlePreview(file) {
        setPreview({ open: true, file });
    }

    function handleClosePreview() {
        setPreview({ open: false, file: null });
    }

    function handleDelete(index, arquivo) {
        const newData = data.filter((_, i) => i !== index);
        deleteArquivo(arquivo)
        setData(newData);
    }

    function renderPreviewContent(file) {
        const src = file.arquivo || file.link
        if (file.tipo.startsWith('image/')) {
            return <img src={src} alt="preview" style={{ width: '100%' }} />;
        } else if (file.tipo.startsWith('video/')) {
            return <video controls style={{ width: '100%' }} src={src}></video>;
        } else if (file.tipo === 'application/pdf') {
            return <embed src={src} type="application/pdf" width="100%" height="600px" />;
        } else if (file.tipo.startsWith('audio/')) {
            return <audio controls style={{ width: '100%' }} src={src}></audio>;
        } else if (file.tipo.startsWith('text/')) {
            return <pre>{src}</pre>;
        } else {
            return <Typography variant="body1">Pré-visualização não disponível para este tipo de arquivo.</Typography>;
        }
    }

    useEffect(() => {
        if (prevData?.arquivos && Array.isArray(prevData?.arquivos)) {
            setData(prevData?.arquivos)
        }
        else {
            setData([])
        }
    }, [prevData])

    return (
        <Box sx={{
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
            gap: 3,
            maxHeight: 300,
            padding: 1
        }}>
            <Button variant="contained" component="label">
                Inserir Arquivo
                <input
                    type="file"
                    hidden
                    onChange={handleFileChange}
                />
            </Button>

            <List sx={{ overflowY: 'scroll' }}>
                {data.map((file, index) => (
                    <ListItem key={index}>
                        <ListItemAvatar>
                            <Avatar>
                                {file.tipo.startsWith('image/') ? (
                                    <img src={file.arquivo || file.link} alt="file preview" style={{ width: '100%', height: '100%' }} />
                                ) : (
                                    <Typography variant="body1">F</Typography>
                                )}
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary={`Nome: ${file.nome}`} secondary={`Tipo: ${file.tipo}`} />
                        <IconButton onClick={() => handlePreview(file)}>
                            <PreviewIcon />
                        </IconButton>
                        <IconButton onClick={() => handleDelete(index, file)}>
                            <DeleteIcon />
                        </IconButton>
                    </ListItem>
                ))}
            </List>

            <Dialog open={preview.open} onClose={handleClosePreview} maxWidth="md" fullWidth>
                <DialogTitle>Pré-visualização do Arquivo</DialogTitle>
                <DialogContent>
                    {preview.file && renderPreviewContent(preview.file)}
                </DialogContent>
            </Dialog>

            <ModalInfo
                hideModal={() => updateModalInfo(false)}
                canDesactive={MInfo.canDesactive}
                visible={MInfo.visible}
                title={MInfo.title}
                subtitle={MInfo.subtitle}
                icon={MInfo.icon}
                funcS={MInfo.funcS}
            />
        </Box>
    );
}
