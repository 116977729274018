import React, { useState, useContext, useEffect } from 'react';
import { useTheme, Box } from '@mui/material';
import { User } from '../../../../../../contexts/user';
import { ModalInfo } from '../../../../../../components/modalInfo';
import { CMultiTextField, CTextField } from '../../../../../../components/TextField';

export function TextSimples({ saveChanges, prevData }) {
    const theme = useTheme();
    const user = useContext(User);
    //estados
    const [data, setData] = useState(dataInit)
    const [MInfo, setMInfo] = useState({
        visible: false,
        title: '',
        subtitle: '',
        canDesactive: true,
        icon: '',
        funcS: () => null
    });

    function updateModalInfo(visible, canDesactive, title, subtitle, icon, func) {
        const newMInfo = { ...MInfo };
        newMInfo.visible = visible;
        newMInfo.canDesactive = canDesactive;
        newMInfo.title = title;
        newMInfo.subtitle = subtitle;
        newMInfo.icon = icon;
        newMInfo.funcS = func;
        setMInfo(newMInfo);
    }
    function handleChangeText(value, type) {
        const newData = { ...data }
        newData[type] = value
        setData(newData)
    }
    function onBlur(type) {
        saveChanges(data[type], type)
    }

    useEffect(() => {
        if (prevData?.mensagem) {
            setData({ mensagem: prevData?.mensagem })
        }
        else {
            setData({ mensagem: '' })
        }
    }, [prevData])

    return (
        <Box sx={{
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
            gap: 3,
            height: '100%'
        }}>
            <CMultiTextField isVariable minRows={12} maxRows={12} prevData={data} label={'Mensagem'} value={data.mensagem} onChangeText={handleChangeText} type={'mensagem'} onBlur={onBlur} />
            <ModalInfo hideModal={() => updateModalInfo(false)} canDesactive={MInfo.canDesactive} visible={MInfo.visible} title={MInfo.title} subtitle={MInfo.subtitle} icon={MInfo.icon} funcS={MInfo.funcS} />
        </Box>
    );
}

const dataInit = {
    mensagem: ''
}