

import React, { useState, useContext } from 'react';
import { useTheme, Box, TableContainer, Paper, Typography } from '@mui/material';
import { ModalInfo } from '../../../../components/modalInfo';
import { User } from '../../../../contexts/user';
import { SimpleTable } from '../../../../components/tables';
import { getIcon } from '../../../../components/icons';
import MAPI from '../../../../api/mainApi';

export function ListVariavel({ variaveis, updatePrevNew, deleteVariavel, canExclude }) {
    const theme = useTheme();
    const user = useContext(User);
    //estados
    const [MInfo, setMInfo] = useState({
        visible: false,
        title: '',
        subtitle: '',
        canDesactive: true,
        icon: '',
        funcS: () => null
    });

    function updateModalInfo(visible, canDesactive, title, subtitle, icon, func) {
        const newMInfo = { ...MInfo };
        newMInfo.visible = visible;
        newMInfo.canDesactive = canDesactive;
        newMInfo.title = title;
        newMInfo.subtitle = subtitle;
        newMInfo.icon = icon;
        newMInfo.funcS = func;
        setMInfo(newMInfo);
    }

    async function Delete(row, control) {
        if (!canExclude) {
            updateModalInfo(true, true, 'Atenção', 'Você não tem permissões para excluir variáveis.', 'exclamation')
            return
        }
        if (!control) {
            updateModalInfo(true, true, 'Atenção', 'Você tem certeza que deseja excluir essa variável?', 'question', () => Delete(row, true))
            return
        }
        updateModalInfo(true, false, 'Excluindo', 'Estamos excluindo sua variável.', 'loading')
        const excluir = await MAPI(`variaveis/deleteVariavel/${row.id}`, 'DELETE', null, user?.data?.token)
        if (excluir.status !== 200) {
            const message = excluir?.apiReturn?.message ? excluir?.apiReturn?.message : 'Ocorreu um erro ao excluir a variável.'
            updateModalInfo(true, true, 'Atenção', message, 'exclamation')
            return
        }
        updateModalInfo(false)
        deleteVariavel(row)

    }

    return (
        <Box sx={{
            flex: 1,
            padding: 2,
            background: theme.palette.background.default,
            maxWidth: '100%',
        }}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 3, justifyContent: 'center' }}>
                {getIcon(30, theme.palette.secondary.main)}
                <Typography sx={{ fontWeight: 'bold', color: theme.palette.text.main, fontSize: 25, textAlign: 'center', color: theme.palette.text.primary }}>Minhas variáveis</Typography>
            </Box>
            <Box sx={{ padding: 2 }}>
                <SimpleTable excluir={(value) => Delete(value)} editar={(value) => updatePrevNew(value)} headers={['Tipo', 'Nome', 'Descricao', 'Texto', 'Editar', 'Excluir']} rows={variaveis} params={['tipo_nome', 'nome', 'descricao', 'texto']} />
            </Box>
            <ModalInfo hideModal={() => updateModalInfo(false)} canDesactive={MInfo.canDesactive} visible={MInfo.visible} title={MInfo.title} subtitle={MInfo.subtitle} icon={MInfo.icon} funcS={MInfo.funcS} />
        </Box>
    );
}