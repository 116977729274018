import { Router } from "./route/router";
import User from "./contexts/user";
import { Box, CssBaseline } from "@mui/material";
import { VariaveisC } from "./contexts/variaveis";

function App() {
  return (
    <div className="content" style={{ display: 'flex', flex: 1 }}>
      <User>
        <VariaveisC>
          <CssBaseline />
          <Router />
        </VariaveisC>
      </User>
    </div>
  );
}

export default App;
