import { useState } from 'react';
import { Box, useTheme, Typography, Button, Avatar, Divider } from '@mui/material';
import { getIcon } from '../../../../components/icons';
import { ConversasTField } from '../../../../components/TextField';

export function LeftSide({ conversas, chamados, grupos }) {
    console.log(conversas, chamados);
    const theme = useTheme();
    const [selectedNav, setSelectedNav] = useState(0);
    const whatData = selectedNav === 0 ? conversas : selectedNav === 1 ? chamados : grupos;
    const whatNav = navigation[selectedNav];

    return (
        <Box sx={{ display: 'flex', height: '100%' }}>
            <Box sx={{ display: 'flex', gap: 2, flexDirection: 'column', height: '100%', padding: 1, background: theme.palette.background.default, borderWidth: 0, borderRightWidth: 1, borderStyle: 'solid', borderColor: theme.palette.divider }}>
                {navigation.map((e, idx) => {
                    const color = selectedNav === idx ? theme.palette.tertiary.main : theme.palette.primary.main;
                    return (
                        <Button key={idx} variant='text' onClick={() => setSelectedNav(idx)}>
                            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, alignItems: 'center' }}>
                                {getIcon(e.icon, color, 30)}
                                <Typography sx={{ color: color, fontSize: 10 }}>{e.title}</Typography>
                            </Box>
                        </Button>
                    );
                })}
            </Box>
            <Box sx={{
                marginTop: 2,
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                height: '100%',
                width: '100%',
                background: theme.palette.background.default,
                gap: 3
            }}>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 3 }}>
                    {getIcon(whatNav.icon, theme.palette.tertiary.main)}
                    <Typography sx={{ fontWeight: 'bold', color: theme.palette.text.main, fontSize: 25, textAlign: 'center', color: theme.palette.text.primary }}>{whatNav.title}</Typography>
                </Box>
                <Box>
                    <ConversasTField />
                </Box>
                <Box sx={{
                    display: 'flex', height: 500, flexDirection: 'column', overflow: 'hidden', overflowY: 'scroll', width: '100%', '&::-webkit-scrollbar': {
                        background: theme.palette.background.default,
                        width: 5
                    }, '&::-webkit-scrollbar-thumb': {
                        background: theme.palette.background.paper,
                    }
                }}>
                    {conversas && whatData.map((e) => {
                        return (
                            <Button variant='text' onClick={() => null} sx={{ textTransform: 'none', textAlign: 'left', color: theme.palette.text.primary, borderWidth: 0, borderBottomWidth: 1, borderStyle: 'solid', borderColor: theme.palette.divider, paddingTop: 2, paddingBottom: 2 }}>
                                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, width: '95%', }} key={e.id}>
                                    <Box sx={{ display: 'flex', justifyContent: 'left', gap: 2, alignItems: 'center' }}>
                                        <Avatar src={e.avatar} sx={{ width: 50, height: 50 }} />
                                        <Box sx={{ width: '100%' }}>
                                            <Typography sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', width: 250 }}>
                                                {e.nome}
                                            </Typography>
                                            <Typography sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', width: 250 }}>
                                                {e.message || "No message available"}
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Box>
                            </Button>
                        );
                    })}
                </Box>
            </Box>
        </Box>
    );
}

const navigation = [
    {
        title: 'Conversas',
        subtitle: 'Minhas conversas',
        icon: 79,
    },
    {
        title: 'Chamados',
        subtitle: 'Meus Chamados',
        icon: 77,
    },
    {
        title: 'Grupos',
        subtitle: 'Meus Grupos',
        icon: 77,
    },
];
