import React, { useState, useContext } from 'react';
import { useTheme, Box, Typography, Modal } from '@mui/material';
import { ModalInfo } from '../../../components/modalInfo';
import { User } from '../../../contexts/user';
import { CTextField } from '../../../components/TextField';
import { onlyInteger } from '../../../components/masks';
import { ButtonSubmit } from '../../../components/buttons';
import { TestaEMAIL } from '../../../components/validaCampo';
import MAPI from '../../../api/mainApi';

export function ConfigEmail({ visible, dismiss, con }) {
    const theme = useTheme();
    const user = useContext(User);
    //estados
    const [data, setData] = useState(dataInit)
    const [MInfo, setMInfo] = useState({
        visible: false,
        title: '',
        subtitle: '',
        canDesactive: true,
        icon: '',
        funcS: () => null
    });

    function updateModalInfo(visible, canDesactive, title, subtitle, icon, func) {
        const newMInfo = { ...MInfo };
        newMInfo.visible = visible;
        newMInfo.canDesactive = canDesactive;
        newMInfo.title = title;
        newMInfo.subtitle = subtitle;
        newMInfo.icon = icon;
        newMInfo.funcS = func;
        setMInfo(newMInfo);
    }

    function changeData(value, type) {
        const newD = { ...data }
        newD[type] = type === 'porta' ? onlyInteger(value) : value
        setData(newD)
    }
    function hideModal() {
        dismiss()
    }
    function handleSubmit(type) {
        if (type === 'Sair') {
            hideModal()
            return
        }
        save()
    }
    async function save() {
        if (!data.porta || !data.senha || !data.servidor || !data.usuario) {
            updateModalInfo(true, true, 'Atenção', 'Preencha todas as informações para prosseguir.', 'exclamation')
            return
        }
        if (!TestaEMAIL(data.usuario)) {
            updateModalInfo(true, true, 'Atenção', 'E-mail preenchido é inválido.', 'exclamation')
            return
        }
        updateModalInfo(true, false, 'Salvando', 'Estamos salvando suas configurações e validando as informações.', 'loading')
        const body = {
            con: con,
            porta: data.porta,
            senha: data.senha,
            servidor: data.servidor,
            usuario: data.usuario
        }
        const save = await MAPI('conexoes/saveEmailConfig', 'POST', body, user?.data?.token)
        if (save?.status !== 200) {
            const message = save?.apiReturn?.message ? save?.apiReturn?.message : 'Ocorreu um erro ao salvar as configurações.'
            updateModalInfo(true, true, 'Atenção', message, 'exclamation')
            return
        }
        updateModalInfo(true, true, 'Sucesso', 'Seu e-mail foi configurado com sucesso.', 'sucess')
    }

    return (
        <Modal open={visible} onClose={() => hideModal ? hideModal() : null} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} slotProps={{
            backdrop: {
                sx: {
                    backgroundColor: theme.palette.mode === 'dark' ? 'rgba(250,255,255, 0.2)' : 'rgba(0,0,0, 0.4)'
                }
            }
        }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', height: 650, width: 600, background: theme.palette.background.default, outline: 'none' }}>
                <Box sx={{ background: theme.palette.background.paper, padding: 5 }}>
                    <Typography sx={{ fontWeight: 'bold', color: theme.palette.text.primary, fontSize: 30 }}>Configurar e-mail</Typography>
                    <Typography sx={{ color: theme.palette.text.primary, fontSize: 18 }}>Sua conexão não está configurada ou com erros de configuração. Ajuste os campos abaixo e salve.</Typography>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 5, padding: 3 }}>
                    <CTextField prevData={data} value={data.servidor} label={'Servidor'} onChangeText={changeData} type={'servidor'} />
                    <CTextField prevData={data} value={data.porta} label={'Porta'} onChangeText={changeData} type={'porta'} />
                    <CTextField prevData={data} value={data.usuario} label={'E-mail'} onChangeText={changeData} type={'usuario'} />
                    <CTextField prevData={data} value={data.senha} label={'Senha'} security={true} onChangeText={changeData} type={'senha'} />
                </Box>
                <Box sx={{ display: 'flex', padding: 3, gap: 2 }}>
                    {['Salvar', 'Sair'].map((e, idx) => {
                        return (
                            <ButtonSubmit key={e} text={e} background={idx === 1 ? theme.palette.background.paper : theme.palette.primary.main} color={idx === 1 ? theme.palette.text.primary : theme.palette.text.onPrimary} func={handleSubmit} funcParameters={e} />
                        )
                    })}
                </Box>
                <ModalInfo hideModal={() => updateModalInfo(false)} canDesactive={MInfo.canDesactive} visible={MInfo.visible} title={MInfo.title} subtitle={MInfo.subtitle} icon={MInfo.icon} funcS={MInfo.funcS} />
            </Box>
        </Modal >
    );
}

const dataInit = {
    servidor: '',
    porta: '',
    usuario: '',
    senha: ''
}