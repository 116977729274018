import { Drawer, useTheme, Box, Divider, Typography, ListItemButton, Collapse, Tooltip } from "@mui/material";
import Logo from '../inc/images/logo_wb.png'
import LogoW from '../inc/images/logo_w.png'
import { useContext, useState } from "react";
import { User } from "../contexts/user";
import { getIcon } from "./icons";
import { Link } from "react-router-dom";
import { DrawerFull } from "./drawer/darawerFull";
import { DrawerOff } from "./drawer/darawerOff";


const drawerWidth = 260;
export function Navegacao({ drawerOpen }) {
    return (
        <Box>
            {drawerOpen ? <DrawerFull /> : <DrawerOff />}
        </Box>
    )
}