import Logo_w from '../../inc/images/logo_w.png'
import Logo_d from '../../inc/images/logo_wb.png'
import MinLogo from '../../inc/images/flaticon.png'

export const OceanSerenityTheme = (mode, changeMode) => ({
    palette: {
        logo: mode === 'light' ? Logo_w : Logo_d,
        minLogo: MinLogo,
        mode: mode,
        changeMode: changeMode,
        ...(mode === 'light' ? {
            primary: {
                main: '#1e3a5f',
                light: '#2e4d7a',
                dark: '#162d4c',
            },
            secondary: {
                main: '#00bcd4',
                light: '#33cde6',
                dark: '#0099b3',
            },
            tertiary: {
                main: '#d3d3d3',
                light: '#e6e6e6',
                dark: '#a6a6a6',
            },
            background: {
                default: '#f0f8ff',
                paper: '#e6f2ff',
                inverse: '#141414',
            },
            text: {
                main: '#1e3a5f',
                primary: '#1e3a5f',
                secondary: '#4d4d4d',
                disabled: '#c0bcbb',
                inverse: '#ffffff',
                onPrimary: '#ffffff',
                onDrawer: '#B4B4B4'
            },
        } : {
            primary: {
                main: '#10273f',
                light: '#1a4d63',
                dark: '#0c1a2c',
            },
            secondary: {
                main: '#008ba3',
                light: '#00bcd4',
                dark: '#006f7f',
            },
            tertiary: {
                main: '#7a7a7a',
                light: '#9c9c9c',
                dark: '#5e5e5e',
            },
            background: {
                default: '#0f1c2a',
                paper: '#1a2b3c',
                inverse: '#f0f8ff',
            },
            text: {
                main: '#ffffff',
                primary: '#ffffff',
                secondary: '#cccccc',
                disabled: '#a6a6a6',
                inverse: '#0f1c2a',
                onPrimary: '#fff',
                onDrawer: '#B4B4B4'
            },
        })
    },
});
