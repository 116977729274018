import { Modal, Box, useTheme, Typography, TextField, IconButton, Button } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { ModalIcones } from "../../../components/modalIcones";
import { getIcon } from "../../../components/icons";
import { ButtonSubmit } from "../../../components/buttons";
import { CTextField } from "../../../components/TextField";
import MAPI from "../../../api/mainApi";
import { ModalInfo } from "../../../components/modalInfo";
import { User } from "../../../contexts/user";
import { FullMenu } from "../../../components/menu";


export function NovaConexao({ visible, hideModal, pushData, conTipos, updatePrevModalInfo }) {
    const theme = useTheme()
    const user = useContext(User)
    const [data, setData] = useState(initData)
    const [iconVisible, setIconVisible] = useState(false)
    const [tipos, setTipos] = useState([])

    const [modalInfo, setModalInfo] = useState(modalInfoInit)
    function updateData(value, type) {
        const newData = { ...data }
        if (type === 'icon') {
            newData.Ícone = value
            setIconVisible(false)
        }
        else {
            newData[type] = type === 'Descricao' && value.length > 40 ? newData[type] : value
        }
        console.log(newData)
        setData(newData)
    }
    function updateModalInfo(visible, canDesactive, title, subtitle, icon) {
        const newModalInfo = { ...modalInfo }
        newModalInfo.visible = visible
        newModalInfo.canDesactive = canDesactive
        newModalInfo.title = title
        newModalInfo.subtitle = subtitle
        newModalInfo.icon = icon
        setModalInfo(newModalInfo)
    }
    async function handleSubmit(parameters) {
        if (parameters === 'Sair') {
            hideModal()
            return
        }
        if ([data.Descricao, data.Nome, data.Ícone].includes('')) {
            updateModalInfo(true, true, 'Atenção', 'Todos os campos devem ser preenchidos', 'exclamation')
            return
        }
        if (!data?.tipo?.id) {
            updateModalInfo(true, true, 'Atenção', 'Tipo deve ser selecionado.', 'exclamation')
            return
        }
        const body = {
            nome: data.Nome,
            descricao: data.Descricao,
            icon: data.Ícone,
            tipo: data?.tipo?.id
        }
        updateModalInfo(true, false, 'Carregando', 'Tentando criar nova conexão.', 'loading')
        const TRYInsert = await MAPI('conexoes/new', 'POST', body, user?.data?.token)
        if (TRYInsert.status !== 200) {
            const message = TRYInsert?.apiReturn?.message ? TRYInsert?.apiReturn?.message : 'Ocorreu um erro ao criar a conexão. Tente novamente mais tarde.'
            updateModalInfo(true, true, 'Atenção', message, 'exclamation')
            return
        }
        body.id = TRYInsert?.apiReturn?.apiReturn
        const dataReturn = {
            id: body.id,
            nome: body.nome,
            descricao: body.descricao,
            icon: body.icon,
            tipo_id: body.tipo,
            tipo_nome: data?.tipo?.nome
        }
        pushData(dataReturn)
        updateModalInfo(true, true, 'Sucesso', 'Conexão criada com sucesso.', 'sucess')
    }

    async function getTipos() {
        if (conTipos && conTipos?.length > 0) {
            setTipos(conTipos)
            return
        }
        updateModalInfo(true, false, 'Aguarde', 'Estamos buscando os tipos de conexão.', 'loading')
        const get = await MAPI('conexoes/getTipos', 'GET', null, user?.data?.token)
        if (get?.status !== 200) {
            const message = get?.apiReturn?.message ? get?.apiReturn?.message : 'Ocorreu um erro ao buscar os tipos de conexão.'
            updatePrevModalInfo(true, true, 'Atenção', message, 'exclamation')
            hideModal()
            return
        }
        const apiReturn = get?.apiReturn?.apiReturn
        if (!apiReturn || !Array.isArray(apiReturn) || apiReturn.length === 0) {
            updatePrevModalInfo(true, true, 'Atenção', 'Nenhum tipo encontrado', 'exclamation')
            hideModal()
            return
        }
    }
    function handleMenu(value, title) {
        const whatTipo = tipos.filter((e) => e.id === value.props.value)
        updateData(whatTipo[0], 'tipo')
    }

    useEffect(() => {
        if (visible) {
            getTipos()
        }
    }, [visible])

    return (
        <Modal open={visible} onClose={() => hideModal ? hideModal() : null} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} slotProps={{
            backdrop: {
                sx: {
                    backgroundColor: theme.palette.mode === 'dark' ? 'rgba(250,255,255, 0.2)' : 'rgba(0,0,0, 0.4)'
                }
            }
        }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', height: 730, width: 600, background: theme.palette.background.default, outline: 'none' }}>
                <Box sx={{ background: theme.palette.background.paper, padding: 5 }}>
                    <Typography sx={{ fontWeight: 'bold', color: theme.palette.text.primary, fontSize: 30 }}>Nova Conexão</Typography>
                    <Typography sx={{ color: theme.palette.text.primary, fontSize: 18 }}>Preencha os campos a baixo para personalizar uma nova conexão.</Typography>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 5, padding: 3 }}>
                    {Object.keys(data).map((e) => {
                        if (e === 'tipo') return
                        if (e === 'Ícone') {
                            return (
                                <Button key={e} sx={{ width: '30%', display: 'flex', alignSelf: 'center' }} onClick={() => setIconVisible(true)}>
                                    {
                                        getIcon(data[e], theme.palette.mode === 'dark' ? theme.palette.text.primary : theme.palette.tertiary, 90)
                                    }
                                </Button>
                            )
                        }
                        return (
                            <CTextField key={e} prevData={data} label={e} value={data[e]} onChangeText={updateData} type={e} />
                        )
                    })}
                    <Box>
                        <Typography sx={{ color: theme.palette.text.primary }}>Tipo da conexão</Typography>
                        <FullMenu options={tipos} param="nome" title="Tipos" selected={data.tipo} handleChange={handleMenu} />
                    </Box>
                    {/* options = [], param = '', title = '', disabled = false, width = '100%', icon = null, selected = null, handleChange  */}
                </Box>
                <Box sx={{ display: 'flex', padding: 3, gap: 2 }}>
                    {['Salvar', 'Sair'].map((e, idx) => {
                        return (
                            <ButtonSubmit key={e} text={e} background={idx === 1 ? theme.palette.background.paper : theme.palette.primary.main} color={idx === 1 ? theme.palette.text.primary : theme.palette.text.onPrimary} func={handleSubmit} funcParameters={e} />
                        )
                    })}
                </Box>
                <ModalIcones visible={iconVisible} onClose={() => setIconVisible(false)} updateIcon={updateData} />
                <ModalInfo hideModal={() => updateModalInfo(false)} title={modalInfo.title} subtitle={modalInfo.subtitle} icon={modalInfo.icon} visible={modalInfo.visible} canDesactive={modalInfo.canDesactive} />
            </Box>
        </Modal>
    )
}

const initData = {
    Ícone: 1,
    Nome: '',
    Descricao: '',
    tipo: null
}

const modalInfoInit = {
    title: '',
    subtitle: '',
    icon: '',
    visible: false,
    canDesactive: false,
}