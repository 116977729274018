import { useEffect, useContext, useState } from 'react'
import { Box, useTheme, Divider } from '@mui/material'
import { ModuloTitle } from '../../../components/moduloTitle'
import MAPI from '../../../api/mainApi'
import { User } from '../../../contexts/user'
import { ModalInfo } from '../../../components/modalInfo'
import { LeftSide } from './components/leftSide'


export function Conversas({ modulo }) {
    document.title = `Conversas - It's Chat`
    const user = useContext(User)
    const theme = useTheme()
    const [conversas, setConversas] = useState(null)
    const [chamados, setChamados] = useState(null)
    const [grupos, setGrupos] = useState(null)
    const [MInfo, setMInfo] = useState({
        visible: false,
        title: '',
        subtitle: '',
        canDesactive: true,
        icon: '',
        funcS: () => null
    });
    function updateModalInfo(visible, canDesactive, title, subtitle, icon, func) {
        const newMInfo = { ...MInfo };
        newMInfo.visible = visible;
        newMInfo.canDesactive = canDesactive;
        newMInfo.title = title;
        newMInfo.subtitle = subtitle;
        newMInfo.icon = icon;
        newMInfo.funcS = func;
        setMInfo(newMInfo);
    }
    async function getConversas() {
        updateModalInfo(true, false, 'Carregando', 'Buscando suas conversas', 'loading')
        const get = await MAPI('conversas/getChats', 'GET', null, user?.data?.token)
        const apiData = get?.apiReturn?.apiReturn
        console.log(apiData)
        if (!apiData) {
            const message = get?.apiReturn?.message ? get?.apiReturn?.message : 'Ocorreu um erro ao buscar suas conversas.'
            updateModalInfo(true, true, 'Atenção', message, 'exclamation')
            setChamados(false)
            setConversas(false)
            return
        }
        setChamados(apiData.getChamados)
        setConversas(apiData.getConversas)
        setGrupos(apiData.getGrupos)
        updateModalInfo()
    }

    useEffect(() => {
        getConversas()
    }, [])
    return (
        <Box sx={{
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            height: '100%'
        }}>
            <ModuloTitle icon={modulo.moduloIcon} title={modulo.moduloNome} desc={modulo.moduloDesc} />
            <Box sx={{ display: 'flex', flexDirection: 'row', flexGrow: 1, background: theme.palette.background.default, width: '100%', height: '100%' }}>
                <Box sx={{ width: 550 }}>
                    <LeftSide conversas={conversas} chamados={chamados} grupos={grupos} />
                </Box>
                <Divider orientation={'vertical'} flexItem />
                <Box />
                <ModalInfo hideModal={() => updateModalInfo(false)} canDesactive={MInfo.canDesactive} visible={MInfo.visible} title={MInfo.title} subtitle={MInfo.subtitle} icon={MInfo.icon} funcS={MInfo.funcS} />
            </Box>
        </Box>
    )
}