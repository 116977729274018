import Logo_w from '../../inc/images/logo_w.png'
import Logo_d from '../../inc/images/logo_wb.png'
import MinLogo from '../../inc/images/flaticon.png'

export const DefaultTheme = (mode, changeMode) => {
    return {
        palette: {
            logo: mode === 'light' ? Logo_w : Logo_d,
            minLogo: MinLogo,
            mode: mode,
            changeMode: changeMode,
            ...(mode === 'light'
                ? {
                    primary: {
                        main: '#2A2F42',
                        light: '#2A2F42',
                        dark: '#2A2F42'
                    },
                    secondary: {
                        main: '#f4a261',
                        light: '#f4a261',
                        dark: '#f4a261'
                    },
                    tertiary: {
                        main: '#E9C46A',
                        light: '#E9C46A',
                        dark: '#E9C46A'
                    },
                    background: {
                        default: '#FFFFFF',
                        paper: '#EEEEEE',
                        inverse: '#141414'
                    },
                    inverseBackground: {
                        default: '#232627',
                        paper: '#3F4342'
                    },
                    warning: { main: '#E76F51' },
                    success: { main: '#8ab17d' },
                    error: { main: '#E36040' },
                    text: {
                        main: '#2A2F42',
                        primary: '#2A2F42',
                        disabled: '#C0BCBC',
                        inverse: '#E7E7E7',
                        onPrimary: '#fff',
                        dark: '#2A2F42',
                        light: '#eee',
                        onDrawer: '#B4B4B4'
                    }
                } : {
                    primary: {
                        main: '#E9C46A',
                        light: '#E9C46A',
                        dark: '#E9C46A'
                    },
                    secondary: {
                        main: '#f4a261',
                        light: '#f4a261',
                        dark: '#f4a261'
                    },
                    tertiary: {
                        main: '#2A2F42',
                        light: '#2A2F42',
                        dark: '#2A2F42'
                    },
                    background: {
                        default: '#141414',
                        paper: '#292929',
                        inverse: '#EEEEEE'
                    },
                    inverseBackground: {
                        default: '#E7EBEC',
                        paper: '#3F4342'
                    },
                    warning: { main: '#E76F51' },
                    success: { main: '#8ab17d' },
                    error: { main: '#E36040' },
                    text: {
                        main: '#E7E7E7',
                        primary: '#E7E7E7',
                        disabled: '#746D6D',
                        inverse: '#656464',
                        onPrimary: '#fff',
                        dark: '#2A2F42',
                        onDrawer: '#B4B4B4',
                        light: '#eee'
                    }
                }
            )
        }
    }
}