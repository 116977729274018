import {
    Typography,
    useTheme,
    Button,
    Avatar,
    Menu,
    MenuItem,
    Switch,
    Box,
    ListItemIcon,
    ListItemText,
    createTheme,
    ThemeProvider
} from "@mui/material";
import { useContext, useState } from "react";
import { User } from "../../contexts/user";
import { ColorModeContext } from "../../theme/theme";
import { Link } from "react-router-dom";
import { common, grey } from "@mui/material/colors";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import LogoutIcon from '@mui/icons-material/Logout';
import PaletteIcon from '@mui/icons-material/Palette';
import { getIcon } from "../icons";
import { ModalInfo } from "../modalInfo";
import { ListThemes } from "../listThemes";

export const UserProfile = () => {
    const theme = useTheme();
    const user = useContext(User);
    const changeTheme = useContext(ColorModeContext);
    const [anchorEl, setAnchorEl] = useState(null);
    const [temaVisible, setTemaVisible] = useState(false)

    const haveAvatar = user?.data?.avatar ? true : false;
    const avatar = haveAvatar ? user?.data?.avatar : user?.data?.nome.slice(0, 1);

    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleLogout = (control) => {
        if (control !== true) {
            updateModalInfo(true, true, 'Atenção', 'Você tem certeza que desaja sair?', 'question', () => handleLogout(true))
            return
        }
        user.logOff()
        handleMenuClose();
    };

    const handleToggleTheme = () => {
        changeTheme.toggleColorMode();
        handleMenuClose();
    };

    const [MInfo, setMInfo] = useState({
        visible: false,
        title: '',
        subtitle: '',
        canDesactive: true,
        icon: '',
        funcS: () => null
    })
    function updateModalInfo(visible, canDesactive, title, subtitle, icon, func) {
        const newMInfo = { ...MInfo }
        newMInfo.visible = visible
        newMInfo.canDesactive = canDesactive
        newMInfo.title = title
        newMInfo.subtitle = subtitle
        newMInfo.icon = icon
        newMInfo.funcS = func
        setMInfo(newMInfo)
    }

    const customTheme = createTheme({
        palette: {
            primary: { main: theme.palette.primary.main },
            action: {
                active: theme.palette.primary.main,
            },
            grey: grey,
            common: common
        },
    });

    return (
        <>
            <Button
                sx={{ textTransform: 'none', gap: 1 }}
                onClick={handleMenuOpen}
            >
                <Typography sx={{ color: theme.palette.text.primary, fontSize: 12 }}>
                    {'Olá, ' + user?.data?.nome.split(' ')[0]}
                </Typography>
                {haveAvatar ? (
                    <Avatar src={avatar} sx={{ width: 30, height: 30 }} />
                ) : (
                    <Avatar sx={{ width: 30, height: 30 }}>{avatar}</Avatar>
                )}
            </Button>
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
                onMouseLeave={handleMenuClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <MenuItem component={Link} to="/perfil">
                    <ListItemIcon>
                        <AccountCircleIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText
                        primary={
                            <Typography variant="body2" sx={{ fontSize: 15 }}>
                                Perfil
                            </Typography>
                        }
                    />
                </MenuItem>
                {/* <MenuItem onClick={() => setTemaVisible(true)}>
                    <ListItemIcon>
                        <PaletteIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText
                        primary={
                            <Typography variant="body2" sx={{ fontSize: 15 }}>
                                Tema
                            </Typography>
                        }
                    />
                </MenuItem>
                <MenuItem>
                    <ListItemIcon>
                        {theme.palette.mode === 'dark' ? <Brightness7Icon fontSize="small" /> : <Brightness4Icon fontSize="small" />}
                    </ListItemIcon>
                    <ListItemText
                        primary={
                            <Typography variant="body2" sx={{ fontSize: 15 }}>
                                Modo {theme.palette.mode === 'dark' ? 'Escuro' : 'Claro'}
                            </Typography>
                        }
                    />
                    <ThemeProvider theme={customTheme}>
                        <Switch
                            checked={theme.palette.mode === 'dark'}
                            onChange={handleToggleTheme}
                            size="small"
                        />
                    </ThemeProvider>
                </MenuItem> */}
                <MenuItem onClick={handleLogout}>
                    <ListItemIcon>
                        <LogoutIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText
                        primary={
                            <Typography variant="body2" sx={{ fontSize: 15 }}>
                                Sair
                            </Typography>
                        }
                    />
                </MenuItem>
            </Menu>
            <ListThemes visible={temaVisible} dismiss={() => setTemaVisible(false)} />
            <ModalInfo hideModal={() => updateModalInfo(false)} canDesactive={MInfo.canDesactive} visible={MInfo.visible} title={MInfo.title} subtitle={MInfo.subtitle} icon={MInfo.icon} funcS={MInfo.funcS} />
        </>
    );
};
