import React, { useState, useContext, useEffect } from 'react';
import { useTheme, Box, Modal, Typography, IconButton } from '@mui/material';
import { ModalInfo } from '../../../../components/modalInfo';
import { User } from '../../../../contexts/user';
import { CTextField } from '../../../../components/TextField';
import { UserAvatar } from '../../../conexoes/components/avatar';
import { UpComp } from '../../../../components/upComp';
import { getIcon } from '../../../../components/icons';
import { MenuList } from '../../../../components/menu';
import MAPI from '../../../../api/mainApi';
import { celularMask, formatPhoneNumber } from '../../../../components/masks';
import { ButtonSubmit } from '../../../../components/buttons';

export function EditContato({ visible, prevContato, pushContato, dismiss, updateContato, canEdit, getCamposContato, contatoCampos }) {
    const theme = useTheme();
    const user = useContext(User);
    //estados
    const [MInfo, setMInfo] = useState({
        visible: false,
        title: '',
        subtitle: '',
        canDesactive: true,
        icon: '',
        funcS: () => null
    });
    const [data, setData] = useState(dataInit)
    function updateModalInfo(visible, canDesactive, title, subtitle, icon, func) {
        const newMInfo = { ...MInfo };
        newMInfo.visible = visible;
        newMInfo.canDesactive = canDesactive;
        newMInfo.title = title;
        newMInfo.subtitle = subtitle;
        newMInfo.icon = icon;
        newMInfo.funcS = func;
        setMInfo(newMInfo);
    }
    function handleImage(base64) {
        const newData = { ...data }
        newData.picture = base64
        setData(newData)
    }
    function handleDismiss() {
        if (dismiss) {
            dismiss()
            setData(dataInit)
        }
    }
    function handleSubmit(type) {
        if (type === 'Sair') {
            handleDismiss()
            return
        }
        save()
        return
    }
    async function save() {
        if (!canEdit && data.id) {
            updateModalInfo(true, true, 'Atenção', 'Você não tem permissões para editar um contato.', 'exclamation')
            return
        }
        updateModalInfo(true, false, 'Atenção', 'Tentando salvar contato.', 'loading')
        const endPoint = !data.id ? 'saveContato' : 'editContato'
        const method = !data.id ? 'POST' : 'PUT'
        const TRYSave = await MAPI(`meusContatos/${endPoint}`, method, data, user?.data?.token)
        if (TRYSave?.status !== 200) {
            const message = TRYSave?.apiReturn?.message ? TRYSave?.apiReturn?.message : 'Não foi possível salvar o contato.'
            updateModalInfo(true, true, 'Atenção', message, 'exclamation')
            return
        }
        updateModalInfo(false)
        const newData = { ...data }
        newData.id = TRYSave?.apiReturn?.apiReturn?.id
        newData.profilePicture = TRYSave?.apiReturn?.apiReturn?.picture ? TRYSave?.apiReturn?.apiReturn?.picture : null
        newData.status = 1
        const whatFunc = !data.id ? pushContato : updateContato
        whatFunc(newData)
    }
    function handleChangeOutros(value, type) {
        const newData = { ...data }
        newData.data[type] = value
        setData(newData)
    }
    function handleChangeText(value, type) {
        const newData = { ...data }
        newData[type] = type === 'numero' ? celularMask(value) : value
        setData(newData)
    }
    useEffect(() => {
        if (contatoCampos === null) {
            getCamposContato()
        }
        if (prevContato?.id) {
            const newData = { ...data }
            newData.id = prevContato?.id
            newData.nome = prevContato?.nome
            newData.numero = formatPhoneNumber(prevContato?.numero)
            newData.picture = prevContato?.profilePicture
            newData.email = prevContato?.email
            newData.bRegistro = prevContato?.bRegistro
            newData.data = prevContato?.data ? prevContato?.data : {}
            console.log(contatoCampos, newData)
            setData(newData)
        } else {
            setData(dataInit)
        }
    }, [visible])
    return (
        <Modal open={visible} onClose={handleDismiss} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 2 }} slotProps={{
            backdrop: {
                sx: {
                    backgroundColor: theme.palette.mode === 'dark' ? 'rgba(250,255,255, 0.2)' : 'rgba(0,0,0, 0.4)'
                }
            }
        }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', height: 750, width: 650, background: theme.palette.background.default, outline: 'none' }}>
                <Box sx={{ background: theme.palette.background.paper, padding: 5 }}>
                    <Typography sx={{ fontWeight: 'bold', color: theme.palette.text.primary, fontSize: 30 }}>{'Novo Contato'}</Typography>
                    <Typography sx={{ color: theme.palette.text.primary, fontSize: 18 }}>Preencha os dados a baixo para criar um novo contato.</Typography>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column', padding: 5, paddingBottom: 2, gap: 2, height: '100%' }}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3, height: 460, overflowY: 'scroll' }}>
                        {Object.keys(data).map((e) => {
                            if (e === 'id') return
                            if (e === 'data') return
                            if (e === 'picture') {
                                return (
                                    <Box key={e} sx={{ display: 'flex', gap: 2, alignItems: 'center', alignSelf: 'center' }}>
                                        <UserAvatar avatarBase64={data[e]} editable={true} funcReturn={handleImage} />
                                    </Box>
                                )
                            }
                            return (
                                <CTextField key={e} prevData={data} label={formatCampoData[e]} value={data[e]} onChangeText={handleChangeText} type={e} />
                            )
                        })}
                        <Box>
                            <Typography sx={{ color: theme.palette.text.primary, fontWeight: 'bold' }}>Outros Dados</Typography>
                            <Box sx={{ display: 'flex', gap: 2, flexDirection: 'column' }}>
                                {Object.keys(data.data).map((e) => {
                                    const findExistCampo = Array.isArray(contatoCampos) ? contatoCampos.findIndex((b) => b.campo === e) : -1
                                    if (findExistCampo !== -1) return
                                    return (
                                        <CTextField key={e} prevData={data} label={e} value={data.data[e]} onChangeText={handleChangeOutros} type={e} />
                                    )
                                })}
                                {contatoCampos && Array.isArray(contatoCampos) && contatoCampos.map((e) => {
                                    console.log(e)
                                    return (
                                        <CTextField key={e.campo} prevData={data} label={e.campo} value={data.data[e.campo]} onChangeText={handleChangeOutros} type={e.campo} />
                                    )
                                })}
                            </Box>
                        </Box>
                    </Box>
                    <Box sx={{ display: 'flex', gap: 2 }}>
                        {['Salvar', 'Sair'].map((e) => {
                            const background = e === 'Salvar' ? theme.palette.primary.main : theme.palette.background.paper
                            const color = e === 'Sair' ? theme.palette.text.primary : theme.palette.text.onPrimary
                            return (
                                <ButtonSubmit key={e} func={handleSubmit} funcParameters={e} text={e} background={background} color={color} />
                            )
                        })}
                    </Box>
                </Box>
                <ModalInfo hideModal={() => updateModalInfo(false)} canDesactive={MInfo.canDesactive} visible={MInfo.visible} title={MInfo.title} subtitle={MInfo.subtitle} icon={MInfo.icon} funcS={MInfo.funcS} />
            </Box>
        </Modal >
    );
}

const dataInit = {
    id: '',
    picture: '',
    nome: '',
    numero: '',
    email: '',
    bRegistro: '',
    data: {}
}

const formatCampoData = {
    nome: 'Nome',
    numero: 'Número',
    email: 'E-mail',
    bRegistro: 'CPF/CNPJ'
}