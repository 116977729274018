import React, { useState, useContext, useEffect } from 'react';
import { useTheme, Box, Typography, Button, Tooltip, IconButton, Pagination } from '@mui/material';
import { ModalInfo } from '../../../../components/modalInfo';
import { User } from '../../../../contexts/user';
import { BoxHover } from '../../../../components/boxHover';
import { getIcon } from '../../../../components/icons';
import { UpComp } from '../../../../components/upComp';
import { formatPhoneNumber, removerCaracteresEspeciais } from '../../../../components/masks';
import MAPI from '../../../../api/mainApi';

export function ListContatos({ contatos, openEdit, updateContato, delContato, updateContTags, canExclude, search }) {
    const theme = useTheme();
    const user = useContext(User);
    const itemsPerPage = 10;
    //estados
    const [MInfo, setMInfo] = useState({
        visible: false,
        title: '',
        subtitle: '',
        canDesactive: true,
        icon: '',
        funcS: () => null
    });
    const [page, setPage] = useState(1);
    const newContatos = contatos.filter((e) => {
        const newSearch = removerCaracteresEspeciais(search).toLowerCase();
        const newNome = removerCaracteresEspeciais(e?.nome).toLowerCase();
        return search ? (newNome.includes(newSearch) || e?.numero?.includes(search)) : true;
    });

    const paginatedContatos = newContatos.slice((page - 1) * itemsPerPage, page * itemsPerPage);
    const handleChangePage = (event, value) => {
        setPage(value);
    };

    function updateModalInfo(visible, canDesactive, title, subtitle, icon, func) {
        const newMInfo = { ...MInfo };
        newMInfo.visible = visible;
        newMInfo.canDesactive = canDesactive;
        newMInfo.title = title;
        newMInfo.subtitle = subtitle;
        newMInfo.icon = icon;
        newMInfo.funcS = func;
        setMInfo(newMInfo);
    }
    async function changeStatus(contato) {
        updateModalInfo(true, false, 'Atenção', 'Tentando alterar o status do contato', 'loading');
        const status = contato?.status === 1 ? 0 : 1;
        const TRYChange = await MAPI('meusContatos/status', 'PUT', { id: contato.id, status: status }, user?.data?.token);
        if (TRYChange?.status !== 200) {
            const message = TRYChange?.apiReturn?.message ? TRYChange?.apiReturn?.message : 'Não foi possível alterar o status.';
            updateModalInfo(true, true, 'Atenção', message, 'exclamation');
            return;
        }
        updateModalInfo(false);
        contato.status = status;
        updateContato(contato);
    }
    async function deleteContato(contato, control) {
        if (!canExclude) {
            updateModalInfo(true, true, 'Atenção', 'Você não tem permissões para excluir um contato.', 'exclamation')
            return
        }
        if (!control) {
            updateModalInfo(true, true, 'Atenção', 'Você tem certeza que deseja excluir esse contato?', 'question', () => deleteContato(contato, true))
            return
        }
        updateModalInfo(true, false, 'Atenção', 'Excluindo contato', 'loading')
        const trys = await MAPI(`meusContatos/deleteContato/${contato.id}`, 'DELETE', null, user?.data?.token)
        if (trys?.status !== 200) {
            const message = trys?.apiReturn?.message ? trys?.apiReturn?.message : 'Ocorreu um erro ao excluir o contato.'
            updateModalInfo(true, true, 'Atenção', message, 'exclamation')
            return
        }
        delContato(contato)
        updateModalInfo(false)
    }

    function handleButton(type, contato) {
        if (type === 'Status') {
            changeStatus(contato)
        } if (type === 'Excluir') {
            deleteContato(contato)
        } if (type === 'Tags') {
            updateContTags(true, contato)
        }
    }

    useEffect(() => {
        if (page !== 1) {
            setPage(1)
        }
    }, [search])

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Box sx={{
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: 'center'
            }}>
                {paginatedContatos.map((e, idx) => {
                    return (
                        <BoxHover key={idx}>
                            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-between', height: 200, width: '70%' }}>
                                <Button onClick={() => openEdit(true, e)} sx={{ textTransform: 'none' }}>
                                    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: 1 }}>
                                        {e?.profilePicture ?
                                            <Box sx={{ borderRadius: 25, boxShadow: theme.palette.mode === 'dark' ? '0px 4px 10px rgba(0, 0, 0, 0.3)' : '0px 4px 10px rgba(0, 0, 0, 0.1)', overflow: 'hidden', height: 70, width: 70 }}>
                                                <img src={e?.profilePicture} style={{ objectFit: 'contain', width: '100%', borderRadius: 90 }} />
                                            </Box>
                                            :
                                            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 75, width: 75, borderRadius: 25, boxShadow: theme.palette.mode === 'dark' ? '0px 4px 10px rgba(0, 0, 0, 0.3)' : '0px 4px 10px rgba(0, 0, 0, 0.1)' }}>
                                                {getIcon(33, theme.palette.text.primary, 50)}
                                            </Box>
                                        }
                                        <Box>
                                            <Typography sx={{ color: theme.palette.text.primary, fontWeight: 'bold', display: '-webkit-box', WebkitLineClamp: 2, WebkitBoxOrient: 'vertical', overflow: 'hidden', textOverflow: 'ellipsis' }}>{e?.nome}</Typography>
                                            <Typography sx={{ color: theme.palette.text.primary }}>{formatPhoneNumber(e?.numero)}</Typography>
                                        </Box>
                                    </Box>
                                </Button>
                                <Box sx={{ display: 'flex', gap: 2 }}>
                                    {['Tags', 'Status', 'Excluir'].map((el, idx) => {
                                        const icon = idx === 0 ? 53 : idx === 2 ? 28 : e?.status === 1 ? 23 : 24;
                                        const title = [0, 2].includes(idx) ? el : e?.status === 1 ? 'Ativo' : 'Inativo';
                                        const color = idx % 2 ? theme.palette.tertiary.main : theme.palette.secondary.main
                                        return (
                                            <UpComp tamanho={30} key={idx}>
                                                <Tooltip title={title}>
                                                    <IconButton onClick={() => {
                                                        handleButton(el, e)
                                                    }}>
                                                        {getIcon(icon, color)}
                                                    </IconButton>
                                                </Tooltip>
                                            </UpComp>
                                        );
                                    })}
                                </Box>
                            </Box>
                        </BoxHover>
                    )
                })}
            </Box>
            <Pagination
                count={Math.ceil(newContatos.length / itemsPerPage)}
                page={page}
                onChange={handleChangePage}
                color="primary"
                sx={{ marginTop: 2 }}
            />
            <ModalInfo hideModal={() => updateModalInfo(false)} canDesactive={MInfo.canDesactive} visible={MInfo.visible} title={MInfo.title} subtitle={MInfo.subtitle} icon={MInfo.icon} funcS={MInfo.funcS} />
        </Box>
    );
}