import React, { useCallback, useEffect, useState } from 'react';
import { getSmoothStepPath, EdgeLabelRenderer } from 'reactflow';
import { useTheme, Box, IconButton } from '@mui/material';

const CustomEdge = ({
    id,
    sourceX,
    sourceY,
    targetX,
    targetY,
    sourcePosition,
    targetPosition,
    style = {},
    data,
    markerEnd,
    setEdges
}) => {
    const [hovered, setHovered] = useState(false);
    const theme = useTheme();

    const edgePath = getSmoothStepPath({
        sourceX,
        sourceY,
        targetX,
        targetY,
        sourcePosition,
        targetPosition,
    });

    const handleDelete = useCallback(() => {
        setEdges((eds) => eds.filter((edge) => edge.id !== id));
    }, [id, setEdges]);

    const midX = (sourceX + targetX) / 2;
    const midY = (sourceY + targetY) / 2;
    return (
        <>
            <g onMouseEnter={() => setHovered(true)} onMouseLeave={() => setHovered(false)} type='straight'>
                <path
                    id={id}
                    style={style} type='straight'
                    className="react-flow__edge-path"
                    d={edgePath[0]}
                    markerEnd={markerEnd}
                />
                {!data?.permanent && hovered && (
                    <EdgeLabelRenderer>
                        <Box
                            sx={{
                                position: 'absolute',
                                transform: `translate(-50%, -50%) translate(${midX}px, ${midY}px)`,
                                pointerEvents: 'all',
                            }}
                        >
                            <IconButton
                                onClick={handleDelete}
                                sx={{
                                    background: theme.palette.secondary.main, textTransform: 'none', color: theme.palette.text.onPrimary, fontSize: 13, '&:hover': {
                                        background: theme.palette.tertiary.main,
                                    }
                                }}
                            >
                                X
                            </IconButton>
                        </Box>
                    </EdgeLabelRenderer>
                )}
            </g>
        </>
    );
};

export default CustomEdge;
