

import React, { useState, useContext } from 'react';
import { useTheme, Box, Typography, Dialog, DialogTitle, DialogContent, Tooltip, IconButton, Button } from '@mui/material';
import { ModalInfo } from '../../../components/modalInfo';
import { User } from '../../../contexts/user';
import { BoxHover } from '../../../components/boxHover';
import { getIcon } from '../../../components/icons';
import { UpComp } from '../../../components/upComp';

export function List({ data, updateNewEmail, deleteEmail }) {
    const theme = useTheme();
    const user = useContext(User);
    //estados
    const [MInfo, setMInfo] = useState({
        visible: false,
        title: '',
        subtitle: '',
        canDesactive: true,
        icon: '',
        funcS: () => null
    });
    const [previewDialog, setPreviewDialog] = useState({
        open: false,
        htmlContent: ''
    });

    function updateModalInfo(visible, canDesactive, title, subtitle, icon, func) {
        const newMInfo = { ...MInfo };
        newMInfo.visible = visible;
        newMInfo.canDesactive = canDesactive;
        newMInfo.title = title;
        newMInfo.subtitle = subtitle;
        newMInfo.icon = icon;
        newMInfo.funcS = func;
        setMInfo(newMInfo);
    }
    function handleButton(type, value) {
        if (type === 'Editar') {
            updateNewEmail(true, value)
        }
        if (type === 'Excluir') {
            deleteEmail(value.id)
        }
    }
    const handlePreview = (htmlContent) => {
        setPreviewDialog({ open: true, htmlContent });
    };

    const closePreviewDialog = () => {
        setPreviewDialog({ open: false, htmlContent: '' });
    };

    return (
        <Box sx={{
            flex: 1
        }}>
            <Box sx={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', columnGap: 6 }}>
                {data.map((e, idx) => {
                    return (
                        <BoxHover key={idx}>
                            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-between', height: 100, width: '100%' }}>
                                <Button onClick={() => handlePreview(e.html)} sx={{ textTransform: 'none', textAlign: 'left', zIndex: 1, width: '100%', height: '100%' }}>
                                    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: 1 }}>
                                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'flex-start' }}>
                                            <Typography sx={{ fontWeight: 'bold', color: theme.palette.text.primary }}>{e.nome}</Typography>
                                        </Box>
                                    </Box>
                                </Button>
                                <Box sx={{ display: 'flex', gap: 2, zIndex: 10, alignItems: 'center' }}>
                                    {['Editar', 'Excluir', 'Enviar',].map((b, idx) => {
                                        const icon = idx === 2 ? 57 : idx === 1 ? 28 : 27
                                        const background = 'transparent'
                                        const color = idx === 1 ? 'red' : theme.palette.primary.main
                                        return (
                                            <UpComp key={idx} tamanho={30}>
                                                <Tooltip title={b}>
                                                    <IconButton sx={{ background: background }} onClick={() => handleButton(b, e)}>
                                                        {getIcon(icon, color)}
                                                    </IconButton>
                                                </Tooltip>
                                            </UpComp>
                                        )
                                    })}
                                </Box>
                            </Box>
                        </BoxHover>
                    )
                })}
            </Box>
            <Dialog open={previewDialog.open} onClose={closePreviewDialog} fullWidth maxWidth="lg">
                <DialogTitle>Pré-Visualização</DialogTitle>
                <DialogContent>
                    <div dangerouslySetInnerHTML={{ __html: previewDialog.htmlContent }} />
                </DialogContent>
            </Dialog>
            <ModalInfo hideModal={() => updateModalInfo(false)} canDesactive={MInfo.canDesactive} visible={MInfo.visible} title={MInfo.title} subtitle={MInfo.subtitle} icon={MInfo.icon} funcS={MInfo.funcS} />
        </Box>
    );
}