import React, { useState, useContext, useEffect } from 'react';
import { useTheme, Box } from '@mui/material';
import { ModalInfo } from '../../../components/modalInfo';
import { User } from '../../../contexts/user';
import { ModuloTitle } from '../../../components/moduloTitle';
import { NovaMensagem } from './components/novaMensagem';
import MAPI from '../../../api/mainApi';
import { ListMensagem } from './components/listMensagens';
import { EnviarMensagem } from './components/enviarMensagem';
import { validaEvento } from '../../../Mainfunctions';

export function Disparador({ modulo }) {
    const theme = useTheme();
    const user = useContext(User);
    document.title = 'Con Contatos - Disparador'
    //estados
    const [MInfo, setMInfo] = useState({
        visible: false,
        title: '',
        subtitle: '',
        canDesactive: true,
        icon: '',
        funcS: () => null
    });
    const [showMensagem, setShowMensagem] = useState({
        visible: false,
        mensagem: null
    })
    const [enviarMensagem, setEnviarMensagem] = useState({
        visible: false,
        mensagem: null
    })
    const [messages, setMessages] = useState([])

    function mensagemEnviada() {
        updateEnvMensagem()
        updateModalInfo(true, true, 'Sucesso', 'A mensagem foi disparada.', 'sucess')
    }

    function pushMessages(message) {
        if (message.tipo !== 4) {
            updateSMensagem(false)
            updateModalInfo(true, true, 'Atenção', 'Mensagem criada com sucesso', 'sucess')
        }
        getMessages()
    }
    function editMessages(message) {
        const newMessages = [...messages]
        const findIndex = newMessages.findIndex((e) => e.id === message.id)
        if (findIndex !== -1) {
            newMessages[findIndex] = message
            if (message?.tipo !== 4) {
                updateSMensagem(false)
                updateModalInfo(true, true, 'Atenção', 'Mensagem editada com sucesso', 'sucess')
                setMessages(newMessages)
                return
            }
            setMessages(newMessages)
        }
    }
    function deleteMensagem(message) {
        const newMessages = messages.filter((e) => e.id !== message.id)
        setMessages(newMessages)
        updateSMensagem(false)
        updateModalInfo(true, true, 'Atenção', 'Mensagem excluida com sucesso', 'sucess')
    }
    function updateSMensagem(visible, mensagem) {
        if (visible) {
            if (validaEvento(modulo?.permissoes, 'criar') === false && !mensagem) {
                updateModalInfo(true, true, 'Atenção', 'Você não tem permissão para criar disparos', 'exclamation')
                return
            }
            if (validaEvento(modulo?.permissoes, 'editar') === false && mensagem) {
                updateModalInfo(true, true, 'Atenção', 'Você não tem permissão para editar disparos', 'exclamation')
                return
            }
        }
        const newM = { ...showMensagem }
        newM.visible = visible ? visible : false
        newM.mensagem = mensagem ? mensagem : null
        setShowMensagem(newM)
    }
    function updateEnvMensagem(visible, mensagem) {
        const newM = { ...enviarMensagem }
        newM.visible = visible ? visible : false
        newM.mensagem = mensagem ? mensagem : null
        setEnviarMensagem(newM)
    }
    function updateModalInfo(visible, canDesactive, title, subtitle, icon, func) {
        const newMInfo = { ...MInfo };
        newMInfo.visible = visible;
        newMInfo.canDesactive = canDesactive;
        newMInfo.title = title;
        newMInfo.subtitle = subtitle;
        newMInfo.icon = icon;
        newMInfo.funcS = func;
        setMInfo(newMInfo);
    }

    async function getMessages() {
        updateModalInfo(true, false, 'Carregando', 'Buscando mensagens', 'loading')
        const get = await MAPI('disparador/getMessages', 'GET', null, user?.data?.token)
        if (get?.status !== 200) {
            const message = get?.apiReturn?.message ? get?.apiReturn?.message : 'Ocorreu um erro ao buscar as mensagens.'
            updateModalInfo(true, true, 'Atenção', message, 'exclamation')
            return
        }
        updateModalInfo(false)
        setMessages(get?.apiReturn?.apiReturn)
    }

    useEffect(() => {
        getMessages()
    }, [])
    return (
        <Box sx={{
            flex: 1,
        }}>
            <ModuloTitle icon={modulo.moduloIcon} title={modulo.moduloNome} desc={modulo.moduloDesc} buttons={[{ text: 'Adicionar', func: () => updateSMensagem(true) }]} />
            <ListMensagem canExclude={validaEvento(modulo?.permissoes, 'excluir')} mensagens={messages} updateSMensagem={updateSMensagem} deleteMensagem={deleteMensagem} updateEnvMensagem={updateEnvMensagem} />
            <NovaMensagem visible={showMensagem.visible} mensagem={showMensagem.mensagem} updateSMensagem={updateSMensagem} pushMessages={pushMessages} editMessages={editMessages} />
            <EnviarMensagem visible={enviarMensagem.visible} mensagem={enviarMensagem.mensagem} updateEnvMensagem={updateEnvMensagem} mensagemEnviada={mensagemEnviada} />
            <ModalInfo hideModal={() => updateModalInfo(false)} canDesactive={MInfo.canDesactive} visible={MInfo.visible} title={MInfo.title} subtitle={MInfo.subtitle} icon={MInfo.icon} funcS={MInfo.funcS} />
        </Box>
    );
}