import React from 'react';
import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Paper, IconButton, useTheme } from '@mui/material';
import { getIcon } from './icons';

export function SimpleTable({ type, headers = [], rows = [], params = [], editar, excluir }) {
    const theme = useTheme()
    return (
        <TableContainer sx={{ width: '100%', height: '100%' }}>
            <Table sx={{ width: '100%', height: '100%' }}>
                <TableHead>
                    <TableRow>
                        {headers.map((header) => (
                            <TableCell key={header}>{header}</TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map((row, rowIndex) => (
                        <TableRow key={rowIndex}>
                            {params.map((param, paramIndex) => {
                                if (row[param].slice(0, 1) === '#') {
                                    return (
                                        <TableCell key={paramIndex}>{getIcon(53, row[param])}</TableCell>
                                    )
                                }
                                return (
                                    <TableCell key={paramIndex}>{row[param]}</TableCell>
                                )
                            })}
                            {editar ?
                                <TableCell sx={{ width: '30px'}}>
                                    <IconButton aria-label="editar" onClick={() => editar(row)}>
                                        {getIcon(27, theme.palette.secondary.main)}
                                    </IconButton>
                                </TableCell>
                                : null}
                            {excluir ?
                                <TableCell sx={{ width: '30px'}}>
                                    <IconButton aria-label="excluir" onClick={() => excluir(row)}>
                                        {getIcon(28, theme.palette.tertiary.main)}
                                    </IconButton>
                                </TableCell>
                                : null}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}
