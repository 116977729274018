

export function validaEvento(evento, tipo) {
    if (typeof evento !== 'string') return false
    if (tipo === 'criar' && evento.charAt(0) === '1') return true
    if (tipo === 'editar' && evento.charAt(1) === '1') return true
    if (tipo === 'excluir' && evento.charAt(2) === '1') return true
    if (tipo === 'status' && evento.charAt(3) === '1') return true
    return false
}

export const eventosTransform = (eventos, userOp) => {
    if (typeof eventos !== 'string' || eventos.length === 0) return false
    const evArr = eventos.split('')
    const newEv = []
    evArr.map((e, index) => {
        const status = typeof userOp !== 'string' || userOp.length === 0 ? false : userOp[index] === '1' ? true : false
        newEv.push({
            text: (evIndexToText(index)),
            status: status
        })
    })
    return newEv
}

export const evIndexToText = (index) => {
    return index === 0 ? 'Criar' : index === 1 ? 'Editar' : index === 2 ? 'Excluir' : index === 3 ? 'Status' : ''
}