import { Box } from '@mui/material';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { ModuloTitle } from '../../components/moduloTitle';
import { CreateEmail } from './components/createEmail';
import { ChoseModel } from './components/choseModel';
import { ModalInfo } from '../../components/modalInfo';
import MAPI from '../../api/mainApi';
import { User } from '../../contexts/user';
import { List } from './components/listEmails';

export function MeusEmails({ modulo }) {
  document.title = `Email - It's Chat`
  const user = useContext(User)
  const [newEmail, setNewEmail] = useState({
    visible: false,
    model: null
  })
  const [chose, setChose] = useState(false)
  const [data, setData] = useState([])
  const [MInfo, setMInfo] = useState({
    visible: false,
    title: '',
    subtitle: '',
    canDesactive: true,
    icon: '',
    funcS: () => null
  });
  function updateModalInfo(visible, canDesactive, title, subtitle, icon, func) {
    const newMInfo = { ...MInfo };
    newMInfo.visible = visible;
    newMInfo.canDesactive = canDesactive;
    newMInfo.title = title;
    newMInfo.subtitle = subtitle;
    newMInfo.icon = icon;
    newMInfo.funcS = func;
    setMInfo(newMInfo);
  }
  function updateNewEmail(visible, model) {
    const newE = { ...newEmail }
    newE.visible = visible ? visible : false
    newE.model = model ? model : null
    setNewEmail(newE)
  }

  async function deleteEmail(email, control) {
    if (!control) {
      updateModalInfo(true, true, 'Atenção', 'Você tem certeza que deseja excluir esse e-mail?', 'question', () => deleteEmail(email, true))
      return
    }
    updateModalInfo(true, false, 'Atenção', 'Estamos excluindo seu e-mail.', 'loading')
    const del = await MAPI(`email/deleteEmail/${email}`, 'DELETE', null, user?.data?.token)
    if (del?.status !== 200) {
      const message = del?.apiReturn?.message ? del?.apiReturn?.message : 'Ocorreu um erro ao excluir seu e-mail.'
      updateModalInfo(true, true, 'Atenção', message, 'exclamation')
      return
    }
    const newD = data.filter((e) => e.id !== email)
    setData(newD)
    updateModalInfo(true, true, 'Sucesso', 'Seu e-mail foi excluído.', 'sucess')
  }

  function saveEmail(email) {
    const findIndex = data.findIndex((e) => e?.id === email?.id)
    const newData = [...data]
    if (findIndex === -1) {
      newData.push(email)
    }
    else {
      newData[findIndex] = email
    }
    setData(newData)
  }

  async function getEmails() {
    updateModalInfo(true, false, 'Carregando', 'Buscando seus e-mails.', 'loading')
    const get = await MAPI('email/getEmails', 'GET', null, user?.data?.token)
    if (get?.status !== 200) {
      const message = get?.apiReturn?.message ? get?.apiReturn?.message : 'Ocorreu um erro ao buscar seus e-mails.'
      updateModalInfo(true, true, 'Atenção', message, 'exclamation')
      return
    }
    updateModalInfo(false)
    setData(get?.apiReturn?.apiReturn)
  }
  useEffect(() => {
    getEmails()
  }, [])

  return (
    <Box sx={{ flex: 1 }}>
      <ModuloTitle title={modulo.moduloNome} desc={modulo.moduloDesc} buttons={[{ text: 'Adicionar', func: () => setChose(true) }]} />
      <List data={data} updateNewEmail={updateNewEmail} deleteEmail={deleteEmail} />
      <CreateEmail saveEmail={saveEmail} visible={newEmail.visible} model={newEmail.model} updateNewEmail={updateNewEmail} />
      <ChoseModel visible={chose} updateNewEmail={updateNewEmail} dismiss={() => setChose(false)} />
      <ModalInfo hideModal={() => updateModalInfo(false)} canDesactive={MInfo.canDesactive} visible={MInfo.visible} title={MInfo.title} subtitle={MInfo.subtitle} icon={MInfo.icon} funcS={MInfo.funcS} />
    </Box>
  )
}
