import Logo_w from '../../inc/images/logo_w.png'
import Logo_d from '../../inc/images/logo_wb.png'
import MinLogo from '../../inc/images/flaticon.png'

export const TropicalVibrantTheme = (mode, changeMode) => ({
    palette: {
        logo: mode === 'light' ? Logo_w : Logo_d,
        minLogo: MinLogo,
        mode: mode,
        changeMode: changeMode,
        ...(mode === 'light' ? {
            primary: {
                main: '#32a852',
                light: '#43c961',
                dark: '#278a43',
            },
            secondary: {
                main: '#ff9800',
                light: '#ffac33',
                dark: '#e68900',
            },
            tertiary: {
                main: '#ffeb3b',
                light: '#ffef62',
                dark: '#e6d12b',
            },
            background: {
                default: '#ffffff',
                paper: '#f0f0f0',
                inverse: '#141414',
            },
            text: {
                main: '#2A2F42',
                primary: '#2A2F42',
                secondary: '#4d4d4d',
                disabled: '#c0bcbb',
                inverse: '#ffffff',
                onPrimary: '#ffffff',
                onDrawer: '#B4B4B4'
            },
        } : {
            primary: {
                main: '#2a6f38',
                light: '#3a894a',
                dark: '#1e4d28',
            },
            secondary: {
                main: '#e67e00',
                light: '#ff9800',
                dark: '#cc6b00',
            },
            tertiary: {
                main: '#e6c200',
                light: '#ffd700',
                dark: '#b39500',
            },
            background: {
                default: '#121212',
                paper: '#1e1e1e',
                inverse: '#f0f0f0',
            },
            text: {
                main: '#ffffff',
                primary: '#ffffff',
                secondary: '#cccccc',
                disabled: '#a6a6a6',
                inverse: '#121212',
                onPrimary: '#fff',
                onDrawer: '#B4B4B4'
            },
        })
    },
});
