import { Box, useTheme, Button } from '@mui/material'
import { OpUnit } from './opUnit'

export function OpList({ data, permissoes, openEdit, deleteOperador, openModulos }) {
    const theme = useTheme()
    return (
        <Box sx={{
            display: 'flex',
            flexWrap: 'wrap'
        }}>
            {Array.isArray(data) ? data.map((e) => {
                return (
                    <OpUnit key={e}permissoes={permissoes} operador={e} openEdit={openEdit} deleteOperador={deleteOperador} openModulos={openModulos} />
                )
            })
                : null
            }
        </Box >
    )
}