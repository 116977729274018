import { Modal, useTheme, Box, Typography, Tooltip } from "@mui/material";
import { useState } from "react";




export function ListThemes({ visible, dismiss }) {
    const theme = useTheme()
    const allThemes = theme.allThemes
    const [selectedTheme, setSelectedTheme] = useState(theme.themeMode);
    function hideModal() {
        dismiss()
    }
    return (
        <Modal open={visible} onClose={() => hideModal()} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} slotProps={{
            backdrop: {
                sx: {
                    backgroundColor: theme.palette.mode === 'dark' ? 'rgba(250,255,255, 0.2)' : 'rgba(0,0,0, 0.4)'
                }
            }
        }}>

            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3, height: 820, width: '95%', maxWidth: 700, background: theme.palette.background.default, outline: 'none', }}>
                <Box sx={{ background: theme.palette.background.paper, padding: 5 }}>
                    <Typography sx={{ fontWeight: 'bold', color: theme.palette.text.primary, fontSize: 30 }}>{'Temas'}</Typography>
                    <Typography sx={{ color: theme.palette.text.primary, fontSize: 18 }}>Escolha um tema para personalizar sua experiência.</Typography>
                </Box>
                <Box sx={{ padding: 2 }}>
                    <Typography variant="h6" sx={{color: theme.palette.text.primary}}>Selecione o Tema</Typography>
                    <Box sx={{ display: 'flex', gap: 2, flexWrap: 'wrap', marginTop: 2 }}>
                        {Object.keys(allThemes).map((themeOption) => {
                            const getThemeFunc = allThemes[themeOption]
                            const thisTheme = getThemeFunc()
                            return (
                                <Tooltip title={themeOption} key={themeOption}>
                                    <Box
                                        key={themeOption}
                                        sx={{
                                            width: 40,
                                            height: 40,
                                            borderRadius: '50%',
                                            backgroundColor: thisTheme.palette.primary.main,
                                            cursor: 'pointer',
                                            border: themeOption === selectedTheme ? `2px solid ${theme.palette.primary.main}` : 'none'
                                        }}
                                        onClick={() => {
                                            setSelectedTheme(themeOption);
                                            theme.changeTheme(themeOption);
                                        }}
                                    />
                                </Tooltip>
                            )
                        })}
                    </Box>
                </Box>
            </Box>
        </Modal>
    )
}