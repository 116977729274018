import React, { useState, useContext, useEffect } from 'react';
import { useTheme, Box, Typography, Button, Modal } from '@mui/material';
import { HexColorPicker } from "react-colorful";
import { ModalInfo } from '../../../../components/modalInfo';
import { User } from '../../../../contexts/user';
import { CTextField } from '../../../../components/TextField';
import { getIcon } from '../../../../components/icons';
import { ButtonSubmit } from '../../../../components/buttons';
import MAPI from '../../../../api/mainApi';

export function Newtag({ dismiss, pushTag, prevTag, updateTag, canCreate }) {
    const theme = useTheme();
    const user = useContext(User);
    //estados
    const [MInfo, setMInfo] = useState({
        visible: false,
        title: '',
        subtitle: '',
        canDesactive: true,
        icon: '',
        funcS: () => null
    });
    const [color, setColor] = useState(theme.palette.primary.main)
    const [name, setName] = useState('')
    const [id, setId] = useState(null)

    function updateModalInfo(visible, canDesactive, title, subtitle, icon, func) {
        const newMInfo = { ...MInfo };
        newMInfo.visible = visible;
        newMInfo.canDesactive = canDesactive;
        newMInfo.title = title;
        newMInfo.subtitle = subtitle;
        newMInfo.icon = icon;
        newMInfo.funcS = func;
        setMInfo(newMInfo);
    }
    function handleDismiss() {
        if (dismiss) {
            dismiss()
        }
    }
    function changeName(value) {
        if (value.length > 50) return
        setName(value)
    }

    function handleSubmit(type) {
        if (type === 'Limpar') {
            handleDismiss()
            return
        }
        saveTag()
    }
    async function saveTag() {
        if (!canCreate) {
            updateModalInfo(true, true, 'Atenção', 'Você não tem permissão para criar tags', 'exclamation')
            return
        }
        if (name === '') {
            updateModalInfo(true, true, 'Atenção', 'O campo nome não pode ser vazio.', 'exclamation')
            return
        }
        const endpoint = id ? 'edit' : 'novo'
        const method = id ? 'PUT' : 'POST'
        const body = {
            nome: name, color: color
        }
        if (id) {
            body.id = id
        }
        updateModalInfo(true, true, 'Salvando', 'Tentando salvar sua tag.', 'loading')
        const save = await MAPI(`tags/${endpoint}`, method, body, user?.data?.token)
        if (save?.status !== 200) {
            const message = save?.apiReturn?.message ? save?.apiReturn?.message : 'Ocorreu um erro ao salvar a tag.'
            updateModalInfo(true, true, 'Atenção', message, 'exclamation')
            return
        }
        updateModalInfo(true, true, 'Sucesso', 'Tag salva no servidor.', 'exclamation')
        const func = id ? updateTag : pushTag
        func({ id: id ? id : save?.apiReturn?.apiReturn, nome: name, color: color })
    }

    useEffect(() => {
        if (prevTag) {
            setId(prevTag.id)
            setColor(prevTag.color)
            setName(prevTag.nome)
        }
        else {
            setId(null)
            setColor(theme.palette.primary.main)
            setName('')
        }
    }, [prevTag])

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            height: '100%',
            width: '100%',
            background: theme.palette.background.default,
            padding: 2,
            gap: 3
        }}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 3 }}>
                {getIcon(53, theme.palette.secondary.main)}
                <Typography sx={{ fontWeight: 'bold', color: theme.palette.text.main, fontSize: 25, textAlign: 'center', color: theme.palette.text.primary }}>Tag</Typography>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', width: '70%' }}>
                <Typography sx={{ fontWeight: 'bold', color: theme.palette.text.primary, fontSize: 22 }}>Escolha um nome</Typography>
                <Box sx={{ width: '100%' }}>
                    <CTextField label={'Nome'} width={'100%'} value={name} onChangeText={changeName} />
                </Box>
            </Box>
            <Box sx={{ display: 'flex', gap: 2, alignItems: 'center', width: '70%' }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                    <Typography sx={{ fontWeight: 'bold', color: theme.palette.text.primary, fontSize: 22 }}>Selecione uma cor</Typography>
                    <HexColorPicker color={color} onChange={setColor} />
                </Box>
                <Box sx={{ position: 'relative', display: 'inline-block', width: 'fit-content' }}>
                    <Box sx={{ position: 'relative' }}>
                        {getIcon(53, color, 120)}
                        <Typography
                            sx={{
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                color: 'white',
                                zIndex: 10,
                                fontWeight: 'bold',
                                pointerEvents: 'none'
                            }}
                        >
                            {color}
                        </Typography>
                    </Box>
                </Box>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, width: '70%' }}>
                {['Salvar', 'Limpar'].map((e) => {
                    const background = e === 'Salvar' ? theme.palette.primary.main : theme.palette.background.paper
                    const color = e === 'Salvar' ? theme.palette.text.onPrimary : theme.palette.text.primary
                    return (
                        <ButtonSubmit key={e} width={'100%'} func={handleSubmit} funcParameters={e} text={e} background={background} color={color} />
                    )
                })}
            </Box>

            <ModalInfo hideModal={() => updateModalInfo(false)} canDesactive={MInfo.canDesactive} visible={MInfo.visible} title={MInfo.title} subtitle={MInfo.subtitle} icon={MInfo.icon} funcS={MInfo.funcS} />
        </Box >
    );
}

{/* <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', padding: 5, paddingTop: 2, height: '100%' }}>
                    <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                            <Typography sx={{ fontWeight: 'bold', color: theme.palette.text.primary, fontSize: 22 }}>Selecione uma cor</Typography>
                            <HexColorPicker color={color} onChange={setColor} />
                        </Box>
                        <Box sx={{ position: 'relative', display: 'inline-block', width: 'fit-content' }}>
                            <Box sx={{ position: 'relative' }}>
                                {getIcon(53, color, 120)}
                                <Typography
                                    sx={{
                                        position: 'absolute',
                                        top: '50%',
                                        left: '50%',
                                        transform: 'translate(-50%, -50%)',
                                        color: 'white',
                                        zIndex: 10,
                                        fontWeight: 'bold',
                                        pointerEvents: 'none'
                                    }}
                                >
                                    {color}
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                    
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                        {['Salvar', 'Limpar'].map((e) => {
                            const background = e === 'Salvar' ? theme.palette.primary.main : theme.palette.background.paper
                            const color = e === 'Salvar' ? theme.palette.text.onPrimary : theme.palette.text.primary
                            return (
                                <ButtonSubmit width={'100%'} func={handleSubmit} funcParameters={e} text={e} background={background} color={color} />
                            )
                        })}
                    </Box>
                </Box> */}