import Logo_w from '../../inc/images/logo_w.png'
import Logo_d from '../../inc/images/logo_wb.png'
import MinLogo from '../../inc/images/flaticon.png'

export const AuroraTheme = (mode, changeMode) => ({
    palette: {
        logo: mode === 'light' ? Logo_w : Logo_d,
        minLogo: MinLogo,
        mode: mode,
        changeMode: changeMode,
        ...(mode === 'light' ? {
            primary: {
                main: '#39ff14',
                light: '#66ff33',
                dark: '#2db30e',
            },
            secondary: {
                main: '#00bfff',
                light: '#33ccff',
                dark: '#0099cc',
            },
            tertiary: {
                main: '#d3d3d3',
                light: '#e6e6e6',
                dark: '#a6a6a6',
            },
            background: {
                default: '#ffffff',
                paper: '#f0f0f0',
                inverse: '#141414',
            },
            text: {
                main: '#2A2F42',
                primary: '#2A2F42',
                secondary: '#4d4d4d',
                disabled: '#c0bcbb',
                inverse: '#ffffff',
                onPrimary: '#006400',
                onDrawer: '#B4B4B4'
            },
        } : {
            primary: {
                main: '#adff2f',
                light: '#bfff4d',
                dark: '#8dbf26',
            },
            secondary: {
                main: '#0047ab',
                light: '#3366cc',
                dark: '#00398a',
            },
            tertiary: {
                main: '#7a7a7a',
                light: '#9c9c9c',
                dark: '#5e5e5e',
            },
            background: {
                default: '#121212',
                paper: '#1e1e1e',
                inverse: '#f0f0f0',
            },
            text: {
                main: '#ffffff',
                primary: '#ffffff',
                secondary: '#cccccc',
                disabled: '#a6a6a6',
                inverse: '#121212',
                onPrimary: '#006400',
                onDrawer: '#B4B4B4'
            },
        })
    },
});
