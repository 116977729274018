import React, { useState, useContext, useEffect } from 'react';
import { useTheme, Box, Typography, Divider, useMediaQuery } from '@mui/material';
import { ModalInfo } from '../../../components/modalInfo';
import { User } from '../../../contexts/user';
import { ModuloTitle } from '../../../components/moduloTitle';
import MAPI from '../../../api/mainApi';
import { validaEvento } from '../../../Mainfunctions';
import { NewCampo } from './components/new';
import { ListCampos } from './components/list';

export function CamposContato({ modulo }) {
    const theme = useTheme();
    const user = useContext(User);
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
    //estados
    const [MInfo, setMInfo] = useState({
        visible: false,
        title: '',
        subtitle: '',
        canDesactive: true,
        icon: '',
        funcS: () => null
    });
    const [campos, setCampos] = useState([])
    const [prevCampo, setPrevCampo] = useState(null)
    function updateEditCampo(campo) {
        setPrevCampo(campo)
    }
    function pushCampo(campo) {
        const newD = [...campos]
        newD.push(campo)
        setCampos(newD)
        updateModalInfo(true, true, 'Sucesso', 'Campo criado com sucesso.', 'sucess')
    }

    function editCampo(campo) {
        const findIndex = campos.findIndex((e) => e.id === campo.id)
        if (findIndex !== -1) {
            const newD = [...campos]
            newD[findIndex] = campo
            setCampos(newD)
            updateModalInfo(true, true, 'Sucesso', 'Seu campo foi editado.', 'sucess')
            setPrevCampo(null)
            return
        } else {
            updateModalInfo(true, true, 'Atenção', 'Campo não foi encontrado na lista.')
        }
    }
    async function deleteCampo(campo, control) {
        if (!validaEvento(modulo?.permissoes, 'excluir')) {
            updateModalInfo(true, true, 'Atenção', 'Você não tem permissões para excluir campos.', 'exclamation')
            return
        }
        if (!control) {
            updateModalInfo(true, true, 'Atenção', 'Você tem certeza que deseja excluir o campo?', 'question', () => deleteCampo(campo, true))
            return
        }
        const tryD = await MAPI(`camposContato/deleteCampo/${campo.id}`, 'DELETE', null, user?.data?.token)
        if (tryD?.status !== 200) {
            const message = tryD?.apiReturn?.message ? tryD?.apiReturn?.message : 'Ocorreu um erro ao excluir o campo'
            updateModalInfo(true, true, 'Atenção', message, 'exclamation')
            return
        }
        const newC = campos.filter((e) => e.id !== campo.id)
        setCampos(newC)
        setPrevCampo(null)
        updateModalInfo(true, true, 'Sucesso', 'Seu campo foi excluído', 'sucess')
    }
    function updateModalInfo(visible, canDesactive, title, subtitle, icon, func) {
        const newMInfo = { ...MInfo };
        newMInfo.visible = visible;
        newMInfo.canDesactive = canDesactive;
        newMInfo.title = title;
        newMInfo.subtitle = subtitle;
        newMInfo.icon = icon;
        newMInfo.funcS = func;
        setMInfo(newMInfo);
    }
    async function getCampos() {
        updateModalInfo(true, false, 'Carregando', 'Buscando Campos.', 'loading')
        const get = await MAPI('camposContato/getCampos', 'GET', null, user?.data?.token)
        if (get?.status !== 200) {
            const message = get?.apiReturn?.message ? get?.apiReturn?.message : 'Ocorreu um erro ao buscar as variaveis'
            updateModalInfo(true, true, 'Atenção', message, 'exclamation')
            return
        }
        updateModalInfo(false)
        const apiReturn = get?.apiReturn?.apiReturn
        setCampos(apiReturn)

    }
    useEffect(() => {
        getCampos()
    }, [])

    return (
        <Box sx={{
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            height: '100%'
        }}>
            <ModuloTitle icon={modulo.moduloIcon} title={modulo.moduloNome} desc={modulo.moduloDesc} />
            <Box sx={{ display: 'flex', flexDirection: isSmallScreen ? 'column' : 'row', flexGrow: 1, background: theme.palette.background.default, width: '100%', height: '100%' }}>
                <Box sx={{ width: isSmallScreen ? '100%' : '35%' }}>
                    <NewCampo editCampo={editCampo} prevData={prevCampo} canEdit={validaEvento(modulo?.permissoes, 'editar')} canCreate={validaEvento(modulo?.permissoes, 'criar')} updateModalInfo={updateModalInfo} pushCampo={pushCampo} />
                </Box>
                <Divider orientation={isSmallScreen ? 'horizontal' : 'vertical'} flexItem />
                <ListCampos updateCampo={updateEditCampo} campos={campos} deleteCampo={deleteCampo} />
            </Box>
            <ModalInfo hideModal={() => updateModalInfo(false)} canDesactive={MInfo.canDesactive} visible={MInfo.visible} title={MInfo.title} subtitle={MInfo.subtitle} icon={MInfo.icon} funcS={MInfo.funcS} />
        </Box>
    );
}