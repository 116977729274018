import React, { useState, useContext, useRef } from 'react';
import { useTheme, Box, Modal, Typography, IconButton, Divider, Button } from '@mui/material';
import Papa from 'papaparse';
import { getIcon } from '../../../../../../components/icons';
import { User } from '../../../../../../contexts/user';
import { ModalInfo } from '../../../../../../components/modalInfo';
import { UpComp } from '../../../../../../components/upComp';
import { WhatsAppMessage } from '../../../../../../components/whatsappMessage';
import { ButtonSubmit } from '../../../../../../components/buttons';
import { TestaEMAIL } from '../../../../../../components/validaCampo';
import { replaceMensagemVariaveis } from '../../../../../../components/replaceMensagemVariaveis';

export function Arquivo({ visible, dismiss, mensagem, tagToListContatos, conTipo }) {
    const theme = useTheme();
    const user = useContext(User);
    const inputRef = useRef(null)
    const [modalInfo, setModalInfo] = useState({
        visible: false,
        title: '',
        subtitle: '',
        canDesactive: true,
        icon: '',
        funcS: () => null
    });
    const [data, setData] = useState([])
    const [variaveis, setVariaveis] = useState([])
    const [activeData, setActiveData] = useState(0)
    const [activeType, setActiveType] = useState(0)
    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (!file) return;
        Papa.parse(file, {
            complete: function (results) {
                console.log('akkkkkkkkkkkkkkkk')
                if (results.data.length < 2) {
                    updateModalInfo(true, true, 'Erro', 'O arquivo CSV deve conter pelo menos duas linhas', 'exclamation', null);
                    return;
                }
                let headerRow = results.data[0];
                if (!headerRow.includes('nome')) {
                    updateModalInfo(true, true, 'Erro', 'O arquivo CSV deve conter um campo com o "nome" do contato.', 'exclamation', null);
                    return
                }
                if (!headerRow.includes('numero')) {
                    updateModalInfo(true, true, 'Erro', 'O arquivo CSV deve conter um campo com o "numero" do contato.', 'exclamation', null);
                    return
                }
                if (conTipo === 2 && !headerRow.includes('email')) {
                    updateModalInfo(true, true, 'Erro', 'O arquivo CSV deve conter um campo com o "email" do contato.', 'exclamation', null);
                    return
                }
                const dataRows = results.data.slice(1);

                let jsonData = dataRows.map((row, idx) => {
                    let obj = {};
                    headerRow.forEach((header, index) => {
                        obj.id = idx + index
                        obj[header] = row[index];
                    });
                    return obj
                });
                const newJsonData = jsonData.filter((e) => {
                    if (!e?.nome) return false
                    if (conTipo === 2 && !e.email && !TestaEMAIL(e.mail)) return false
                    if ([1].includes(conTipo) && !e?.numero) return false
                    return true
                })
                console.log(newJsonData, '<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<')
                setVariaveis(headerRow)
                setData(newJsonData)
            }
        });
    };

    // Função para atualizar o estado do modal de informações
    const updateModalInfo = (visible, canDesactive, title, subtitle, icon, func) => {
        setModalInfo({
            visible: visible,
            canDesactive: canDesactive,
            title: title,
            subtitle: subtitle,
            icon: icon,
            funcS: func
        });
    };
    function handleSubmit(type) {
        if (type === 'Voltar') {
            hideModal()
            return
        }
        if (data.length === 0) {
            updateModalInfo(true, true, 'Atenção', 'Nenhum contato encontrado.', 'exclamation')
            return
        }
        hideModal()
        tagToListContatos(data, variaveis)
    }
    function handlePrevNext(type) {
        if (type === 69 && activeData > 0) {
            setActiveData(activeData - 1)
            return
        }
        if (activeData < data.length) {
            setActiveData(activeData + 1)
        }
    }
    // Função para fechar o modal
    const hideModal = () => {
        setData([])
        setVariaveis([])
        setActiveData(0)
        dismiss()
    };
    const handleIconClick = () => {
        inputRef.current.click();
    };

    return (
        <Modal open={visible} onClose={hideModal} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} slotProps={{
            backdrop: {
                sx: {
                    backgroundColor: theme.palette.mode === 'dark' ? 'rgba(250,255,255, 0.2)' : 'rgba(0,0,0, 0.4)'
                }
            }
        }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, height: '90%', width: '95%', maxWidth: 1200, background: theme.palette.background.default, outline: 'none' }}>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: 5, paddingBottom: 0 }}>
                    <Box sx={{ display: 'flex', gap: 3, alignItems: 'center' }}>
                        {getIcon(53, theme.palette.primary.main, 30)}
                        <Box>
                            <Typography sx={{ color: theme.palette.text.primary, fontWeight: 'bold', fontSize: 22 }}>Adicione o arquivo para prosseguir</Typography>
                            <Typography sx={{ color: theme.palette.text.primary }}>Ao selecionar um arquivo, será mostrado o preview da mensagem com as variáveis.</Typography>
                        </Box>
                    </Box>
                    <input ref={inputRef} type="file" accept=".csv" onChange={handleFileChange} style={{ display: 'none' }} />
                    <UpComp tamanho={50}>
                        <IconButton onClick={handleIconClick}>
                            {getIcon(68, theme.palette.primary.main, 45)}
                        </IconButton>
                    </UpComp>
                </Box>
                <Box sx={{ borderBottom: 1, borderColor: theme.palette.divider, marginBottom: 2, marginLeft: 5, marginRight: 5 }}>
                    {['Pré-visualizar', 'Variáveis'].map((e, idx) => (
                        <Button
                            key={idx}
                            sx={{
                                borderBottom: activeType === idx ? 2 : 0,
                                borderBottomColor: activeType === idx ? theme.palette.primary.main : 'transparent',
                                borderRadius: 0,
                                textTransform: 'none',
                                color: activeType === idx ? theme.palette.primary.main : theme.palette.text.primary,
                                fontWeight: activeType === idx ? 'bold' : 'normal',
                                padding: '8px 16px',
                                backgroundColor: 'transparent',
                                '&:hover': {
                                    backgroundColor: 'transparent'
                                }
                            }}
                            onClick={() => setActiveType(idx)}
                        >
                            {e}
                        </Button>
                    ))}
                </Box>
                <Box sx={{ padding: 5, paddingTop: 1, gap: 3, height: '70%' }}>
                    {activeType === 1 &&
                        <Box sx={{ display: 'flex', gap: 2, flexDirection: 'column', height: '100%', width: '100%' }}>
                            <Box>
                                <Typography sx={{ fontWeight: 'bold', color: theme.palette.text.primary, fontSize: 22 }}>Variáveis</Typography>
                                <Typography sx={{ color: theme.palette.text.primary }}>Use [[variavel]] na mensagem para o funcionamento correto.</Typography>
                            </Box>
                            <Divider />
                            <Box>
                                <Box sx={{ display: 'flex', justifyContent: 'flex-start', flexWrap: 'wrap', gap: 2, height: '100%', overflowY: 'scroll' }}>
                                    {variaveis.map((e) => {
                                        console.log(e)
                                        return (
                                            <Box>
                                                <Typography sx={{ color: theme.palette.text.primary, fontWeight: 'bold' }}>{e}</Typography>
                                            </Box>
                                        )
                                    })}
                                </Box>
                            </Box>
                        </Box>
                    }
                    {activeType === 0 &&
                        <Box sx={{ display: 'flex', gap: 2, flexDirection: 'column', height: '100%', maxWidth: '100%' }}>
                            <Box>
                                <Typography sx={{ fontWeight: 'bold', color: theme.palette.text.primary, fontSize: 22 }}>Pré-visualização</Typography>
                                <Typography sx={{ color: theme.palette.text.primary }}>Use os botões de navegação para trocar a visualização dos dados.</Typography>
                            </Box>
                            <Divider />
                            <Box sx={{ display: 'flex', gap: 1, }}>
                                {[69, 70].map((e) => (
                                    <IconButton onClick={() => handlePrevNext(e)}>
                                        {getIcon(e)}
                                    </IconButton>
                                ))}
                            </Box>
                            <Box sx={{ height: '100%', overflowY: 'scroll' }}>
                                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', width: '100%', }}>
                                    {mensagem.tipo === 4 ? <div dangerouslySetInnerHTML={{ __html: replaceMensagemVariaveis(mensagem.mensagem, variaveis, data[activeData]) }} /> : ![1, 4].includes(mensagem.tipo) ? mensagem.mensagem.map((e, index) => {
                                        return (
                                            <WhatsAppMessage key={index} {...e} variaveis={variaveis} data={data[activeData]} width={'95%'} />
                                        );
                                    }) : <WhatsAppMessage mensagem={mensagem.mensagem} tipo={1} variaveis={variaveis} data={data[activeData]} width={'95%'} />}
                                </Box>
                            </Box>
                        </Box>
                    }
                </Box>
                <Box sx={{ display: 'flex', padding: 2, paddingTop: 0, paddingBottom: 2, gap: 2 }}>
                    {['Salvar', 'Voltar',].map((e, idx) => {
                        return (
                            <ButtonSubmit key={e} text={e} background={idx === 1 ? theme.palette.background.paper : idx === 2 ? theme.palette.secondary.main : theme.palette.primary.main} color={idx === 1 ? theme.palette.text.primary : theme.palette.text.onPrimary} func={handleSubmit} funcParameters={e} />
                        )
                    })}
                </Box>
                <Box>
                    <ModalInfo
                        hideModal={() => updateModalInfo(false)}
                        canDesactive={modalInfo.canDesactive}
                        visible={modalInfo.visible}
                        title={modalInfo.title}
                        subtitle={modalInfo.subtitle}
                        icon={modalInfo.icon}
                        funcS={modalInfo.funcS}
                    />
                </Box>
            </Box>
        </Modal >
    );
}
