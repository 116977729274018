import { Drawer, useTheme, Box, Divider, Typography, ListItemButton, Collapse, Tooltip, Popover } from "@mui/material";
import Logo from '../../inc/images/flaticon.png'
import { useContext, useState } from "react";
import { User } from "../../contexts/user";
import { getIcon } from "../icons";
import { Link, useLocation } from "react-router-dom";
import { removerCaracteresEspeciais } from "../masks";
import { alpha } from '@mui/material/styles';

const drawerWidth = 60;

export function DrawerOff() {
    const theme = useTheme();
    const user = useContext(User);
    const [colAtive, setColAtive] = useState(null);
    const [hover, setHover] = useState(null);
    const [anchorEl, setAnchorEl] = useState(null);
    const [currentModule, setCurrentModule] = useState(null); // Estado para armazenar o módulo atualmente aberto
    const location = useLocation();

    function handleCollAtive(col) {
        setColAtive(col === colAtive ? null : col);
    }

    function handlePopoverOpen(event, idx) {
        setHover(idx);
        setAnchorEl(event.currentTarget);
        setCurrentModule(idx); // Atualiza o estado com o índice do módulo atual
    }

    function handlePopoverClose() {
        setHover(null);
        setAnchorEl(null);
    }
    const activeColor = alpha('#fff', 0.6);
    return (
        <Drawer
            sx={{
                width: drawerWidth,
                flexShrink: 0,
                '& .MuiDrawer-paper': {
                    width: drawerWidth,
                    boxSizing: 'border-box',
                    backgroundColor: '#2A2F42',
                },
            }}
            variant='permanent'
            anchor="left"
        >
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 3 }}>
                <img src={Logo} style={{ objectFit: 'contain', maxWidth: 30 }} />
            </Box>
            <Box>
                {user?.data?.permissoes.map((e, idx) => {
                    const isActive = location.pathname.includes(removerCaracteresEspeciais(e.moduloEndp).toLowerCase());
                    const color = isActive ? '#fff' : activeColor;
                    const iconColor = isActive ? '#fff' : activeColor
                    const isInside = e?.inside ? true : false;
                    return (
                        <Box
                            key={idx}
                            onMouseEnter={(event) => isInside && handlePopoverOpen(event, idx)}
                            onMouseLeave={(event) => isInside && handlePopoverClose()}
                        >
                            <ListItemButton
                                component={isInside ? null : 'div'}  // Tornando o módulo principal não clicável
                                sx={{
                                    width: '100%',
                                    zIndex: 10,
                                    padding: 1,
                                    color: color,
                                    '&:hover': {
                                        color: '#fff'
                                    }
                                }}
                            >
                                <Box sx={{ display: 'flex', flex: 1, alignItems: 'center', padding: 1, justifyContent: 'space-between' }}>
                                    <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                                        {getIcon(e.moduloIcon, iconColor)}
                                    </Box>
                                </Box>
                            </ListItemButton>
                            {isInside && (
                                <Popover
                                    open={hover === idx}
                                    anchorEl={anchorEl}
                                    onClose={handlePopoverClose}
                                    anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'left',
                                    }}
                                    PaperProps={{
                                        onMouseEnter: () => setHover(idx),
                                        onMouseLeave: handlePopoverClose,
                                        sx: { zIndex: 1 }
                                    }}
                                    sx={{ zIndex: 2 }}
                                >
                                    <Box sx={{ backgroundColor: '#2A2F42', padding: 1 }}>
                                        <Typography sx={{ color: color, padding: '8px 16px' }}>
                                            {e.moduloNome}
                                        </Typography>
                                        {e?.inside.map((i) => {
                                            const isActive = location.pathname.includes(removerCaracteresEspeciais(i.moduloEndp).toLowerCase());
                                            const iconColor = isActive ? '#fff' : activeColor
                                            const color = isActive ? '#fff' : activeColor;
                                            return (
                                                <Tooltip title={i.moduloDesc} placement='right-end' key={i.moduloID}>
                                                    <ListItemButton component={Link} to={i?.moduloEndp} sx={{
                                                        color: color, '&:hover': {
                                                            color: '#fff'
                                                        }
                                                    }}>
                                                        <Box sx={{ display: 'flex', flex: 1, alignItems: 'center', padding: 1, justifyContent: 'space-between' }}>
                                                            <Box sx={{ display: 'flex', gap: 2, alignItems: 'center', justifyContent: 'center', paddingLeft: 2 }}>
                                                                {getIcon(i.moduloIcon, iconColor)}
                                                                <Typography>{i.moduloNome}</Typography>
                                                            </Box>
                                                        </Box>
                                                    </ListItemButton>
                                                </Tooltip>
                                            )
                                        })}

                                    </Box>
                                </Popover>
                            )}
                        </Box>
                    )
                })}
            </Box>
        </Drawer>
    )
}
