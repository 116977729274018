import React, { useContext, useEffect, useRef, useState } from 'react';
import grapesjs from 'grapesjs';
import 'grapesjs/dist/css/grapes.min.css';
import pt from 'grapesjs/locale/pt';
import plugin from 'grapesjs-preset-newsletter';
import { Box, Typography, Divider, useTheme, Button } from '@mui/material';
import { getIcon } from '../../../../../../components/icons';
import { CTextField } from '../../../../../../components/TextField';
import MAPI from '../../../../../../api/mainApi';
import { User } from '../../../../../../contexts/user';

export function EmailBuilder({ model, handleDismiss, updateModalInfo, pushMessages, editMessages, nome }) {
    const user = useContext(User)
    const editorRef = useRef(null);
    const editorInstance = useRef(null)
    const theme = useTheme();
    const [id, setId] = useState(model.id)

    useEffect(() => {
        const editor = grapesjs.init({
            container: editorRef.current,
            plugins: [plugin],
            storageManager: false,
            i18n: {
                locale: 'pt',
            },
            styleManager: {
                sectors: [{
                    name: 'Body',
                    open: false,
                    buildProps: ['background-color', 'color'],
                    properties: [{
                        property: 'background-color',
                        name: 'Background Color'
                    }, {
                        property: 'color',
                        name: 'Text Color'
                    }]
                }],
            },
        });

        // Se o model existir, definir o HTML do editor
        const html = model.mensagem ? model.mensagem : model.html ? model.html : null
        console.log(html)
        if (html) {
            setId(model.id ? model.id : null)
            editor.setComponents(html);
        }

        const translations = {
            "1 Section": "1 Seção",
            "1/2 Section": "1/2 Seção",
            "1/3 Section": "1/3 Seção",
            "3/7 Section": "3/7 Seção",
            "Button": "Botão",
            "Divider": "Divisor",
            "Text": "Texto",
            "Text Section": "Seção de Texto",
            "Image": "Imagem",
            "Quote": "Citação",
            "Link": "Link",
            "Link Block": "Bloco de Link",
            "Grid Items": "Itens de Grade",
            "List Items": "Itens de Lista",
            "Lista": 'Lista'
        };

        editor.on('load', () => {
            editor.Panels.getButton('views', 'open-blocks').set('active', true);
            const blockManager = editor.BlockManager;
            const blocks = blockManager.getAll();

            blocks.forEach(block => {
                const label = block.get('label');
                const translatedLabel = translations[label];
                block.set('label', translatedLabel);
            });
        });

        editor.I18n.addMessages({ 'pt': pt });

        // Adicionando botão para editar HTML diretamente
        editor.Panels.addButton('options', [{
            id: 'edit-html',
            className: 'fa fa-cube',
            command: 'open-code',
            attributes: { title: 'Inserir HTML' }
        }]);

        editor.BlockManager.add('list', {
            label: 'Lista',
            content: '<ul style="padding: 10px 20px;"><li>Item 1</li><li>Item 2</li><li>Item 3</li></ul>',
            media: `<svg width="75" height="75" viewBox="0 0 24 24">
                        <path fill="currentColor" d="M3 13h2v-2H3v2zm0 4h2v-2H3v2zm0-8h2V7H3v2zm4 0h14V7H7v2zm0 4h14v-2H7v2zm0 4h14v-2H7v2z"/>
                    </svg>`
        });

        editor.Commands.add('open-code', {
            run(editor, sender) {
                sender && sender.set('active', 0);
                const modal = editor.Modal;
                const container = document.createElement('div');
                const textarea = document.createElement('textarea');
                textarea.style.width = '100%';
                textarea.style.height = '300px';
                container.appendChild(textarea);
                modal.setTitle('Editar HTML').setContent(container).open();

                const htmlContent = editor.getHtml();
                textarea.value = htmlContent;

                const btn = document.createElement('button');
                btn.innerHTML = 'Salvar';
                btn.onclick = () => {
                    editor.setComponents(textarea.value.trim());
                    modal.close();
                };
                container.appendChild(btn);
            }
        });
        editorInstance.current = editor;

        return () => {
            editor.destroy();
        };
    }, [model]);

    async function save() {
        if (nome.length === 0) {
            updateModalInfo(true, true, 'Atenção', 'Nome precisa ser preenchido.', 'exclamation')
            return
        }
        const html = editorInstance.current.runCommand('gjs-get-inlined-html')
        const body = {
            id: id,
            tipo: {
                id: 4,
            },
            nome: nome,
            mensagem: html
        }
        updateModalInfo(true, false, 'Salvando', 'Estamos salvando seu e-mail', 'loading')
        const endpoint = id ? 'editMessage' : 'saveMessage'
        const method = id ? 'PUT' : 'POST'
        const save = await MAPI(`disparador/${endpoint}`, method, body, user?.data?.token)
        if (save?.status !== 200) {
            const message = save?.apiReturn?.message ? save?.apiReturn?.message : 'Ocorreu um erro ao salvar o e-mail'
            updateModalInfo(true, true, 'Atenção', message, 'exclamation')
            return
        }
        updateModalInfo(true, true, 'Sucesso', 'Seu e-mail foi salvo.', 'sucess')
        setId(id ? id : save?.apiReturn?.apiReturn?.id)
        const whatFunc = id ? editMessages : pushMessages
        whatFunc(save?.apiReturn?.apiReturn)
    }
    function dismiss(value) {
        if (!value) {
            updateModalInfo(true, true, 'Atenção', 'Você tem certeza que deseja sair da tela?', 'question', () => dismiss(true))
            return
        }
        setId(null)
        updateModalInfo(false)
        handleDismiss()
    }
    return (
        <Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                <Box sx={{ display: 'flex', gap: 3, padding: 2 }}>
                    {getIcon(71)}
                    <Typography sx={{ color: theme.palette.text.primary, fontWeight: 'bold' }}>Gerador de e-mails.</Typography>
                </Box>
                <Box sx={{ display: 'flex', gap: 3, padding: 2 }}>
                    <Button onClick={save} sx={{ background: theme.palette.primary.main, color: theme.palette.text.onPrimary, textTransform: 'none' }}>Salvar</Button>
                    <Button onClick={() => dismiss()} sx={{ background: theme.palette.background.paper, color: theme.palette.text.primary, textTransform: 'none' }}>Voltar</Button>
                </Box>
            </Box>
            <Divider />
            <Box sx={{ display: 'flex', gap: 2, height: '100%', width: '100%' }}>
                <Box sx={{ width: '100%' }}>
                    <div ref={editorRef} style={{ maxHeight: 800 }} />
                </Box>
            </Box>
        </Box>
    );
}
