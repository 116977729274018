

import React, { useState, useContext, useEffect } from 'react';
import { useTheme, Box, Typography, Button, Badge, createTheme, ThemeProvider } from '@mui/material';
import { ModalInfo } from '../../../../../components/modalInfo';
import { User } from '../../../../../contexts/user';
import { getIcon } from '../../../../../components/icons';
import { UpComp } from '../../../../../components/upComp';
import { ListContatos } from './contatos/listContatos';
import MAPI from '../../../../../api/mainApi';
import { Tags } from './contatos/tags';
import { Arquivo } from './contatos/arquivo';

export function Contatos({ slideAtivo, data, updateData, mensagem }) {
    const theme = useTheme();
    const user = useContext(User);
    const customTheme = createTheme({
        palette: {
            primary: { main: theme.palette.primary.main },
        },
    });
    //estados
    const [MInfo, setMInfo] = useState({
        visible: false,
        title: '',
        subtitle: '',
        canDesactive: true,
        icon: '',
        funcS: () => null
    });
    const [contatos, setContatos] = useState({
        visible: false,
        data: [],
        variaveis: []
    })
    const [arquivoVisible, setArquivoVisible] = useState(false)
    const [tagVisible, setTagVisible] = useState(false)
    const [selectType, setSelectType] = useState(null)

    function updateModalInfo(visible, canDesactive, title, subtitle, icon, func) {
        const newMInfo = { ...MInfo };
        newMInfo.visible = visible;
        newMInfo.canDesactive = canDesactive;
        newMInfo.title = title;
        newMInfo.subtitle = subtitle;
        newMInfo.icon = icon;
        newMInfo.funcS = func;
        setMInfo(newMInfo);
    }

    function updateVariaveis(variaveis) {
        const newC = { ...contatos }
        newC.variaveis = variaveis
        console.log(newC)
        setContatos(newC)
    }
    function updateContatos(visible, listContatos, variaveis) {
        const newC = { ...contatos }
        newC.visible = visible ? visible : false
        newC.data = listContatos && Array.isArray(listContatos) ? listContatos : []
        newC.variaveis = variaveis ? variaveis : null
        setContatos(newC)
    }
    async function getContatosCon() {
        updateModalInfo(true, false, 'Carregando', 'Buscando contatos.', 'loading')
        const get = await MAPI(`conexoes/listContatos/${data.con.id}`, 'GET', null, user?.data?.token)
        if (get?.status !== 200) {
            const message = get?.apiReturn?.message ? get?.apiReturn?.message : 'Ocorreu um erro ao buscar os contatos.'
            updateModalInfo(true, true, 'Atenção', message, 'exclamation')
            return
        }
        const apiContatos = get?.apiReturn?.apiReturn
        if (!Array.isArray(apiContatos) || apiContatos?.length === 0) {
            updateModalInfo(true, true, 'Atenção', 'Nenhum contato encontrado.', 'exclamation')
            return
        }
        updateModalInfo(false)
        updateContatos(true, apiContatos)
    }
    async function getMeusContatos() {
        updateModalInfo(true, false, 'Carregando', 'Buscando contatos.', 'loading')
        const get = await MAPI(`meusContatos/list/conTipo=${data.con.tipo_id}`, 'GET', null, user?.data?.token)
        if (get?.status !== 200) {
            const message = get?.apiReturn?.message ? get?.apiReturn?.message : 'Ocorreu um erro ao buscar os contatos.'
            updateModalInfo(true, true, 'Atenção', message, 'exclamation')
            return
        }
        const apiContatos = get?.apiReturn?.apiReturn
        if (!Array.isArray(apiContatos) || apiContatos?.length === 0) {
            updateModalInfo(true, true, 'Atenção', 'Nenhum contato encontrado.', 'exclamation')
            return
        }
        updateModalInfo(false)
        updateContatos(true, apiContatos)
    }

    function tagToListContatos(contatos, variaveis) {
        setTagVisible(false)
        updateContatos(true, contatos, variaveis)
    }

    function saveContatos(selectedContatos) {
        const value = {
            type: selectType,
            data: selectedContatos
        }
        if (contatos.variaveis) {
            value.variaveis = contatos.variaveis
        }
        updateContatos()
        updateData(value, 'contatos')
    }
    function handleSubmit(type) {
        if (type === 'Conexão') {
            setSelectType(type)
            getContatosCon()
        }
        if (type === 'Meus Contatos') {
            setSelectType(type)
            getMeusContatos()
        }
        if (type === 'Tags') {
            setSelectType(type)
            setTagVisible(true)
        }
        if (type === 'Arquivo') {
            setSelectType(type)
            setArquivoVisible(true)
        }
        return
    }
    useEffect(() => {
        console.log(contatos)
    }, [contatos])
    return (
        <Box sx={{
            flex: 1
        }}>
            <Box sx={{ overflowY: 'scroll', maxHeight: 400, padding: 2, paddingBottom: 0 }}>
                {lista.map((e, index) => {
                    console.log(mensagem.tipo)
                    if (mensagem.tipo === 4 && e.icon === 1) return
                    const isSelected = data?.contatos.type === e.nome
                    return (
                        <Button sx={{ width: '100%' }} onClick={() => handleSubmit(e.nome)}>
                            <ThemeProvider theme={customTheme}>
                                <Badge sx={{ width: '100%' }} badgeContent={isSelected ? <Box sx={{ background: theme.palette.secondary.main, borderRadius: 5 }}>{getIcon(59, theme.palette.text.onPrimary)}</Box> : null} anchorOrigin={{ vertical: 'top', horizontal: 'left' }}>
                                    <Box key={index} sx={{ width: '100%', display: 'flex', flexDirection: 'column', gap: 2 }}>
                                        <Box sx={{ display: 'flex', background: theme.palette.background.default, boxShadow: theme.palette.mode === 'dark' ? '0px 4px 10px rgba(155, 155, 155, 0.3)' : '0px 4px 10px rgba(0, 0, 0, 0.1)', padding: 3, alignItems: 'center', gap: 3 }}>
                                            <UpComp>
                                                {
                                                    getIcon(e.icon, theme.palette.primary.main, 25)
                                                }
                                            </UpComp>
                                            <Box>
                                                <Typography sx={{ color: theme.palette.text.primary, textTransform: 'none', fontWeight: 'bold', textAlign: 'left' }}>{e.nome}</Typography>
                                                <Typography sx={{ color: theme.palette.text.primary, textTransform: 'none', textAlign: 'left' }}>{e.descricao}</Typography>
                                            </Box>
                                            {isSelected ?
                                                <Box>
                                                    <Typography sx={{ color: theme.palette.text.primary, textTransform: 'none', fontWeight: 'bold', textAlign: 'left' }}>Contatos</Typography>
                                                    <Typography sx={{ color: theme.palette.text.primary, textTransform: 'none', fontWeight: 'bold', textAlign: 'center' }}>{data.contatos.data.length}</Typography>
                                                </Box>
                                                : null}
                                        </Box>
                                    </Box>
                                </Badge>
                            </ThemeProvider>
                        </Button>
                    )
                })}
            </Box>
            <Arquivo conTipo={data?.con?.tipo_id} visible={arquivoVisible} dismiss={() => setArquivoVisible(false)} mensagem={mensagem} tagToListContatos={tagToListContatos} updateVariaveis={updateVariaveis} />
            <Tags visible={tagVisible} con={data?.con?.id} dismiss={() => setTagVisible(false)} tagToListContatos={tagToListContatos} />
            <ListContatos contatos={contatos.data} visible={contatos.visible} updateContatos={updateContatos} saveContatos={saveContatos} />
            <ModalInfo hideModal={() => updateModalInfo(false)} canDesactive={MInfo.canDesactive} visible={MInfo.visible} title={MInfo.title} subtitle={MInfo.subtitle} icon={MInfo.icon} funcS={MInfo.funcS} />
        </Box >
    );
}

const lista = [
    {
        nome: 'Conexão',
        descricao: 'Envie para os contatos da sua conexão.',
        icon: 1
    },
    {
        nome: 'Meus Contatos',
        descricao: 'Envie para os seus contatos salvos.',
        icon: 39
    },
    {
        nome: 'Tags',
        descricao: 'Envie para contatos por tags.',
        icon: 52
    },
    {
        nome: 'Arquivo',
        descricao: 'Suba um arquivo csv com os contatos para serem enviados.',
        icon: 60
    },
]