import React, { useState, useContext, useEffect } from 'react';
import { useTheme, Box, Modal, Typography, Button, Dialog, DialogTitle, DialogContent } from '@mui/material';
import { ModalInfo } from '../../../../../components/modalInfo';
import { User } from '../../../../../contexts/user';
import { getIcon } from '../../../../../components/icons';
import MAPI from '../../../../../api/mainApi';
import EmptyModel from '../../../../../inc/images/emptyEmail.png';
import { ButtonSubmit } from '../../../../../components/buttons';

export function ChoseModel({ visible, dismiss, updateNewEmail }) {
    const theme = useTheme();
    const user = useContext(User);
    // estados
    const [MInfo, setMInfo] = useState({
        visible: false,
        title: '',
        subtitle: '',
        canDesactive: true,
        icon: '',
        funcS: () => null
    });
    const [templates, setTemplates] = useState([{
        id: 0,
        nome: 'Em branco',
        imagem: EmptyModel
    }]);
    const [meusTemplates, setMeusTemplates] = useState([])
    const [activeType, setActiveType] = useState(0)
    const whatModel = activeType === 0 ? templates : meusTemplates
    // Novo estado para o diálogo de pré-visualização
    const [previewDialog, setPreviewDialog] = useState({
        open: false,
        htmlContent: ''
    });

    function updateModalInfo(visible, canDesactive, title, subtitle, icon, func) {
        const newMInfo = { ...MInfo };
        newMInfo.visible = visible;
        newMInfo.canDesactive = canDesactive;
        newMInfo.title = title;
        newMInfo.subtitle = subtitle;
        newMInfo.icon = icon;
        newMInfo.funcS = func;
        setMInfo(newMInfo);
    }

    function handleDismiss() {
        dismiss()
    }

    async function getTemplates() {
        if (templates.length > 1) return;
        updateModalInfo(true, false, 'Carregando', 'Buscando modelos.', 'loading');
        const get = await MAPI('email/getTemplates', 'GET', null, user?.data?.token);
        console.log(get);
        updateModalInfo(false);
        if (get?.status === 200 && get?.apiReturn?.apiReturn.length > 0) {
            setTemplates(prevTemplates => [...prevTemplates, ...get?.apiReturn?.apiReturn]);
        }
    }

    function handleButton(model, idx) {
        if (idx === 0) {
            handlePreview(model.html)
            return
        }
        const newM = {
            html: model.html
        }
        updateNewEmail(true, newM)
        handleDismiss()
    }

    useEffect(() => {
        getTemplates();
    }, []);

    const handlePreview = (htmlContent) => {
        setPreviewDialog({ open: true, htmlContent });
    };

    const closePreviewDialog = () => {
        setPreviewDialog({ open: false, htmlContent: '' });
    };

    return (
        <Modal open={visible} onClose={handleDismiss} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} slotProps={{
            backdrop: {
                sx: {
                    backgroundColor: theme.palette.mode === 'dark' ? 'rgba(250,255,255, 0.2)' : 'rgba(0,0,0, 0.4)'
                }
            }
        }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', height: '95%', width: '80%', maxHeight: 800, maxWidth: 800, background: theme.palette.background.default, outline: 'none', overflow: 'hidden', padding: 5, gap: 3, justifyContent: 'space-between' }}>
                <Box>
                    <Box sx={{ display: 'flex', gap: 3, alignItems: 'center', marginBottom: 5 }}>
                        {getIcon(22, theme.palette.text.primary, 22)}
                        <Box>
                            <Typography sx={{ color: theme.palette.text.primary, fontWeight: 'bold', fontSize: 22 }}>Modelos de e-mails</Typography>
                            <Typography sx={{ color: theme.palette.text.primary }}>Escolha um modelo para prosseguir para a criação.</Typography>
                        </Box>
                    </Box>
                    <Box sx={{ borderBottom: 1, borderColor: theme.palette.divider, marginBottom: 2 }}>
                        {['Básicos', 'Meus modelos'].map((e, idx) => (
                            <Button
                                key={idx}
                                sx={{
                                    borderBottom: activeType === idx ? 2 : 0,
                                    borderBottomColor: activeType === idx ? theme.palette.primary.main : 'transparent',
                                    borderRadius: 0,
                                    textTransform: 'none',
                                    color: activeType === idx ? theme.palette.primary.main : theme.palette.text.primary,
                                    fontWeight: activeType === idx ? 'bold' : 'normal',
                                    padding: '8px 16px',
                                    backgroundColor: 'transparent',
                                    '&:hover': {
                                        backgroundColor: 'transparent'
                                    }
                                }}
                                onClick={() => setActiveType(idx)}
                            >
                                {e}
                            </Button>
                        ))}
                    </Box>
                    <Box sx={{ display: 'flex', gap: 3, flexWrap: 'wrap', overflowY: 'scroll' }}>
                        {whatModel.length > 0 ? whatModel.map((e) => (
                            <Box key={e.id} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2, padding: 2, border: 1, borderColor: theme.palette.divider }}>
                                <img src={e.imagem} alt={e.nome} style={{ width: 100 }} />
                                <Typography sx={{ color: theme.palette.text.primary, textAlign: 'center' }}>{e.nome}</Typography>
                                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                                    {['Pré-Visualizar', 'Escolher'].map((b, idx) => {
                                        const background = idx === 1 ? theme.palette.primary.main : theme.palette.background.paper
                                        const color = idx === 1 && theme.palette.text.onPrimary
                                        return (
                                            <Button disabled={e?.id === 0 && idx === 0} sx={{ background: background, textTransform: 'none', color: color }} onClick={() => handleButton(e, idx)}>{b}</Button>
                                        )
                                    })}
                                </Box>
                            </Box>
                        )) :
                            <Box sx={{ padding: 3, background: theme.palette.secondary.main, width: '100%' }}>
                                <Typography sx={{ color: theme.palette.text.onPrimary, textAlign: 'center' }}>Você ainda não tem modelos salvos</Typography>
                            </Box>
                        }
                    </Box>
                </Box>
                <Box>
                    <ButtonSubmit background={theme.palette.background.paper} color={theme.palette.text.primary} text={'Voltar'} func={() => handleDismiss()} />
                </Box>
                <ModalInfo hideModal={() => updateModalInfo(false)} canDesactive={MInfo.canDesactive} visible={MInfo.visible} title={MInfo.title} subtitle={MInfo.subtitle} icon={MInfo.icon} funcS={MInfo.funcS} />
                <Dialog open={previewDialog.open} onClose={closePreviewDialog} fullWidth maxWidth="lg">
                    <DialogTitle>Pré-Visualização</DialogTitle>
                    <DialogContent>
                        <div dangerouslySetInnerHTML={{ __html: previewDialog.htmlContent }} />
                    </DialogContent>
                </Dialog>
            </Box>
        </Modal>
    );
}
