import { useContext, useRef, useState } from "react";
import { CTextField } from "./TextField";
import { Modal, Box, useTheme, Button, Typography } from "@mui/material";
import { ButtonSubmit } from "./buttons";
import { getIcon } from "./icons";
import { ModalInfo } from "./modalInfo";
import { TestaEMAIL } from "./validaCampo";
import MAPI from "../api/mainApi";
import { User } from "../contexts/user";



export function ModalAuth({ visible, funcReturn, hideModal }) {
    const theme = useTheme()
    const user = useContext(User)
    const credInit = {
        email: '',
        senha: ''
    }
    const [credenciais, setCredenciais] = useState(credInit)
    const [MInfo, setMInfo] = useState({
        visible: false,
        title: '',
        subtitle: '',
        canDesactive: true,
        icon: ''
    })
    const refs = {
        email: useRef(),
        senha: useRef()
    }
    function onKeyPress(value, type) {
        if (value.key === 'Enter' && type === 'email') {
            refs.senha.current.focus()
        }
        if (value.key === 'Enter' && type === 'senha') {
            refs.senha.current.blur()
            ValidaUser()
        }
    }
    function updateModalInfo(visible, canDesactive, title, subtitle, icon) {
        const newMInfo = { ...MInfo }
        newMInfo.visible = visible
        newMInfo.canDesactive = canDesactive
        newMInfo.title = title
        newMInfo.subtitle = subtitle
        newMInfo.icon = icon
        setMInfo(newMInfo)
    }

    function handleSubmit(type) {
        const types = {
            'Entrar': ValidaUser,
            'Limpar': () => setCredenciais(credInit)
        }
        const func = types[type]
        func()
    }

    async function ValidaUser() {
        if ([credenciais.email, credenciais.senha].includes('')) {
            updateModalInfo(true, true, 'Atenção', 'Todos os campos devem ser preenchidos', 'exclamation')
            return
        }
        if (!TestaEMAIL(credenciais.email)) {
            updateModalInfo(true, true, 'Atenção', 'E-mail preenchido é inválido', 'exclamation')
            return
        }

        const body = {
            email: credenciais.email,
            senha: credenciais.senha
        }
        updateModalInfo(true, false, 'Carregando', 'Validando dados', 'loading')
        const getUser = await MAPI('usuarios/login', 'POST', body, null)
        if (getUser?.status !== 200) {
            const message = 'Não foi possível autenticar os dados.'
            updateModalInfo(true, true, 'Atenção', message, 'exclamation')
            return
        }
        const newUser = getUser?.apiReturn?.apiReturn
        if (user?.data?.token !== newUser?.token) {
            updateModalInfo(true, true, 'Atenção', 'Usuário autenticado é diferente do logado no sistema.', 'exclamation')
            return
        }
        setCredenciais(credInit)
        updateModalInfo(false, false, 'Carregando', 'Validando dados', 'loading')
        funcReturn()
    }

    function handleChangeText(value, type) {
        const newCred = { ...credenciais }
        newCred[type] = value
        setCredenciais(newCred)
    }
    return (
        <Modal open={visible} onClose={hideModal} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} slotProps={{
            backdrop: {
                sx: {
                    backgroundColor: theme.palette.mode === 'dark' ? 'rgba(250,255,255, 0.2)' : 'rgba(0,0,0, 0.4)'
                }
            }
        }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', width: 450, height: 450, background: theme.palette.background.default, outline: 'none', padding: 2.5, borderRadius: 5 }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', gap: 1 }}>
                    {getIcon(29, theme.palette.mode === 'dark' ? theme.palette.text.primary : theme.palette.primary.main, 60)}
                    <Typography sx={{ color: theme.palette.text.primary, fontWeight: 'bold', fontSize: 25, textAlign: 'center' }}>Autenticação</Typography>
                    <Typography sx={{ color: theme.palette.text.primary, fontSize: 18, textAlign: 'center' }}>Autentique seus dados para continuar.</Typography>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                    {['email', 'senha'].map((e) => {
                        const label = e === 'email' ? 'E-mail' : 'Senha'
                        const autoFocus = e === 'email' ? true : false
                        return (
                            <CTextField prevData={credenciais} key={e} type={e} onKeyPress={onKeyPress} ref={refs[e]} autoFocus={autoFocus} label={label} value={credenciais[e]} security={e === 'senha' ? true : false} onChangeText={handleChangeText} />
                        )
                    })}
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, }}>
                    {['Entrar', 'Limpar'].map((e) => {
                        return (
                            <ButtonSubmit func={handleSubmit} funcParameters={e} key={e} text={e} width={'100%'} background={e === 'Limpar' ? theme.palette.background.paper : theme.palette.primary.main} color={e === 'Limpar' ? theme.palette.text.primary : theme.palette.text.onPrimary} />
                        )
                    })}
                </Box>
                <ModalInfo hideModal={() => updateModalInfo(false)} canDesactive={MInfo.canDesactive} visible={MInfo.visible} title={MInfo.title} subtitle={MInfo.subtitle} icon={MInfo.icon} />
            </Box>
        </Modal>
    )
}