
import { Login } from "../views/login/login";
import { Routes, Route, useLocation } from 'react-router-dom'
import { NewUser } from "../views/login/newUser";


export function NotUser() {
    return (
        <Routes>
            <Route path="*" element={<Login />} />
            {/* //<Route path="/nova-conta" element={<NewUser />} /> */}
        </Routes>
    )
}