import React, { useState, useContext, useEffect } from 'react';
import { useTheme, Box, Modal, Typography, Switch, Button, Zoom, Collapse, Checkbox, ThemeProvider, createTheme } from '@mui/material';
import { ModalInfo } from '../../../components/modalInfo';
import { User } from '../../../contexts/user';
import MAPI from '../../../api/mainApi';
import { getIcon } from '../../../components/icons'
import { eventosTransform } from '../../../Mainfunctions';
import { ButtonSubmit } from '../../../components/buttons';
import { RenderModulos } from './modulos/renderModulos';

export function Modulos({ visible, data, operador, dismiss, updateModulosData, updateOperador }) {
    const theme = useTheme();
    const user = useContext(User);
    const customTheme = createTheme({
        palette: {
            primary: { main: theme.palette.primary.main },
        },
    });
    //estados
    const [MInfo, setMInfo] = useState({
        visible: false,
        title: '',
        subtitle: '',
        canDesactive: true,
        icon: '',
        funcS: () => null
    });
    const [colAtive, setColAtive] = useState(null)
    const [colAtiveSub, setcolAtiveSub] = useState(null)
    const [op, setOp] = useState({})

    //funções
    async function getData() {
        const newOperador = { ...operador }
        if (!Array.isArray(data) || data.length === 0) {
            updateModalInfo(true, true, 'Carregando', 'Buscando módulos no servidor.', 'loading')
            const getUserModulos = await MAPI('modulos/usuario', 'GET', null, user?.data?.token)
            if (getUserModulos?.status !== 200) {
                const message = getUserModulos?.apiReturn?.message ? getUserModulos?.apiReturn?.message : 'Ocorreu um erro ao buscar os módulos.'
                updateModalInfo(true, true, 'Atenção', message, 'exclamation')
                updateModulosData(false)
                return
            }
            updateModulosData(getUserModulos?.apiReturn?.apiReturn)
        }
        if (!operador?.modulos) {
            updateModalInfo(true, true, 'Carregando', 'Buscando permissoes do operador.', 'loading')
            const getOpModulos = await MAPI('modulos/operador/' + operador.id, 'GET', null, user?.data?.token)
            if (getOpModulos?.status !== 200) {
                const message = getOpModulos?.apiReturn?.message ? getOpModulos?.apiReturn?.message : 'Ocorreu um erro ao buscar os módulos.'
                updateModalInfo(true, true, 'Atenção', message, 'exclamation')
                return
            }
            newOperador.modulos = getOpModulos?.apiReturn?.apiReturn
            updateOperador(newOperador)
        }
        setOp(newOperador)
        updateModalInfo(false)
    }
    function updateModalInfo(visible, canDesactive, title, subtitle, icon, func) {
        const newMInfo = { ...MInfo };
        newMInfo.visible = visible;
        newMInfo.canDesactive = canDesactive;
        newMInfo.title = title;
        newMInfo.subtitle = subtitle;
        newMInfo.icon = icon;
        newMInfo.funcS = func;
        setMInfo(newMInfo);
    }
    function handleDismiss() {
        setOp({})
        setColAtive(null)
        setcolAtiveSub(null)
        dismiss(false)
    }
    function handleSwith(modulo, isSub) {
        const newOp = { ...op }
        const whatCol = isSub ? setcolAtiveSub : setColAtive
        const newModulos = { ...modulo }
        newOp.modulos = Array.isArray(newOp.modulos) ? newOp.modulos : []
        const findIndex = Array.isArray(newOp?.modulos) ? newOp.modulos.findIndex((e) => e.modulo === newModulos?.id) : -1
        if (findIndex === -1) {
            newModulos.permissoes = newModulos.permissoes.replace(/1/g, '0')
            newOp.modulos.push({
                modulo: newModulos.id,
                permissoes: newModulos.permissoes
            })
        }
        else {
            newOp.modulos = newOp.modulos.filter((e, index) => index !== findIndex)
            whatCol(null)
        }
        setOp(newOp)

    }
    function handleCheck(index, modulo) {
        const newOp = { ...op }
        let findIndex = newOp.modulos.findIndex((e) => e.modulo === modulo.id)
        if (findIndex !== -1) {
            const userPerm = newOp.modulos[findIndex].permissoes
            const status = userPerm.charAt(index) === '0' ? '1' : '0'
            newOp.modulos[findIndex].permissoes = userPerm.substring(0, index) + status + userPerm.substring(index + 1);
            setOp(newOp)
        }

    }
    function handleCollapse(id, userOp, isSub) {
        if (userOp.length === 0) return false
        const whatCol = isSub ? colAtiveSub : colAtive
        const control = whatCol !== null ? whatCol !== id ? id : null : id
        const whatFunc = isSub ? setcolAtiveSub : setColAtive
        whatFunc(control)
    }

    function handleButton(type) {
        if (type === 'Sair') handleDismiss()
        if (type === 'Salvar') Save()
    }

    async function Save() {
        updateModalInfo(true, false, 'Salvando', 'Tentando salvar informações no servidor.', 'loading')
        const body = {
            operador: op.id,
            modulos: op.modulos
        }
        const TRYSave = await MAPI('modulos/op_modulos', 'POST', body, user?.data?.token)
        if (TRYSave.status !== 200) {
            const message = TRYSave?.apiReturn?.message ? TRYSave?.apiReturn.message : 'Ocorreu um erro ao salvar as permissões.'
            updateModalInfo(true, true, 'Atenção', message, 'exclamation')
            return
        }
        const newOperador = { ...operador }
        newOperador.modulos = null
        updateOperador(newOperador)
        updateModalInfo(true, true, 'Sucesso', 'Permissões salvas com sucesso.', 'sucess')
    }

    useEffect(() => {
        if (visible === true) getData()
    }, [visible])

    return (
        <Modal open={visible} onClose={handleDismiss} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 2 }} slotProps={{
            backdrop: {
                sx: {
                    backgroundColor: theme.palette.mode === 'dark' ? 'rgba(250,255,255, 0.2)' : 'rgba(0,0,0, 0.4)'
                }
            }
        }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', height: 680, width: '100%', maxWidth: 800, background: theme.palette.background.default, outline: 'none' }}>
                <Box sx={{ background: theme.palette.background.paper, padding: 5 }}>
                    <Typography sx={{ fontWeight: 'bold', color: theme.palette.text.primary, fontSize: 30 }}>{'Permissões #' + operador?.nome}</Typography>
                    <Typography sx={{ color: theme.palette.text.primary, fontSize: 18 }}>Configure as permissões para o operador.</Typography>
                </Box>
                <Box sx={{
                    display: 'flex', flexDirection: 'column', flex: 1,
                    padding: 5, overflowY: 'scroll', '&::-webkit-scrollbar': {
                        background: theme.palette.background.paper,
                        width: 20
                    },
                    '&::-webkit-scrollbar-thumb': {
                        background: theme.palette.primary.main
                    }
                }}>
                    {data === null ?
                        <Box sx={{ display: 'flex', justifyContent: 'center', padding: 5 }}>
                            {
                                getIcon(25, theme.palette.secondary.main)
                            }
                        </Box>
                        :
                        data.length === 0 || data === false ?
                            <Box sx={{ padding: 2, background: theme.palette.tertiary.main }}>
                                <Typography sx={{ color: theme.palette.text.onPrimary, fontWeight: 'bold', textAlign: 'center' }}>Não foi encontrado módulos.</Typography>
                            </Box>
                            :
                            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
                                <RenderModulos data={data} op={op} level={0} handleSwitch={handleSwith} handleCollapse={handleCollapse} colAtive={colAtive} colAtiveSub={colAtiveSub} handleCheck={handleCheck} />
                            </Box>
                    }
                </Box>
                <Box sx={{ display: 'flex', gap: 2, padding: 5 }}>
                    {['Salvar', 'Sair'].map((e, idx) => {
                        return (
                            <ButtonSubmit key={e} text={e} background={idx === 1 ? theme.palette.background.paper : theme.palette.primary.main} color={idx === 1 ? theme.palette.text.primary : theme.palette.text.onPrimary} func={handleButton} funcParameters={e} />
                        )
                    })}
                </Box>
                <ModalInfo hideModal={() => updateModalInfo(false)} canDesactive={MInfo.canDesactive} visible={MInfo.visible} title={MInfo.title} subtitle={MInfo.subtitle} icon={MInfo.icon} funcS={MInfo.funcS} />
            </Box >
        </Modal >
    );
}