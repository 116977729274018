import React, { useState, useContext } from 'react';
import { useTheme, Box, Typography, Pagination, Collapse, Button, IconButton, Tooltip, Dialog, DialogContent, DialogTitle } from '@mui/material';
import { ModalInfo } from '../../../../components/modalInfo';
import { User } from '../../../../contexts/user';
import { BoxHover } from '../../../../components/boxHover';
import { UpComp } from '../../../../components/upComp';
import { getIcon } from '../../../../components/icons';
import MAPI from '../../../../api/mainApi';
import { WhatsAppMessage } from '../../../../components/whatsappMessage';

export function ListMensagem({ mensagens, updateSMensagem, deleteMensagem, updateEnvMensagem, canExclude }) {
    const theme = useTheme();
    const user = useContext(User);
    const data = Array.isArray(mensagens) ? mensagens : []
    const [activeCol, setActiveCol] = useState(null)
    //estados
    const [MInfo, setMInfo] = useState({
        visible: false,
        title: '',
        subtitle: '',
        canDesactive: true,
        icon: '',
        funcS: () => null
    });
    const [preview, setPreview] = useState({ open: false, data: null });
    const [page, setPage] = useState(1);
    const itemsPerPage = 12;

    function handleActiveCol(col) {
        setActiveCol(activeCol === col ? null : col)
    }
    function updateModalInfo(visible, canDesactive, title, subtitle, icon, func) {
        const newMInfo = { ...MInfo };
        newMInfo.visible = visible;
        newMInfo.canDesactive = canDesactive;
        newMInfo.title = title;
        newMInfo.subtitle = subtitle;
        newMInfo.icon = icon;
        newMInfo.funcS = func;
        setMInfo(newMInfo);
    }

    async function deleteM(mensagem, control) {
        if(!canExclude) {
            updateModalInfo(true, true, 'Atenção', 'Você não tem permissões para excluir disparos.', 'exclamation')
            return
        }
        if (!control) {
            updateModalInfo(true, true, 'Atenção', 'Você tem certeza que deseja excluir essa mensagem?', 'question', () => deleteM(mensagem, true))
            return
        }
        updateModalInfo(true, false, 'Excluindo', 'Estamos excluindo a mensagem do servidor.', 'loading')
        const tryDelete = await MAPI(`disparador/deleteMessage/${mensagem.id}`, 'DELETE', null, user?.data?.token)
        if (tryDelete.status !== 200) {
            const message = tryDelete?.apiReturn?.message ? tryDelete?.apiReturn?.message : 'Ocorreu um erro ao excluir a mensagem.'
            updateModalInfo(true, true, 'Atenção', message, 'exclamation')
        }
        updateModalInfo(false)
        deleteMensagem(mensagem)
    }
    function handlePreview(file) {
        setPreview({ open: true, data: file });
    }
    function handleClosePreview() {
        setPreview({ open: false, file: null });
    }


    function handleButton(type, mensagem) {
        if (type === 'Editar') {
            updateSMensagem(true, mensagem)
        }
        if (type === 'Excluir') {
            deleteM(mensagem)
        }
        if (type === 'Enviar') {
            updateEnvMensagem(true, mensagem)
        }
        if (type === 'Atualizar') {

        }
    }
    const handleChangePage = (event, value) => {
        setPage(value);
    };

    const paginatedMensagens = data.slice((page - 1) * itemsPerPage, page * itemsPerPage);

    return (
        <Box sx={{
            flex: 1,
            marginTop: 2,
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            alignItems: 'center'
        }}>
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 3, width: '100%' }}>
                {paginatedMensagens.map((e, idx) => {
                    return (
                        <BoxHover key={idx}>
                            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-between', height: 200, width: '100%' }}>
                                <Button onClick={() => handlePreview(e)} sx={{ textTransform: 'none', textAlign: 'left', zIndex: 1, width: '100%', height: '100%' }}>
                                    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: 1 }}>
                                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 75, width: 75, borderRadius: 25, boxShadow: theme.palette.mode === 'dark' ? '0px 4px 10px rgba(0, 0, 0, 0.3)' : '0px 4px 10px rgba(0, 0, 0, 0.1)' }}>
                                            {getIcon(e.tipo_icon, theme.palette.text.primary, 50)}
                                        </Box>
                                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'flex-start' }}>
                                            <Typography sx={{ fontWeight: 'bold', color: theme.palette.text.primary }}>{e.nome}</Typography>
                                        </Box>
                                    </Box>
                                </Button>
                                <Box sx={{ display: 'flex', gap: 2, zIndex: 10, alignItems: 'center' }}>
                                    {['Editar', 'Excluir', 'Enviar',].map((b, idx) => {
                                        const icon = idx === 2 ? 57 : idx === 1 ? 28 : 27
                                        const background = 'transparent'
                                        const color = idx % 2 ? theme.palette.tertiary.main : theme.palette.secondary.main
                                        return (
                                            <UpComp key={idx} tamanho={35}>
                                                <Tooltip title={b}>
                                                    <IconButton sx={{ background: background }} onClick={() => handleButton(b, e)}>
                                                        {getIcon(icon, color)}
                                                    </IconButton>
                                                </Tooltip>
                                            </UpComp>
                                        )
                                    })}
                                </Box>
                            </Box>
                        </BoxHover>
                    )
                })}
            </Box>
            <Pagination
                count={Math.ceil(data.length / itemsPerPage)}
                page={page}
                onChange={handleChangePage}
                color="primary"
                sx={{ marginTop: 2 }}
            />
            <Dialog open={preview.open} onClose={handleClosePreview} maxWidth='lg' fullWidth>
                <DialogTitle>Pré-visualização #{preview?.data?.nome}</DialogTitle>
                <DialogContent>
                    <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                        {preview?.data?.tipo === 4 ? <div dangerouslySetInnerHTML={{ __html: preview?.data?.mensagem }} /> : preview?.data?.tipo !== 1 ? preview?.data?.mensagem.map((e, index) => {
                            return (
                                <WhatsAppMessage key={index} {...e} />
                            );
                        }) : <WhatsAppMessage mensagem={preview?.data?.mensagem} tipo={1} />}
                    </Box>
                </DialogContent>
            </Dialog>
            <ModalInfo hideModal={() => updateModalInfo(false)} canDesactive={MInfo.canDesactive} visible={MInfo.visible} title={MInfo.title} subtitle={MInfo.subtitle} icon={MInfo.icon} funcS={MInfo.funcS} />
        </Box >
    );
}