


import React, { useState, useContext, useMemo } from 'react';
import { useTheme, Box, Modal } from '@mui/material';
import { ModalInfo } from '../../../../../components/modalInfo';
import { User } from '../../../../../contexts/user';
import { EmailBuilder } from './createEmail/EmailBuilder';

export function CreateEmail({ visible, updateNewEmail, model, editMessages, pushMessages, nome }) {
    const theme = useTheme();
    const user = useContext(User);
    //estados
    const [MInfo, setMInfo] = useState({
        visible: false,
        title: '',
        subtitle: '',
        canDesactive: true,
        icon: '',
        funcS: () => null
    });

    function updateModalInfo(visible, canDesactive, title, subtitle, icon, func) {
        const newMInfo = { ...MInfo };
        newMInfo.visible = visible;
        newMInfo.canDesactive = canDesactive;
        newMInfo.title = title;
        newMInfo.subtitle = subtitle;
        newMInfo.icon = icon;
        newMInfo.funcS = func;
        setMInfo(newMInfo);
    }
    function handleDismiss() {
        updateNewEmail()
    }

    return (
        useMemo(() => (
            <Modal open={visible} onClose={() => null} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} slotProps={{
                backdrop: {
                    sx: {
                        backgroundColor: theme.palette.mode === 'dark' ? 'rgba(250,255,255, 0.2)' : 'rgba(0,0,0, 0.4)'
                    }
                }
            }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', height: '95%', width: '80%', background: theme.palette.background.default, outline: 'none', overflow: 'hidden' }}>
                    <EmailBuilder nome={nome} editMessages={editMessages} pushMessages={pushMessages} model={model} handleDismiss={handleDismiss} updateModalInfo={updateModalInfo} />
                    <ModalInfo hideModal={() => updateModalInfo(false)} canDesactive={MInfo.canDesactive} visible={MInfo.visible} title={MInfo.title} subtitle={MInfo.subtitle} icon={MInfo.icon} funcS={MInfo.funcS} />
                </Box>
            </Modal>
        ), [visible, model, nome, MInfo])
    );
}