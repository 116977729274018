import { Box, Typography, useTheme } from '@mui/material'
export function IsSmallScreen() {
    const theme = useTheme()
    return (
        <Box sx={{ display: 'flex', flexGrow: 1, height: '100%', width: '100%', background: theme.palette.background.default, justifyContent: 'center', alignItems: 'center' }}>
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh',
                background: theme.palette.background.default,
                padding: theme.spacing(2)
            }}>
                <img src={theme.palette.logo} alt="Logo" style={{ objectFit: 'contain', maxWidth: 100, marginBottom: 30 }} />
                <Typography variant="h5" gutterBottom>Oops! Tamanho de tela não suportado.</Typography>
                <Typography variant="body1" align="center" gutterBottom>
                    Este tamanho de tela ainda não está disponível. <br />
                    Estamos trabalhando para melhorar sua experiência. <br />
                    Por favor, aguarde futuras versões.
                </Typography>
            </Box>
        </Box>
    );
}