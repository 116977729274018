import { useEffect, useMemo, useState } from 'react'
import { useTheme, Box, Typography, Button, Badge, ThemeProvider, createTheme, IconButton, Collapse, Input } from '@mui/material'
import { CMultiTextField, CTextField } from '../../../../components/TextField'
import { Handle, Position, handleParentExpand } from 'reactflow';
import { BoxHover } from '../../../../components/boxHover';
import { CustomHandle } from './customHande';
import { MenuFluxo } from '../../../../components/menu';
import { getIcon } from '../../../../components/icons';
import { onlyInteger, removerCaracteresEspeciais } from '../../../../components/masks';
import MAPI from '../../../../api/mainApi';
function BaseBlock({ children, title, subtitle, data, id, width }) {
    const theme = useTheme()
    const [hovered, setHovered] = useState(false)
    const customTheme = createTheme({
        palette: {
            primary: { main: theme.palette.primary.main },
            secondary: { main: theme.palette.tertiary.main }
        },
        background: theme.palette.mode === 'dark' ? '#141414' : '#FFFFFF'
    });
    return (
        <ThemeProvider theme={customTheme}>
            <Badge onMouseEnter={() => data?.permanent ? null : setHovered(true)} onMouseLeave={() => setHovered(false)} badgeContent={hovered ? <IconButton onClick={() => data.excludeNode(id)} sx={{ padding: 0, height: '100%', textTransform: 'none', color: theme.palette.text.onPrimary, fontSize: 13 }}>x</IconButton> : null} color='secondary'>
                <BoxHover width={width && width} newTheme={theme}>
                    <Box sx={{ padding: 0, background: theme.palette.background.default, height: '100%', width: '100%' }}
                    >
                        <Typography sx={{ color: theme.palette.text.primary, fontWeight: 'bold' }}>{title}</Typography>
                        <Typography sx={{ color: theme.palette.text.primary }}>{subtitle}</Typography>
                        <Box>
                            {children}
                        </Box>
                    </Box>
                </BoxHover>
            </Badge>
        </ThemeProvider >
    )
}
export function Init({ }) {
    const theme = useTheme()
    return (
        <BoxHover>
            <Box sx={{ padding: 0, background: theme.palette.background.default, height: '100%', width: '100%' }}
            >
                <Typography sx={{ color: theme.palette.text.primary, fontWeight: 'bold' }}>Início</Typography>
                <Typography sx={{ color: theme.palette.text.primary }}>Inicie a partir daqui.</Typography>
                <CustomHandle id={'k'} type={'source'} position={'Right'} />
            </Box>
        </BoxHover>
    )
}
export function EnvEmail({ GetEmailModelos, emailModelos, id, data }) {
    const theme = useTheme()
    const selected = data.enviarEmail.selected
    function handleChange(value, type) {
        const filter = emailModelos[type].filter((_, idx) => idx === value)
        console.log(filter)
        data.enviarEmail.function({ ...selected, [type]: filter[0] }, 'selected')
    }
    const titleStyle = {
        color: theme.palette.text.primary,
        fontWeight: 'bold',
        fontSize: 12
    }
    useEffect(() => {
        if (emailModelos === null && id) {
            GetEmailModelos()
        }
    }, [emailModelos, id])
    return (
        <BaseBlock title={'Enviar email'} subtitle={''} data={data} id={id} width={300}>
            <>
                {emailModelos === null ?
                    <Box>{getIcon(25, theme.palette.primary.main, 10)}</Box>
                    : emailModelos === false ?
                        <Box sx={{ padding: 2, background: theme.palette.secondary.main }}><Typography sx={{ color: theme.palette.text.onPrimary }}>Ocorreu um erro ao carregar seus modelos</Typography></Box> :
                        emailModelos.length === 0 ? <Box sx={{ padding: 2, background: theme.palette.secondary.main }}><Typography sx={{ color: theme.palette.text.onPrimary }}>Nenhum modelo encontrado</Typography></Box> :
                            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3, marginTop: 3, }}>
                                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                                    <Typography sx={titleStyle}>Escolha uma conexão</Typography>
                                    <MenuFluxo newTheme={theme} title={'conexoes'} disabled={false} options={emailModelos.conexoes} param={'nome'} handleChange={handleChange} selected={selected?.conexoes} />
                                </Box>
                                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                                    <Typography sx={titleStyle}>Escolha seu e-mail</Typography>
                                    <MenuFluxo newTheme={theme} title={'modelos'} disabled={false} options={emailModelos.modelos} param={'nome'} handleChange={handleChange} selected={selected?.modelos} />
                                </Box>
                            </Box>
                }
                <CustomHandle id={'k'} type={'source'} position={'Right'} />
                <CustomHandle id={'k'} type={'target'} position={'Left'} />
            </>
        </BaseBlock >
    )
}
export function EnvMessage({ id, data, createYesNoNodes, excludeTargetNodes, createOptions, referencias, updateReferencias, userToken, updateModalInfo }) {
    const theme = useTheme()
    const thisData = data.enviarMensagem
    const selected = thisData.selected
    function handleChange(value, type) {
        const filter = options.filter((_, idx) => idx === value)
        thisData.function(filter[0], 'selected')
    }
    function handleMessage(value) {
        thisData.function(value, 'mensagem')
    }
    function handleChangeOptionsID2(value) {
        const filter = options[1].options.filter((_, idx) => idx == value)
        if (filter[0] === selected?.selected) return
        thisData.function({ ...selected, selected: filter[0] }, 'selected')
        if (filter[0].id === 6) {
            createYesNoNodes(id);
        }
        if (filter[0].id === 7) {
            createOptions(id, filter[0].quantidade)
        }
        else {
            excludeTargetNodes(id)
        }
    }
    function handleChangeRefID2(value) {
        const filter = selected.selected
        filter.referencia = removerCaracteresEspeciais(value).replace(' ', '')
        thisData.function({ ...selected, selected: filter }, 'selected')
    }
    function handleChangeRefID2(value) {
        const filter = selected
        filter.referencia = removerCaracteresEspeciais(value).replace(' ', '')
        thisData.function({ ...selected, filter }, 'selected')
    }
    function handleQuantidade(value) {
        const filter = selected.selected
        filter.quantidade = onlyInteger(value)
        if (filter.quantidade > 10) return
        thisData.function({ ...selected, selected: filter }, 'selected')
    }
    function handleBlurQuantidade() {
        const quantidade = selected?.selected?.quantidade && selected?.selected?.quantidade > 0 ? selected?.selected?.quantidade : 1
        createOptions(id, quantidade)
    }
    function onBlurReferencia() {
        updateReferencias(selected?.referencia, id, selected?.selected?.id)
    }
    function handleFileChange(event) {
        const file = event.target.files[0];
        if (file) {
            const fileReader = new FileReader();
            fileReader.onload = async (e) => {
                const newFile = {
                    tipo: file.type,
                    nome: file.name,
                    arquivo: e.target.result,
                };
                updateModalInfo(true, false, 'Salvando', 'Buscando link para o arquivo', 'loading')
                const getLink = await MAPI('fluxo/saveMidia', 'POST', newFile, userToken)
                if (getLink?.status !== 200) {
                    const message = getLink?.apiReturn?.message ? getLink?.apiReturn?.message : 'Ocorreu um erro ao processar o arquivo.'
                    updateModalInfo(true, true, 'Atenção', message, 'exclamation')
                    return
                }
                updateModalInfo(false)
                newFile.arquivo = getLink?.apiReturn?.apiReturn
                const filter = selected
                filter.midia = newFile
                thisData.function({ ...selected, selected: filter }, 'selected')
            };
            fileReader.readAsDataURL(file);
        }
    }
    const titleStyle = {
        color: theme.palette.text.primary,
        fontWeight: 'bold',
        fontSize: 12
    }
    const options = [
        { id: 1, nome: 'Texto Simples', mensagem: '' },
        {
            id: 2, nome: 'Perguntas e respostas', selected: null, referencia: '', options: [
                { id: 1, nome: 'Nome' },
                { id: 2, nome: 'Nome Completo' },
                { id: 3, nome: 'CPF' },
                { id: 4, nome: 'CNPJ' },
                { id: 5, nome: 'E-mail' },
                { id: 8, nome: 'Outro' },
                { id: 6, nome: 'Sim/Não' },
                { id: 7, nome: 'Numeração', quantidade: 2, perguntas: [] }
            ]
        },
        { id: 3, nome: 'Mídia', mensagem: '', midia: null }
    ];

    return (
        <BaseBlock title={'Enviar mensagem'} subtitle={''} data={data} id={id} width={350}>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, paddingTop: 2, background: theme.palette.background.default, height: '100%', width: '100%' }}
            >
                <Typography sx={titleStyle}>Qual o tipo de mansagem?</Typography>
                <MenuFluxo newTheme={theme} options={options} param={'nome'} handleChange={handleChange} selected={selected} />
                <Collapse in={selected?.id === 1}>
                    <CMultiTextField newTheme={theme} referencias={referencias} isVariable prevData={selected} value={thisData?.mensagem ? thisData.mensagem : ''} onChangeText={handleMessage} />
                </Collapse>
                <Collapse in={selected?.id === 2}>
                    <Box sx={{ marginBottom: 2 }}>
                        <Typography sx={titleStyle}>Qual sua mensagem?</Typography>
                        <CMultiTextField newTheme={theme} referencias={referencias} isVariable minRows={3} maxRows={3} prevData={thisData} value={thisData.mensagem} onChangeText={handleMessage} />
                    </Box>
                    <Typography sx={titleStyle}>Qual o tipo de resposta esperado?</Typography>
                    <MenuFluxo newTheme={theme} options={options[1].options} param={'nome'} handleChange={handleChangeOptionsID2} selected={selected?.selected} />
                    <Collapse in={selected?.selected?.id === 7}>
                        <Box sx={{ marginTop: 2 }}>
                            <Typography sx={titleStyle}>Quantas respostas você espera?</Typography>
                            <CTextField newTheme={theme} referencias={referencias} value={selected?.selected?.quantidade} onChangeText={handleQuantidade} onBlur={handleBlurQuantidade} />
                        </Box>
                    </Collapse>
                    <Collapse in={selected?.selected?.id === 8 ? true : false}>
                        <Box sx={{ marginTop: 2 }}>
                            <Typography sx={titleStyle}>Defina uma referência para ser usada. (opcional)</Typography>
                            <CTextField newTheme={theme} onBlur={onBlurReferencia} label={'[[Referência]]'} minRows={1} maxRows={1} prevData={selected} value={selected?.referencia ? selected?.referencia : ''} onChangeText={handleChangeRefID2} />
                        </Box>
                    </Collapse>
                </Collapse>
                <Collapse in={selected?.id === 3}>
                    <Box sx={{}}>
                        {selected?.selected?.midia &&
                            <Typography sx={{ color: theme.palette.text.primary }}>{selected?.selected?.midia?.nome}</Typography>
                        }
                        <Button variant="contained" component="label">
                            <input
                                type="file"
                                hidden
                                onChange={handleFileChange}
                            />
                            Nova Mídia
                        </Button>
                    </Box>
                </Collapse>
            </Box>
            <CustomHandle id={'k'} type={'source'} position={'Right'} />
            <CustomHandle id={'k'} type={'target'} position={'Left'} />
        </BaseBlock >
    )
}
export function Yes({ id, data }) {
    return (
        <BaseBlock title={''} subtitle={'Sim'} data={data} id={id} width={70}>
            <CustomHandle id={'k'} type={'source'} position={'Right'} />
            <CustomHandle id={'k'} type={'target'} position={'Left'} />
        </BaseBlock>
    )
}
export function No({ id, data }) {
    return (
        <BaseBlock title={''} subtitle={'Não'} data={data} id={id} width={70}>
            <CustomHandle id={'k'} type={'source'} position={'Right'} />
            <CustomHandle id={'k'} type={'target'} position={'Left'} />
        </BaseBlock>
    )
}
export function Options({ id, data, referencias }) {
    const thisData = data.envOptionMsn
    const theme = useTheme()
    function handleChangeText(value) {
        thisData.function(value, 'mensagem')
    }
    return (
        <BaseBlock title={`Opção ${data.option} - `} subtitle={''} data={data} id={id} width={300}>
            <CMultiTextField newTheme={theme} value={thisData.mensagem} minRows={2} maxRows={2} referencias={referencias} isVariable onChangeText={handleChangeText} />
            <CustomHandle id={'k'} type={'source'} position={'Right'} />
            <CustomHandle id={'k'} type={'target'} position={'Left'} />
        </BaseBlock>
    )
}
export function Condicao({ id, data, createIfElse, tags, getTags }) {
    const theme = useTheme()
    const thisData = data.condicao
    const selected = thisData.selected
    const titleStyle = {
        color: theme.palette.text.primary,
        fontWeight: 'bold',
        fontSize: 12
    }
    function handleChange(value, type) {
        const filter = options.filter((_, idx) => idx === value)
        thisData.function(filter[0], 'selected')
        if (!thisData.IfElse) {
            createIfElse(id)
            thisData.function(true, 'IfElse')
        }
    }
    function handleTag(value) {
        const filter = tags.filter((_, idx) => idx === value)
        thisData.function(filter[0], 'tag')
    }
    const options = [
        { id: 1, nome: 'É contato?' },
        { id: 2, nome: 'É um lead?' },
        { id: 3, nome: 'É uma reentrada?' },
        { id: 4, nome: 'Possui tag?' },
    ]
    useEffect(() => {
        if (selected?.id === 4 && tags === null) {
            getTags()
        }
    }, [selected])
    return (
        <BaseBlock title={'Condições'} subtitle={''} id={id} width={350} data={data}>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, paddingTop: 2, background: theme.palette.background.default, height: '100%', width: '100%' }}
            >
                <Typography sx={titleStyle}>Qual a condição?</Typography>
                <MenuFluxo newTheme={theme} options={options} param={'nome'} handleChange={handleChange} selected={selected} />
                <Collapse in={selected?.id === 4}>
                    <Typography sx={titleStyle}>Selecione uma tag</Typography>
                    {tags === null ?
                        <Box>{getIcon(25, theme.palette.primary.main, 10)}</Box>
                        : tags === false ?
                            <Box sx={{ padding: 2, background: theme.palette.secondary.main }}><Typography sx={{ color: theme.palette.text.onPrimary }}>Ocorreu um erro ao carregar suas tags</Typography></Box> :
                            <MenuFluxo newTheme={theme} options={tags} param={'nome'} handleChange={handleTag} selected={thisData.tag} />
                    }
                </Collapse>
            </Box>
            <CustomHandle id={'k'} type={'source'} position={'Right'} />
            <CustomHandle id={'k'} type={'target'} position={'Left'} />
        </BaseBlock>
    )
}
export function IfElse({ id, data }) {
    return (
        <BaseBlock title={`${data.option}`} subtitle={''} data={data} id={id} width={100}>
            <CustomHandle id={'k'} type={'source'} position={'Right'} />
            <CustomHandle id={'k'} type={'target'} position={'Left'} />
        </BaseBlock>
    )
}
export function NewContato({ id, data }) {
    return (
        <BaseBlock title={'Tornar contato'} subtitle={'Torne essa entrada um usuário.'} data={data} id={id} width={250}>
            <CustomHandle id={'k'} type={'source'} position={'Right'} />
            <CustomHandle id={'k'} type={'target'} position={'Left'} />
        </BaseBlock>
    )
}
export function NewLead({ id, data }) {
    return (
        <BaseBlock title={'Tornar lead'} subtitle={'Torne essa entrada um lead.'} data={data} id={id} width={250}>
            <CustomHandle id={'k'} type={'source'} position={'Right'} />
            <CustomHandle id={'k'} type={'target'} position={'Left'} />
        </BaseBlock>
    )
}
export function Relogio({ id, data }) {
    const theme = useTheme()
    const thisData = data?.relogio
    const selected = thisData?.selected
    const options = [
        { id: 1, nome: 'Segundos' },
        { id: 2, nome: 'Minutos' },
        { id: 3, nome: 'Horas' },
        { id: 4, nome: 'Dias' }
    ];
    function handleTempo(value) {
        let tempo = onlyInteger(value)
        if (tempo > 60) return
        if (tempo < 1) {
            tempo = 1
        }
        thisData.function(tempo, 'tempo')
    }
    function handleChange(value, type) {
        const filter = options.filter((_, idx) => idx === value)
        console.log(filter)
        thisData.function(filter[0], 'selected')
    }
    const titleStyle = {
        color: theme.palette.text.primary,
        fontWeight: 'bold',
        fontSize: 12
    }
    return (
        <BaseBlock title={'Relógio'} subtitle={''} data={data} id={id} width={250}>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, paddingTop: 2, background: theme.palette.background.default, height: '100%', width: '100%' }}
            >
                <Typography sx={titleStyle}>Quanto tempo você deseja aguardar?</Typography>
                <CTextField label={'Tempo'} newTheme={theme} value={thisData?.tempo} onChangeText={handleTempo} />
                <MenuFluxo newTheme={theme} options={options} param={'nome'} handleChange={handleChange} selected={selected} />
            </Box>
            <CustomHandle id={'k'} type={'source'} position={'Right'} />
            <CustomHandle id={'k'} type={'target'} position={'Left'} />
        </BaseBlock>
    )
}

export function InitChamado({ id, data, getResponsaveis, responsaveis }) {
    const theme = useTheme()
    const thisData = data?.initChamado
    const tipos = responsaveis?.tipos
    const operadores = responsaveis?.operadores
    const setores = responsaveis?.setores
    const selectedType = thisData?.tipos?.id === 2 ? 'setores' : 'operadores'
    const selectedWData = thisData?.tipos?.id === 2 ? setores : operadores
    function handleChange(value, type) {
        const filter = responsaveis[type].filter((_, idx) => idx === value)
        type = type === 'tipos' ? type : 'selected'
        if (type === 'tipos') {
            thisData.function(null, 'selected')
        }
        thisData.function(filter[0], type)
    }
    const titleStyle = {
        color: theme.palette.text.primary,
        fontWeight: 'bold',
        fontSize: 12
    }
    useEffect(() => {
        if (responsaveis === null && id) {
            getResponsaveis()
        }
    }, [responsaveis, id])
    return (
        <BaseBlock title={'Iniciar Chamado'} subtitle={''} data={data} id={id} width={300}>
            <>
                {responsaveis === null ?
                    <Box>{getIcon(25, theme.palette.primary.main, 10)}</Box>
                    : responsaveis === false ?
                        <Box sx={{ padding: 2, background: theme.palette.secondary.main }}><Typography sx={{ color: theme.palette.text.onPrimary }}>Ocorreu um erro ao carregar seus modelos</Typography></Box> :
                        !tipos ? <Box sx={{ padding: 2, background: theme.palette.secondary.main }}><Typography sx={{ color: theme.palette.text.onPrimary }}>Não foi encontrado responsáveis</Typography></Box> :
                            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3, marginTop: 3, }}>
                                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                                    <Typography sx={titleStyle}>Escolha um tipo</Typography>
                                    <MenuFluxo newTheme={theme} title={'tipos'} disabled={false} options={tipos} param={'nome'} handleChange={handleChange} selected={thisData?.tipos} />
                                </Box>
                                <Collapse in={[2, 3].includes(thisData?.tipos?.id)}>
                                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                                        <Typography sx={titleStyle}>Escolha o atendimento</Typography>
                                        <MenuFluxo newTheme={theme} title={selectedType} disabled={false} options={selectedWData} param={'nome'} handleChange={handleChange} selected={thisData?.selected} />
                                    </Box>
                                </Collapse>
                                {/* <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                                    <Typography sx={titleStyle}>Escolha seu e-mail</Typography>
                                    <MenuFluxo newTheme={theme} title={'modelos'} disabled={false} options={responsaveis.modelos} param={'nome'} handleChange={handleChange} selected={selected?.modelos} />
                                </Box> */}
                            </Box>
                }
                <CustomHandle id={'k'} type={'source'} position={'Right'} />
                <CustomHandle id={'k'} type={'target'} position={'Left'} />
            </>
        </BaseBlock >
    )
}