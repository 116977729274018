import React, { useState, useContext } from 'react';
import { useTheme, Box, CircularProgress } from '@mui/material';
import Logo from '../inc/images/logo_w.png'
import LogoD from '../inc/images/logo_wb.png'

export function SplashScreen() {
    const theme = useTheme();
    return (
        <Box sx={{
            flex: 1,
            background: theme.palette.background.default,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 10
        }}>
            <img src={theme.palette.mode === 'dark' ? LogoD : Logo} style={{ objectFit: 'contain', width: 300 }} />
            <CircularProgress sx={{ color: theme.palette.mode === 'dark' ? theme.palette.text.primary : theme.palette.primary.main }} />
        </Box>
    );
}